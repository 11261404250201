import root_url from './../components/api_url';
import {SavedSolvedSamplePaper} from './SavedSolvedSamplePaper'
import {CallingOptions} from './CallingOptions'

export  function  CreatingPracticeQuestionDetail (question_id,testType)  {
    let auth = "Token " + localStorage.getItem('key');
    let url = root_url + 'api/utils/practice_question_details/';
    let practice = localStorage.getItem('practice_id');
    let tempDate = new Date();
    let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
    let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
    fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth,
        },
        body: JSON.stringify({
            "is_correct": false,
            "question_sequence": count,
            "ques_start_timestamp": start_time,
            "ques_end_timestamp": null,
            "choice_selected": null,
            "practice": practice,
            "question": question_id

        })
    }).then(response => {
        if (response.status === 201) {
            return response.json();
        } else {
            return false;
        }
    }).then(response => {
        let practice_details = response.id;
        localStorage.setItem('practice_question_details_id', practice_details);
        if(testType=="sample_paper_test"){
            let SamplePaperId=localStorage.getItem('samplePaperID')
            let samplePaperName=localStorage.getItem('samplePaperName')
            let practiceTestId=practice
            let startTime=start_time
            let productInfo=localStorage.getItem('samplePaperProdInfo')
            let user=localStorage.getItem('mobile');
            SavedSolvedSamplePaper(user,SamplePaperId,samplePaperName,practiceTestId,startTime,productInfo);
        }
        if(testType=="timed_test"){
            CallingOptions(question_id);
        }
    })
}
