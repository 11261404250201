import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import { useEffect, useState } from 'react';
import { Tab, Tabs, makeStyles, } from "@material-ui/core";
import { useTheme } from '@mui/material/styles';
import { isMobileOnly } from 'mobile-device-detect';
import SamplePaperDetail from '../SamplePaperDetail';
import Loader from '../Loader'
import QuestionCard from '../QuestionCard';
import ButtonMob from '../TopBottomBar/BurttonMob';


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '10px',
    fontSize: '12px',
    textTransform: "none",
    color: "black",
    
  },
  selected: {
    background: "#747AF9",
    color: "white",
    border: '1px solid #747AF90',
    borderRadius: '90px',
    height: '30px',
    fontSize: '12px',
  },
  selectedDesktop: {
    height: '30px',
    fontSize: '12px',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabComponent(props) {
  const { labels, componentData, componentName,tabIndex} = props;
  const theme = useTheme();
  const classes = useStyles();
  const userGoalLang = props.userGoalLang
  const [value, setValue] = useState(0);
  const [allSamplePapers, setAllSamplePapers] = useState([]);
  useEffect(() => {
   if(tabIndex)
   { 
    setValue(parseInt(tabIndex));
    }
  }, [tabIndex]);
  
const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  let tabPanels = [];
  for (let i = 0; i < componentData.length; i++) {
    let tabPanelData = componentData[i];
    tabPanels.push(
      <TabPanel value={value} index={i} dir={theme.direction}>
        <div className='row justify-content-between pr-lg-5 pl-lg-5'>
          {
            tabPanelData.length > 0 ? tabPanelData.map((item, index) => (
              componentName == "SamplePapersQuestions" ?
                <QuestionCard index={item.question_index} reviewQuestion={props.reviewQuestion} id={item.question_id} question_topic={item.question_topic} question_content={item.question_content} renderNextQuestion={props.renderNextQuestion} QuestionId={props.QuestionId} question_id={item.question_id} /> :
                <SamplePaperDetail samplePaper={item} index={parseInt((item.assignment_code).slice(-1)) + 1} key={index} />
            )
            ) : null
          }
        </div>
      </TabPanel>)
  }

  return (
    <>
      <Box sx={{ borderBottom: isMobileOnly ? 0 : 1, borderColor: 'divider',width:props.centered?'100%':'70%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isMobileOnly ? 'scrollable' : 'standard'}
          scrollButtons="on"
          style={{ minHeight: '10px' }}
          classes={classes}
          centered={props.centered}
          TabIndicatorProps={{ style: { background: isMobileOnly ? 'None' : '',alignContent:'center',margin:'auto' } }}
          indicatorColor="primary"
          
        >:
        { 
            labels.map((label, index) => (
              <Tab label={label} {...a11yProps(index)} classes={{
                selected: isMobileOnly ? classes.selected : classes.selectedDesktop, root: classes.root
              }} />
            ))
          }
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabPanels}
      </SwipeableViews>
    </>
  );
}
