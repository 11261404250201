import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import Loader from '../Loader';


export default function NeedStrengthComponent(props) {
    useEffect(() => {

    }, [])

    return (
        <>
            <div className="row pt-5 lst-five-mob st-mob">
                <div className="col">
                    <h3 className="text-dark ml-n1">{props.heading}</h3>
                </div>
            </div>
            {props.isLoader ? <div className={`${props.heading == "Strengths" ? 'pt-1' : 'pt-4'} pb-3`}><Loader color="primary" height="60px" width="60px" divHeight="h-100" /></div> :
                <div className="last-five-gr ml-n4  pl-0 pb-5 mt-3" id="result_mob_data">
                    <ul>
                        {props.tabledata} 
                    </ul>
                    {props.onlyDataFive.length == props.allPracticeData.length ? null : <div className="show-more d-flex justify-content-center shadow-sm ml-2 pt-2 pb-2" onClick={() => { props.showMore(props.heading) }}>Show More</div>}
                    {props.onlyDataFive.length <= 5 ? null : <div className="show-more d-flex justify-content-center shadow-sm ml-2 pt-2 pb-2" onClick={() => { props.showLess(props.heading) }}>Show Less</div>}
                </div>}
        </>

    )
}
