import React, { Component } from 'react';
import root_url from './../../components/api_url';

export class PausePractie extends Component {
    constructor() {
        super();
        this.resumePractice = this.resumePractice.bind(this);
    }
    resumePractice(e){
       e.preventDefault();
       localStorage.setItem('is_paused', 'false');
        let auth = "Token "+localStorage.getItem('key');
        let url = root_url+'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();

        fetch(url, {
            method: 'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },
            body:JSON.stringify({
               "action_name": 'resume',
               "action_id": "resume"+practice_id,
               "action_timestamp": start_time,
               "practice_question_detail": practice_id

            })
        }).then(response=>{
            if(response.status===201){
                return response.json();
            }
            else{
                return false;
            }
        }).then(response=>{
           console.log(response);
            let timer_url = root_url + 'api/utils/practice_timer_perform_update/'+practice_id+'/';
           fetch(timer_url, {
               method: 'PATCH',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },
            body:JSON.stringify({
                  "timer_resume": start_time,
            })
            })
        })
    }
    render() {  
        return (
            <div className="container share-mod share-mod2 background-blur">
                <div className="modal" id="myModal2" onClick={this.resumePractice}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal">
                            <img src={require("./../../../assests/img/cross.jpg")} />
                            </button>
                            <div className="pop2-img"><img src={require("./../../../assests/img/pop-img.jpg")} /></div>
                            <div className="row pt-3">
                                <div className="col">
                                    <div className="divh3">
                                        <h3>Your practice has been paused.
                                        Please try to come back soon and resume practice 
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <div className="common-btn" data-dismiss="modal"><button onClick={this.resumePractice}>Resume</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        )
    }
}

export default PausePractie