import React, { Component } from 'react';
import OTPComponent from '../components/OTPComponent';
import root_url from './../components/api_url';
import Loader from '../components/Loader';
import { withRouter } from "react-router";

export class EnterotpEkal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            counter: 0,
            mobile: '',
            count: 30,
            otperrorMessage: '',
            successMessage: '',
            user_type: '',
            isLoader: false,
            submiterrorMessage: '',
        }
        this.Submit = this.Submit.bind(this)
        this.resendOTP = this.resendOTP.bind(this);
        this.countdown = this.countdown.bind(this);
        this.otpChange = this.otpChange.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.userVerify = this.userVerify.bind(this);
    }
    otpChange(given_otp) {
        this.setState({ otp: given_otp });
    }
    Submit(e) {
        e.preventDefault();
        this.setState({ successMessage: '', otperrorMessage: '' })
        let otp = this.state.otp;
        let mobile = this.state.mobile;
        if (otp === '') {
            this.setState({ otperrorMessage: 'Please enter otp' })
        }
        else if (otp.length != 6) {
            this.setState({ otperrorMessage: 'Please filled all otp numbers' })
        }
        else {
            this.verifyOTP()
        }
    }

    resendOTP() {
        let resend_hit = localStorage.getItem('confirm_password_resend_otp_hit');
        localStorage.setItem('confirm_password_resend_otp_hit', parseInt(resend_hit) + 1);
        let mobile = this.state.mobile ? this.state.mobile : localStorage.getItem('UserPhone');
        let user_type = this.state.user_type ? this.state.user_type : localStorage.getItem('user_type');
        this.setState({ counter: 30 });
        this.setState({ count: 30 });
        this.countdown();
        let url = root_url + `resend_mobile_otp/${mobile}/${user_type}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response.status == 200) {
                this.setState({ successMessage: 'OTP sent your mobile', otperrorMessage: '' })
            }
            if (response.status == 400) {
                this.setState({ successMessage: '', otperrorMessage: 'Please create account' })
            }
        })
    }

    countdown() {
        let myInterval = setInterval(() => {
            if (this.state.count > 0) {
                this.setState({ counter: this.state.counter - 1 })
                this.state.count = this.state.count - 1;
            }
            if (this.state.count == 0) {
                clearInterval(myInterval);
            }
        }, 1000)
    }

    componentDidMount() {
        localStorage.setItem('confirm_password_resend_otp_hit', 0);
        const query = new URLSearchParams(this.props.location.search);
        const mobile = query.get('mobile');
        const user_type = query.get('user_type');
        this.setState({ successMessage: 'OTP sent your mobile', user_type: user_type, })
        if (mobile) {
            this.setState({ mobile: mobile })
        }
    }

    verifyOTP() {
        let given_otp = this.state.otp;
        let mobile = this.state.mobile;
        let user_type=this.state.user_type;
        this.setState({ otperrorMessage: '', successMessage: '', isLoader: true })
        let url = root_url + `verify_mobile_otp/${mobile}/${given_otp}/${user_type}/`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 201) {
                this.userVerify(mobile);
            }
            if (response.status === 400) {
                this.setState({ otperrorMessage: response.message, isLoader: false });
            }
        })
    }

    userVerify(given_mobile) {
        let mobile = given_mobile;
        let url = root_url + `user_verifying/${mobile}/`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 201) {
                //enter here paymnet page redirect link because this location giving  user email verify success response
                // document.location.href = document.location.origin + `/ekal-plans`;
                this.loginHandler();
            }
            if (response.status === 400) {
                this.setState({ otperrorMessage: response.message, isLoader: false });
            }
        })
    }

        // user login 
        loginHandler() {
            let username = localStorage.getItem('user_username');
            let password = localStorage.getItem('user_pass');
            let url = root_url + 'api/accounts/login/';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                })
            }).then(response => {
                if (response.status === 400) {
                    return response.json();
                }
                else if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.email) {
                    this.setState({ isLoader: false });
                    this.setState({ submiterrorMessage: response.email[0] });
                }
                else if (response.key) {
                    let key = response.key;
                    localStorage.setItem('key', key);
                    let url1 = root_url + 'api/profile/profile/';
                    let auth = "Token " + localStorage.getItem('key')
                    fetch(url1, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth
                        },
    
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        let email = response.results[0].user.email;
                        let id = response.results[0].user.id;
                        localStorage.setItem('user_id', id);
                        localStorage.setItem('is_timed', 'true');
                        localStorage.setItem('email', email);
                    })
                    document.location.href = document.location.origin + '/dashboard';
                }
                else {
                    this.setState({
                        submiterrorMessage: response.non_field_errors
                    })
                    this.setState({ isLoader: false });
                }
            })
        }


    render() {
        return (
            <div>
                <section class="forgot-pass">
                    <div class="container pl-0 pr-0 w-100">
                        <div class="col-md-5 m-auto">
                            <div class="forgot-p">
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divh3"><h3>New User</h3></div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center pt-5" style={{ height: '50px' }}>
                                    {this.state.counter != 0 ? <div className=" text-success ">Resend OTP in 00:{this.state.counter < 10 ? '0' + this.state.counter : this.state.counter}</div> : <div className=" text-primary"></div>}
                                </div>
                                <div className="row d-flex justify-content-between  pl-1 pr-1 mt-5">
                                    <div className="text-secondary pl-3"><h6>OTP</h6></div>
                                    {this.state.counter != 0 || localStorage.getItem('confirm_password_resend_otp_hit') > 2 ?
                                        <div className="text-secondary pr-3" style={{ cursor: 'not-allowed' }}><h6>Resend OTP</h6></div>
                                        : <div className="text-primary pr-3"  ><h6 style={{ cursor: 'pointer' }} onClick={this.resendOTP}>Resend OTP</h6></div>}
                                </div>
                                <div className="row otp-sec center pl-1 " style={{ marginTop: '0px' }}>
                                    <div className="col">
                                        <div className="otp ">
                                            <OTPComponent otpChange={this.otpChange} />
                                        </div>
                                        <div className="d-flex justify-content-start mt-2"><h6> {this.state.otperrorMessage && <span className="error"> {this.state.otperrorMessage} </span>}</h6></div>
                                        <div className="d-flex justify-content-start mt-2">{this.state.successMessage && <span className="success"> {this.state.successMessage} </span>}</div>
                                    </div>
                                </div>
                                <div class="row sign-in pt-5 mt-5">
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader color="primary" height="50px" width="50px" /></div>}
                                    <div class="col-md-12">
                                        <div class="common-btn pt-5"><button onClick={this.Submit}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 height-10 ">
                            {this.state.submiterrorMessage && <span className="error ml-2"> {this.state.submiterrorMessage} </span>}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(EnterotpEkal);