import React, { Component } from 'react';
import root_url from './../components/api_url';
import { useEffect, useState } from 'react';
import EndPracticeMobile from './EndPracticeMobile';
import { isMobileOnly } from 'mobile-device-detect';
import EndPracticeDesktop from './EndPracticeDesktop';
import { useHistory } from "react-router-dom";


export default function EndPracticeReview() {
    const history = useHistory();
    const [score, setScore] = useState("");
    const [solve, setSolve] = useState("");
    const [accuracy, setAccuracy] = useState("");
    const [totalTime, setTotalTime] = useState("");
    const [message, setMessage] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [showReview, setShowReview] = useState(false);
    useEffect(() => {
        getPracticeDetail();
    }, [])
    const getPracticeDetail = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const practiceId = params.get('practiceId') ? params.get('practiceId') : localStorage.getItem('practice_id');
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/utils/end_practice/?practice_id=' + practiceId;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response && response.data[0]) {
                setScore(response.data[0].score);
                setSolve(response.data[0].solved);
                setAccuracy(response.data[0].accuracy);
                setMessage(response.data[0].message_option);
                setMinutes(response.data[0].minutes);
                setSeconds(response.data[0].seconds);
                setTotalTime(response.data[0].total_time);
                setShowReview(true);
                localStorage.setItem('percentage', response.data[0].accuracy)
                localStorage.setItem('total_score', response.data[0].score)
                localStorage.setItem('solved', response.data[0].solved)
                localStorage.setItem('total', response.data[0].total)
                localStorage.setItem('question_time_difference', response.data[0].speed)
                localStorage.setItem('end_minutes', response.data[0].minutes)
                localStorage.setItem('end_seconds', response.data[0].seconds)
                localStorage.setItem('msg', response.data[0].message_option)
                localStorage.setItem('total_time', response.data[0].total_time)
                localStorage.setItem('practice_id',practiceId)
            }
        });
    }

    const reviewPage = () => {
       history.push(`/review/`);
    }
    return (
        <>
            {
                isMobileOnly ? <EndPracticeMobile showReview={showReview} solved={solve} score={score} accuracy={accuracy} totalTime={totalTime} minutes={minutes} seconds={seconds} message={message} reviewPage={reviewPage} />
                    : <EndPracticeDesktop showReview={showReview} solved={solve} score={score} accuracy={accuracy} totalTime={totalTime} minutes={minutes} seconds={seconds} message={message} reviewPage={reviewPage} />
            }
        </>
    );
}
