import React from 'react';
import { isMobileOnly } from 'mobile-device-detect';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import TopicNameComponent from './TopicNameComponent'

export default function AllTopicsMobile(props) {
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [allTopicsName, setAllTopicsName] = useState([]);
  const [allTopicsID, setAllTopicsID] = useState([]);
  const [disabledBeginPractice, setDisabledBeginPractice] = useState(true);
  useEffect(() => {
    let arr = []
    localStorage.setItem('topic_selected', JSON.stringify(arr));
    localStorage.setItem("topic_name", JSON.stringify(arr));
    let given_topic_data = props.topic_dropdown;
    let all_topic_names = ["all"]
    let all_topic_id = []
    for (let i = 0; i < given_topic_data.length; i++) {
      all_topic_names.push(given_topic_data[i].label);
      all_topic_id.push(String(given_topic_data[i].value));
    }
    setAllTopicsID(all_topic_id);
    setAllTopicsName(all_topic_names);
    setAllTopics(props.topic_dropdown);
  }, [])

  const topicChange = (e) => {
    let topic_id_array = JSON.parse(localStorage.getItem("topic_selected"));
    let topic_name_array = JSON.parse(localStorage.getItem("topic_name"));
    if (e.target.checked) {
      if (!topic_id_array.includes(e.target.value)) {
        topic_id_array.push(e.target.value);
        topic_name_array.push(e.target.name);
        localStorage.setItem('topic_selected', JSON.stringify(topic_id_array));
        localStorage.setItem('topic_name', JSON.stringify(topic_name_array));
        setSelectedTopics([...selectedTopics, e.target.name]);
        changeBeginPracticeButtonStatus(topic_id_array);
        if (isMobileOnly) {
          props.changeBeginPracticeStatus(topic_id_array);
        }
      }
    }
    else {
      if (topic_id_array.includes(e.target.value)) {
        let given_array = selectedTopics
        let new_array = given_array.filter(v => v !== e.target.name)
        let new_topic_id_array = topic_id_array.filter(v => v !== e.target.value)
        let new_topic_name_array = topic_name_array.filter(v => v !== e.target.name)
        if (new_array.includes("all")) {
          new_array = new_array.filter(v => v !== 'all')
          new_topic_name_array = new_topic_name_array.filter(v => v !== 'all')
        }
        localStorage.setItem('topic_selected', JSON.stringify(new_topic_id_array));
        localStorage.setItem('topic_name', JSON.stringify(new_topic_name_array));
        setSelectedTopics(new_array);
        changeBeginPracticeButtonStatus(new_topic_id_array);
        if (isMobileOnly) {
          props.changeBeginPracticeStatus(new_topic_id_array);
        }

      }
    }
  }

  const allTopicChange = (e) => {
    setSelectedTopics([]);
    if (e.target.checked) {
      setSelectedTopics(allTopicsName);
      localStorage.setItem('topic_selected', JSON.stringify(allTopicsID));
      localStorage.setItem('topic_name', JSON.stringify(allTopicsName));
      changeBeginPracticeButtonStatus(allTopicsID);
      if (isMobileOnly) {
        props.changeBeginPracticeStatus(allTopicsID)
      }
    }
    else {
      let delete_all_id = []
      setSelectedTopics([]);
      localStorage.setItem('topic_selected', JSON.stringify(delete_all_id));
      localStorage.setItem('topic_name', JSON.stringify(delete_all_id));
      changeBeginPracticeButtonStatus(delete_all_id)
      if (isMobileOnly) {
        props.changeBeginPracticeStatus(delete_all_id);
      }
    }
  }

  const changeBeginPracticeButtonStatus = (given_array) => {
    if (given_array.length > 0) {
      setDisabledBeginPractice(false);
    }
    else {
      setDisabledBeginPractice(true);
    }
  }


  return (
    <>
      <div className="border shadow-sm rcorners1 mt-2">
        <div className="">
          <div className="w-100  d-flex justify-content-between border-bottom pt-1">
            <div className="pl-3 ">
              <h6 className="text-dark mt-2">Select All</h6>
            </div>
            <div className="pr-3 mt-1">
              <FormControlLabel
                value="all"
                control={<Checkbox color="primary" />}
                labelPlacement="start"
                id="all"
                name="all"
                onChange={allTopicChange}
                checked={selectedTopics.includes('all')}
              />
            </div>
          </div>
          {allTopics.map((topic, index) =>
            <>
              {"totalQuestions" in topic && topic.totalQuestions > 0 ?
                <TopicNameComponent topic={topic} selectedTopics={selectedTopics} topicChange={topicChange} totalQuestions={topic.totalQuestions} allTopicChange={allTopicChange} /> :
                "totalQuestions" in topic && topic.totalQuestions == 0 ? null :
                  <TopicNameComponent topic={topic} selectedTopics={selectedTopics} topicChange={topicChange} allTopicChange={allTopicChange} />}
            </>
          )}
          {!isMobileOnly ?
            <div className="w-100 d-flex justify-content-center border-bottom pt-4 pb-4">
            <Button 
              style={{
                borderRadius: 28,
                backgroundColor: disabledBeginPractice?"rgba(0, 0, 0, 0.12)":"#5d39db",
                textTransform: "capitalize",
                color:"white",
                paddingTop:8,
                paddingRight:15,
                paddingLeft:15,
                paddingBottom:8,
                }} 
                data-toggle="modal" data-target="#myModal2"  onClick={props.selectedTopicsSaved} disabled={disabledBeginPractice}>Begin Practice</Button>
            </div> : null}
        </div>
      </div>
    </>
  );
}
