import React from 'react';
import LeftSideBar from './LeftSideBar';
import MainbodyPanel from './MainbodyPanel';
import Dash from './../reusecomponent/dash';

export default function SamplePaperDesktop() {
    return (
        <>
            <div className="d-flex flex-column w-100" style={{ height: '100vh' }} >
                <Dash />
                <div className='d-flex'>
                    <div className='h-100'><LeftSideBar activeTab="Assignments" toggled={true} /></div>
                    <div className='h-100 w-100 mt-5'> <MainbodyPanel childComponent="SamplePapers" /></div>
                </div>
            </div>
        </>
    )

}

