import React, { Component } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './../../../assests/js/progress.js';
import $ from 'jquery'
import jQuery from 'jquery'
import root_url from "../api_url";
import { PieChart } from 'react-minimal-pie-chart';
import Moment from 'react-moment';
import Select from "react-select";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loader from 'react-loader-spinner';
import Bottombar from '../TopBottomBar/Bottombar'
import { isMobileOnly } from 'mobile-device-detect';
import AccuracyPieChartContainMob from './AccuracyPieChartContainMob.js';
import Tooltip from '@mui/material/Tooltip';
import InformationTooltip from './InformationTooltip'
import { withRouter } from 'react-router-dom';
import TabsSlider from 'tabs-slider';
import NeedAndPracticeTab from './NeedAndPracticeTab.js';




class PerformanceTab extends React.Component {
    constructor() {
        super();
        this.state = {
            options: {
                loop: false,
                margin: 10,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true
                    },
                    768: {
                        items: 5,
                        nav: false
                    },
                    1100: {
                        items: 5,
                        nav: false,

                    },
                    1024: {
                        items: 3,
                        nav: true,
                        autoplay: true,
                    },
                },
            },
            name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
            attempted: localStorage.getItem('attempted') ? localStorage.getItem('attempted') : '',
            accuracy: localStorage.getItem('accuracy') ? localStorage.getItem('accuracy') : '',
            correct_exceeded_percentage: localStorage.getItem('correct_exceeded_percentage') ? localStorage.getItem('correct_exceeded_percentage') : '',
            correct_within_percentage: localStorage.getItem('correct_within_percentage') ? localStorage.getItem('correct_within_percentage') : '',
            incorrect_percentage: localStorage.getItem('incorrect_percentage') ? localStorage.getItem('incorrect_percentage') : '',
            performance_strength: [],
            performance_strengths: [],
            performance_subjects: [],
            last_five_test: [],
            all_test: [],
            subjectSelected: '',
            isLoader: false,

        }
        this.getProfile = this.getProfile.bind(this);
        this.getPerformanceAccuracy = this.getPerformanceAccuracy.bind(this);
        this.getPerformanceLastFiveTest = this.getPerformanceLastFiveTest.bind(this);
        this.getPerformanceStrengths = this.getPerformanceStrengths.bind(this);
        this.ReviewTopic = this.ReviewTopic.bind(this);
        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);
        this.stringTruncate = this.stringTruncate.bind(this);
        this.redirectReview = this.redirectReview.bind(this);
        this.changeSubject = subjectSelected => {
            this.setState({ subjectSelected });
            this.setState({ isLoader: true });
            if (subjectSelected.value === 'Subject All') {
                let url1 = window.undefined;
                let url2 = window.undefined;
                let url3 = window.undefined;
                this.getPerformanceAccuracy(url1);
                this.getPerformanceLastFiveTest(url2);
                
            }
            else {
                let url1 = root_url + 'api/utils/performance_accuracy/?subject=' + subjectSelected.value;
                let url2 = root_url + 'api/utils/performance_last_five_test/?subject=' + subjectSelected.value;
                let url3 = root_url + 'api/utils/performance_strength_need/?subject=' + subjectSelected.value;
                this.getPerformanceLastFiveTest(url2);
                //this.getPerformanceStrengths(url3);
                this.getPerformanceAccuracy(url1);
            }

        }
    }
    showMore() {
        let currentTestData = this.state.last_five_test;
        let currentLength = currentTestData.length;
        let updatedlength = currentLength + 5;
        let updatedTestData = this.state.all_test.slice(currentLength, updatedlength)
        this.setState({
            last_five_test: [...this.state.last_five_test, ...updatedTestData]
        });
    }
    showLess() {
        this.setState({
            last_five_test: this.state.last_five_test.slice(0, 5)
        });
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }
    redirectReview(given_practice) {
        this.props.history.push(`/result-summary?practiceId=${given_practice}`)
    }
    stringTruncate(given_topic) {
        let given_string = given_topic;
        let updated_string = ""
        if (given_string.length > 9) {
            updated_string = given_string.slice(0, 9) + '...';
        }
        else {
            updated_string = given_string
        }
        return updated_string;
    }
    handleSelect(ranges) {
        console.log(ranges);
    }
    getProfile(e) {

        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/profile/profile/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    name: response.results[0].user.first_name,
                    email: response.results[0].user.email,
                    goal: response.results[0].goal[0],
                    subject: response.results[0].subject,
                    board: response.results[0].board,
                    mobile: response.results[0].mobile,
                    country: response.results[0].country,
                })
                localStorage.setItem('name', response.results[0].user.first_name)
                this.setState({ isLoader: false });

            }
            
        });
    }

    getPerformanceAccuracy(url1) {
        this.setState({ isLoader: true });
        let auth = 'Token ' + localStorage.getItem('key');
        let url = ''
        if (url1 === undefined) {
            url = root_url + 'api/utils/performance_accuracy/';
        }
        else {
            url = url1
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({
                attempted: response.data.count,
                accuracy: response.data.accuracy,
                correct_exceeded_percentage: response.data.correct_exceeded_time,
                correct_within_percentage: response.data.correct_within_time,
                incorrect_percentage: response.data.incorrect,

            })
            localStorage.setItem('attempted', response.data.count)
            localStorage.setItem('accuracy', response.data.accuracy)
            localStorage.setItem('correct_exceeded_percentage', response.data.correct_exceeded_time)
            localStorage.setItem('correct_within_percentage', response.data.correct_within_time)
            localStorage.setItem('incorrect_percentage', response.data.incorrect)
            this.setState({ isLoader: false });
            //-----------------------------this code add for  waiting api response by PREP_PREP_1ST---------------- 
            let accuracy_card = document.getElementById("accuracy_card");
            if (accuracy_card) {
                document.getElementById("accuracy_card").style.opacity = "0.9";
                document.getElementById("acc_spiner").innerHTML = '';
                document.getElementById("accuracy_card_mob").style.opacity = "0.9";
                document.getElementById("acc_mob_data").style.opacity = "0.9";
                document.getElementById("acc_mob_spiner").innerHTML = '';

            }
        });
    }

    getPerformanceLastFiveTest(url2) {
        let auth = 'Token ' + localStorage.getItem('key');
        let url = ''
        if (url2 === undefined) {
            url = root_url + 'api/utils/performance_all_test/';
        }
        else {
            url = url2
        }
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({
                last_five_test: response.data.slice(0, 5),
                all_test: response.data
            })
            this.setState({ isLoader: false });
            let result_card = document.getElementById("result_card");
            if (result_card) {
                document.getElementById("result_card").style.opacity = "0.9";
                document.getElementById("result_spiner").innerHTML = '';
                document.getElementById("result_mob_data").style.opacity = "0.9";
                document.getElementById("result_mob_spinner").innerHTML = '';

            }
        });

    }

    componentDidMount() {
        //-----------------------------this code add for  waiting api response by PREP_PREP_1ST---------------- 
        document.getElementById("accuracy_card").style.opacity = "0";
        document.getElementById("accuracy_card_mob").style.opacity = "0";
        document.getElementById("acc_mob_data").style.opacity = "0";
        document.getElementById("result_mob_data").style.opacity = "0";
        document.getElementById("acc_spiner").innerHTML = '&nbsp;&nbsp;&nbsp;<div class="spinner-border spinner-border-sm text-info "></div>';
        document.getElementById("acc_mob_spiner").innerHTML = '&nbsp;&nbsp;&nbsp;<div class="spinner-border spinner-border-sm text-info "></div>';
        document.getElementById("result_card").style.opacity = "0";
        document.getElementById("result_spiner").innerHTML = '&nbsp;&nbsp;&nbsp;<div class="spinner-border spinner-border-sm text-info "></div>';
        document.getElementById("result_mob_spinner").innerHTML = '&nbsp;&nbsp;&nbsp;<div class="spinner-border spinner-border-sm text-info "></div>';
        this.getProfile();
        this.getPerformanceAccuracy();
        this.setState({
            name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
            attempted: localStorage.getItem('attempted') ? localStorage.getItem('attempted') : '',
            accuracy: localStorage.getItem('accuracy') ? localStorage.getItem('accuracy') : '',
            correct_exceeded_percentage: localStorage.getItem('correct_exceeded_percentage') ? localStorage.getItem('correct_exceeded_percentage') : '',
            correct_within_percentage: localStorage.getItem('correct_within_percentage') ? localStorage.getItem('correct_within_percentage') : '',
            incorrect_percentage: localStorage.getItem('incorrect_percentage') ? localStorage.getItem('incorrect_percentage') : '',
        })
}

    getPerformanceStrengths(url3) {
        let auth = 'Token ' + localStorage.getItem('key');
        let url = ''
        if (url3 === undefined) {
            url = root_url + 'api/utils/performance_strength_need/';
        }
        else {
            url = url3
        }
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({
                performance_strengths: response.data,
            })
            this.setState({ isLoader: false });

        });

    }

    ReviewTopic(practice) {
        localStorage.setItem('practice_found', practice);
        window.location.href = window.location.origin + '/performance-review';
    }

    componentWillMount() {
        let auth = 'Token ' + localStorage.getItem('key');
        let url1 = root_url + 'api/utils/subject_drop/';
        fetch(url1, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({
                performance_subjects: response.data,
            })
        });
        this.getPerformanceLastFiveTest();
        if(!isMobileOnly){
            this.getPerformanceStrengths();
        }
    }


    render() {
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }

        let subject_drop = []
        if (this.state.performance_subjects !== undefined) {
            for (let i = 0; i < this.state.performance_subjects.length; i++) {
                subject_drop.push(
                    { value: this.state.performance_subjects[i].subject, label: this.state.performance_subjects[i].subject },
                )
            }
        }
        else {
            subject_drop.push(
                { value: 'all_subject', label: 'Subject All' }
            )
        }
        const { subjectSelected } = this.state;
        let name = this.state.name;
        let goal = localStorage.getItem('goal_name')
        let attempted = this.state.attempted;
        let accuracy = this.state.accuracy ? this.state.accuracy : 0;
        let correct_exceeded_percentage = this.state.correct_exceeded_percentage ? this.state.correct_exceeded_percentage : 0;
        let correct_within_percentage = this.state.correct_within_percentage ? this.state.correct_within_percentage : 0;
        let incorrect_percentage = this.state.incorrect_percentage ? this.state.incorrect_percentage : 0;
        let strengths = []
        let need_practice = []
        let test_data = []
        let test_data1 = []
        let last_five_mob = []
        let accuracy_graph = []
        let strength_mob_data = []
        let need_practice_mob_data = []
        //this code is used to show accuracy graph results on web as wll as on mobile responsive
        if (correct_exceeded_percentage > 0 || correct_within_percentage > 0 || incorrect_percentage > 0) {
            accuracy_graph.push(<PieChart data={[
                { title: 'Correct(within time)', value: parseInt(correct_within_percentage), color: '#bcd43e' },
                { title: 'Correct(exceeded time)', value: parseInt(correct_exceeded_percentage), color: '#f7d478' },
                { title: 'incorrect time', value: parseInt(incorrect_percentage), color: '#fc7fab' },
            ]}


            />)
        }
        else {
            accuracy_graph.push(<p> Woops! You have not taken any practice test yet. Please take at-least one practice
                test to see your performance.
                <a href='/dashboard'>Click here to take a test</a>
            </p>)
        }

        //this code is used to show last five test results on web
        let last_five_test = this.state.last_five_test;
        if (last_five_test.length > 0) {
            for (let i = 0; i < last_five_test.length; i++) {
                test_data.push(
                    <div className="item">
                        <div className="result-top">
                            <ul>
                                <li>{last_five_test[i].correct_within_count}</li>
                                <li>{parseInt(last_five_test[i].correct_exceeded_count)}</li>
                                <li>{parseInt(last_five_test[i].incorrect_count)}</li>
                            </ul>
                        </div>
                        <div className="pt-4 g-center">
                            <h5>{parseInt(parseInt(last_five_test[i].correct_within_time) + parseInt(last_five_test[i].correct_exceeded_time))}%</h5>
                            <PieChart
                                data={[
                                    { title: 'Correct(within time)', value: parseInt(last_five_test[i].correct_within_time), color: '#bcd43e' },
                                    { title: 'Correct(exceeded time)', value: parseInt(last_five_test[i].correct_exceeded_time), color: '#f7d478' },
                                    { title: 'incorrect time', value: parseInt(last_five_test[i].incorrect), color: '#fc7fab' },
                                ]} lineWidth={15} />
                        </div>
                        <div className="result-tit">
                            <p>{last_five_test[i].subject}</p>
                            <h6>{last_five_test[i].subject}</h6>
                            <span><Moment format="YYYY/MM/DD">{last_five_test[i].start_date}</Moment></span>
                        </div>
                    </div>

                )
            }
        }
        else {
            test_data1.push(<p> Woops! You have not taken any practice test yet. Please take at-least one practice test to see your performance.
                <a href='/dashboard'>Click here to take a test</a>
            </p>)
        }

        //this code is used to show last five test results on mobile responsive
        if (last_five_test.length > 0) {
            for (let i = 0; i < last_five_test.length; i++) {
                last_five_mob.push(
                    <li onClick={() => { this.redirectReview(last_five_test[i].practice_id) }}>
                        <div className="mob-five-graph dib col-6 ml-n1" >
                            <h5 >{parseInt(parseInt(last_five_test[i].correct_within_time) + parseInt(last_five_test[i].correct_exceeded_time))}%</h5>
                            <PieChart
                                data={[
                                    { title: 'Correct(within time)', value: parseInt(last_five_test[i].correct_within_time), color: '#bcd43e' },
                                    { title: 'Correct(exceeded time)', value: parseInt(last_five_test[i].correct_exceeded_time), color: '#f7d478' },
                                    { title: 'incorrect time', value: parseInt(last_five_test[i].incorrect), color: '#fc7fab' },
                                ]} lineWidth={15} />
                        </div>&nbsp;&nbsp;
                        <div className=" mob-five-msg dib ml-n3 mt-2">
                            <div className="mob-five-arrow "><i className="fa fa-angle-right" aria-hidden="true"></i> </div>
                            <div className="mob-five-subj dib  col-12 p-0" >
                                <div className="multi-sub-mob d-flex justify-content-between ">
                                    <div className="multi-icon ">
                                        <h4 className="">{this.stringTruncate(last_five_test[i].type)}<InformationTooltip subject={last_five_test[i].type} /> </h4>
                                    </div>
                                    <div className="mr-n3 d-flex mt-1">
                                        <div className="score-font-heading mt-n1">Your Score:</div>
                                        <div className="score-font mt-n1">{last_five_test[i].your_score}/{last_five_test[i].maximum_score}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-n2 dib d-flex justify-content-between  " >
                                <div><h5 className="last-five-date"><Moment format="ddd Do MMM">{last_five_test[i].start_date}</Moment></h5></div>
                                <div className="mr-n3 d-flex">
                                    <div className="score-font-heading mt-n1">Avg Speed:</div>
                                    <div className="score-font mt-n1">0:{last_five_test[i].average_speed}</div>
                                </div>

                            </div>
                        </div>
                    </li>
                )
        }
        }
        else {
            last_five_mob.push(<p> Woops! You have not taken any practice test yet. Please take at-least one practice test to see your performance.
                <a href='/dashboard'>Click here to take a test</a>
            </p>)
        }

        //this code is used to show strength and need practice results on web
        let performance_strengths = this.state.performance_strengths
        if (performance_strengths.length > 0) {
            for (let i = 0; i < performance_strengths.length; i++) {
                let class_name = ''
                let prog_class_name = ''
                if (performance_strengths[i].bar_color === 'Red') {
                    class_name = 'progress-bar bar3'
                    prog_class_name = 'prog-msg2'
                }
                else if (performance_strengths[i].bar_color === 'Green') {
                    class_name = 'progress-bar bar1'
                    prog_class_name = 'prog-msg'
                }
                else {
                    class_name = 'progress-bar bar2'
                    prog_class_name = 'prog-msg1'
                }
                if (performance_strengths[i].category === 'Strength') {
                    strengths.push(
                        <tr key={performance_strengths[i]}>
                            <td>{performance_strengths[i].topic ? performance_strengths[i].topic : ''}</td>
                            <td>
                                <div className="prog-main">
                                    <div className="progress">
                                        <div className={class_name} style={{ width: performance_strengths[i].accuracy + '%' ? performance_strengths[i].accuracy + '%' : '0%' }}></div>
                                    </div>
                                    <div className={prog_class_name}>
                                        <ul>
                                            <li>{performance_strengths[i].topic ? performance_strengths[i].topic : ''} <span></span></li>
                                            <li>Solved <span>{performance_strengths[i].solved}</span></li>
                                            <li>Accuracy <span>{performance_strengths[i].accuracy + '%' ? performance_strengths[i].accuracy + '%' : '0%'}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td>{Math.floor(performance_strengths[i].speed / 60)}:{Math.round((performance_strengths[i].speed - (Math.floor(performance_strengths[i].speed / 60)) * 60))} min</td>
                            <td>NA</td>
                            <td><span onClick={() => this.ReviewTopic(performance_strengths[i].practice)}>Review</span></td>
                            <td><span>Practice</span></td>
                        </tr>)
                }
                else {
                    need_practice.push(
                        <tr key={performance_strengths[i]}>
                            <td>{performance_strengths[i].topic ? performance_strengths[i].topic : ''}</td>
                            <td>
                                <div className="prog-main">
                                    <div className="progress">
                                        <div className={class_name} style={{ width: performance_strengths[i].accuracy + '%' ? performance_strengths[i].accuracy + '%' : '0%' }}></div>
                                    </div>
                                    <div className={prog_class_name}>
                                        <ul>
                                            <li>{performance_strengths[i].topic ? performance_strengths[i].topic : ''} <span></span></li>
                                            <li>Solved <span>{performance_strengths[i].solved}</span></li>
                                            <li>Accuracy <span>{performance_strengths[i].accuracy + '%' ? performance_strengths[i].accuracy + '%' : '0%'}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td>{Math.floor(performance_strengths[i].speed / 60)}:{Math.round((performance_strengths[i].speed - (Math.floor(performance_strengths[i].speed / 60)) * 60))} min</td>
                            <td>NA</td>
                            <td><span onClick={() => this.ReviewTopic(performance_strengths[i].practice)}>Review</span></td>
                            <td><span>Practice</span></td>
                        </tr>)
                }
            }
        }

       

        // this is return method for dom
        return (
            <div id="tab1" className="tabcontent tabcon">
                <div className="row mob-design">
                    <div className="col">
                        <div className="tab-content">
                            <div id="home" className="">
                                <div className="row pt-3 mob-graph ">
                                    <div className="col-md-6">
                                        <div className="pt-4 graph-large">
                                            <div className="row">
                                                <div><h3 className="ml-2">Accuracy</h3></div>
                                                <div id="acc_mob_spiner"> </div>
                                            </div>
                                            <div className="row ">
                                                <div id="accuracy_card_mob" className="col-5 ml-n2 mt-2">
                                                    {accuracy_graph}
                                                </div>
                                                <div className="col-7 percent-show-m " id="acc_mob_data">
                                                    <ul >
                                                        <AccuracyPieChartContainMob heading="Correct" subHeading="(within time)" calculatedValue={correct_within_percentage} />
                                                        <AccuracyPieChartContainMob heading="Correct" subHeading="(exceeded time)" calculatedValue={correct_exceeded_percentage} />
                                                        <AccuracyPieChartContainMob heading="Incorrect" subHeading="" calculatedValue={incorrect_percentage} />
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="clr"></div>
                                <div className="row pt-3 lst-five-mob topfive pb-5">
                                    <div className="col ml-1">
                                        <div className="row pb-20p">
                                            <div><h3>Results | Last five tests</h3> </div>
                                            <div id="result_mob_spinner"></div>
                                        </div>
                                        <div className="last-five-gr ml-n4  pl-0 pb-5" id="result_mob_data">
                                            <ul >
                                                {last_five_mob}
                                            </ul>
                                            {this.state.last_five_test.length >= this.state.all_test.length ? null : <div className="show-more d-flex justify-content-center shadow-sm ml-2 pt-2 pb-2" onClick={this.showMore}>Show More</div>}
                                            {this.state.last_five_test.length > 5 ? <div className="show-more d-flex justify-content-center shadow-sm ml-2 pt-2 pb-2 mt-2" onClick={this.showLess}>Show Less</div> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>


                {/*<div className="row mob-side-nav perm-bot-nav">
                    <Bottombar value="perform" />
                                    </div>*/}

                <div className="row pt-3 perf-bdr-sha mrgn-0 " >
                    <div id="" className="">
                        <div className="col">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="divh3">
                                        <h3>Performance Monitor</h3>
                                    </div>
                                </div>

                            </div>
                            <div className="row pt-3 bdr-side perform-graph">
                                <div className="col-md-3" id="user_card" >
                                    <div className="user">
                                        <img src={require("./../../../assests/img/profile-new.png")} />
                                        <div className="user-table">
                                            <ul>
                                                <li><h4>{this.state.name == "NULL" ? "User" : this.state.name}<span>Goal: {goal}</span></h4></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="perm-sove-ac">
                                        <ul>
                                            <li>Solved <span>{attempted}</span></li>
                                            {/*<li>Solved <span>1234</span></li>*/}
                                            <li>Accuracy <span>{accuracy}%</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="divh4" ><h4>Accuracy </h4></div>
                                                <div id="acc_spiner" className="text-center"></div></div>
                                        </div>

                                    </div>
                                    <div className="row perform-in perm-graph" id="accuracy_card">
                                        <div className="col-md-6">
                                            <div className="pt-4 graph-large" >
                                                {accuracy_graph}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <ul>
                                                <li>Correct <span>(within time)</span></li>
                                                <li>Correct <span>(exceeded time)</span></li>
                                                <li>Incorrect </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-6 perf-graph graphnew">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="divh4"><h4>Results | Last five tests </h4></div>
                                                <div id="result_spiner"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div id="result_card">
                                        <div className="row graphs pt-3 " id="demos">
                                            <div className="col">
                                                {/*<ul>*/}
                                                <div className="col-graph">
                                                    <OwlCarousel className="owl-theme" {...this.state.options}>
                                                        {test_data}
                                                    </OwlCarousel>
                                                    {/*</ul>*/}
                                                    {test_data1}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row graph-results">
                                            <div className="col">
                                                <ul>
                                                    <li>Correct <span>(within time)</span></li>
                                                    <li>Correct <span>(exceeded time)</span></li>
                                                    <li>Incorrect </li>
                                                </ul>
                                            </div>
                                        </div>


                                        <div className="graph-result-ab">
                                            <div className="col graph-results">
                                                <ul>
                                                    <li>Correct <span>(within time)</span></li>
                                                    <li>Correct <span>(exceeded time)</span></li>
                                                    <li>Incorrect </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLoader && <div style={{
                    width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                <div className="row mt-4 pt-5 pb-5 perf-bdr-sha mrgn-0 shdw-off">
                    <div className="col">
                        <div className="row perform-graph-table">
                            <div className="col-md-6">
                                <div className="divh3">
                                    <h3>Strengths</h3>
                                </div>
                                <div className="perf-table pt-3">
                                    <table className="table">
                                        <thead>
                                            <tr className="selected">
                                                <th>Topic</th>
                                                <th>Accuracy</th>
                                                <th>Speed <span>(You)</span></th>
                                                <th>Speed <span>(Median)</span></th>
                                                <th colSpan="2" >Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {strengths}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="divh3">
                                    <h3>Needs Practice</h3>
                                </div>
                                <div className="perf-table pt-3">
                                    <table className="table">
                                        <thead>
                                            <tr className="selected">
                                                <th>Topic</th>
                                                <th>Accuracy</th>
                                                <th>Speed <span>(You)</span></th>
                                                <th>Speed <span>(Median)</span></th>
                                                <th colSpan="2" >Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {need_practice}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-right"><a href="">More >></a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PerformanceTab)