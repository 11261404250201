import React from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import AllSamplePapers from './AllSamplePapers';

export default function SamplePaperMobile() {
    return (
        <>
            <div className="d-flex flex-column w-100 " style={{height:'100vh'}} >
                <Topbar backButton={false} topbarHeading="Sample Papers" />
                <AllSamplePapers />
                <Bottombar value="assignments"/>
            </div>

        </>
    )

}

