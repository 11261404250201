import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: 'auto',
    bottom: '12%',
    left: '20%',
    position: 'fixed',
    zIndex: 9999,
    width: '60%',

  },
  }));


export default function ButtonMob(props) {
  const classes = useStyles();

  return (
    <>
      {props.button_name == "Start Test" ?
        <Fab variant="extended" className={classes.fab} color="primary" style={{ textTransform: 'capitalize', textAlign: 'center', opacity: 1 }} onClick={props.modalStatusChange}>
          {props.button_name}
        </Fab>
        : props.button_name == "Review" ?
          <Fab variant="extended" className={classes.fab} color="primary" style={{ textTransform: 'capitalize', textAlign: 'center', opacity: 1 }} disabled={!props.status} onClick={props.reviewPage}>
            {props.button_name}
          </Fab>
          : props.button_name == "Begin Practice" ?
            props.status ?
              <Link to="/test-start"><Fab variant="extended" className={classes.fab} color="primary" style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                {props.button_name}
              </Fab></Link>
              : <Fab variant="extended" className={classes.fab} color="secondary" style={{ textTransform: 'capitalize', textAlign: 'center' }} disabled={true}>
                {props.button_name}
              </Fab>

            : ''}
    </>
  );
}
