import React, {Component} from "react";
import root_url from "../../components/api_url";
import $ from "jquery";


export class Errorlog extends Component{
    constructor() {
        super();
        this.state = {
            is_question_error:localStorage.getItem('question_error')?localStorage.getItem('question_error'):false,
            is_option_error:localStorage.getItem('option_error')?localStorage.getItem('option_error'):false,
            is_solution_error:localStorage.getItem('solution_error')?localStorage.getItem('solution_error'):false,
            is_other_error:localStorage.getItem('other_error')?localStorage.getItem('other_error'):false,
            feedback:localStorage.getItem('feedback')?localStorage.getItem('feedback'):'',
        }
        this.questionError = this.questionError.bind(this);
        this.optionError = this.optionError.bind(this);
        this.solutionError = this.solutionError.bind(this);
        this.otherError = this.otherError.bind(this);
        this.submitErrorLog = this.submitErrorLog.bind(this);
        this.ExtraError = this.ExtraError.bind(this);

    }
    ExtraError(e) {
        this.setState({
            feedback:e.target.value
        });
        localStorage.setItem('feedback', e.target.value);
  }


    questionError(e){
        this.setState({
            is_question_error:e.target.checked
        });
        localStorage.setItem('question_error', e.target.checked);
    }

    optionError(e){
        this.setState({
            is_option_error:e.target.checked
        });
        localStorage.setItem('option_error', e.target.checked);
    }

    solutionError(e){
        this.setState({
            is_solution_error:e.target.checked
        });
        localStorage.setItem('solution_error', e.target.checked);
    }

    otherError(e){
        this.setState({
            is_other_error:e.target.checked
        });
        localStorage.setItem('other_error', e.target.checked);
    }

    submitErrorLog(e){
        e.preventDefault();
        let practice = localStorage.getItem('practice_id');
        let practice_id = parseInt(practice);
        let question = localStorage.getItem('question_id');
        let question_id = parseInt(question);
        let question_error = localStorage.getItem('question_error')?localStorage.getItem('question_error'):false;
        let option_error = localStorage.getItem('option_error')?localStorage.getItem('option_error'):false;
        let solution_error = localStorage.getItem('solution_error')?localStorage.getItem('solution_error'):false;
        let other_error = localStorage.getItem('other_error')?localStorage.getItem('other_error'):false;
        let feedback = localStorage.getItem('feedback')?localStorage.getItem('feedback'):'';
        // console.log(question_error, solution_error, option_error, other_error);
        let auth = "Token "+localStorage.getItem('key');
        let url = root_url+'api/utils/error_logs/';
          fetch(url, {
            method: 'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },
            body:JSON.stringify({
                "question_error": question_error,
                "solution_error": solution_error,
                "option_error": option_error,
                "other_error": other_error,
                "practice": practice_id,
                "question": question_id,
                "feedback": feedback,
            })
        }).then(response=>{
            if(response.status===201){
                alert('Your response for this question has been send.')
                $('#dismiss_log').click();
                return response.json();
            }
            else{
                return false;
            }
        });
    }

    render() {
        return(
            <div>
            <div className="container share-mod popfive">
                <div className="modal" id="myModal5">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" id="dismiss_log" className="close" data-dismiss="modal">
                                    <img src={require("./../../../assests/img/cross-menu.png")} width="16px"/>
                                </button>
                                <div className="row">
                                    <div className="col">

                                        <div className="row  pt-4">
                                            <div className="col new">
                                                <form>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="one" onClick={this.questionError}/>
                                                            <label htmlFor="one">&nbsp;&nbsp;Question has an
                                                                error</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="two" onClick={this.optionError}/>
                                                            <label htmlFor="two">&nbsp;&nbsp;Options have an
                                                                error</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="three" onClick={this.solutionError}/>
                                                            <label htmlFor="three">&nbsp;&nbsp;Solution has an
                                                                error</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="four" onClick={this.otherError}/>
                                                            <label htmlFor="four">&nbsp;&nbsp;Other Issues</label>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-md-12 pt-1 m-auto">
                                                            <textarea  className="form-control" onChange={this.ExtraError}></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="common-btn">
                                                    <button onClick={this.submitErrorLog}>Submit</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default Errorlog