import React, { Component } from 'react';
import LeftSideBar from './LeftSideBar';
import MainbodyPanel from './MainbodyPanel';
import Dash from './../reusecomponent/dash';

export default function SamplePaperQuestionDesktop(props) {
    return (
        <>
            <div className="d-flex flex-column w-100" style={{ height: '100vh' }} >
                <Dash backButton={true} />
                <div className='d-flex'>
                    <div className='h-100'><LeftSideBar activeTab="SamplePapersTest" toggled={false} confirmEndPractice={props.confirmEndPractice}/></div>
                    <div className='h-100 w-100 mt-5'> <MainbodyPanel childComponent="SamplePapersQuestion" changePauseTimerState={props.changePauseTimerState} id={props.id} score={props.score} pausePractice={props.pausePractice} seconds={props.seconds} paused={props.paused} confirmEndPractice={props.confirmEndPractice} pauseTimer={props.pauseTimer} saveBookmark={props.saveBookmark} right_side_panel={props.right_side_panel} isLoader={props.isLoader} isVisible={props.isVisible} questionData={props.questionData} option_dropdown={props.option_dropdown} getNextQuestion={props.getNextQuestion} getSkipQuestion={props.getSkipQuestion}/></div>
                </div>
            </div>
        </>
    );
}
