import React, { Component } from 'react';
import ReviewMessage from './ReviewMessage';
import ReviewTime from './ReviewTime';
import ReviewQuestionSolution from './ReviewQuestionSolution'
import ReviewRightSidePanel from './ReviewRightSidePanel'
export default function ReviewQuestionDetail(props) {
    return (
        <>
            <div className="d-flex flex-column p-3 overflow-auto h-80" id="questionSolution">
                <ReviewRightSidePanel right_side_panel={props.right_side_panel} />
                <ReviewMessage response_msg={props.response_msg}/>
                <ReviewTime recommTime={props.recommTime} questionTime={props.questionTime}/>
                <ReviewQuestionSolution topic={props.topic} questionId={props.questionId} questionScore={props.questionScore} questionContent={props.questionContent} option_dropdown={props.option_dropdown} questionsolution={props.questionsolution}  previousQuestion={props.previousQuestion} nextQuestion={props.nextQuestion} endReview={props.endReview} level={props.level}   visible={props.visible}/>
            </div>
        </>
    );
}
