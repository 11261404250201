import React, { Component } from 'react';
export default function ReviewMessage(props) {
    return (
        <>
            <div>
                {props.response_msg}
            </div>
        </>
    );
}
