import {CreatingPracticeQuestionDetail} from './CreatingPracticeQuestionDetail.js'
import root_url from './../components/api_url';
const axios = require('axios');

export  function  CallingSamplePaperQuestion(samplePaperID,questionIndex){
    axios.get(`${root_url}api/utils/get_sample_paper_questions/${samplePaperID}/5/`)
            .then((res) => {
                let api_result=res.data;
                if(api_result.status_code==201){
                    let question_data=api_result.data;
                    let intialQuestionData=question_data[0];
                    let total_section=api_result.total_sections
                    let samplePaperTime= localStorage.getItem("samplePaperTimeInMinutes")*60;
                    localStorage.setItem('question_score', intialQuestionData.question_score);
                    localStorage.setItem('question_time', intialQuestionData.question_time);
                    localStorage.setItem('question_id', intialQuestionData.question_id);
                    localStorage.setItem('question_level', intialQuestionData.question_level);
                    localStorage.setItem('question_content', intialQuestionData.question_content);
                    localStorage.setItem('question_section',intialQuestionData.question_section);
                    localStorage.setItem('is_visible', true);
                    localStorage.setItem('question_topic',intialQuestionData.question_topic);
                    localStorage.setItem("samplePaperQuestionData", JSON.stringify(question_data));
                    localStorage.setItem('question_options',JSON.stringify(intialQuestionData.question_options))
                    let quest = '' + 0;
                    localStorage.setItem("questions_count", quest);
                    localStorage.setItem("question_count", 1);
                    localStorage.setItem("countdown_seconds", String(samplePaperTime));
                    localStorage.setItem("total_sections",JSON.stringify(total_section));
                    localStorage.setItem("selected_sample_paperID",samplePaperID);
                    CreatingPracticeQuestionDetail(intialQuestionData.question_id,"sample_paper_test");
                }
                
            })
            .catch((err) => {
                console.log(err);
            });
}