import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import root_url from "./api_url";
import Loader from "react-loader-spinner";

export class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email:'',
            phone:'',
            isLoader:false,
            errorMessage:'',
            successMessage:''
        }
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }
    emailChange(e){
        this.setState({
            email:e.target.value
        });
    }
    phoneChange(e){
        this.setState({
            phone:e.target.value
        });
    }
    submitHandler(e){
        e.preventDefault();
        let mobileexp=/^\d{10}$/;
        let phone = this.state.phone;
        this.setState({
            errorMessage:''
        })
        if(phone===''){
            this.setState({
                errorMessage:'Please enter your phone'
            })
        }
        else if (!phone.match(mobileexp)) {
            this.setState({ errorMessage: 'Enter valid mobile number' });
        }
        else{
             this.setState({isLoader: true});
            let url = root_url + 'mobile_otp_send/';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mobile: phone,
                    user_entry_type:'Password_Reset'
                })
            }).then(response =>{
                this.setState({isLoader: false});
                if(response.status===400){
                    return response.json();
                }
                else if(response.status===200){
                    return response.json();
                }
                else {
                    return false;
                }
            })
            .then(response=>{
                if(response.status==200){
                    this.setState({
                        errorMessage: response.message,
                    });
                }
                if(response.status==201){
                    document.location.href = document.location.origin + `/password-reset?phone=${phone}`;
                }
                 
             })
            
            
        }
    }
    render() {  
        return (
            <div>
                <section className="forgot-pass">
                <div className="container pl-0 pr-0 w-100  ">
                    <div className="col-md-5 m-auto">
                        <div className="forgot-p">
                            <div className="row">
                            <div className="col">
                                <div className="divh4">
                                    <h4><Link to="login_help">Need help?</Link></h4>
                                </div>
                            </div>
                            </div>
                            <div className="row pt-2">
                            <div className="col">
                                <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col">
                                <div className="divh3">
                                    <h3>Forgot Password ?</h3>
                                </div>
                            </div>
                            </div>
                            <div className="row form-group" >
                                <div className="col-md-12 pt-5 m-auto" /*data-aos="fade-left" */>
                                <input type="text" placeholder="Enter 10 digit mobile number"  className="form-control border-top-0 border-left-0 border-right-0" id="id_phone" onChange={this.phoneChange} required />
                                </div>
                            </div>
                            {this.state.isLoader && <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
     }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                            { this.state.errorMessage && <span className="error"> { this.state.errorMessage } </span> }
                            { this.state.successMessage && <span className="success"> { this.state.successMessage } </span> }
                            <div className="row sign-in pt-5  justify-content-center">
                                <div className="common-btn pt-5"><button onClick={this.submitHandler}>Next</button></div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        )
    }
}

export default ForgotPassword