import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useHistory } from "react-router-dom";
import TimerIcon from '@material-ui/icons/Timer';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShareIcon from '@material-ui/icons/Share';
import ErrorIcon from '@material-ui/icons/Error';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CountDownTimer from '../CountDownTimer'
import { isMobileOnly } from 'mobile-device-detect';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: 'black',
    width: '100%',
    padding: 'none',
    zIndex: 999,
    position: `${isMobileOnly ? 'fixed' : ''}`,
    top: 0,
    boxShadow: 'none',
},
  menuButton: {
    width: '5%',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    paddingLeft: 10,
    width: '80%',
  },
}));



export default function TopBarTestPage(props) {
  let history = useHistory();
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    { props.backButton ? setBackButton(true) : setBackButton(false) }

  }, [])

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

    <div className="h-100">
      <div className={classes.root} >
        <AppBar position="static" className={classes.root}>
          {isMobileOnly ? <div className="h-100 w-100 d-flex justify-content-between border-bottom pl-3 pr-2">
            <div className="h-100 pt-2 d-flex flex-column" >
              <div className="QuestionIDColor">Question id - #{props.id}</div>
              <div className="QuestionIDColor">Score : {props.score} points</div>
            </div>
            <div className="d-flex justify-content-between pt-2 mt-1" >
              <div className=""><TimerIcon /></div>&nbsp;
              <div className="text-black">
                {parseInt(localStorage.getItem("countdown_seconds")) > 0 ? <CountDownTimer seconds={localStorage.getItem("countdown_seconds")} paused={props.paused} callingTimerInterval={props.callingTimerInterval} clearTimerInterval={props.clearTimerInterval} testType='samplePaperTest'/> : <div className='timer-container text-black font-weight-bold'>0m 0s</div>}
              </div>&nbsp;&nbsp;
              <div data-toggle="modal" data-target="#myModal2" onClick={props.pausePractice} className=""><PausePresentationIcon /></div>&nbsp;
              <div className="">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => { handleClose(); props.pausePractice(); }}><p data-toggle="modal" data-target="#myModal2"><PausePresentationIcon /> Pause</p></MenuItem>
                  <MenuItem onClick={() => { handleClose(); props.confirmEndPractice(); }}><p ><AlarmOnIcon /> End Practice</p></MenuItem>
                  <MenuItem onClick={() => { handleClose(); props.saveBookmark(); }}><p data-toggle="modal" data-target="#myModal4" ><BookmarkIcon /> Bookmark</p></MenuItem>
                  <MenuItem onClick={() => { handleClose(); props.pauseTimer(); }}><p data-toggle="modal" data-target="#myModal" ><ShareIcon /> Share</p></MenuItem>
                  <MenuItem onClick={() => { handleClose(); props.pauseTimer(); }}><p data-toggle="modal" data-target="#myModal5" ><ErrorIcon /> Error</p></MenuItem>
                </Menu>
              </div>
            </div>
          </div> :
            <>
              <div className="h-100 w-100 d-flex justify-content-between border-bottom pb-2 ">
                <div className="d-flex ml-n1">
                  <div className="QuestionIDColorlg">Question id - #{props.id} (Score : {props.score} points)</div>
                  <div className="ml-3 d-flex">
                    <div ><img src={require("./../../../assests/img/bookmark_icon.png")} onClick={() => {props.saveBookmark(); }} data-toggle="modal" data-target="#myModal4" className="cursor-pointer"/></div>
                    <div className="ml-2"><img src={require("./../../../assests/img/share.jpg")} onClick={() => {props.pauseTimer(); }} data-toggle="modal" data-target="#myModal" className="cursor-pointer"/></div>
                    <div className="ml-2" ><img src={require("./../../../assests/img/error.png")}onClick={() => { props.pauseTimer(); }} data-toggle="modal" data-target="#myModal5" className="cursor-pointer"/></div>
                  </div>
                </div>
                <div className="d-flex push-end-btn pr-3">
                  <div><TimerIcon /></div>
                  <div className="text-black">{parseInt(localStorage.getItem("countdown_seconds")) > 0 ? <CountDownTimer seconds={localStorage.getItem("countdown_seconds")} paused={props.paused} callingTimerInterval={props.callingTimerInterval} clearTimerInterval={props.clearTimerInterval} testType='samplePaperTest'/> : <div className='timer-container text-black font-weight-bold'>0m 0s</div>}</div>
                  <div><button type="button" className="btnStyle ml-3 mt-n4" onClick={() => { props.pausePractice(); }} data-toggle="modal" data-target="#myModal2">Pause</button></div>
                  <div><button type="button" className="btnStyle ml-3 mt-n4" onClick={() => { props.confirmEndPractice(); }} >End Test</button></div>
                </div>
              </div>
            </>
          }
        </AppBar>
      </div>
    </div>

  );
}
