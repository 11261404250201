import React, { Component } from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import ButtonMob from './TopBottomBar/BurttonMob'
import TestStartInfromation from './TestStartInformation'
import root_url from './../components/api_url';
import { useEffect, useState } from 'react';
import {CallingQuestion} from './CallingQuestion.js';

const beginPractice = () => {
    let topic = localStorage.getItem('topic_selected') ? localStorage.getItem('topic_selected') : '';
    if (topic !== '') {
        let verified = localStorage.getItem('user_verified')
        if (verified == "true") {
            let given_t_name = ""
            let topics = JSON.parse(localStorage.getItem('topic_name'));
            if (topics.includes('all')) {
                given_t_name = "All"
            }
            else {
                given_t_name = topics.join()
            }
            localStorage.setItem("topic_name_given",given_t_name);
            let auth = "Token " + localStorage.getItem('key');
            let url = root_url + 'api/utils/practice/';
            let goal = localStorage.getItem('goal');
            let user_id = localStorage.getItem('user_id');
            let subject = localStorage.getItem('subject_selected');
            let topic = JSON.parse(localStorage.getItem('topic_selected'))
            let is_timed = localStorage.getItem('is_timed');
            let s_name = localStorage.getItem('subject_name');
            let t_name = given_t_name
            let g_name = localStorage.getItem('goal_name');
            let tempDate = new Date();
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let end_time = new Date(tempDate);
            end_time.setMinutes(tempDate.getMinutes() + 40);
            let topic_id_list = topic.map(item => parseInt(item, 10));
            let new_end_time = end_time.getFullYear() + '-' + (end_time.getMonth() + 1) + '-' + end_time.getDate() + ' ' + end_time.getHours() + ':' + end_time.getMinutes() + ':' + end_time.getSeconds();
            let final_data = {
                "goal_name": g_name,
                "subject_name": s_name,
                "topic_name": t_name,
                "timed": is_timed,
                "start_datetime": start_time,
                "end_datetime": new_end_time,
                "timer_start": start_time,
                "timer_end": new_end_time,
                "user": user_id,
                "goal": goal,
                "subject": subject,
                "topic": topic_id_list
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify(final_data)
            }).then(response => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                let practice = response.id;
                let start_time = response.start_datetime;
                let end_time = response.end_datetime;
                localStorage.setItem('practice_id', practice);
                localStorage.setItem('practice_start_datetime', start_time);
                localStorage.setItem('practice_end_datetime', end_time);
                localStorage.setItem('topic_selected', topic.join());
                localStorage.setItem("backdrop_open","true");
                let get_level = localStorage.getItem('level') ? localStorage.getItem('level') : 2;
                let level = parseInt(get_level);
                let given_topic = localStorage.getItem('topic_selected')
                if (is_timed === 'true') {
                    CallingQuestion(level, practice, given_topic)
                } else {
                    document.location.href = document.location.origin + '/untimed-questions/';
                }
            })
        }

    }
    else {
        alert('Please select any topic to continue Practice.')
    }

}



export default function TestStartMob() {
    const [open, setOpen] = useState(false);
    const [start, setStart] = useState(false);
    const modalStatusChange = () => {
        setOpen(true);
        setStart(true);
    }
    return (
        <>
            <div className="d-flex flex-column w-100" style={{ height: '100vh' }}>
                <Topbar backButton={true} />
                <TestStartInfromation modalOpen={open} beginPractice={beginPractice} />
                <ButtonMob button_name="Start Test" status={start} modalStatusChange={modalStatusChange} />
                <Bottombar value="dashboard" />
            </div>

        </>
    );
}
