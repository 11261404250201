import React, { Component } from 'react';
import root_url from "../../components/api_url";
import $ from 'jquery';

export class SharePopup extends Component {
    constructor() {
        super();
        this.state = {
            email:'',
            mobile:'',
        }
        this.shareQuestion = this.shareQuestion.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeMobile = this.changeMobile.bind(this);
    }
    changeEmail(e){
        this.setState({
            email:e.target.value
        })
    }

    changeMobile(e){
        this.setState({
            mobile:e.target.value
        })
    }

    shareQuestion(e){
        e.preventDefault();
        let url = root_url+'api/utils/share_questions/';
        let email = this.state.email;
        let mobile = this.state.mobile;
        let question_id = localStorage.getItem('question_id');
        let practice_id = localStorage.getItem('practice_id');
        let question = parseInt(question_id);
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        if (email == '' || mobile== '') {
            alert('Please enter fields info to share')
        }
        else {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "email": email,
                    "mobile": mobile,
                    "practice": practice,
                    "question": question,
                })
            }).then(response=>{
                if(response.status===201){
                    alert('You have successfully shared this question');
                    $('#dismiss').click();
                    return response.json()

                }
                else{
                    return false;
                }
            })
        }
    }

    render() {  
        return (
            <div>
            <div className="container share-mod">
            <div className="modal" id="myModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-body">
                        <button id="dismiss" type="button" className="close" data-dismiss="modal">
                        <img src={require("./../../../assests/img/cross.jpg")} />
                        </button>
                        <div className="divh3">
                            <h3>Share Question</h3>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row  pt-4">
                                    <div className="col-md-9">
                                    <input type="text" className="form-control" onChange={this.changeEmail} placeholder="Enter Email Id" name="" />
                                    </div>
                                    <div className="col-md-3">
                                    <div className="common-btn"><button onClick={this.shareQuestion}>Share</button>
                                    </div>
                                    </div>
                                </div>
                                <div className="row pt-2">
                                    <div className="col-md-9">
                                    <input type="text" className="form-control" onChange={this.changeMobile} placeholder="Enter Mobile Number" name="" />
                                    </div>
                                    <div className="col-md-3">
                                    <div className="common-btn"><button onClick={this.shareQuestion}>Share</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        )
    }
}

export default SharePopup