import React, { Component } from 'react';

export default function ReviewTime(props) {
    
    return (
        <>
            <div className="d-flex w-100  pt-1" >
                <div className="w-50  d-flex ">
                    <div className="w-25 ml-n2">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="90%" viewBox="0 0 24 24" width="100%"><g><rect fill="none" height="24" width="24"/></g><g><path d="M18,22l-0.01-6L14,12l3.99-4.01L18,2H6v6l4,4l-4,3.99V22H18z M8,7.5V4h8v3.5l-4,4L8,7.5z"/></g></svg>                    </div>
                    <div className="w-75">
                        <div className="text-dark font-weight-bold">Your Time</div>
                        <div className="text-dark">{props.questionTime?props.questionTime:'0 min. 00 sec'}</div>
                    </div>
                </div>
                <div className="w-50 d-flex ">
                    <div className="w-25 ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="90%" viewBox="0 0 24 24" width="100%"><g><rect fill="none" height="24" width="24"/></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z"/></g></svg>
                    </div>
                    <div className="w-75">
                        <div className="text-dark font-weight-bold">Recommended</div>
                        <div className="text-dark"> {props.recommTime?props.recommTime:'0 min. 00 sec'}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
