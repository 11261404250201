import React from 'react';
export default function Loader(props) {
    return (
        <><div className={`d-flex justify-content-center align-items-center ${props.divHeight?props.divHeight:''} `}>
                <div className={`spinner-border text-${props.color}`} role="status" style={{height:props.height,width:props.width}}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>

        </>


    );
}
