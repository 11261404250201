import React, { useState } from 'react';

const EkalPlans = () => {

    const HALF_YEARLY = "HALF_YEARLY";
    const QUARTERLY = "QUARTERLY";

    const [plan, setPlan] = useState(HALF_YEARLY);

    const selectHalfYearly = React.useCallback(() => {
        setPlan(HALF_YEARLY);
    }, [setPlan]);

    const selectQuarterly = React.useCallback(() => {
        setPlan(QUARTERLY);
    }, [setPlan]);

    const submitHandler = (e) => {
        e.preventDefault();
    }

        return (
            <div>
                <section className="forgot-pass learning">
                    <div className="container pl-0 pr-0 w-100">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img2.png")} /></div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="divh3 ">
                                            <h3>Select Your Plan</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row loginhform mt-10">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="payment-option-tile mr-1">
                                                    <div className={`payment-container ${plan === HALF_YEARLY ? "payment-selected" : ""}`} onClick={selectHalfYearly}>
                                                        <span className="payment-period">6 months</span>
                                                        <span className="payment-amount">INR 749/-</span>
                                                        <div>
                                                            <a style={{'width': '135px', 'background-color': '#5D38DB', 'text-align': 'center', 'font-weight': '800','padding': '11px 0px','color': 'white','font-size':'12px', 'display': 'inline-block','text-decoration': 'none'}} href='https://pmny.in/jIHf9srf1Z5U'>
                                                                Pay Now
                                                            </a>
                                                        </div>
                                                        <span className="payment-offer">(Save 25% - INR 250/-)</span>
                                                        <span className="payment-period-footer">Half-yearly subscription</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="payment-option-tile ml-1">
                                                    <div className={`payment-container ${plan === QUARTERLY ? "payment-selected" : ""}`} onClick={selectQuarterly}>
                                                        <span className="payment-period">3 months</span>
                                                        <span className="payment-amount">INR 499/-</span>
                                                        <div>
                                                            <a style={{'width': '135px', 'background-color': '#5D38DB', 'text-align': 'center', 'font-weight': '800','padding': '11px 0px','color': 'white','font-size':'12px', 'display': 'inline-block','text-decoration': 'none'}} href='https://pmny.in/9IeKsXVE6XVp'>
                                                                Pay Now
                                                            </a>
                                                        </div>
                                                        <span className="payment-offer hidden">(Save 25% - INR 250/-)</span>
                                                        <span className="payment-period-footer">Quarterly subscription</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group mt-2 pr-2" >
                                            <div class="col my-1">
                                                <label className="sr-only" for="inlineFormInputGroupUsername"></label>
                                                <div className="input-group">
                                                    <input type='text' placeholder="Enter Access Code" className="form-control" id="access_code" required=""  autocomplete="off" spellcheck="false"/>
                                                    <div className="input-group-prepend bg-white">
                                                        <div className="input-group-text bg-white border-top-0 border-left-0 border-right-0 "><i className={`fa fa-check-circle text-success`} aria-hidden="true"> </i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row sign-in pt-2">
                                    <div className="col-md-12">
                                        <div className="common-btn pt-2"><button onClick={submitHandler}>Pay Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

export default EkalPlans