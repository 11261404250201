import {CreatingPracticeQuestionDetail} from './CreatingPracticeQuestionDetail'
import root_url from './../components/api_url';

export  function  CallingQuestion (level, practice, topic){
    let questionType=localStorage.getItem('userSelectedQuestionsType');
    let url = root_url + 'api/utils/random_question/?topic=' + topic + '&practice=' + practice + '&level=' + level + '&questionType=' + questionType;
    let auth = "Token " + localStorage.getItem('key');
    fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth,
        },
    }).then(response => {
        if (response.status === 200) {
            return response.json();
        }
        else {
            return false;
        }
    }).then(response => {

        if (response && response.results && response.results.length > 0) {
            localStorage.setItem('score', response.results[0].score);
            localStorage.setItem('time', response.results[0].time);
            localStorage.setItem('question_id', response.results[0].id);
            localStorage.setItem('level', response.results[0].level);
            localStorage.setItem('question_content', response.results[0].content);
            localStorage.setItem('is_visible', true);
            localStorage.setItem('given_topic',response.results[0].topic);
            let get_question_count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 0;
            let question_count = parseInt(get_question_count);
            question_count = question_count + 1;
            let quest = '' + question_count;
            localStorage.setItem("question_count", quest);
            CreatingPracticeQuestionDetail(response.results[0].id,"timed_test");
        }
        else {
            alert('No Questions found..')
        }
    })
}