import React, { Component } from 'react';
import Dash from './../reusecomponent/dash';
import Timer from './../reusecomponent/timer';
import SharePopup from './../reusecomponent/allpopup/sharepopup';
import PausePractie from './../reusecomponent/allpopup/pauseprac';
import Endprac from './../reusecomponent/allpopup/endprac';
import Bookmarkprac from './../reusecomponent/allpopup/bookmarkprac';
import 'react-circular-progressbar/dist/styles.css';
import {Link} from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import $ from 'jquery';
import root_url from './../components/api_url';
import './../../../src/assests/css/progress.css';
import Errorlog from "../reusecomponent/allpopup/errorlog";
import Loader from 'react-loader-spinner';
import {confirmAlert} from "react-confirm-alert";
import PrepHub_logo from "./../../assests/img/preb-logo.png";
//let Latex = require('react-latex');
import Latex from "react-latex-next";
import Editor from "react-editor-md";
let status_list = [];

export class Solution1 extends Component {

    openNav = () => {
        document.getElementById("mySidepanel").style.width = "240px";
    }
    confirmEndPractice = () => {

        confirmAlert({
          title: 'End Practice',
          message: 'Are you sure you want to end practice ? ',
          buttons: [
            {
              label: 'Yes',
              onClick: () =>{
                   let id = localStorage.getItem('practice_id');
         let auth = 'Token '+localStorage.getItem('key');
         let url = root_url+'api/utils/end_practice/?practice_id='+id;
        this.setState({isLoader: true});
  	    fetch(url,{
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth
            }

                }).then(response=>{
                            if(response.status===200){
                                return response.json();
                            }
                            else{
                                return false;
                            }
                    }).then(response=>{
                        console.log('End Practice', response);
                        this.setState({
                            end_practice:response.data[0],
                        });
                        localStorage.setItem('percentage', response.data[0].accuracy)
                        localStorage.setItem('total_score', response.data[0].score)
                        localStorage.setItem('solved', response.data[0].solved)
                        localStorage.setItem('question_time_difference', response.data[0].speed)
                        localStorage.setItem('msg', response.data[0].message_option)
                    });
                document.getElementById('confirm_end_practice').click()
                  this.setState({isLoader: false});
              }
            },
            {
              label: 'No',
              onClick: () => ''
            }
          ]
        });
  };

    closeNav = () =>{
        document.getElementById("mySidepanel").style.width = "";
    }

    constructor() {
        super();
        this.state={
            question_content:[],
            correct_answer:'',
            option_list:[],
            isLoader:false,
            isVisible:false,
        }
        // this.pausePractice = this.pausePractice.bind(this)
        this.goBack = this.goBack.bind(this)
        this.getStatus = this.getStatus.bind(this);
    }


    goBack(){
        document.location.href = document.location.origin+'/untimed-questions/';

    }

    getStatus(){
        let question_count = localStorage.getItem('question_count');
        let practice_id = localStorage.getItem('practice_id');
        let qc = parseInt(question_count);
        let status='';
        for(let i=1; i<qc; i++) {
            let url = root_url + 'api/utils/practice_question_details/?question_sequence='+i+'&practice__id='+practice_id;
            let auth = "Token " + localStorage.getItem('key');
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    if (response.results[0].status === null) {
                        status = 'correct';
                    } else {
                        let index = status_list.findIndex(x => x.id === i)
                              if (index === -1) {
                                 status_list.push({'id': i, 'status': response.results[0].status});
                              }

                    }
                }

            })
        }
    }

    componentDidMount() {
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

        $("#menu-toggle1").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
            $("#wrapper").toggleClass("bgblack");
        });
        localStorage.removeItem('selected_option');
        let question_id = localStorage.getItem('question_id')
        let ques_url = root_url + 'api/utils/questions/'+question_id+'/';
        let auth = "Token "+localStorage.getItem('key')
        this.setState({isLoader: true});
        fetch(ques_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            this.setState({
                question_content: response,
                isVisible: false,
            });
            setTimeout(()=>{
                            this.setState({
                                isVisible: true,
                            })
                        }, 100)
        })

         if (question_id !== undefined) {
            let option_url = root_url + 'api/utils/options/?question__id=' + question_id + '';
            fetch(option_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },

            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                // console.log('op', response);
                this.setState({
                    option_list: response.results,
                });
                for (let i = 0; i < this.state.option_list.length; i++) {
                    if (this.state.option_list[i].is_correct === true) {
                        this.setState({correct_option: this.state.option_list[i].option});
                    }
                }
                this.setState({isLoader: false});
            });
        }
        else{
            this.setState({isLoader: false});
            alert('Did not find any question to display its options');
        }
    }

    render() {
//        console.log('==================', this.state.question_content);
        let solution = this.state.question_content.solution;
        let content = this.state.question_content.content;
        let logo = '';
         if(localStorage.getItem('is_logo')=='true'){
                logo = localStorage.getItem('org_logo');
            }
            else{
                logo = PrepHub_logo;
            }

        let topic = localStorage.getItem('topic_name');
        let goal = localStorage.getItem('goal_name');
        let question_count = localStorage.getItem('question_count');
        let qc = parseInt(question_count);
        let count = localStorage.getItem('solved') ? localStorage.getItem('solved'): 0;
        let correct = localStorage.getItem('correct') ? localStorage.getItem('correct'): 0;
        let skip = localStorage.getItem('skip') ? localStorage.getItem('skip'): 0;
        let review_question_li =[]
        this.getStatus();
        let correct_option = '';
        for(let i=0;i<this.state.option_list.length;i++) {
            if (this.state.option_list[i].option === this.state.correct_option) {
                correct_option = this.state.correct_option;

            }
        }
//        console.log('total==========================',status_list);
        for(let i=1; i<=qc; i++){
            if(status_list.length>0) {
            for (let j = 0; j < status_list.length; j++) {
                if (status_list[j].id === i) {
                    if (status_list[j].status === "correct") {
                        review_question_li.push(
                            <li style={{"backgroundImage" : "linear-gradient(#2f7a73, #42a37a)"}} key={i}>{i}</li>
                        )
                    }
                    else if (status_list[j].status === "wrong") {
                        review_question_li.push(
                            <li style={{"backgroundImage" : "linear-gradient(#882c6d, #a93672)"}} key={i}>{i}</li>
                        )
                    }
                    else {
                        review_question_li.push(
                            <li style={{"backgroundImage" : "linear-gradient(#696969, #808080)"}} key={i}>{i}</li>
                        )
                    }
                }
            }
        }
        else {
               review_question_li.push(
                            <li style={{"backgroundImage" : "linear-gradient(#2f7a73, #42a37a)"}} key={i}>{i}</li>
                        )
            }
        }

        return (
            <div>
                <SharePopup />
                <Errorlog/>
                <Endprac />
                <Bookmarkprac />
                <Dash backButton={true}/>
                <section className="dash-mid">
                <div id="wrapper">
                    <div id="sidebar-wrapper">
                        <div className="btn-tog" id="menu-toggle"></div>
                        <div className="sidebar-nav perf-bdr">
                            <div className="side-nav">
                            <div className="logo dnone"><img src={logo} /></div>
                            <div className="ham2" id="menu-toggle1"><img src={require("./../../assests/img/cross-menu.png")} width="15" /></div>
                            <div className="side-select dnone">
                                <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                <select id="mySelect" data-show-content="true" className="form-control">
                                    <option>{goal}</option>
                                </select>
                            </div>
                            <div className="lft-side-menu middle-menu">
                                <ul className="fix-left-nav">
                                    <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2-active.png")} /><h6>Practice Mode</h6></Link> <span></span></li>
                                    <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} /><h6>Performance Management</h6></Link></li>
                                    <li><Link to="#"><img src={require("./../../assests/img/side-icon-3.png")} /><h6>Contact Us</h6></Link></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- sidebar-wrapper --> */}
                    {/* <!--   Right-side-page --> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/*Right-side-panel*/}
                            <div className="row right-s-pan">
                            <div className="col">
                                <div className="panel-op">
                                    <div className="panel-open">
                                        <button className="openbtn" onClick={this.openNav}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div id="mySidepanel" className="sidepanel">
                                    <Link  className="closebtn" onClick={this.closeNav}>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                    <div className="scroe-c">
                                        <div className="scroec-tit"> Accuracy</div>
                                         <CircularProgressbar value={localStorage.getItem('percentage')} text={`${localStorage.getItem('percentage')}%`} styles={buildStyles({
                                            strokeLinecap: 'round',

                                            textSize: '12px',

                                            pathTransitionDuration: 0.5,

                                            pathColor: '#a55fcae0',
                                            textColor: '#a55fcae0',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#a55fcae0',
                                        })} />;
                                        <div className="scr-result">
                                            <ul>
                                                <li>Attempted <span>{count}</span></li>
                                                <li>Accurate <span>{correct}</span></li>
                                                <li>Skipped <span>{skip}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="score-num pt-5">
                                        <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                                        <ul>
                                            {review_question_li}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {/* <!--     Right-side-panel-end --> */}
                            <div className="row">
                            <div className="col-lg-12">
                                <div className="row pl-4 pr-4 pt-4 dash2-q">
                                    <div className="col">
                                        {/*<Timer />*/}
                                        <div className="row  dash-accord">
                                        <div className="col">
                                            <div id="accordion" className="accordion">
                                                <div className="card mb-0">
                                                    <div className="faq-accord">
                                                    <div className="card-header collapsed" data-toggle="collapse" to="#collapseOne">
                                                        <Link className="card-title">
                                                            {topic}
                                                        </Link>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                                        <div  className="card-body ptag">
                                                            {(this.state.isVisible)?(
                                                                             <Editor.EditorShow  config={
                                                                        {
                                                                            markdown: this.state.question_content.content,
                                                                            id : "id_content",
                                                                            height:300,
                                                                            name:"id_content-markdown-doc",
                                                                            saveHTMLToTextarea : true,
                                                                            toolbar: false,
                                                                            flowChart:false,
                                                                            sequenceDiagram:false,

                                                                        }
                                                                    }/>
                                                                        ):''}

                                                            {/*<p><Latex>{content}</Latex></p>*/}
                                                        </div>
                                                    </div>
                                                    </div>
                                                    {this.state.isLoader && <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
     }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                                    <div className="radio-btn">
                                                        <ul>
                                                            <li>
                                                            <input type="radio" id="f-option" name="selector" checked />
                                                            <label htmlFor="f-option">
                                                                {(this.state.isVisible)?(
                                                                             <Editor.EditorShow  config={
                                                                        {
                                                                            markdown: correct_option,
                                                                            id : "id_correct_option",
                                                                            height:300,
                                                                            name:"id_option-markdown-doc",
                                                                            saveHTMLToTextarea : true,
                                                                            toolbar: false,
                                                                            flowChart:false,
                                                                            sequenceDiagram:false,

                                                                        }
                                                                    }/>
                                                                        ):''}
                                                                {/*<Latex>{correct_option}</Latex>*/}
                                                            </label>
                                                            <div className="check"></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                                       <div  className="card-body ptag">
                                                         <p><br/><br/>Solution : </p>
                                                            {(this.state.isVisible)?(
                                                                             <Editor.EditorShow  config={
                                                                        {
                                                                            markdown: this.state.question_content.solution,
                                                                            id : "id_solution",
                                                                            height:300,
                                                                            name:"id_solution-markdown-doc",
                                                                            saveHTMLToTextarea : true,
                                                                            toolbar: false,
                                                                            flowChart:false,
                                                                            sequenceDiagram:false,

                                                                        }
                                                                    }/>
                                                                        ):''}
                                                         {/*<p><Latex displayMode={true} minRuleThickness={1} >{solution}</Latex></p>*/}
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                        <div className="row dash-2-btn pt-5">
                                        <div className="col dash-hov text-center solution">
                                            <ul>
                                                <li>
                                                    <div className="common-btn"><button onClick={this.goBack}>Next Question</button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="push-end-btn">
                                            <ul>
                                                <li>
                                                    <div className="common-btn btn-change"><button id='end_practice' data-toggle="modal"  onClick={this.confirmEndPractice}>End Practice</button>
                                                    </div>
                                                    <div className="hidethisdiv"><button id='confirm_end_practice' data-toggle="modal" data-target="#myModal3" onClick={this.endPractice}></button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- col-lg-12-END --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!--   Right-side-page END --> */}
                </div>
                {/* <!-- wrapper end  --> */}
                </section>
            </div>
        )
    }

}

export default Solution1