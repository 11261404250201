import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import root_url from "../api_url";
import NeedAndPracticeTable from './NeedAndPracticeTable';
import NeedStrengthComponent from './NeedStrengthComponent';


export default function NeedAndPracticeTab() {
    const [isLoader, setIsLoader] = useState(true);
    const [onlyStrengthPracticeData, setOnlyStrengthPracticeData] = useState([])
    const [onlyNeedPracticeData, setOnlyNeedPracticeData] = useState([])
    const [onlyNeedPracticeDataFive, setOnlyNeedPracticeDataFive] = useState([])
    const [onlyStrengthPracticeDataFive, setOnlyStrengthPracticeDataFive] = useState([])

    useEffect(() => {
        getPerformanceStrengths();
    }, [])

    const showMore = (given_type) => {
        if (given_type == "Needs Practice") {
            let currentTestData = onlyNeedPracticeDataFive;
            let alltest = onlyNeedPracticeData
            let currentLength = currentTestData.length;
            let updatedlength = currentLength + 5;
            let updatedTestData = alltest.slice(0, updatedlength);
            setOnlyNeedPracticeDataFive(updatedTestData);
        }
        if (given_type == "Strengths") {
            let currentTestData = onlyStrengthPracticeDataFive;
            let alltest = onlyStrengthPracticeData;
            let currentLength = currentTestData.length;
            let updatedlength = currentLength + 5;
            let updatedTestData = alltest.slice(0, updatedlength);
            setOnlyStrengthPracticeDataFive(updatedTestData);

        }

    }
    const showLess = (given_type) => {
        if (given_type == "Needs Practice") {
            let alltest = onlyNeedPracticeData;
            setOnlyNeedPracticeDataFive(alltest.slice(0, 5));
        }
        if (given_type == "Strengths") {
            let alltest = onlyStrengthPracticeData;
            setOnlyStrengthPracticeDataFive(alltest.slice(0, 5));

        }

    }
    const getPerformanceStrengths = () => {
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/utils/performance_strength_need/';
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            let onlyStrengthsArray = [];
            let onlyNeedPracticeArray = []
            setIsLoader(false);
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].category == 'Needs Practice') {
                    onlyNeedPracticeArray.push(response.data[i])
                }
                if (response.data[i].category == 'Strength') {
                    onlyStrengthsArray.push(response.data[i]);
                }
            }
            setOnlyNeedPracticeData(onlyNeedPracticeArray);
            setOnlyNeedPracticeDataFive(onlyNeedPracticeArray.slice(0, 5))
            setOnlyStrengthPracticeData(onlyStrengthsArray);
            setOnlyStrengthPracticeDataFive(onlyStrengthsArray.slice(0, 5))
        });

    }

    const createRenderingStrengthNeedPracticeData = (given_array, given_type) => {
        let performanceNeedPracticeStrengthdata = []
        if (given_array.length > 0) {
            for (let i = 0; i < given_array.length; i++) {
                performanceNeedPracticeStrengthdata.push(
                    <NeedAndPracticeTable topic={given_array[i].topic} total={given_array[i].total} solved={given_array[i].solved} average_speed={given_array[i].average_speed} accuracy={given_array[i].accuracy} last_seen={given_array[i].last_seen} correct_within_time={given_array[i].correct_within_time} correct_exceeded_time={given_array[i].correct_exceeded_time} incorrect={given_array[i].incorrect} />
                )
            }
        }
        return performanceNeedPracticeStrengthdata;
    }
    let performanceStrengthPracticedata = createRenderingStrengthNeedPracticeData(onlyStrengthPracticeDataFive, "Strengths");
    let performanceNeedPracticedata = createRenderingStrengthNeedPracticeData(onlyNeedPracticeDataFive, "Need of practice");
    let NeedPracticeAndStrengthNull = []
    let OnlyStrengthNull = []
    let OnlyNeedPracticeNull = []
    NeedPracticeAndStrengthNull.push(<div className="" style={{ paddingLeft: "20px", paddingRight: "20px" }}><p>Woops! We do not have enough information to evaluate your performance.Click <a href='/dashboard' className="text-primary"><u>here</u></a> to take a practice test.</p></div>)
    OnlyStrengthNull.push(<div className="" style={{ paddingLeft: "20px", paddingRight: "20px" }}><p>We could not find any strengths from your performance so far. Focus on your accuracy as you practice more.Click <a href='/dashboard' className="text-primary"><u> here</u></a> to take a practice test.</p></div>)
    OnlyNeedPracticeNull.push(<div className="" style={{ paddingLeft: "20px", paddingRight: "20px" }}><p>You are doing a great! We did not spot any areas for improvement.Click <a href='/dashboard' className="text-primary"><u> here</u></a> to continue practice more.</p></div>)
    const checkDataExist = (performanceStrengthPracticedata, performanceNeedPracticedata) => {
        let performanceStrength = performanceStrengthPracticedata;
        let performanceNeed = performanceNeedPracticedata;
        let updatedPerformanceStrength = '';
        let updatedPerformanceNeed = '';
        let strengthsNeedData = [];
        if (performanceStrength.length == 0 && performanceNeed.length == 0) {
            updatedPerformanceStrength = NeedPracticeAndStrengthNull;
            updatedPerformanceNeed = NeedPracticeAndStrengthNull;
        }
        else if (performanceStrength.length != 0 && performanceNeed.length == 0) {
            updatedPerformanceStrength = performanceStrength;
            updatedPerformanceNeed = OnlyNeedPracticeNull;
        }
        else if (performanceStrength.length == 0 && performanceNeed.length != 0) {
            updatedPerformanceStrength = OnlyStrengthNull;
            updatedPerformanceNeed = performanceNeed;
        }
        else {
            updatedPerformanceStrength = performanceStrength;
            updatedPerformanceNeed = performanceNeed;
        }
        strengthsNeedData.push(updatedPerformanceStrength);
        strengthsNeedData.push(updatedPerformanceNeed);
        return strengthsNeedData;
    }
    let needStrengthsDataArray = checkDataExist(performanceStrengthPracticedata, performanceNeedPracticedata)
    return (
        <>
            <NeedStrengthComponent heading="Strengths" tabledata={needStrengthsDataArray[0]} onlyDataFive={onlyStrengthPracticeDataFive} showMore={showMore} showLess={showLess} allPracticeData={onlyStrengthPracticeData} isLoader={isLoader} />
            <NeedStrengthComponent heading="Needs Practice" tabledata={needStrengthsDataArray[1]} onlyDataFive={onlyNeedPracticeDataFive} showMore={showMore} showLess={showLess} allPracticeData={onlyNeedPracticeData} isLoader={isLoader} />
        </>
    )
}
