import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Moment from 'react-moment';
import PerformanceTab from './PerformanceTab';
import PracticeTab from './PracticeTab';
import LeaderBoard from './LeaderBoard';
import { isMobileOnly } from 'mobile-device-detect';


class Tab1 extends React.Component{
    constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
    componentDidMount() {
    //window.addEventListener('scroll', this.handleScroll);
  }

 handleScroll(event) {
        let x = document.getElementsByClassName("react-tabs__tab-list")[0];
        x.id="myHeader"
        let header = document.getElementById("myHeader");

     if(header) {
         let sticky = header.offsetTop;
         if (window.pageYOffset > sticky) {
             header.classList.add("sticky");
         } else {
             header.classList.remove("sticky");
         }
     }
  }
    render(){
        return(

<div>
<div className="perf-title">Performance Management</div>
               <Tabs>
                    <TabList>
                    <Tab>Your Performance</Tab>
                    <Tab>Review Practice</Tab>
                    <Tab>Weekly Leaderboard</Tab>
                    </TabList>
                
                    <TabPanel>
                        <PerformanceTab />
                    </TabPanel>
                    <TabPanel>
                        <PracticeTab />

                    </TabPanel>
                   <TabPanel>
                    <LeaderBoard />
                   </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Tab1
