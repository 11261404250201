import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
// import {Helmet} from "react-helmet";
import Select from "react-select";
import root_url from './../components/api_url';
import Loader from 'react-loader-spinner';

export class LearningOrganic extends Component {
    constructor() {
        super();
        this.state = {
            access_code:'',
            country_list:[],
            country_select:'',
            goal_list:[],
            goal_select:'',
            language_list:[],
            language_select:'',
            csrftoken:'',
            errorMessage: '',
            isLoader:false,

        }
        this.getGoal = this.getGoal.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
        this.getCountry = this.getCountry.bind(this);
        this.goalSelect = this.goalSelect.bind(this);
        this.getAccessCode = this.getAccessCode.bind(this);
        this.countrySelect = this.countrySelect.bind(this);
        this.languageSelect = this.languageSelect.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.loginHandler = this.loginHandler.bind(this);

        this.notAccessCode = this.notAccessCode.bind(this);
        localStorage.setItem('key', 'cb54ee07a63fc0efa9070565357541158f705a8e')
        localStorage.setItem('key_local', 'f895ed4f212ae9c78da01795c54631e4de598638')

    }
     notAccessCode(){
            this.setState({isLoader: true});
            let url=root_url+'api/utils/organization/?name=Organic+User';
            var auth="Token "+localStorage.getItem('key');
            var auth1="Token "+localStorage.getItem('key_local');
            fetch(url,{
                method:"GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':auth,
                },
                credentials: 'same-origin',
            }).then(response => response.json()).then(response => {
            if(response.count>0){
                if(response.results[0].id){
                    const code_id=response.results[0].id;
                    let url = root_url +'api/utils/organization_access_code/?organization__id='+code_id;
                          fetch(url,{
                            method:"GET",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization':auth,
                            },
                            credentials: 'same-origin',
                        }).then(response => response.json()).then(response => {
                                for(let i=0;i<response.results.length;i++){
                                  if(code_id==response.results[i].id){
                                    // document.getElementById('access_code').value = response.results[0].access_code
                                    localStorage.setItem("access_code", response.results[i].access_code);
                                    this.getAccessCode(response.results[i].access_code);
                                  }
                                }
                            });
                            this.setState({isLoader: false});
                    }
                }
            else{
            this.setState({isLoader: false});
                 this.setState({errorMessage: "Please create an Organization with the name Organic User"});
                }
            });
        
    }
    
    getAccessCode(code)	{
        
            this.setState({isLoader: true});
              let url=root_url+'api/utils/organization_access_code/?access_code='+code;
              var auth="Token "+localStorage.getItem('key');
              var auth1="Token "+localStorage.getItem('key_local');
              fetch(url,{
                  method:"GET",
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization':auth,
                  },
                  credentials: 'same-origin',
              }).then(response => response.json()).then(response => {
              this.setState({isLoader: false});
                  if (response.count>0){
                    let org_capacity = response.results[0].capacity;
                    let goal = response.results[0].goal;
                    let language = response.results[0].language;
                    let country = response.results[0].country ;
                    if(org_capacity=='unlimited' || org_capacity=='Unlimited')
                        {
                        this.setState({errorMessage: ''});
                        if (goal !== null ){
                         let url=root_url+'api/utils/goal/?id='+goal;
                            this.getGoal(url);
                         }
                         else{
                         let url=root_url+'api/utils/goal/';
                            this.getGoal(url);
                         }
                         if (language !== null ){
                         let url =root_url+'api/utils/language/?id='+language;
                            this.getLanguage(url);
                         }
                         else{
                         let url =root_url+'api/utils/language/';
                            this.getLanguage(url);
                         }
                         if (country !== null ){
                         let url = root_url+'api/utils/country/?id='+country;
                            this.getCountry(url);
                         }
                         else{
                            let url = root_url+'api/utils/country/';
                            this.getCountry(url);
                         }
                        }
                   else{
                        this.setState({errorMessage: ''});
                        let url = root_url+'api/utils/org_capacity/?access_code='+code
                         fetch(url,{
                                method:"GET",
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization':auth,
                                },
                                credentials: 'same-origin',
                            }).then(response => response.json()).then(response => {
                                if(response.results >= parseInt(org_capacity)){
                                    this.setState({errorMessage: "Your Organization's capacity is full. Please contacts your Administration for more details."});
                                }
                                else{
                                    if (goal !== null ){
                                     let url=root_url+'api/utils/goal/?id='+goal;
                                        this.getGoal(url);
                                     }
                                     else{
                                     let url=root_url+'api/utils/goal/';
                                        this.getGoal(url);
                                     }
                                     if (language !== null ){
                                     let url =root_url+'api/utils/language/?id='+language;
                                        this.getLanguage(url);
                                     }
                                     else{
                                     let url =root_url+'api/utils/language/';
                                        this.getLanguage(url);
                                     }
                                     if (country !== null ){
                                     let url = root_url+'api/utils/country/?id='+country;
                                        this.getCountry(url);
                                     }
                                     else{
                                        let url = root_url+'api/utils/country/';
                                        this.getCountry(url);
                                     }
                                }
                            });
                        }
                      }
                  else{
                  this.setState({isLoader: false});
                  this.setState({errorMessage: 'Yor are not recognized under any Organization. Please contacts your Administration for more details.'});
                  }
        
                  });
              }
        
   
    goalSelect = goal_select =>{
            this.setState({goal_select});
            localStorage.setItem('goal', goal_select.value);
        }

    countrySelect = country_select =>{
        this.setState({country_select});
        localStorage.setItem('country', country_select.value);
    }

    languageSelect = language_select =>{
        this.setState({language_select});
        localStorage.setItem('language', language_select.value);
    }
    getGoal(url) {
  	var auth="Token "+localStorage.getItem('key');
  	var auth1="Token "+localStorage.getItem('key_local');
  	fetch(url,{
  		method:"GET",
  		headers: {
  			'Accept': 'application/json',
  			'Content-Type': 'application/json',
  			'Authorization':auth,
  		},
  		credentials: 'same-origin',
  	}).then(response => response.json()).then(response => {
  		if (response.count>0){
  			 	this.setState({
  					goal_list:response.results
  				})
  				if(response.count==1){
  				    this.setState({
  					    goal_select:{value: response.results[0].id, label: response.results[0].name}
  				    })
                    localStorage.setItem('goal', this.state.goal_select.value);
  				}
  				this.setState({isLoader: false});
  			}
  		});
  	}
  	getLanguage(url) {
  	var auth="Token "+localStorage.getItem('key');
  	var auth1="Token "+localStorage.getItem('key_local');
  	fetch(url,{
  		method:"GET",
  		headers: {
  			'Accept': 'application/json',
  			'Content-Type': 'application/json',
  			'Authorization':auth,
  		},
  		credentials: 'same-origin',
  	}).then(response => response.json()).then(response => {
  		if (response.count>0){
  			 	this.setState({
  					language_list:response.results
  				})
  				if(response.count==1){
  				    this.setState({
  					    language_select:{value: response.results[0].id, label: response.results[0].name}
  				    })
                    localStorage.setItem('language', this.state.language_select.value);
  				}
  				this.setState({isLoader: false});
  			}
  		});
  	}

  	getCountry(url) {
  	var auth="Token "+localStorage.getItem('key');
  	var auth1="Token "+localStorage.getItem('key_local');
  	fetch(url,{
  		method:"GET",
  		headers: {
  			'Accept': 'application/json',
  			'Content-Type': 'application/json',
  			'Authorization':auth,
  		},
  		credentials: 'same-origin',
  	}).then(response => response.json()).then(response => {
  		if (response.count>0){
  			 	this.setState({
  					country_list:response.results
  				})
  				if(response.count==1){
  				    this.setState({
  					    country_select:{value: response.results[0].id, label: response.results[0].name}
  				    })
                    localStorage.setItem('country', this.state.country_select.value);
  				}
  				this.setState({isLoader: false});
  			}
  		});
  	}

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/js/select2.min.js";
        script.async = true;
        this.notAccessCode();
        document.body.appendChild(script);
    }

    componentWillMount(){
        let name="csrftoken";
        let cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = $.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
//        console.log("cokie", cookieValue)
        this.setState({
            csrftoken:cookieValue
        })
    }



    loginHandler(user_username,user_password) {
       // alert("for check");
       // e.preventDefault();
        let email = user_username
        let password = user_password
        let url = root_url + 'api/accounts/login/';
        fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: email,
                    password: password,
                })
            }).then(response =>{
                if(response.status===400){
                    return response.json();
                }
                else if(response.status===200){
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response=>{
                if(response.email){
                    this.setState({isLoader: false});
                    this.setState({errorMessage: response.email[0]});
                    // alert(response.email[0]);
                }
                else if (response.key) {
                        let key = response.key;
                        localStorage.setItem('key', key);
                        // alert("Logged in Successfully");
                        let url1 = root_url + 'api/profile/profile/';
                        let auth = "Token " + localStorage.getItem('key')
                        fetch(url1, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth
                            },

                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            } else {
                                return false;
                            }
                        }).then(response => {
                            let email = response.results[0].user.email;
                            let id = response.results[0].user.id;
                            localStorage.setItem('user_id', id);
                            localStorage.setItem('is_timed', 'true');
                            localStorage.setItem('email', email);

                        })
                    document.location.href = document.location.origin + '/dashboard';
                    }
                else {
                    this.setState({
                        errorMessage: response.non_field_errors
                    })
                    this.setState({isLoader: false});
                    // alert(response.non_field_errors[0]);
                }
            })
        }

    
    submitHandler(e){
        e.preventDefault();
        let email=localStorage.getItem('email');
        let first_name = localStorage.getItem('first_name');
        let last_name = localStorage.getItem('last_name');
        let mobile = localStorage.getItem('mobile');
        let goal = this.state.goal_select;
        let language = this.state.language_select;
        let country = this.state.country_select;
        let get_goal = localStorage.getItem('goal');
        let get_language = localStorage.getItem('language');
        let get_country = localStorage.getItem('country');
        let get_access_code = localStorage.getItem('access_code');
        let error = this.state.errorMessage;
        let gol = [get_goal]

        if(get_goal===''){
            alert("Goal can't be left blank");

        }

        else if(get_country===''){
            alert("Country can't be left blank");

        }

        else if(get_language===''){
            alert("Language can't be left blank" );

        }

        else if(get_access_code===''){
            alert("Access Code can't be left blank");

        }
        else if(error!==''){
            alert("Your Organization is not allowing you.Please contact your administrator for more details.")
        }
        else {
            let url = root_url + 'api/profile/update/?email='+email;
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrftoken,
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                    username: email,
                    first_name: "NULL",
                    last_name: "NULL",
                     "profile": {
                         "mobile": "NULL",
                         // "board": null,
                         "country": get_country,
                         "goal": gol,
                         "language": get_language,
                         "access_code": get_access_code,
                     }
                })
            }).then(response =>{
                if(response.status===200){
                    return response.json();
                }
                else if(response.status===400){
                    return response.json();
                }
                else{
                    return false;
                }
            }).then(response=>{
                if (response.email) {
                    localStorage.setItem('user', response.email);
                   // alert("Profile Updated.");
                   let user_username=localStorage.getItem('user_email');
                   let user_password=localStorage.getItem('user_pass');
                   if(user_username){
                   this.loginHandler(user_username,user_password);
                   }
                   else{
                   document.location.href = document.location.origin;
                }
                }
                if(response.goal){
                    alert("Goal : " + response.goal);
                }
                if(response.country){
                    alert("Country : " + response.country);
                }

            })
        }
    }



    render() {
        let goal_dropdown = []
        let country_dropdown = []
        let board_dropdown = []
        let language_dropdown = []
        const {goal_select} = this.state;
        const {board_select} = this.state;
        const {language_select} = this.state;
        const {country_select} = this.state;

        if(this.state.goal_list !== undefined){
            for(let i=0; i<this.state.goal_list.length;i++){
                goal_dropdown.push(
                    {value: this.state.goal_list[i].id, label: this.state.goal_list[i].name}
                )
            }
        }
        else{
			goal_dropdown.push(
				{value: '', label: 'No Goal Found'}
				)
		}
        if(this.state.country_list !== undefined){
            for(let i=0; i<this.state.country_list.length;i++){
                country_dropdown.push(
                    {value: this.state.country_list[i].id, label: this.state.country_list[i].name}
                )
            }
        }
        else{
			country_dropdown.push(
				{value: '', label: 'No Country Found'}
				)
		}
        if(this.state.board_list !== undefined){
            for(let i=0; i<this.state.board_list.length; i++){
                board_dropdown.push(
                    {value: this.state.board_list[i].id, label: this.state.board_list[i].name}
                )
            }
        }
        else{
            board_dropdown.push(
                {value: '', label: 'No Board Found'}
            )
        }
        if(this.state.language_list !== undefined){
            for(let i=0; i<this.state.language_list.length; i++){
                language_dropdown.push(
                    {value: this.state.language_list[i].id, label: this.state.language_list[i].name}
                )
            }
        }
        else{
            language_dropdown.push(
               {value:'', label: 'No Language Found'}
            )
        }
        return (
            <div>
                <section className="forgot-pass learning">
                    <div className="container">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row">
                                <div className="col">
                                    <div className="dots"><Link to="login_help.html"><img src={require("./../../assests/img/dots.jpg")} /></Link></div>
                                    <div className="close"><img src={require("./../../assests/img/cross.jpg")} /></div>
                                </div>
                                </div>
                                <div className="row pt-2">
                                <div className="col">
                                    <div className="form-img"><img src={require("./../../assests/img/form-top-img2.png")} /></div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col">
                                    <div className="divh3">
                                        <h3>Few to help us personalise learning</h3>
                                    </div>
                                </div>
                                </div>
                                <div className="row loginhform">
                                <div className="col">
                                 
                                    
                                    <div className="row form-group ">
                                        <div className="col-md-12 pt-2 m-auto">
                                            <Select value={country_select} placeholder='Select Country..' onChange={this.countrySelect} options={country_dropdown} className=""/>
                                        </div>
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-md-12 pt-3 m-auto">
                                            {/* <!-- <label></label> --> */}
                                            <Select value={goal_select} placeholder='Select Goal..' onChange={this.goalSelect} options={goal_dropdown}/>
                                        </div>
                                    </div>

                                    <div className="row form-group" >
                                        <div className="col-md-12 pt-3 m-auto">
                                            {/* <!-- <label></label> --> */}
                                            <Select value={language_select} placeholder='Select Language..'  onChange={this.languageSelect} options={language_dropdown}/>
                                        </div>
                                    </div>

                                </div>
                                </div>
                                <div className="row sign-in pt-5">
                                <div className="col-md-12">
                                    <div className="common-btn pt-5"><button onClick={this.submitHandler} id="learningorganic-id">Go!</button>
                                    {/*<div className="common-btn pt-5"><button onChange={this.submitHandler}><Link to="dash1">Go!</Link></button>*/}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<Helmet>*/}
                {/*    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/css/select2.min.css" rel="stylesheet" />*/}
                {/*    <script>$('.select2').select2();</script>*/}
                {/*</Helmet>*/}

            </div>
        )
    }
}

export default LearningOrganic