import root_url from './../components/api_url';
import {CallingSamplePaperQuestion} from './CallingSamplePaperQuestion.js';


export  function BeginPractice()  {
    let verified = localStorage.getItem('user_verified')
        if (verified == "true") {
            let test_time=localStorage.getItem('samplePaperTimeInMinutes')?localStorage.getItem('samplePaperTimeInMinutes'):90;
            let topic_list=JSON.parse(localStorage.getItem('sample_papers_topics_name')) 
            let topic_ids=JSON.parse(localStorage.getItem('samplePaperTopicIds'))
            let auth = "Token " + localStorage.getItem('key');
            let url = root_url + 'api/utils/practice/';
            let goal = localStorage.getItem('goal');
            let user_id = localStorage.getItem('user_id');
            let subject = localStorage.getItem('subject_selected');
            let is_timed = localStorage.getItem('is_timed');
            let s_name = localStorage.getItem('subject_name');
            let g_name = localStorage.getItem('goal_name');
            let tempDate = new Date();
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let end_time = new Date(tempDate);
            end_time.setMinutes(tempDate.getMinutes() + parseInt(test_time));
            let new_end_time = end_time.getFullYear() + '-' + (end_time.getMonth() + 1) + '-' + end_time.getDate() + ' ' + end_time.getHours() + ':' + end_time.getMinutes() + ':' + end_time.getSeconds();
            let final_data = {
                "goal_name": g_name,
                "subject_name": s_name,
                "topic_name": topic_list.join(),
                "timed": is_timed,
                "start_datetime": start_time,
                "end_datetime": new_end_time,
                "timer_start": start_time,
                "timer_end": new_end_time,
                "user": user_id,
                "goal": goal,
                "subject": subject,
                "topic": topic_ids
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify(final_data)
            }).then(response => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                let practice = response.id;
                let start_time = response.start_datetime;
                let end_time = response.end_datetime;
                localStorage.setItem('practice_id', practice);
                localStorage.setItem('practice_start_datetime', start_time);
                localStorage.setItem('practice_end_datetime', end_time);
                if (is_timed === 'true') {
                    let SamplePaperId=localStorage.getItem('samplePaperID')
                    let QuestionIndex=localStorage.getItem('samplePaperQuestionCount')
                    localStorage.setItem("backdrop_open","true")
                    localStorage.setItem("skip_question",0)
                   CallingSamplePaperQuestion(SamplePaperId, QuestionIndex);
                } 
            })
        }

}

