import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EndPracticeDetail from './EndPracticeDetail'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function EndPracticeInformation(props) {
    return (
        <>
            <div className="d-flex flex-column  align-items-center pl-2 pr-2 pt-2 pb-5 mt-2 h-80 overflow-auto"  >
                <div className="pop2-img"><img src={require("./../../assests/img/pop-img.jpg")} /></div>
                <div className="text-secondary pt-3">
                    <div className="col">
                        <div className="divh3 text-center">
                            <h3> {props.message}</h3></div>
                    </div>
                </div>
                <div className="col-12 col-lg-8 pl-3 pr-3 pt-2 pb-5">
                    <EndPracticeDetail icon={<EventAvailableIcon color="action" fontSize="medium" />} heading="Score" data={`${props.score} points`} />
                    <EndPracticeDetail icon={<ViewModuleIcon color="action" fontSize="medium" />} heading="Solves" data={`${props.solved} Questions`} />
                    <EndPracticeDetail icon={<CheckCircleIcon color="action" fontSize="medium" />} heading="Accuracy" data={`${props.accuracy}%`} />
                    <EndPracticeDetail icon={<WatchLaterIcon color="action" fontSize="medium" />} heading="Total Time" data={`${props.totalTime} minutes`} />
                    <EndPracticeDetail icon={<AssistantPhotoIcon color="action" fontSize="medium" />} heading="Average Speed" data={`${props.minutes} min ${props.seconds} sec`} />
                </div>
                {props.showReviewButton ?
                    <div className="w-100  d-flex justify-content-center  pt-4 pb-4">
                       <Link to={`/review/`}> <Button variant="contained" sx={{ borderRadius: 28, backgroundColor: "#5d39db", textTransform: 'capitalize', paddingLeft: 5, paddingRight: 5 }}>Review</Button></Link>
                    </div> : null}
            </div>

        </>
    );
}
