import React, { Component } from 'react';
import { DateRangePicker } from 'react-date-range';

class LeaderBoard extends React.Component{
    handleSelect(ranges){
        console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
      }
    render(){
        return(
            <div id="tab3" className="tabcontent tab2-cont tab3-cont mob-filter-review">

                     <div class="row mob-design">
                         <div class="col">


                        <div class="row weekly-mod-cont pt-3">
                        <div class="col">
                        <h3>Weekly LeaderBoard</h3>

                        <div class="week-mob-table">
                        <table class="table">

    <tbody>
      <tr>
        <td>#1</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
        <tr>
        <td>#2</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
          <tr>
        <td>#3</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
          <tr>
        <td>#4</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
          <tr>
        <td>#5</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
          <tr>
        <td>#6</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
           <tr>
        <td>#7</td>
        <td><img src={require("./../../../assests/img/profile.jpg")} /></td>
        <td><h4>T Ritika Singh <br/><span>Score-330</span></h4></td>
        <td>
        <div class="week-acuracy-mob"><h5>Accuracy: <span>95%</span></h5></div>
        <div class="week-acuracy-mob"><h5>Avg. Speed <span>3:25</span></h5></div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="last-five-mob-btn pt-3"> <button> Show More </button></div>
                        </div>


                        </div>
                        </div>





               </div>
               </div>

<div class="row mob-side-nav">
      <div class="col">
         <ul>
          <li><a href=""><img src={require("./../../../assests/img/side-icon-2.png")} />Practice</a></li>
          <li><a href=""><img src={require("./../../../assests/img/side-icon-3.png")} />Performance </a></li>

        </ul>
      </div>
    </div>





                                  <div className="row pt-3 bdr-shad  weekly-mob">
                                      <div className="col">
                                        <div className="row  pt-2 perform-tab-in">
                                            <div className="col">
                                              <div className="tabs ">
                                                  <ul className="tabs-nav">
                                                    <li><a href="#tab-1">Overall</a></li>
                                                    <li><a href="#tab-2">Maths</a></li>
                                                    <li><a href="#tab-3">Physics</a></li>
                                                    <li><a href="#tab-4">Chemistry</a></li>
                                                    <li><a href="#tab-5">Biology</a></li>
                                                  </ul>
                                                  <div className="tabs-stage">
                                                    <div id="tab-1" className="weekly-tab1">
                                                        <table className="table ">
                                                          <thead>
                                                              <tr>
                                                                <th>User Name</th>
                                                                <th>Score</th>
                                                                <th>Accuracy</th>
                                                                <th>Speed</th>
                                                                <th>Current Rank</th>
                                                                <th>Previous Rank</th>
                                                                <th>Delta</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile-pic.png")} /></span>T Ritika Singh</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-up" aria-hidden="true"></i>4</td>
                                                              </tr>
                                                              <tr className="selected">
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Arun Thakwale</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-up" aria-hidden="true"></i>1</td>
                                                              </tr>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Mallika Chopra</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-up" aria-hidden="true"></i>5</td>
                                                              </tr>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Jhon Decosta</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-down" aria-hidden="true"></i>3</td>
                                                              </tr>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Jhon Decosta</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-down" aria-hidden="true"></i>3</td>
                                                              </tr>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Jhon Decosta</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-down" aria-hidden="true"></i>3</td>
                                                              </tr>
                                                              <tr>
                                                                <td><span><img src={require("./../../../assests/img/profile.jpg")} /></span>Jhon Decosta</td>
                                                                <td>330</td>
                                                                <td>95%</td>
                                                                <td>3:30min</td>
                                                                <td>#1</td>
                                                                <td>#5</td>
                                                                <td><i className="fa fa-caret-down" aria-hidden="true"></i>3</td>
                                                              </tr>
                                                          </tbody>
                                                        </table>
                                                    </div>
                                                    <div id="tab-2">
                                                    </div>
                                                    <div id="tab-3">
                                                    </div>
                                                    <div id="tab-4">
                                                    </div>
                                                    <div id="tab-5">
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                
        )
    }
}

export default LeaderBoard