import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Loader from './Loader'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999999,
    color: '#fff',
  },
}));

export default function BackDrop(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(5);
  const CountDownStartTest = (given_number) => {
    props.beginPractice()
  }
  const CountDownTimedQuestion = (given_number) => {
    let n = given_number;
    let timer = setInterval(function () {
      n = n - 1;
      if (n > -1) {
        setCounter(n);
      }
      if (n === 0) {
        clearInterval(timer);
        setOpen(false);
        localStorage.setItem("backdrop_open", "false");
        if(props.component == "timed_question" || props.component == "samplePaper_question" ){
          props.changePauseTimerState();
        }
        
      }
    }, 1000);
   
  }

  useEffect(() => {
    setOpen(props.openStatus);
    if (props.component == "timed_question" || props.component == "samplePaper_question") {
      if (props.openStatus) {
        CountDownTimedQuestion(5);
      }
    }
    if (props.component == "start_test") {
      if (props.openStatus) {
        CountDownStartTest(3);
      }
    }
    if (props.component == "start_sample_paper_test") {
      if (props.openStatus) {
        props.getIntialQuestionSamplepaper()
      }
    }
    

  }, [props.openStatus])



  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        {props.component == "timed_question" || props.component == "samplePaper_question" ?
          counter > 0 ? <h1 style={{ color: 'white', fontSize: '120px' }}>{counter}</h1> : <Loader color="white" height="120px" width="120px" />
          :
          <Loader color="white" height="120px" width="120px" divHeight="h-100" />}
      </Backdrop>
    </div>
  );
}
