import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import NoteIcon from '@material-ui/icons/Note';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles({
  root: {
    width: "100%",
    background: 'white',
    height: '100%',
    paddingRight: 10,
  },
});

export default function Bottombar(props) {
  const classes = useStyles();
  const history = useHistory();
  const handleChange = (given_route) => {
   history.push(`/${given_route}`);
   }
  return (
    <>
      <div className="row mob-side-nav perm-bot-nav h-10">
        <div className="w-100 d-flex align-content-end flex-wrap border-top h-100 " >
          <BottomNavigation
            value={props.value}
            onChange={(event, newValue) => {
              handleChange(newValue)
            }}
            showLabels
            className={classes.root}
          >
            {(localStorage.getItem('userGoal') == 'Goal1001-GSEB Class 10' || localStorage.getItem('userGoal') == 'Goal1000-CBSE Class 10') ?
              <BottomNavigationAction value="assignments" label="Assignments " icon={<PostAddIcon />} /> : ''}
            <BottomNavigationAction value="dashboard" label="Practice" icon={<NoteIcon />} />
            {/*<BottomNavigationAction value="assignments" label="Assignments " icon={<PostAddIcon />} />*/}
            <BottomNavigationAction value="perform" label="Performance" icon={<TrendingUpIcon />} />
          </BottomNavigation>
        </div>
      </div>
    </>
  );
}
