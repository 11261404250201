import React from 'react';
import LeftSideBar from './LeftSideBar';
import MainbodyPanel from './MainbodyPanel'
import Dash from './../reusecomponent/dash';

export default function AssignmentsDesktop() {
    return (
        <div className="d-flex flex-column w-100" style={{ height: '100vh' }} >
            <Dash />
            <div className='d-flex mt-5'>
                <div className='h-100'><LeftSideBar activeTab="Assignments" toggled={true} /></div>
                <div className='h-100'> <MainbodyPanel childComponent="Assignments" /></div>
            </div>
        </div>
    )

}

