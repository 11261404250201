import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import root_url from './api_url';
import AssignmentMobile from './AssignmentMobile';
import { isMobileOnly } from 'mobile-device-detect';
import AssignmentsDesktop from './AssignmentsDesktop';


export default function Assignments() {
    const query = useLocation().search;
    const given_key = new URLSearchParams(query).get('User-Token');
    const given_contact = new URLSearchParams(query).get('User-Mobile');
    const given_goal = new URLSearchParams(query).get('User-Goal');
    const history = useHistory();
    const location = useLocation();

    if (given_key) {
        localStorage.setItem('key', given_key);
        localStorage.setItem("UserType", "Unpaid");
        localStorage.setItem('mobile', given_contact);
        localStorage.setItem('userGoal', given_goal);
        localStorage.setItem('goal_name',given_goal);
    }

    const mobileNumberFromLocalStorage = localStorage.getItem('mobile');
    const getPaymentInfo = (mobileNumber) => {
        const paidUserUrl = root_url + `user_payment_detail/${mobileNumber}/`;
        fetch(paidUserUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.message == "User payment record found" && response.status == 201) {
                localStorage.setItem("UserType", "Paid");
                history.push("/sample-papers");
                
            } else {
                localStorage.setItem("UserType", "Unpaid");
                history.push("/assignments");
            }
        })
    }

    React.useEffect(() => {
        const userType = localStorage.getItem("UserType");
        if (!!userType) {
           if (userType === "Paid" && location.pathname !== "/sample-papers") {
                history.push("/sample-papers");
                
            } else {
                getPaymentInfo(mobileNumberFromLocalStorage);
            }

        } else {
            getPaymentInfo(mobileNumberFromLocalStorage);
        }
    }, [mobileNumberFromLocalStorage]);

    return (
        <>
        {isMobileOnly?<AssignmentMobile />:<AssignmentsDesktop />}
       
    </>
    )

}

