import React, { Component } from 'react';
import root_url from './../components/api_url';
let is_paused='';

export class Timer extends Component {
    constructor(props) {
        super(props);
        this.state={
        pause: this.props.paused,
        minutes: localStorage.getItem('minutes')?localStorage.getItem('minutes'):40,
        seconds: localStorage.getItem('seconds')?localStorage.getItem('seconds'):0,
        is_paused: localStorage.getItem('is_paused')?localStorage.getItem('is_paused'):'false'
        }
        this.saveBookmark = this.saveBookmark.bind(this)
        // this.myPauseInterval = this.myPauseInterval.bind(this)
        // this.getPracticeTime = this.getPracticeTime.bind(this)
    }

    // getPracticeTime(e){
    //     e.preventDefault();
    //     let id = localStorage.getItem('practice_id');
    //     let auth = "Token "+localStorage.getItem('key');
    //     let url = root_url+'api/utils/practice/'+id+'/';
    //     fetch(url,{
    //         method: 'GET',
    //         headers:{
    //             "Authorization":auth,
    //             "Content-Type":'application/json',
    //             "Accept":'application/json',
    //         }
    //     }).then(response=>{
    //         if(response.status===200){
    //             return response.json();
    //         }
    //         else
    //             return false;
    //     }).then(response=>{
    //         console.log(response);
    //     })
    // }

    saveBookmark(e){
        e.preventDefault();
        let auth = "Token "+localStorage.getItem('key');
        let url = root_url+'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();

        fetch(url, {
            method: 'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },
            body:JSON.stringify({
               "action_name": 'bookmark',
               "action_id": "bookmark"+practice_id,
               "action_timestamp": start_time,
               "practice_question_detail": practice_id

            })
        }).then(response=>{
            if(response.status===201){
                return response.json();
            }
            else{
                return false;
            }
        }).then(response=>{
           console.log(response);
        })
    }

    componentDidMount() {
        console.log('passing value==================', this.state.pause);
        let tempDate = new Date();
        let current_time = tempDate.getFullYear() + '/' + (tempDate.getMonth()+1) + '/' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
        console.log("current------------------------", current_time);
        let end_time = localStorage.getItem('practice_end_datetime');
        console.log("end------------------------", end_time);
        let end_t =Date.parse(end_time);
        let tempDate1 = new Date(end_t);
        let time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth()+1) + '-' + tempDate1.getDate() +' '+ tempDate1.getHours()+':'+ tempDate1.getMinutes()+':'+ tempDate1.getSeconds();
        let time1 = new Date(time);
        time1.setMinutes ( tempDate1.getMinutes() - 330 );
        let time2 = time1.getFullYear() + '/' + (time1.getMonth()+1) + '/' + time1.getDate() +' '+ time1.getHours()+':'+ time1.getMinutes()+':'+ time1.getSeconds();
        let diffInMilliseconds = 0
        let minutes = 0;
        let seconds = 0;
        if(Date.parse(current_time) < Date.parse(time2)){
               diffInMilliseconds = Math.abs(new Date(time2) - new Date(current_time));
               minutes = Math.floor(diffInMilliseconds / 60000);
               seconds = ((diffInMilliseconds % 60000) / 1000).toFixed(0);

            }else{
            console.log('false');
            console.log('current time==========', current_time, 'end time=========', time2);
            minutes = Math.floor(diffInMilliseconds / 60000);
            seconds = ((diffInMilliseconds % 60000) / 1000).toFixed(0);
            console.log("====else====minutes=======", minutes);
            console.log("=====else===seconds=======", seconds);
            document.getElementById('end_practice').click();
            }
        this.setState({
            minutes:minutes,
            seconds:seconds,
        })
        // let pause = this.state.is_paused;
        // console.log('================pause', is_paused)
        // if(is_paused==='false') {
            console.log('run counter');
            this.myInterval = setInterval(() => {
                const {seconds, minutes} = this.state

                if (seconds > 0) {
                    this.setState(({seconds}) => ({
                        seconds: seconds - 1
                    }))
                    localStorage.setItem('seconds', seconds);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this.myInterval)
                    } else {
                        this.setState(({minutes}) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                       localStorage.setItem('seconds', seconds);
                       localStorage.setItem('minutes', minutes);
                    }
                }
            }, 1000)
        // }
        // else{
        //     console.log('stop counter');
        //   this.myPauseInterval = setInterval(()=>{
        //    let { seconds, minutes } = this.state
        //     console.log('called the function from question', seconds, minutes);
        //     if (seconds > 0) {
        //         this.setState(({ seconds }) => ({
        //             seconds: seconds
        //         }))
        //     }
        //     if (seconds === 0) {
        //         if (minutes === 0) {
        //             // clearInterval(this.myPauseInterval)
        //         } else {
        //             this.setState(({minutes}) => ({
        //                 minutes: minutes
        //             }))
        //         }
        //     }
        // }, 1000)
        // }
    }



    componentWillUnmount() {
        this.setState({
            is_paused:localStorage.getItem('is_paused')?localStorage.getItem('is_paused'):'false',
        })
        // let is_paused = this.state.is_paused;
        console.log("====================================componentwillmount", this.state.is_paused);
        // if(is_paused==='true') {
        //     console.log('calling paused', is_paused)
        //     clearInterval(this.myPauseInterval)
        // }
        // else{
        //     console.log('calling resume', is_paused)
        //     clearInterval(this.myInterval)
        // }
    }

    render () {
        is_paused = localStorage.getItem('is_paused');
        // console.log("====================================render", is_paused);
        let score = localStorage.getItem("score");
        let time = localStorage.getItem("time");
        let id = localStorage.getItem("question_id");
        let time_in_minutes = Math.floor(time/60);
        let time_in_seconds = time - time_in_minutes * 60;
        const { minutes, seconds } = this.state


        return (
            <div className="row dash-2-bdr ipad-responsive">
                <div className="col-md-3">
                    <div className="divh4">
                        <h4>Question id - Q00{id} (Score: {score} points)</h4>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <ul>
                        <li><img src={require("./../../assests/img/flg.jpg")}  data-toggle="modal" onClick={this.saveBookmark} data-target="#myModal4" /></li>
                        <li><img src={require("./../../assests/img/share.jpg")}  data-toggle="modal" data-target="#myModal" /></li>
                        <li><img src={require("./../../assests/img/error.png")} width="22" data-toggle="modal" data-target="#myModal5"/></li>
                    </ul>
                </div>
                <div className="col-md-2 c-4 offset-5 clock text-right">
                    <img src={require("./../../assests/img/clock.jpg")} /> <span>{ minutes === 0 && seconds === 0
                    ? <h1>0:0</h1>
                    : <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
                }</span>
                </div>
            </div>
        )
    }
}

export default Timer