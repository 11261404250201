import root_url from './../components/api_url';
const axios = require('axios');

export  function  SavedSolvedSamplePaper(user,samplePaperID,samplePaperName,practiceTestId,startTime,productInfo){
    let payload={
            "user":user,
            "productinfo":productInfo,
            "sample_paper_name":samplePaperName,
            "sample_paper_id":samplePaperID,
            "practice_test_id":practiceTestId,
            "start_datetime":startTime,
            "end_datetime":null
        }
    axios.post(`${root_url}user_solved_sample_paper/`,payload)
            .then((res) => {
                let api_result=res.data;
                if(api_result.message=='Sample paper solving process start' && api_result.status_code==201){
                   document.location.href = document.location.origin + '/sample-papers-test'
                }
            })
            .catch((err) => {
                console.log(err);
            });
}


