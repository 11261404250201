import React, { Component } from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import ButtonMob from './TopBottomBar/BurttonMob'
import { useEffect, useState } from 'react';
import SamplePaperTestInstruction from './SamplePaperTestInstruction'
import { BeginPractice } from './BeginPractice.js';
import { isMobileOnly } from 'mobile-device-detect';
import DesktopTopbar from './DesktopTopbar'
import LeftSideBar from './LeftSideBar';
import MainbodyPanel from './MainbodyPanel';
import Dash from './../reusecomponent/dash';


export default function TestStartSamplePaper() {
    const [open, setOpen] = useState(false);
    const [start, setStart] = useState(false);
    const modalStatusChange = () => {
        setOpen(true);
        setStart(true);
    }

    const getIntialQuestionSamplepaper = () => {
        BeginPractice()
    }
    return (
        <>
            {isMobileOnly ? <div className="d-flex flex-column w-100" style={{ height: '100vh' }}>
                <Topbar backButton={false} topbarHeading="Test Instructions" />
                <SamplePaperTestInstruction modalOpen={open} getIntialQuestionSamplepaper={getIntialQuestionSamplepaper} />
                <ButtonMob button_name="Start Test" status={start} modalStatusChange={modalStatusChange} />
                <Bottombar value="assignments" />
            </div> :
                <div className="d-flex flex-column w-100" style={{ height: '100vh' }} >
                    <Dash />
                    <div className='d-flex'>
                        <div className='h-100'><LeftSideBar activeTab="Assignments" toggled={true}/></div>
                        <div className='h-100 w-100 mt-5'> <MainbodyPanel childComponent="SamplePaperTestStart" modalOpen={open} getIntialQuestionSamplepaper={getIntialQuestionSamplepaper} modalStatusChange={modalStatusChange} /></div>
                    </div>
                </div>}

        </>
    );
}
