import React from 'react';
import { useEffect, useState } from 'react';
import root_url from './../components/api_url';
import Loader from './Loader'
import BackDrop from './BackDrop';
import { isMobileOnly } from 'mobile-device-detect';
import TopBarTestPage from './TopBottomBar/TopBarTestPage';
import Editor from "react-editor-md";
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReviewRightSidePanel from './ReviewRightSidePanel'
import 'react-circular-progressbar/dist/styles.css';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SamplePaperSection from './SamplePaperSection';
import SamplePaperReviewSidePanelDetail from './SamplePaperReviewSidePanelDetail'
import { useHistory, useLocation } from 'react-router-dom';
import SamplePaperQuestionDesktop from './SamplePaperQuestionDesktop'
import QuestionCard from './QuestionCard';
import TabComponent from './Reacttab/TabComponent';
import ButtonMob from './TopBottomBar/BurttonMob';

const axios = require('axios');
let myInterval;

export default function SamplePaperQuestion() {
    const history = useHistory();
    const [questionData, setQuestionData] = useState({
        'QuestionId': '',
        'QuestionScore': '',
        'QuestionContent': '',
        'QuestionOption': '',
        'QuestionTopic': '',
        'QuestionOptions': [],
        'QuestionCount': 0,
        'QuestionSection': '',
    })
    const [reviewQuestionData, setReviewQuestionData] = useState({
        'TotalSkipped': 0,
        'TotalNotVisited': 0,
        'TotalAnswered': 0,
        'TotalBookmarked': 0,
        'TotalAnsweredBookmarked': 0,
    })
    const [reviewQuestion, setReviewQuestion] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const [optionSelected, setOptionSelected] = useState('');
    const [percentage, setPercentage] = useState('');
    const [pause, setPause] = useState(false);
    const [countdown_pause, setCountdown_pause] = useState(localStorage.getItem('countdown_pause') == 'false' ? false : true);
    const [countdown_seconds, setCountdown_seconds] = useState(localStorage.getItem("countdown_seconds") ? localStorage.getItem("countdown_seconds") : 0);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [showTab, setShowTab] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    useEffect(() => {
        setQuestionData({
            'QuestionId': localStorage.getItem('question_id') ? localStorage.getItem('question_id') : '',
            'QuestionScore': localStorage.getItem('question_score') ? localStorage.getItem('question_score') : '',
            'QuestionContent': localStorage.getItem('question_content') ? localStorage.getItem('question_content') : '',
            'QuestionTopic': localStorage.getItem('question_topic') ? localStorage.getItem('question_topic') : '',
            'QuestionOptions': localStorage.getItem('question_options') ? JSON.parse(localStorage.getItem('question_options')) : '',
            'QuestionCount': localStorage.getItem("questions_count") ? localStorage.getItem("question_count") : 0,
            'QuestionSection': localStorage.getItem('question_section') ? localStorage.getItem('question_section') : 'section1',
        });
        setIsVisible(true);
        setIsLoader(false);
        storeCorrectOption(JSON.parse(localStorage.getItem('question_options')));
        callingRemaningSamplePaperQuestions();
        callReviewQuestions();
    }, [])

    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type == 1) {
                clearInterval(myInterval);
                myInterval = null;
                callingTimerInterval();
            }
        }

    }, []);

    const getSelectedOption = (selected_options) => {
        setOptionSelected(selected_options);
        localStorage.setItem("selected_option", selected_options);
        localStorage.removeItem('selectedOptionReviewQuestion')
    }

    const storeCorrectOption = (given_option_list) => {
        let option_list = given_option_list;
        for (let i = 0; i < option_list.length; i++) {
            if (option_list[i].is_correct === true) {
                localStorage.setItem('match', option_list[i].option)
            }
        }
    }

    const getSkipQuestion = () => {
        let review_questions = reviewQuestion
        let filtered_obj = review_questions.find(o => o.question === questionData.QuestionId);
        if (filtered_obj !== undefined) {
            if (filtered_obj.status == null && filtered_obj.choice_selected == null) {
                skipQuestion();
            }
            else {
                let quesCount = localStorage.getItem("questions_count")
                renderNextQuestion(quesCount);
            }
        }
        else {
            skipQuestion();
        }
    }
    const skipQuestion = () => {
        setIsVisible(false);
        setIsLoader(true);
        let skip_count = localStorage.getItem('skip_question') ? localStorage.getItem('skip_question') : 0;
        let sequential_skip_count = localStorage.getItem('sequential_skip_question') ? localStorage.getItem('sequential_skip_question') : 0;
        let skip = parseInt(skip_count);
        let sequential_skip = parseInt(sequential_skip_count);
        skip = skip + 1;
        sequential_skip = sequential_skip + 1;
        let skp = '' + skip;
        let sequential_skp = '' + sequential_skip;
        localStorage.setItem("skip_question", skp);
        localStorage.setItem("sequential_skip_question", sequential_skp);
        let auth = "Token " + localStorage.getItem('key');
        let id = localStorage.getItem('practice_question_details_id')
        let tempDate1 = new Date();
        let end_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
        let url1 = root_url + 'api/utils/practice_question_details/' + id + '/';
        fetch(url1, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "ques_end_timestamp": end_time,
                "status": 'skip',
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            let end_time = response.ques_end_timestamp;
            localStorage.setItem('ques_end_timestamp', end_time);
            let update_count = localStorage.getItem("questions_count") ? parseInt(localStorage.getItem("questions_count")) + 1 : 0;
            localStorage.setItem("questions_count", update_count);
            let question_count = localStorage.getItem("questions_count");
            let question_data = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
            if (parseInt(question_count) >= question_data.length) {
                document.getElementById('endQuestion').click()
            }
            else {
                let selected_question_data = question_data[question_count]
                setQuestionData({
                    'QuestionOptions': selected_question_data.question_options,
                    'QuestionId': selected_question_data.question_id,
                    'QuestionScore': selected_question_data.question_score,
                    'QuestionContent': selected_question_data.question_content,
                    'QuestionTopic': selected_question_data.question_topic,
                    'QuestionSection': selected_question_data.question_section,
                })
                practiceAction();
                CreatingPracticeQuestionDetail(selected_question_data.question_id);
                storeCorrectOption(selected_question_data.question_options);
                localStorage.setItem('question_score', selected_question_data.question_score);
                localStorage.setItem('question_id', selected_question_data.question_id);
                localStorage.setItem('question_content', selected_question_data.question_content);
                localStorage.setItem('is_visible', true);
                localStorage.setItem('question_topic', selected_question_data.question_topic);
                localStorage.setItem('question_options', JSON.stringify(selected_question_data.question_options))
                setIsVisible(true);
                setIsLoader(false);

            }
        })
    }

    const getNextQuestion = () => {
        let selected_option = localStorage.getItem("selected_option");
        let answerStatus = "wrong";
        if (selected_option == undefined || selected_option == "null") {
            document.getElementById('selectQuestion').click()
        }
        else {
            if (selected_option === localStorage.getItem('match')) {
                answerStatus = "correct"
            }
            localStorage.removeItem('selected_option');
            let review_questions = reviewQuestion;
            let filtered_obj = review_questions.find(o => o.question === questionData.QuestionId);
            if (filtered_obj !== undefined) {
                if (filtered_obj.choice_selected != null) {
                    updateAnswerOnly(filtered_obj.id, selected_option, answerStatus)
                }
                else if (filtered_obj.choice_selected == null && filtered_obj.status != null) {
                    updateAnswerOnly(filtered_obj.id, selected_option, answerStatus)
                }
                else {
                    updateQuestion(selected_option)
                }
            }
            else {
                updateQuestion(selected_option)
            }

        }

    }


    const updateAnswerOnly = (given_id, given_option, answerStatus) => {
        let is_correct = false;
        if (answerStatus == "correct") {
            is_correct = true;
        }
        let auth = "Token " + localStorage.getItem('key');
        let id = localStorage.getItem('practice_question_details_id')
        let url = root_url + `api/utils/practice_question_details/${given_id}/`;
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "choice_selected": given_option,
                "status": answerStatus,
                "is_correct": is_correct,
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            let tempDate1 = new Date(response.ques_end_timestamp);
            let en_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
            let end_time1 = new Date(en_time);
            end_time1.setMinutes(tempDate1.getMinutes() - 330);
            let end_time2 = end_time1.getFullYear() + '-' + (end_time1.getMonth() + 1) + '-' + end_time1.getDate() + ' ' + end_time1.getHours() + ':' + end_time1.getMinutes() + ':' + end_time1.getSeconds();
            let tempDate = new Date(response.ques_start_timestamp);
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let start_time1 = new Date(start_time);
            start_time1.setMinutes(tempDate.getMinutes() - 330);
            let start_time2 = start_time1.getFullYear() + '-' + (start_time1.getMonth() + 1) + '-' + start_time1.getDate() + ' ' + start_time1.getHours() + ':' + start_time1.getMinutes() + ':' + start_time1.getSeconds();
            let diffInMilliseconds = Math.abs(new Date(end_time2) - new Date(start_time2));
            let minutes = Math.floor(diffInMilliseconds / 60000);
            let seconds = ((diffInMilliseconds % 60000) / 1000).toFixed(0);
            if (minutes > 0) {
                seconds = seconds + (minutes * 60);
            }
            localStorage.setItem('question_time_diff', seconds);
            let quesCount = localStorage.getItem("questions_count")
            renderNextQuestion(quesCount);
        })

    }


    const updateQuestion = (given_option) => {
        let selected_option = given_option;
        setIsVisible(false);
        setIsLoader(true);
        localStorage.removeItem('selected_option');
        let get_solved_count = localStorage.getItem('solved') ? localStorage.getItem('solved') : 0;
        let solved_count = parseInt(get_solved_count);
        solved_count = solved_count + 1;
        let solved = '' + solved_count;
        localStorage.setItem("solved", solved);
        let sequential_skp = '' + 0;
        localStorage.setItem("sequential_skip_question", sequential_skp)
        if (selected_option === localStorage.getItem('match')) {
            let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
            let correct = parseInt(correct_count);
            correct = correct + 1;
            let crct = '' + correct;
            localStorage.setItem("correct", crct);
            let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
            let perc = parseInt(get_percentage);
            if (solved_count > 0 && correct > 0)
                perc = ((correct / solved_count) * 100).toFixed(2);
            else
                perc = 0;
            let percent = '' + perc;
            localStorage.setItem('percentage', percent);
            setPercentage(perc)
            let auth = "Token " + localStorage.getItem('key');
            let id = localStorage.getItem('practice_question_details_id')
            let tempDate = new Date();
            let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let url = root_url + 'api/utils/practice_question_details/' + id + '/';
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "is_correct": true,
                    "ques_end_timestamp": end_time,
                    "choice_selected": selected_option,
                    "status": 'correct',
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                let tempDate1 = new Date(response.ques_end_timestamp);
                let en_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
                let end_time1 = new Date(en_time);
                end_time1.setMinutes(tempDate1.getMinutes() - 330);
                let end_time2 = end_time1.getFullYear() + '-' + (end_time1.getMonth() + 1) + '-' + end_time1.getDate() + ' ' + end_time1.getHours() + ':' + end_time1.getMinutes() + ':' + end_time1.getSeconds();
                let tempDate = new Date(response.ques_start_timestamp);
                let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let start_time1 = new Date(start_time);
                start_time1.setMinutes(tempDate.getMinutes() - 330);
                let start_time2 = start_time1.getFullYear() + '-' + (start_time1.getMonth() + 1) + '-' + start_time1.getDate() + ' ' + start_time1.getHours() + ':' + start_time1.getMinutes() + ':' + start_time1.getSeconds();
                let diffInMilliseconds = Math.abs(new Date(end_time2) - new Date(start_time2));
                let minutes = Math.floor(diffInMilliseconds / 60000);
                let seconds = ((diffInMilliseconds % 60000) / 1000).toFixed(0);
                if (minutes > 0) {
                    seconds = seconds + (minutes * 60);
                }
                localStorage.setItem('question_time_diff', seconds);
                let quesCount = localStorage.getItem("questions_count")
                renderNextQuestion(quesCount);
            })

        }
        else {
            let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
            let perc = parseInt(get_percentage);
            let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
            let correct = parseInt(correct_count);
            if (solved_count > 0 && correct > 0)
                perc = ((correct / solved_count) * 100).toFixed(2);
            else
                perc = 0;
            let percent = '' + perc;
            localStorage.setItem('percentage', percent);
            setPercentage(perc)
            let auth = "Token " + localStorage.getItem('key');
            let id = localStorage.getItem('practice_question_details_id')
            let url = root_url + 'api/utils/practice_question_details/' + id + '/';
            let tempDate = new Date();
            let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            let question_id = localStorage.getItem('question_id');
            let count = localStorage.getItem('questions_count') ? localStorage.getItem('questions_count') : 1;
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "is_correct": false,
                    "ques_end_timestamp": end_time,
                    "status": 'wrong',
                    "choice_selected": selected_option,
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                let end_time = response.ques_end_timestamp;
                localStorage.setItem('ques_end_timestamp', end_time);
                let quesCount = localStorage.getItem("questions_count")
                renderNextQuestion(quesCount);
            })

        }

    }

    const Isbookmarked = () => {
        let auth = "Token " + localStorage.getItem('key');
        let id = localStorage.getItem('practice_question_details_id')
        let url = ""
        let review_questions = reviewQuestion
        let filtered_obj = review_questions.find(o => o.question === questionData.QuestionId);
        if (filtered_obj !== undefined) {
            let requiredReviewID = filtered_obj.id;
            url = root_url + `api/utils/practice_question_details/${requiredReviewID}/`;
        }
        else {
            url = root_url + 'api/utils/practice_question_details/' + id + '/';
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "is_bookmarked": true,
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.is_bookmarked == true) {
                let bm = document.getElementById('bookmarked-msg');
                let ubm = document.getElementById('un-bookmarked-msg');
                bm.style.display = "block";
                ubm.style.display = "none";
            }
            else {
                let bm = document.getElementById('bookmarked-msg');
                let ubm = document.getElementById('un-bookmarked-msg');
                bm.style.display = "none";
                ubm.style.display = "block";
            }
        })

    }


    const renderNextQuestion = (given_question_count, reviewQuestionSelectedChoice = "empty") => {
        localStorage.removeItem('selectedOptionReviewQuestion')
        if (reviewQuestionSelectedChoice !== "empty" && reviewQuestionSelectedChoice !== null && reviewQuestionSelectedChoice != undefined) {
            renderNextQuestionWithSelectedOption(given_question_count, reviewQuestionSelectedChoice);
            setOptionSelected(reviewQuestionSelectedChoice);
            localStorage.setItem("selected_option", reviewQuestionSelectedChoice);
        }
        else {
            let update_count = parseInt(given_question_count) + 1
            localStorage.setItem("questions_count", update_count);
            let question_count = update_count
            let question_data = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
            let selected_question_data = question_data[question_count]
            if (parseInt(question_count) >= question_data.length) {
                document.getElementById('endQuestion').click()
            }
            else {
                let review_questions = reviewQuestion
                let filtered_obj = review_questions.find(o => o.question === selected_question_data.question_id);
                if (filtered_obj !== undefined) {
                    renderNextQuestionWithSelectedOption(question_count - 1, filtered_obj.choice_selected);
                    setOptionSelected(filtered_obj.choice_selected);
                    localStorage.setItem("selected_option", filtered_obj.choice_selected);
                }
                else {
                    setQuestionData({
                        'QuestionOptions': selected_question_data.question_options,
                        'QuestionId': selected_question_data.question_id,
                        'QuestionScore': selected_question_data.question_score,
                        'QuestionContent': selected_question_data.question_content,
                        'QuestionTopic': selected_question_data.question_topic,
                        'QuestionSection': selected_question_data.question_section,
                    })
                    localStorage.setItem('question_score', selected_question_data.question_score);
                    localStorage.setItem('question_id', selected_question_data.question_id);
                    localStorage.setItem('question_content', selected_question_data.question_content);
                    localStorage.setItem('is_visible', true);
                    localStorage.setItem('question_topic', selected_question_data.question_topic);
                    localStorage.setItem('question_options', JSON.stringify(selected_question_data.question_options))
                    CreatingPracticeQuestionDetail(selected_question_data.question_id);
                    storeCorrectOption(selected_question_data.question_options);
                    setIsVisible(true);
                    setIsLoader(false);
                }
            }
        }
    }

    const renderNextQuestionWithSelectedOption = (given_question_count, reviewQuestionSelectedChoice) => {
        let update_count = parseInt(given_question_count) + 1
        localStorage.setItem("questions_count", update_count);
        let question_count = update_count
        let question_data = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
        let selected_question_data = question_data[question_count]
        if (parseInt(question_count) >= question_data.length) {
            document.getElementById('endQuestion').click()
        }
        else {
            setQuestionData({
                'QuestionOptions': selected_question_data.question_options,
                'QuestionId': selected_question_data.question_id,
                'QuestionScore': selected_question_data.question_score,
                'QuestionContent': selected_question_data.question_content,
                'QuestionTopic': selected_question_data.question_topic,
                'QuestionSection': selected_question_data.question_section,
            })
            localStorage.setItem('question_score', selected_question_data.question_score);
            localStorage.setItem('question_id', selected_question_data.question_id);
            localStorage.setItem('question_content', selected_question_data.question_content);
            localStorage.setItem('is_visible', true);
            localStorage.setItem('question_topic', selected_question_data.question_topic);
            localStorage.setItem('question_options', JSON.stringify(selected_question_data.question_options))
            localStorage.setItem('selectedOptionReviewQuestion', reviewQuestionSelectedChoice)
            storeCorrectOption(selected_question_data.question_options);
            setIsVisible(true);
            setIsLoader(false);
        }

    }

    const CreatingPracticeQuestionDetail = (question_id) => {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_question_details/';
        let practice = localStorage.getItem('practice_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let count = localStorage.getItem('questions_count') ? localStorage.getItem('questions_count') : 1;
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "is_correct": false,
                "question_sequence": parseInt(count) + 1,
                "ques_start_timestamp": start_time,
                "ques_end_timestamp": null,
                "choice_selected": null,
                "practice": practice,
                "question": question_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            let practice_details = response.id;
            localStorage.setItem('practice_question_details_id', practice_details);
        })
    }

    const practiceAction = () => {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'skip_question',
                "action_id": "skip_question" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            //console.log("practice action saved")
        })
    }

    const callingTimerInterval = () => {
        if (!myInterval) {
            myInterval = setInterval(() => {
                let seconds = parseInt(localStorage.getItem("countdown_seconds"));
                if (seconds == 0) {
                    localStorage.setItem("countdown_seconds", seconds);
                    clearInterval(myInterval);
                    myInterval = null;
                }
                else {
                    if (seconds > 0) {
                        seconds = seconds - 1
                        localStorage.setItem("countdown_seconds", seconds);
                    }
                }
            }, 1000);
        }
    }

    const pausePractice = () => {
        localStorage.setItem('is_paused', 'true');
        setPause(true);
        setCountdown_pause(true);
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        setIsLoader(true)
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'pause',
                "action_id": "pause" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            setIsLoader(false)
            callingPausePractice();
        })
    }

    const callingPausePractice = () => {
        clearInterval(myInterval);
        myInterval = null;
        setCountdown_pause(true)
        let auth = "Token " + localStorage.getItem('key');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let practice_id = localStorage.getItem('practice_id');
        let timer_url = root_url + 'api/utils/practice/' + practice_id + '/';
        fetch(timer_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "timer_pause": start_time,
            })
        })
    }

    const resumePractice = () => {
        localStorage.setItem('is_paused', 'false');
        setCountdown_pause(false);
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        setIsLoader(true);
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'resume',
                "action_id": "resume" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            callingResumePractice();
        })
        setIsLoader(false);

    }

    const callingResumePractice = () => {
        callingTimerInterval();
        setCountdown_pause(false)
        let auth = "Token " + localStorage.getItem('key');
        let practice_id = localStorage.getItem('practice_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let timer_url = root_url + 'api/utils/practice_timer_perform_update/' + practice_id + '/';
        setIsLoader(true)
        fetch(timer_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "timer_resume": start_time,
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            let timer_url = root_url + 'api/utils/practice/' + practice_id + '/';
            fetch(timer_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                localStorage.setItem('practice_end_datetime', response.timer_end);
                localStorage.setItem('practice_start_datetime', response.timer_start);
            });

            setIsLoader(false);
        });
    }

    /* countDown start */
    const changePauseTimerState = () => {
        setCountdown_pause(false);
        callingTimerInterval();
        localStorage.setItem('countdown_pause', 'false');
    }

    //end practice test
    const confirmEndPractice = () => {
        callingPausePractice();
        confirmAlert({
            title: 'End Practice',
            message: 'Are you sure you want to end practice ? ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        saveStrengthNeedPracticeData();
                        EndPracticeLastQuestionScenario();
                        updateSolvedPaperEndTime();
                        let id = localStorage.getItem('practice_id');
                        let auth = 'Token ' + localStorage.getItem('key');
                        let practice_url = root_url + 'api/utils/practice/' + parseInt(id) + '/';
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
                        setIsLoader(true)
                        fetch(practice_url, {
                            method: 'PATCH',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify({
                                "end_datetime": start_time,
                            })
                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            }
                            else {
                                return false;
                            }
                        }).then(response => {
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': auth
                                }

                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    return false;
                                }
                            }).then(response => {
                                if (response && response.data[0]) {
                                    localStorage.setItem('percentage', response.data[0].accuracy)
                                    localStorage.setItem('total_score', response.data[0].score)
                                    localStorage.setItem('solved', response.data[0].solved)
                                    localStorage.setItem('total', response.data[0].total)
                                    localStorage.setItem('question_time_difference', response.data[0].speed)
                                    localStorage.setItem('end_minutes', response.data[0].minutes)
                                    localStorage.setItem('end_seconds', response.data[0].seconds)
                                    localStorage.setItem('msg', response.data[0].message_option)
                                }
                            });
                            getNullQuestion();
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        callingResumePractice();

                    }
                }
            ]
        });
    };

    const saveStrengthNeedPracticeData = () => {
        let topic_ids = JSON.parse(localStorage.getItem('samplePaperTopicIds'))
        let topic = topic_ids.join()
        let practice = localStorage.getItem('practice_id');
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/save_strength_data/' + '?topic_id=' + topic + '&practice_id=' + practice;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {

        })
    }


    const EndPracticeLastQuestionScenario = () => {
        let selected_option = localStorage.getItem("selected_option");
        if (selected_option == undefined) {
            setIsLoader(true);
            let skip_count = localStorage.getItem('skip_question') ? localStorage.getItem('skip_question') : 0;
            let skip = parseInt(skip_count);
            skip = skip + 1;
            let skp = '' + skip;
            localStorage.setItem("skip", skp);
            let auth = "Token " + localStorage.getItem('key');
            let id = localStorage.getItem('practice_question_details_id')
            let tempDate1 = new Date();
            let end_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
            let url1 = root_url + 'api/utils/practice_question_details/' + id + '/';
            fetch(url1, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "ques_end_timestamp": end_time,
                    "status": 'skip',
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                let end_time = response.ques_end_timestamp;
                localStorage.setItem('ques_end_timestamp', end_time);
            })
            let url = root_url + 'api/utils/practice_action_details/';
            let practice_id = localStorage.getItem('practice_question_details_id');
            let tempDate = new Date();
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "action_name": 'skip_question',
                    "action_id": "skip_question" + practice_id,
                    "action_timestamp": start_time,
                    "practice_question_detail": practice_id

                })
            }).then(response => {
                if (response.status === 201) {
                    setIsLoader(false)
                    return response.json();
                }
                else {
                    return false;
                }
            })
        }
        else {
            setIsLoader(true);
            localStorage.removeItem('selected_option');
            let get_solved_count = localStorage.getItem('solved') ? localStorage.getItem('solved') : 0;
            let solved_count = parseInt(get_solved_count);
            solved_count = solved_count + 1;
            let solved = '' + solved_count;
            localStorage.setItem("solved", solved);
            if (selected_option === localStorage.getItem('match')) {
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                correct = correct + 1;
                let crct = '' + correct;
                localStorage.setItem("correct", crct);
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                setPercentage(perc);
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let question_id = localStorage.getItem('question_id');
                let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": true,
                        "ques_end_timestamp": end_time,
                        "choice_selected": selected_option,
                        "status": 'correct',
                    })
                }).then(response => {
                    if (response.status === 200) {
                        setIsLoader(false);
                        return response.json();
                    }
                    else {
                        return false;
                    }
                })
            }
            else {
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                setPercentage(perc)
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": false,
                        "ques_end_timestamp": end_time,
                        "status": 'wrong',
                        "choice_selected": selected_option,
                    })
                }).then(response => {
                    if (response.status === 200) {
                        setIsLoader(false)
                        return response.json();
                    }
                    else {
                        return false;
                    }
                })
            }

        }
    }

    useEffect(() => {
        findCurrentSection(questionData.QuestionId);
    }, [questionData.QuestionId]);

    const openNav = () => {
        callReviewQuestions();
        document.getElementById("mySidepanel").style.width = "85%";
    }
    const findCurrentSection = (given_question_id) => {
        let all_question = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
        let filtered_obj = all_question.find(o => o.question_id === parseInt(given_question_id));
        if (filtered_obj !== undefined) {
            let all_sections = JSON.parse(localStorage.getItem("total_sections"));
            let currentSections = filtered_obj.question_section
            let given_req_index = all_sections.indexOf(currentSections)
            setCurrentSection(given_req_index);
        }
        else {
            setCurrentSection(0);

        }
    }
    const closeNav = () => {
        setShowTab(false);
        document.getElementById("mySidepanel").style.width = "";
    }
    const pauseTimer = () => {
        callingPausePractice();
    }

    window.addEventListener('click', function (e) {
        if (document.getElementById('mySidepanel') != null) {
            if (document.getElementById('mySidepanel').contains(e.target)) {
                // Clicked in right side panel
            }
            else {
                let rightSidePanelWidth = document.getElementById('mySidepanel').clientWidth;
                if (rightSidePanelWidth > 0) {
                    closeNav();
                }
            }
        }
    });

    const shareQuestion = () => {
        let url = root_url + 'api/utils/share_questions/';
        let given_email = email;
        let given_mobile = mobile;
        let question_id = localStorage.getItem('question_id');
        let practice_id = localStorage.getItem('practice_id');
        let question = parseInt(question_id);
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        if (email == '' || mobile == '') {
            alert('Please enter fields info to share')
        }
        else {
            setIsLoader(true);
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "email": given_email,
                    "mobile": given_mobile,
                    "practice": practice,
                    "question": question,
                })
            }).then(response => {
                if (response.status === 201) {
                    alert('You have successfully shared this question');
                    $('#dismiss').click();
                    setIsLoader(false);
                    return response.json()
                }
                else {
                    setIsLoader(true);
                    return false;
                }
            })
        }
    }

    const ExtraError = (e) => {
        localStorage.setItem('feedback', e.target.value);
    }
    const questionError = (e) => {
        localStorage.setItem('question_error', e.target.checked);
    }

    const optionError = (e) => {
        localStorage.setItem('option_error', e.target.checked);
    }

    const solutionError = (e) => {
        localStorage.setItem('solution_error', e.target.checked);
    }

    const otherError = (e) => {
        localStorage.setItem('other_error', e.target.checked);
    }

    const submitErrorLog = (e) => {
        e.preventDefault();
        let practice = localStorage.getItem('practice_id');
        let practice_id = parseInt(practice);
        let question = localStorage.getItem('question_id');
        let question_id = parseInt(question);
        let question_error = localStorage.getItem('question_error') ? localStorage.getItem('question_error') : false;
        let option_error = localStorage.getItem('option_error') ? localStorage.getItem('option_error') : false;
        let solution_error = localStorage.getItem('solution_error') ? localStorage.getItem('solution_error') : false;
        let other_error = localStorage.getItem('other_error') ? localStorage.getItem('other_error') : false;
        let feedback = localStorage.getItem('feedback') ? localStorage.getItem('feedback') : '';
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/error_logs/';
        let dt = {
            "question_error": question_error,
            "solution_error": solution_error,
            "option_error": option_error,
            "other_error": other_error,
            "practice": practice_id,
            "question": question_id,
            "feedback": feedback,
        }

        setIsLoader(true)
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "question_error": question_error,
                "solution_error": solution_error,
                "option_error": option_error,
                "other_error": other_error,
                "practice": practice_id,
                "question": question_id,
                "feedback": feedback,
            })
        }).then(response => {
            if (response.status === 201) {
                alert('Your response for this question has been send.')
                $('#dismiss_error_log').click();
                setIsLoader(false)
                return response.json();
            }
            else {
                setIsLoader(false)
                return false;
            }
        });
    }

    const callReviewQuestions = () => {
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + `api/utils/get_review_questions/${practice}/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {

                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            let allReviewQuestion = response.data;
            localStorage.setItem('reviewQuestionsData', JSON.stringify(allReviewQuestion))
            setReviewQuestion(allReviewQuestion);
            let all_questions_data = JSON.parse(localStorage.getItem("samplePaperQuestionData"))
            let totalNotVisited = all_questions_data.length - allReviewQuestion.length;
            let totalSkipped = 0;
            let totalBookmarked = 0;
            let totalAnsweredBookmarked = 0;
            let totalAnswered = 0;
            for (let i = 0; i < allReviewQuestion.length; i++) {
                if (allReviewQuestion[i].status == "skip") {
                    totalSkipped += 1;
                }
                if (allReviewQuestion[i].status != null && allReviewQuestion[i].status != "skip") {
                    totalAnswered += 1;
                }
                if (allReviewQuestion[i].is_bookmarked == true && allReviewQuestion[i].status == null) {
                    totalBookmarked += 1;
                }
                if (allReviewQuestion[i].is_bookmarked == true && allReviewQuestion[i].status !== null) {
                    totalAnsweredBookmarked += 1;
                }
            }
            setReviewQuestionData({
                'TotalSkipped': totalSkipped,
                'TotalNotVisited': totalNotVisited,
                'TotalAnswered': totalAnswered,
                'TotalBookmarked': totalBookmarked,
                'TotalAnsweredBookmarked': totalAnsweredBookmarked
            })

        })

    }

    const updateSolvedPaperEndTime = () => {
        let mobile = localStorage.getItem('mobile');
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let practice_url = root_url + `user_solved_sample_paper/${mobile}/${practice}/`;
        let tempDate = new Date();
        let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        fetch(practice_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "end_datetime": end_time,
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        })
    }
    const callingRemaningSamplePaperQuestions = () => {
        let getSamplePaperData = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
        if (getSamplePaperData.length < 6) {
            let samplePaperID = localStorage.getItem("selected_sample_paperID");
            axios.get(`${root_url}api/utils/get_sample_paper_questions/${samplePaperID}/all_remaning_questions/`)
                .then((res) => {
                    let api_result = res.data;
                    if (api_result.status_code == 201) {
                        let question_data = api_result.data;
                        getSamplePaperData = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
                        let updatedGetSamplePaperData = getSamplePaperData.concat(question_data);
                        localStorage.setItem("samplePaperQuestionData", JSON.stringify(updatedGetSamplePaperData));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const getSolvedQuestion = () => {
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let url = root_url + `api/utils/get_review_questions/${practice}/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            let allReviewQuestion = response.data;
            let getSamplePaperData = JSON.parse(localStorage.getItem("samplePaperQuestionData"));
            let filteredSolvedQuestions = allReviewQuestion.filter((item) => item.status !== null);
            for (let i = 0; i < getSamplePaperData.length; i++) {
                getSamplePaperData[i]['question_sequence'] = i + 1
            }
            SavingUnsolvedQuestionInDB(filteredSolvedQuestions, getSamplePaperData)
        })
    }

    const SavingUnsolvedQuestionInDB = (solved_questions, all_sample_paper_questions) => {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/store_samplePaper_unsolved_questions/';
        let practice = localStorage.getItem('practice_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let givenSolvedQuestionArray = solved_questions;
        let allQuestionArray = all_sample_paper_questions;
        let updatedAllQuestionArray = []
        for (let i = 0; i < givenSolvedQuestionArray.length; i++) {
            let filtered_obj = allQuestionArray.find(o => o.question_id === givenSolvedQuestionArray[i].question);
            if (filtered_obj !== undefined) {
                let idToRemove = filtered_obj.question_id;
                allQuestionArray = allQuestionArray.filter((item) => item.question_id !== idToRemove);
            }
        }
        if (allQuestionArray.length !== 0) {
            for (let i = 0; i < allQuestionArray.length; i++) {
                let skipObject = {}
                skipObject["is_correct"] = false
                skipObject["question_sequence"] = allQuestionArray[i].question_sequence
                skipObject["ques_start_timestamp"] = start_time
                skipObject["ques_end_timestamp"] = start_time
                skipObject["choice_selected"] = null
                skipObject["practice"] = practice
                skipObject["question"] = allQuestionArray[i].question_id
                skipObject["status"] = "skip"
                updatedAllQuestionArray.push(skipObject)
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify(updatedAllQuestionArray)
            }).then(response => {
                if (response.status === 201) {
                    return response.json();
                }
                else if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.status_code == 201) {
                    history.push(`/result-summary?practiceId=${practice}`);
                }
            })
        }
        else {
            history.push(`/result-summary?practiceId=${practice}`);
        }
    }
    const getNullQuestion = () => {
        setIsVisible(false);
        setIsLoader(true);
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let url = root_url + `api/utils/get_review_questions/${practice}/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            let allReviewQuestion = response.data;
            let filteredSolvedNullQuestions = allReviewQuestion.filter((item) => item.status == null);
            if (filteredSolvedNullQuestions.length !== 0) {
                updateNullStatusAsSkip(filteredSolvedNullQuestions);
            }
            else {
                getSolvedQuestion();
            }
        })
    }

    const updateNullStatusAsSkip = (nullQuestions) => {
        let givenNullQuestion = nullQuestions;
        let url = root_url + 'api/utils/store_samplePaper_unsolved_questions/';
        let tempDate = new Date();
        let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions: givenNullQuestion, end_datetime: end_time })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response.status_code == 201) {
                getSolvedQuestion();
            }
        })
    }

    let option_dropdown = []
    let questionOption = questionData.QuestionOptions;
    for (let i = 0; i < questionOption.length; i++) {
        if (questionOption[i].option == localStorage.getItem('selectedOptionReviewQuestion') && localStorage.getItem('selectedOptionReviewQuestion') !== null) {
            option_dropdown.push(
                <li key={questionOption[i].id}>
                    <input type="radio" id={questionOption[i].id} value={questionOption[i].option} name="selector" onClick={() => { getSelectedOption(questionOption[i].option) }} checked />
                    <label htmlFor={questionOption[i].id}>
                        {(isVisible) ? (
                            <Editor.EditorShow config={
                                {
                                    markdown: questionOption[i].option,
                                    id: i + 1,
                                    height: 300,
                                    name: "id_option-markdown-doc",
                                    saveHTMLToTextarea: true,
                                    toolbar: false,
                                    flowChart: false,
                                    sequenceDiagram: false,

                                }
                            } />
                        ) : ''}

                    </label>
                    <div className="check">
                        <div className="inside"></div>
                    </div>
                </li>
            )
        }
        else {
            option_dropdown.push(
                <li key={questionOption[i].id}>
                    <input type="radio" id={questionOption[i].id} value={questionOption[i].option} name="selector" onClick={() => { getSelectedOption(questionOption[i].option) }} />
                    <label htmlFor={questionOption[i].id}>
                        {(isVisible) ? (
                            <Editor.EditorShow config={
                                {
                                    markdown: questionOption[i].option,
                                    id: i + 1,
                                    height: 300,
                                    name: "id_option-markdown-doc",
                                    saveHTMLToTextarea: true,
                                    toolbar: false,
                                    flowChart: false,
                                    sequenceDiagram: false,

                                }
                            } />
                        ) : ''}

                    </label>
                    <div className="check">
                        <div className="inside"></div>
                    </div>
                </li>
            )

        }
    }
    const given_string = (given_string) => {
        let x = given_string
        x = x.substring(0, 7) + " " + x.substring(7, x.length);
        let updated_section_name = capitalizeFirstLetter(x)
        return updated_section_name;
    }
    const capitalizeFirstLetter = (str) => {
        const capitalized = str.replace(/^./, str[0].toUpperCase());
        return capitalized;
    }
    let right_side_panel = [];
    let right_side_panel_desktop = [];
    let all_section = []
    let all_question_data = JSON.parse(localStorage.getItem("samplePaperQuestionData"))
    let given_sections = JSON.parse(localStorage.getItem("total_sections"));
    let questionsSectionWise = []
    for (let i = 0; i < given_sections.length; i++) {
        all_section.push(<SamplePaperSection section_data={{ 'name': given_sections[i], all_question_data: all_question_data, reviewQuestion: reviewQuestion, QuestionId: questionData.QuestionId }} renderNextQuestion={renderNextQuestion} />)
        questionsSectionWise.push(new Array())
    }
    for (let i = 0; i < all_question_data.length; i++) {
        for (let j = 0; j < given_sections.length; j++) {
            if (given_sections[j] == all_question_data[i]['question_section']) {
                all_question_data[i]['question_index'] = i + 1
                let required_index = parseInt(given_sections[j].slice(-1)) - 1;
                questionsSectionWise[required_index].push(all_question_data[i])
            }
        }
    }
    let sectionLables=given_sections.map(given_string)
    
    right_side_panel.push(
        <div className="row right-s-pan ">
            <div className="col">
                <div className="panel-op">
                    <div className="panel-open">
                        <button className="openbtn" onClick={openNav}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div id="mySidepanel" className="sidepanel">
                    <div className="col-12 ">
                        <div className="d-flex justify-content-between border-bottom  pb-1 mt-3  pl-1  ml-2">
                            <div className="text-secondary">Filters</div>
                            <div className="mt-n2 pr-2">
                                <button type="button " class="btn btn-sm rounded-sm text-white" style={{ backgroundColor: "#7F72EE" }} onClick={closeNav}>Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="row pl-4 pr-4 mt-2 ml-n1" >
                        <SamplePaperReviewSidePanelDetail heading="Skipped" countBackground="bg-skip" total_count={reviewQuestionData.TotalSkipped} />
                        <SamplePaperReviewSidePanelDetail heading="Not Visited" countBackground="bg-notVisited" total_count={reviewQuestionData.TotalNotVisited} />
                        <SamplePaperReviewSidePanelDetail heading="Answered" countBackground="bg-answered" total_count={reviewQuestionData.TotalAnswered} />
                        <SamplePaperReviewSidePanelDetail heading="Bookmarked" countBackground="bg-bookmarked" total_count={reviewQuestionData.TotalBookmarked} />
                        <SamplePaperReviewSidePanelDetail heading="Answered & Bookmarked" countBackground="bg-answeredandBookmarked" total_count={reviewQuestionData.TotalAnsweredBookmarked} />
                    </div>
                    <div className="score-num pl-2 pr-1 pt-2">
                        <ul id="ul">
                            {all_section}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
    right_side_panel_desktop.push(
        <div className="row right-s-pan ">
            <div className="col">
                <div className="panel-op">
                    <div className="panel-open">
                        <button className="openbtn" onClick={openNav}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div id="mySidepanel" className="sidepanel">
                    <div className="d-flex">
                        <div className="col-8 border-right">
                            <div className="d-flex">
                                <div><Link className="sticky w-10" onClick={closeNav}>
                                    <i className="fa fa-angle-right sticky-top" aria-hidden="true"></i></Link></div>
                                <div>
                                    <TabComponent labels={sectionLables} componentName="SamplePapersQuestions" componentData={questionsSectionWise} reviewQuestion={reviewQuestion} renderNextQuestion={renderNextQuestion} tabIndex={String(currentSection)} centered={false} QuestionId={questionData.QuestionId} />
                                </div></div>
                        </div>
                        <div className="col-4" style={{ height: "100vh" }}>
                            <div className="col-12 ">
                                <div className="d-flex justify-content-between border-bottom  pb-1 mt-3  pl-1  ml-2">
                                    <div className="text-secondary">Filters</div>
                                    <div className="mt-n2 pr-2">
                                        <button type="button" class="btn btn-sm rounded-sm text-white" style={{ backgroundColor: "#7F72EE" }} onClick={closeNav}>Close</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row pl-4 pr-4 mt-2 ml-n1" >
                                <SamplePaperReviewSidePanelDetail heading="Skipped" countBackground="bg-skip" total_count={reviewQuestionData.TotalSkipped} />
                                <SamplePaperReviewSidePanelDetail heading="Not Visited" countBackground="bg-notVisited" total_count={reviewQuestionData.TotalNotVisited} />
                                <SamplePaperReviewSidePanelDetail heading="Answered" countBackground="bg-answered" total_count={reviewQuestionData.TotalAnswered} />
                                <SamplePaperReviewSidePanelDetail heading="Bookmarked" countBackground="bg-bookmarked" total_count={reviewQuestionData.TotalBookmarked} />
                                <SamplePaperReviewSidePanelDetail heading="Answered & Bookmarked" countBackground="bg-answeredandBookmarked" total_count={reviewQuestionData.TotalAnsweredBookmarked} />
                            </div>
                            <div className="score-num pl-2 pr-1 pt-2">
                                <ul id="ul">
                                    {all_section}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <>
            {isMobileOnly ? <div>
                <div className="dash-mid pr-3" >
                    {localStorage.getItem('backdrop_open') == "true" ? <BackDrop openStatus={true} component="samplePaper_question" changePauseTimerState={changePauseTimerState} /> : ''}
                    <TopBarTestPage id={questionData.QuestionId} score={questionData.QuestionScore} pausePractice={pausePractice} seconds={countdown_seconds} paused={countdown_pause} confirmEndPractice={confirmEndPractice} pauseTimer={pauseTimer} saveBookmark={Isbookmarked} />
                    <ReviewRightSidePanel right_side_panel={right_side_panel} />
                    <div className="pl-3 pr-3 pt-1">{questionData.QuestionTopic}</div>
                    <div className="pl-3 pr-3 pt-1 pb-3">
                        {(isVisible) ? (
                            <div key={questionData.QuestionId}>
                                <Editor.EditorShow config={
                                    {
                                        markdown: questionData.QuestionContent,
                                        id: "id_content" + questionData.QuestionId,
                                        height: 300,
                                        name: "id_content-markdown-doc",
                                        saveHTMLToTextarea: true,
                                        toolbar: false,
                                        flowChart: false,
                                        sequenceDiagram: false,
                                    }

                                } />  </div>
                        ) : ''}


                    </div>
                    {isLoader && <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Loader color="primary" height="80px" width="80px" /></div>}

                    <div className="p-3" >
                        <div className={`col qustion-mob ${isMobileOnly ? ' mt-n3' : 'mt-3'}`} >
                            <div className="raio-tit ">Select all that apply:</div>
                            <div className="radio-btn mt-n2 mt-md-0 pl-0 ml-3 ">
                                <ul className="ml-n5">
                                    {option_dropdown}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid p-0 mt-4">
                        <div class="row mt-3 pt-3">
                            <div className="lightGray col p-0 "></div>
                            <div className="col p-0">
                                <button type="button" className="btn text-white font-weight-bold shadow-none d-block btn-block pl-1 pr-1 pt-2 pb-2 rounded-0 skipbutton" onClick={getSkipQuestion}>
                                    Skip <br />
                                    Question<br />
                                </button>
                            </div>
                            <div className="col p-0">
                                <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 submitNext" onClick={getNextQuestion}>Submit & Next</button>
                            </div>
                        </div>
                        <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModal6" id='selectQuestion'></button></div>
                        <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModal1" id='endQuestion'></button></div>

                    </div>
                </div>
            </div>
                : <SamplePaperQuestionDesktop changePauseTimerState={changePauseTimerState} id={questionData.QuestionId} score={questionData.QuestionScore} pausePractice={pausePractice} seconds={countdown_seconds} paused={countdown_pause} confirmEndPractice={confirmEndPractice} pauseTimer={pauseTimer} saveBookmark={Isbookmarked} right_side_panel={right_side_panel_desktop} isLoader={isLoader} isVisible={isVisible} questionData={questionData} option_dropdown={option_dropdown} getNextQuestion={getNextQuestion} getSkipQuestion={getSkipQuestion} />}
            <div>
                <div className="container share-mod popfive">
                    <div className="modal" id="myModal6">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_log" className="close" data-dismiss="modal" >
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px"></img>
                                    </button>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <p className="popup-text-color">Please select any option before clicking on Next Question</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container share-mod share-mod2 background-blur">
                    <div className="modal" id="myModal2" onClick={resumePractice}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross.jpg")} />
                                    </button>
                                    <div className="pop2-img"><img src={require("./../../assests/img/pop-img.jpg")} /></div>
                                    <div className="row pt-3">
                                        <div className="col">
                                            <div className="divh3">
                                                <h3>Your practice has been paused.
                                                    Please try to come back soon and resume practice
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <div className="common-btn" data-dismiss="modal"><button onClick={resumePractice}>Resume</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container share-mod popfive">
                    <div className="modal" id="myModal1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_log" className="close" data-dismiss="modal" >
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px"></img>
                                    </button>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <p className="popup-text-color">End questions for selected sample paper.</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container share-mod background-blur">
                    <div className="modal" id="myModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button id="dismiss" type="button" onClick={callingResumePractice} className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross.jpg")} />
                                    </button>
                                    <div className="divh3">
                                        <h3>Share Question</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col-md-9 col-lg-8">
                                                    <input type="text" className="form-control" onChange={(e) => { setEmail(e.target.value); }} placeholder="Enter Email Id" name="" />
                                                </div>
                                                <div className="col-md-3 col-lg-4 mt-1 mt-lg-0">
                                                    <div className="common-btn"><button onClick={shareQuestion}>Share</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-2">
                                                <div className="col-md-9 col-lg-8">
                                                    <input type="text" className="form-control" onChange={(e) => { setMobile(e.target.value); }} placeholder="Enter Mobile Number" name="" />
                                                </div>
                                                <div className="col-md-3 col-lg-4 mt-1 mt-lg-0">
                                                    <div className="common-btn"><button onClick={shareQuestion}>Share</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container share-mod popfive background-blur">
                    <div className="modal" id="myModal5">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_error_log" onClick={callingResumePractice} className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px" />
                                    </button>
                                    <div className="row">
                                        <div className="col">

                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <form>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="one" onClick={questionError} />
                                                            <label htmlFor="one">&nbsp;&nbsp;Question has an
                                                                error</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="two" onClick={optionError} />
                                                            <label htmlFor="two">&nbsp;&nbsp;Options have an
                                                                error</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="three" onClick={solutionError} />
                                                            <label htmlFor="three">&nbsp;&nbsp;Solution has an
                                                                error</label>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="checkbox" id="four" onClick={otherError} />
                                                            <label htmlFor="four">&nbsp;&nbsp;Other Issues</label>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-md-12 pt-1 m-auto">
                                                                <textarea className="form-control" onChange={ExtraError}></textarea>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="common-btn">
                                                        <button onClick={submitErrorLog}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container share-mod bookmark background-blur">
                    <div className="modal" id="myModal4">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="close" onClick={callingResumePractice} data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross.jpg")} />
                                    </button>
                                    <p id='bookmarked-msg'>The question has been bookmarked, you can review all your bookmarked questions in the performance management tab under the <span>Review Practice </span>session</p>
                                    <p id='un-bookmarked-msg'>The question has been un-bookmarked successfully.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}

