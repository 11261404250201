import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import root_url from './../components/api_url';
import Loader from "react-loader-spinner";
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import PrepHub_footer_logo from "./../../assests/img/powered_by_logo.png";
import { map } from 'jquery';
import MultiSelectAll from "./multiselect";

export class Dash1 extends Component {

    constructor() {

        super();
        //-------------------------This two string add for instruction purpose---------------------------
        this.timed_mode_instruction_string = `1.You have selected <font  style="font-weight: bold;" >timed</font> mode  and you will get <font  style="font-weight: bold;" >40 minutes </font> to solve as many questions as you can in that time. <br />
                                 2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br />
                                 3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                 4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next"</font>. Once submitted answers to the questions cannot be changed.<br />
                                 5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br /> <br />
                                 Pro-tip: Always focus on accuracy before speed.`;

        this.non_timed_mode_instruction_string = `1.You have selected a <font  style="font-weight: bold;" > non-timed </font> practice mode. As the name suggests, there is no limit on time and you can practice as many questions you would like.<br />
                                                2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br /> 
                                                3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                                4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next" </font>. Once submitted answers to the questions cannot be changed.<br />
                                                5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br />
                                                6.You can also see the solution to questions in this mode during the course of the test by clicking on <font  style="font-weight: bold;" >"See Solution" </font>, however, we recommend reviewing them together at the end of the test.<br />
                                                7.If you select an option before clicking on "See solution" that question will be counted as submitted, but if you see the solution without selecting any option, that question will be counted as skipped.`;

        this.state = {
            name: '',
            email: '',
            goal: '',
            subject: '',
            board: '',
            mobile: '',
            country: '',
            goal_code: '',
            goal_name: '',
            goal_id: '',
            subject_list: '',
            topic_list: '',
            subjectSelected: '',
            topicSelected: '',
            is_timed: '',
            isLoader: false,
            instruction_msg: this.timed_mode_instruction_string,
            selected_topic: '',
            sel_subject: '',
            togl: 'toggled',
            user_verified: '',
            updated_selected_topic: '',
            full_name: '',
            issue_type: 'Issues in using PrepHub',
            comments: '',
            contact_phone: '',
            contact_email: '',
            send_me: 'false',
            full_name_error: '',
            validation_error: '',
            email_error: '',
            phone_error: '',
            feedback_success: '',
            comments_error: ''


        }
        this.changeSubject = subjectSelected => {
            this.setState({ subjectSelected });

            this.setState({ sel_subject: subjectSelected.label })
            // console.log(`Subject selected:`, subjectSelected);
            localStorage.setItem('subject_selected', subjectSelected.value);
            let subject_id = localStorage.getItem('subject_selected');
            let url = root_url + 'api/utils/subject/' + subjectSelected.value + '/';
            let auth = 'Token ' + localStorage.getItem('key');
            this.setState({ isLoader: true });
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {

                localStorage.setItem("subject_name", response.name);
                // this.setState({isLoader: false});
            });
            this.getTopic();
        };
        this.sidebarOnToogled = () => {

            this.setState({ togl: '' });
        }
        this.sidebarOffToogled = () => {

            this.setState({ togl: 'toggled' });
        }








        this.getProfile = this.getProfile.bind(this);
        this.getGoal = this.getGoal.bind(this);
        this.getSubject = this.getSubject.bind(this);
        this.getTopic = this.getTopic.bind(this);
        this.timedChange = this.timedChange.bind(this);
        this.beginPractice = this.beginPractice.bind(this);
        this.userLogout = this.userLogout.bind(this);
        this.getOrganizationLogo = this.getOrganizationLogo.bind(this);
        this.sendVerificationLink = this.sendVerificationLink.bind(this);
        this.changeUserdata = this.changeUserdata.bind(this);
        this.sendContactdata = this.sendContactdata.bind(this);
        this.send_me_check = this.send_me_check.bind(this);





    }

    // send verification method for banner
    sendVerificationLink() {
        document.getElementById("resend").innerHTML = 'Re-send Verification Email'
        document.getElementById('verification_popup').click()
    }

    // user logout method
    userLogout(e) {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/accounts/logout/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            localStorage.removeItem('language');
            localStorage.removeItem('practice_action_id');
            localStorage.removeItem('subject_name');
            localStorage.removeItem('user');
            localStorage.removeItem('question_time_diff');
            localStorage.removeItem('practice_found');
            localStorage.removeItem('name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('not_access_code');
            localStorage.removeItem('bookmark_id');
            localStorage.removeItem('bookmark');
            localStorage.removeItem('country');
            localStorage.removeItem('goal');
            localStorage.removeItem('mobile');
            localStorage.removeItem('topic_name');
            localStorage.removeItem('ques_status');
            localStorage.removeItem('is_paused');
            localStorage.removeItem('userLoggedIn');
            localStorage.removeItem('sequential_skip');
            localStorage.removeItem('practice_start_datetime');
            localStorage.removeItem('message_option');
            localStorage.removeItem('topic_selected');
            localStorage.removeItem('key');
            localStorage.removeItem('feedback');
            localStorage.removeItem('key_local');
            localStorage.removeItem('board');
            localStorage.removeItem('subject_selected');
            localStorage.removeItem('goal_name');
            localStorage.removeItem('is_timed');
            document.location.href = document.location.origin;
        })
    }
    timedChange(e) {

        if (e.target.checked) {
            this.setState({ instruction_msg: this.timed_mode_instruction_string })
        }
        else {
            this.setState({ instruction_msg: this.non_timed_mode_instruction_string })

        }


        this.setState({
            is_timed: e.target.checked
        });
        localStorage.setItem('is_timed', e.target.checked)
        
        // console.log('is_timed', e.target.checked)
    }
    beginPractice(e) {


        // document.getElementById("start_button").innerHTML='';

        e.preventDefault();
        let topic = localStorage.getItem('topic_selected') ? localStorage.getItem('topic_selected') : '';
        if (topic !== '') {
            let verified = this.state.user_verified;
            if (verified == true) {
                //----------------------- This code add for show countDown if test start-----------------
                let n = 4;
                document.getElementById("start_button").style.display = 'none';

                let timer = setInterval(function () {
                    n = n - 1;
                    if (n > 0) {

                        document.getElementById("countdown").innerText = n
                    }
                    if (n === 0) {
                        clearInterval(timer);
                        //document.getElementById("start_button").style.display='inline-block';

                        document.getElementById("countdown").innerHTML = n = '<div class="spinner-border text-primary"></div>';
                        //----------------------------End countDown code here-----------------------
                        let auth = "Token " + localStorage.getItem('key');
                        let url = root_url + 'api/utils/practice/';
                        let goal = localStorage.getItem('goal');
                        let user_id = localStorage.getItem('user_id');
                        let subject = localStorage.getItem('subject_selected');
                        let topic = localStorage.getItem('topic_selected');
                        let is_timed = localStorage.getItem('is_timed');
                        let s_name = localStorage.getItem('subject_name');
                        let t_name = localStorage.getItem('topic_name');
                        let g_name = localStorage.getItem('goal_name');
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let end_time = new Date(tempDate);
                        end_time.setMinutes(tempDate.getMinutes() + 40);
                        // console.log('endtime--------', end_time);
                        let topic_id_list = topic.split(",").map(item => parseInt(item, 10));
                        let new_end_time = end_time.getFullYear() + '-' + (end_time.getMonth() + 1) + '-' + end_time.getDate() + ' ' + end_time.getHours() + ':' + end_time.getMinutes() + ':' + end_time.getSeconds();
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify({
                                "goal_name": g_name,
                                "subject_name": s_name,
                                "topic_name": t_name,
                                "timed": is_timed,
                                "start_datetime": start_time,
                                "end_datetime": new_end_time,
                                "timer_start": start_time,
                                "timer_end": new_end_time,
                                "user": user_id,
                                "goal": goal,
                                "subject": subject,
                                "topic": topic_id_list
                            })
                        }).then(response => {
                            if (response.status === 201) {
                                return response.json();
                            } else {
                                return false;
                            }
                        }).then(response => {
                            let practice = response.id;
                            let start_time = response.start_datetime;
                            let end_time = response.end_datetime;
                            localStorage.setItem('practice_id', practice);
                            localStorage.setItem('practice_start_datetime', start_time);
                            localStorage.setItem('practice_end_datetime', end_time);
                            // document.location.href = document.location.origin+'/question1/';
                            if (is_timed === 'true') {
                                document.location.href = document.location.origin + '/questions/';
                            } else {
                                document.location.href = document.location.origin + '/untimed-questions/';
                            }
                        })
                    }
                }, 1000);
            }
            else {
                //close instruction popup
                document.getElementById('instructions_close').click();
                //open verification popup
                document.getElementById('verification_popup').click();
            }

        }
        else {
            alert('Please select any topic to continue Practice.')
        }
        // document.getElementById("start_button").innerHTML=' <div className="common-btn" ><button onClick={this.beginPractice}>Start</button> </div>';
        //document.getElementById("start_button").innerHTML='<div className="col text-center" ><div className="common-btn" ><button onClick={this.beginPractice}>Start</button></div></div>';

    }

    // Method to get organization logo
    getOrganizationLogo(e) {
        let auth = 'Token ' + localStorage.getItem('key');
        let access = localStorage.getItem('access_code')
        let url = root_url + 'api/utils/get_organization_logo/?access=' + access;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            //            console.log('check this', response);
            if (response.is_logo) {
                localStorage.setItem('is_logo', true);
                localStorage.setItem('org_logo', response.org_logo);
            }
            else {
                localStorage.setItem('is_logo', false);
            }
        })
    }


    //method to get user profile with verification details
    getProfile(e) {
        const query = new URLSearchParams(this.props.location.search);
        const given_key = query.get('User-Token');
        if(given_key){
            localStorage.setItem('key',given_key);
        }
        
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/profile/profile/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    email: response.results[0].user.email,
                    goal: response.results[0].goal[0],
                    subject: response.results[0].subject,
                    board: response.results[0].board,
                    mobile: "",
                    name: response.results[0].user.first_name,
                    full_name: "",
                    contact_phone:"",
                    country: response.results[0].country,
                    user_verified: response.results[0].is_verified,
                    contact_email: response.results[0].user.email,
                    //full_name: response.results[0].user.first_name + " " + response.results[0].user.last_name,
                    //mobile: response.results[0].mobile,
                    //name: response.results[0].user.first_name,
                    //contact_phone: response.results[0].mobile,
                })
                localStorage.setItem('access_code', response.results[0].access_code);
                localStorage.setItem('user_id', response.results[0].user.id);
                localStorage.setItem('email', response.results[0].user.email);
                localStorage.setItem('is_timed', 'true');
                this.getOrganizationLogo();
                let goal = response.results[0].goal[0]
                // console.log(goal);
                let goal_code = goal.substring(0, goal.indexOf("-"));
                let url2 = root_url + 'api/utils/goal/?code=' + goal_code;
                fetch(url2, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    },

                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    localStorage.setItem('goal', response.results[0].id);
                    localStorage.setItem("goal_name", response.results[0].name);
                    this.setState({ isLoader: false });
                    this.getGoal();
                    this.getSubject();
                })
            }
        });
    }

    //method to get goal list
    getGoal() {
        let id = localStorage.getItem('goal')
        let url = root_url + 'api/utils/goal/' + id + '/';
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            // console.log('Goal', response);
            this.setState({
                goal_code: response.code,
                goal_name: response.name,
                goal_id: response.id,
            });
            localStorage.setItem('goal_name', response.name);
            localStorage.setItem('goal', response.id);
            this.setState({ isLoader: false });
        });
    }

    //method to get subject list
    getSubject() {
        let id = localStorage.getItem('goal')
        let goal_id = parseInt(id);
        let url = root_url + 'api/utils/subject/' + '?goal__id=' + goal_id + '&goal__name=';
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            // console.log('Subject', response);
            this.setState({
                subject_list: response.results,
            });
            let subject_default = []
            subject_default.push(
                { value: response.results[0].id, label: response.results[0].name },
            )
            this.setState({
                subjectSelected: subject_default
            })
            if (this.state.subjectSelected !== '') {
                let subjectSelected = this.state.subjectSelected;
                localStorage.setItem('subject_selected', subjectSelected[0].value);
                let url = root_url + 'api/utils/subject/' + subjectSelected[0].value + '/';
                let auth = 'Token ' + localStorage.getItem('key');
                this.setState({ isLoader: true });
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        return false;
                    }
                }).then(response => {
                    localStorage.setItem("subject_name", response.name);
                    // this.setState({isLoader: false});
                });
                this.getTopic();
            }
            // this.setState({isLoader: false});
        });
    }

    //method to get topic list
    getTopic() {
        let id = localStorage.getItem('subject_selected');
        // let url = root_url+'api/utils/topic/'+'?subject__id='+id+'&subject__name=';
        let url = root_url + 'api/utils/topic_filter/?subject_id=' + id;
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            this.setState({
                topic_list: response.results,
            });
            this.setState({ isLoader: false });
        });
    }

    // component did mount
    componentDidMount() {
       
        localStorage.removeItem('level');
        localStorage.removeItem('question_id');
        localStorage.removeItem('ques_end_timestamp');
        localStorage.removeItem('ques_start_timestamp');
        localStorage.removeItem('selected_option');
        localStorage.removeItem('practice_id');
        localStorage.removeItem('question_count');
        localStorage.removeItem('confirm_password');
        localStorage.removeItem('password');
        localStorage.removeItem('correct');
        localStorage.removeItem('score');
        localStorage.removeItem('correct_option');
        localStorage.removeItem('practice_question_details_id');
        localStorage.removeItem('time');
        localStorage.removeItem('skip');
        localStorage.removeItem('solved');
        localStorage.removeItem('percentage');
        localStorage.removeItem('total_score');
        localStorage.removeItem('question_time_difference');
        localStorage.removeItem('practice_end_datetime');
        localStorage.removeItem('minutes');
        localStorage.removeItem('seconds');
        localStorage.removeItem('total_review_ques');
        localStorage.removeItem('review_question_sequence');
        localStorage.removeItem('q_count');
        localStorage.removeItem('question_error');
        localStorage.removeItem('option_error');
        localStorage.removeItem('solution_error');
        localStorage.removeItem('other_error');
        localStorage.removeItem('id');
        localStorage.removeItem('choice_selected');
        localStorage.removeItem('is_correct');
        localStorage.removeItem('total_review_question');
        localStorage.removeItem('match');
        localStorage.removeItem('msg');
        localStorage.removeItem('access_code');
        localStorage.removeItem('incorrect_percentage');
        localStorage.removeItem('correct_exceeded_percentage');
        localStorage.removeItem('correct_within_percentage');
        localStorage.removeItem('attempted');
        localStorage.removeItem('accuracy');
        localStorage.removeItem('end_minutes');
        localStorage.removeItem('end_seconds');
        localStorage.removeItem('total');
        localStorage.removeItem('options');
        this.getProfile();
        //this.getTopic();
        
    }


    //----------this function used for get user data for feedback purpose by prep prep 1st-------------
    changeUserdata(event) {
        this.setState({ feedback_success: "" });

        if (event.target.name == "full_name") {
            this.setState({ full_name: event.target.value });
            if (event.target.value == "") {
                this.setState({ full_name_error: "Invalid. Please enter name" });
            }
            else {
                this.setState({ full_name_error: "" });

            }

        }

        if (event.target.name == "emails") {
            this.setState({ contact_email: event.target.value });
            if (event.target.value == "") {
                this.setState({ email_error: "Invalid. Please enter email" });
            }
            else {
                this.setState({ email_error: "" });

            }

        }
        if (event.target.name == "phone") {
            this.setState({ contact_phone: event.target.value });
            if (event.target.value == "") {
                this.setState({ phone_error: "Invalid. Please enter phone number" });
            }
            else {
                this.setState({ phone_error: "" });

            }

        }
        if (event.target.name == "issue_type") {

            this.setState({ issue_type: event.target.value });


        }
        if (event.target.name == "comments") {
            this.setState({ comments: event.target.value });
            if (event.target.value == "") {
                this.setState({ comments_error: "Invalid. Please enter comment" });
            }
            else {
                this.setState({ comments_error: "" });

            }


        }




    }
    //----------this function used for send user data for feedback purpose by prep prep 1st-------------
    sendContactdata() {
        let contact_data = {
            full_name: this.state.full_name,
            contact: this.state.contact_phone,
            email: this.state.contact_email,
            issue_type: this.state.issue_type,
            comments: this.state.comments,
            send_me: this.state.send_me
        };
        //console.log(contact_data);
        if (this.state.full_name_error == "" && this.state.email_error == "" && this.state.phone_error == "") {
            this.setState({ validation_error: "" });
            this.setState({ feedback_success: "Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours." });
            
            let url = root_url + "Contact_Us/";
            //let url = "http://127.0.0.1:8000+"/Contact_Us/"
            let auth = 'Token ' + localStorage.getItem('key');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                   
                }, body: JSON.stringify(contact_data)
            }).then(response => {
                if (response.status === 200) {
                   //this.setState({feedback_success:"Your feedback send successfully."});

                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                //console.log('Goal', response);


            });
        }
        else {
            this.setState({ validation_error: "Feedback not sent. All fields are required." });
            this.setState({ feedback_success: "" });

        }

    }

       //----------this function used for send me checked or not -------------

    send_me_check(e) {
        this.setState({ feedback_success: "" });

        if (e.target.checked) {
            this.setState({ send_me: "true" });
        }
        else {
            this.setState({ send_me: "false" });
        }


    }

    render() {
        let logo = '';
        let footer_logo = '';
        let footer_logo_msg = '';
        let full_name = this.state.name;
        let name = (full_name.split(" ")[0]);
        let goal_name = this.state.goal_name;
        let subject_dropdown = []
        let topic_dropdown = []
        const { subjectSelected } = this.state;
        const { topicSelected } = this.state;

        if (this.state.subject_list !== undefined) {
            for (let i = 0; i < this.state.subject_list.length; i++) {
                subject_dropdown.push(
                    { value: this.state.subject_list[i].id, label: this.state.subject_list[i].name },
                )
            }
        }
        else {
            subject_dropdown.push(
                { value: '', label: 'No subject Found' }
            )
        }
        if (this.state.topic_list !== undefined) {
            for (let i = 0; i < this.state.topic_list.length; i++) {
                topic_dropdown.push(
                    { value: this.state.topic_list[i].id, label: this.state.topic_list[i].name },
                )
            }
        }
        else {
            topic_dropdown.push(
                { value: '', label: 'No Topic Found' }
            )
        }
        if (localStorage.getItem('is_logo') == 'true') {
            logo = localStorage.getItem('org_logo');
            footer_logo = PrepHub_footer_logo;
            footer_logo_msg = 'powered by :';
        }
        else {
            logo = PrepHub_logo;
            footer_logo = '';
            footer_logo_msg = '';
        }
        return (
            // Dashboard Html
            <div>
                <Popup />
                <section className="dash banner-dash">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="pro-mob ">
                                <div className="dropdown notification profile-pic profile-mob">
                                    <button type="button" data-toggle="dropdown">
                                        <img src={require("./../../assests/img/profile-pic.png")} /></button>
                                    <div className="dropdown-menu"> <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i> My Account</Link>
                                    
                                        <Link className="dropdown-item" to="#" onClick={this.userLogout}>
                                            <i className="fa fa-sign-out" aria-hidden="true"></i>Log Out</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 dash-mob">
                                <div className="logo"><img src={logo} /></div>
                            </div>
                            <div className="col-md-2 offset-8">
                                <div className="row wxh">
                                    <div className="col-md-6">
                                        <div className="dropdown-notification notification">
                                            <i className="fa fa-bell-o" aria-hidden="true"><span>12</span></i>
                                            <div className="dropdown-content">
                                                <div className="top-noti">

                                                    <img src={require('./../../assests/img/noti-icon-0.jpg')} />12 Notification <span>Clear All</span></div>
                                                <div className="bot-noti">
                                                    <ul>
                                                        <li><img src={require('./../../assests/img/noti-icon-1.jpg')} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>Sales dashbaord have</h5>
                                                            <h6>4 Minutes Ago</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dash">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="pro-mob ">
                                <div className="dropdown notification profile-pic profile-mob">
                                    <button type="button" data-toggle="dropdown">
                                        <img src={require("./../../assests/img/profile-pic.png")} /></button>
                                    <div className="dropdown-menu">
                                        <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i> My Account</Link>
                                        <Link data-toggle="modal" data-target="#contact_modal" className="dropdown-item" to="#"><i className="fa fa-envelope-open" aria-hidden="true"></i> Contact Us </Link>

                                        <Link className="dropdown-item" to="#" onClick={this.userLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>Log Out</Link>
                                    </div>



                                </div>
                            </div>
                            <div className="col-md-2 dash-mob">
                                <div className="logo"><img src={logo} /></div>
                            </div>
                            <div className="col-md-2 offset-8">
                                <div className="row wxh">
                                    <div className="col-md-6">
                                        <div className="dropdown-notification notification">
                                            <i className="fa fa-bell-o" aria-hidden="true"><span>12</span></i>
                                            <div className="dropdown-content">
                                                <div className="top-noti">

                                                    <img src={require('./../../assests/img/noti-icon-0.jpg')} />12 Notification <span>Clear All</span></div>
                                                <div className="bot-noti">
                                                    <ul>
                                                        <li><img src={require('./../../assests/img/noti-icon-1.jpg')} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>Sales dashbaord have</h5>
                                                            <h6>4 Minutes Ago</h6>
                                                        </li>

                                                        <li><img src={require("./../../assests/img/noti-icon-2.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                        <li><img src={require("./../../assests/img/noti-icon-3.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                        <li><img src={require("./../../assests/img/noti-icon-4.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="dropdown notification profile-pic">
                                            <button type="button" data-toggle="dropdown">
                                                <img src={require("./../../assests/img/profile-pic.png")} />
                                            </button>
                                            <div className="dropdown-menu">
                                                <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i>My Account</Link>
                                                <Link data-toggle="modal" data-target="#contact_modal" className="dropdown-item pointer" to="#" onClick={this.sidebarOnToogled}><i className="fa fa-envelope-open" aria-hidden="true"></i> Contact Us</Link>
                                                <Link className="dropdown-item" to='#' onClick={this.userLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>Log Out</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dash-mid banner-pop" >
                    <div id="wrapper" className={this.state.togl}>
                        <div id="sidebar-wrapper">
                            <div className="btn-tog" id="menu-toggle"> </div>
                            <div className="sidebar-nav perf-bdr">
                                <div className="side-nav">
                                    <div className="dashboard-logo">
                                        <img src={logo} />
                                    </div>

                                    <div className="side-select">
                                        <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                        <select id="mySelect" data-show-content="true" className="form-control">
                                            <option>{goal_name}</option>
                                            {/*<option>CBSE className Tution</option>*/}
                                            {/*<option>CBSE Extra className</option>*/}
                                            {/*    <option></option>*/}
                                        </select>
                                    </div>

                                    <div className="lft-side-menu">
                                        <ul>
                                            <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2.png")} />Practice Mode</Link></li>
                                            <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} />Performance Management</Link></li>

                                            <li data-toggle="modal" data-target="#contact_modal" onClick={this.sidebarOnToogled} className=" pointer"><Link><img src={require("./../../assests/img/mail.png")} style={{ height: "30px", width: "30px" }} />Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--   Right-side-page --> */}
                        <div className="pt-0" id="page-content-wrapper">
                            <div className="container-fluid">
                                {/* <!--     <div className="panel-open">
                                <button className="openbtn" onClick="openNav()"><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                                </div> --> */}
                                {/* <!--     Right-side-panel --> */}
                                <div className="row right-s-pan">
                                    <div className="col">
                                        <div id="mySidepanel" className="sidepanel">
                                            <Link to="javascript:void(0)" className="closebtn" onClick="closeNav()">
                                                <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                            <div className="scroe-c">
                                                <div className="scroec-tit"> Score</div>
                                                <img src={require("./../../assests/img/score-card.png")} />
                                            </div>
                                            <div className="score-num pt-5">
                                                <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li>8</li>
                                                    <li>9</li>
                                                    <li>10</li>
                                                    <li>11</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--     Right-side-panel-end --> */}
                                <div className="row">
                                    <div className="col-lg-12 mb-d-80">
                                        {this.state.user_verified ? ('') :
                                            (
                                                <div className="row">
                                                    <div className='col'>
                                                        <div className="verification-banner">
                                                            <p>Your account verification is pending. Please verify your email address to continue.
                                                                 <span><button onClick={this.sendVerificationLink}>Resend Verification email</button></span>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="row dash-right-s">
                                            <div className="col-md-8 m-auto">
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <div className="dash-mid-img"  /*data-aos="fade-right"*/
                                                            data-aos-offset="300"
                                                            data-aos-easing="ease-in-sine">
                                                            <h1>Welcome {this.state.name =="NULL"?"User":this.state.name} !</h1>
                                                            <p>An expert in everything was once a beginner. Even the most brilliant put in tons of hours in practice before  they ace the topic.</p>
                                                            <p>Stop thinking, Begin Practicing!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.isLoader && <div style={{
                                            width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                        }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                        <div className="row dash-select pt-5 mb-d-80">
                                            <div className="col-md-8 m-auto ">
                                                <div className="row">
                                                    <div className="col-md-6" style={{ opacity: '1' }} /*data-aos="fade-up"*/ data-aos-offset="100">
                                                        <Select value={subjectSelected} placeholder='Select Subject...' onChange={this.changeSubject} options={subject_dropdown} />
                                                    </div>
                                                    <div className="col-md-6" /*data-aos="fade-up"*/ data-aos-offset="100">
                                                        <div className="multiple-select-custom">
                                                            {/*<ReactMultiSelectCheckboxes value={topicSelected} placeholderButtonLabel='Select Topic...' placeholder='Search...' onChange={this.changeTopic} options={topic_dropdown} />*/}
                                                            {/*<Select value={topicSelected} placeholder='Select Topic...' onChange={this.changeTopic} options={topic_dropdown}/>*/}
                                                           {topic_dropdown.length>0?<MultiSelectAll props={topic_dropdown} />:<Select placeholder='Topic:All'/>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pt-4">
                                                    <div className="col text-center">
                                                        <label className="switch" style={{ margin: '0px' }}>
                                                            <input type="checkbox" className="switch-input" onChange={this.timedChange} defaultChecked={"true"} />
                                                            <span className="switch-label" data-on="timed" data-off="Off"></span>
                                                            <span className="switch-handle"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="row pt-5 dash1">
                                                    <div className="col-md-12 text-center">
                                                        <div className="common-btn pt-3"><button data-toggle="modal" data-target="#myModal2" onClick={this.sidebarOnToogled} > Begin Practice</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModalVerified" id='verification_popup'></button></div>
                                                <div className="row mob-side-nav">
                                                    <div className="col">
                                                        <ul>
                                                            <li><a href="#"><img src={require("./../../assests/img/side-icon-2.png")} />Practice</a></li>
                                                            <li><a href="/perform"><img src={require("./../../assests/img/side-icon-3.png")} />Performance </a></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer-logo"><img src={footer_logo} /></div>
                {/*<Helmet>*/}
                {/*    <link href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/css/select2.min.css" rel="stylesheet" />*/}
                {/*    <script>$('.select2').select2();</script>*/}
                {/*</Helmet>*/}

                <div className="modal fade" id="myModal2" onClick={this.resumePractice}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mtcolor "><strong>Practice Test </strong></h5>
                                <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                                    <img src={require("./../../assests/img/cross.jpg")} />
                                </button>
                            </div>
                            <div className="modal-body">

                                <p className="text-dark"> Welcome <strong>{name.charAt(0).toUpperCase() + name.slice(1)} </strong> to your practice session for {localStorage.getItem("topic_name_given")}. Please read the following instructions before you start your practice test,</p>
                                <p className="text-dark"> <strong>Instructions</strong></p>
                                <p className="text-dark" dangerouslySetInnerHTML={{ __html: this.state.instruction_msg }} />
                                <div className="row justify-content-center">
                                    <h1 id="countdown" className="text-primary "><strong></strong></h1>
                                </div>
                                <div className="row mt-1" id="start_button">
                                    <div className="col text-center" >
                                        <div className="common-btn" ><button onClick={this.beginPractice}>Start</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="contact_modal" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-body contact_modal-body">
                                <div className="row mt-1 justify-content-between">
                                    <h5 class="modal-title text-dark ml-2"><strong>Report / Feedback </strong></h5>
                                    <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                                        <img src={require("./../../assests/img/cross.jpg")} id="close_modal" />
                                    </button>
                                </div>
                                <div className="row">
                                    <p className="text-dark ml-3">Facing issues in using PrepHub? Let us know your feedback in the form below and our team will get in touch with you in the next 48 hours.</p>
                                </div>
                                <div className="row mt-3">
                                    <p className="text-dark ml-3"> Please complete all fields.</p>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <p className="text-danger">{this.state.validation_error}</p>
                                        <p className="text-success">{this.state.feedback_success}</p>

                                        <div className="form-group ">

                                            <label for="exampleFormControlInput1" className="text_color">Name</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="full_name" placeholder="" value={this.state.full_name} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.full_name_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Email</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="emails" placeholder="" value={this.state.contact_email} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.email_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Phone</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="phone" placeholder="" value={this.state.contact_phone} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.phone_error}</p>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputState" className="text_color">Issue type</label>
                                            <select id="inputState" class="form-control form-control-sm  border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="issue_type" onChange={this.changeUserdata} >

                                                <option value="Issues in using PrepHub" className="text-primary" selected>Issues in using PrepHub</option>
                                                <option value="New feature request" className="text-primary">New feature request</option>
                                                <option value="Others" className="text-primary">Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1" className="text_color">Comments</label>
                                            <textarea className="form-control  border-top-0 text-dark" id="exampleFormControlTextarea1" rows="3" name="comments" onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.comments_error}</p>

                                        </div>
                                    </div>

                                </div>
                                <div className="row justify-content-between">

                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="send_me_checkbox" onChange={this.send_me_check} />
                                        <label className="form-check-label text-dark" for="send_me_checkbox">Send me a copy of my response</label>
                                    </div>
                                    <div>
                                        <button type="button " className="btn btn-primary  btn-sm" onClick={this.sendContactdata}>Send</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>




        )
    }
}

export class Popup extends React.Component {
    constructor() {
        super();
        this.state = {
            errorMessage: '',
            successMessage: '',
            isLoader: false,
        }
        this.sendVerification = this.sendVerification.bind(this)
    }
    sendVerification(e) {
        e.preventDefault();
        let auth = 'Token ' + localStorage.getItem('key');
        let email = localStorage.getItem('email')
        let url = root_url + 'api/profile/send-verification/?email=' + email;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({ isLoader: false });
            if (response.error) {
                this.setState({
                    errorMessage: response.error,
                });
            }
            else {
                this.setState({
                    successMessage: response.detail,
                    errorMessage: '',
                });
                document.getElementById("resend").innerHTML = 'Re-send Verification Email'
            }
        })
    }

    render() {
        return (
            <div>
                <div className="container share-mod pop_verified">
                    <div className="modal " id="myModalVerified">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_log" className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px" />
                                    </button>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <p className="popup-text-color">Your account verification is pending. Please verify your email address to continue practice.</p>
                                                    {this.state.isLoader && <div style={{
                                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                                    }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                                    {this.state.errorMessage && <span className="error"> {this.state.errorMessage} </span>}
                                                    {this.state.successMessage && <span className="success"> {this.state.successMessage} </span>}
                                                    <div className="common-btn pt-5"><button id="resend" onClick={this.sendVerification}>Send Verification Email</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dash1