import React, { Component } from 'react';
import Dash from './../reusecomponent/dash';
import SharePopup from './../reusecomponent/allpopup/sharepopup';
import PausePractie from './../reusecomponent/allpopup/pauseprac';
import Endprac from './../reusecomponent/allpopup/endprac';
import Bookmarkprac from './../reusecomponent/allpopup/bookmarkprac';
import Errorlog from './../reusecomponent/allpopup/errorlog';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import root_url from "./api_url";
import Loader from 'react-loader-spinner';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
//let Latex = require('react-latex');
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import Latex from "react-latex-next";
import Editor from 'react-editor-md';
import Bottombar from '../components/TopBottomBar/Bottombar'
import { isMobileOnly } from 'mobile-device-detect';
import ReviewQuestionMobile from './ReviewQuestionMobile'

let status_list = [];
const bookmarkImagePath = {
    iconb: require("./../../assests/img/bookmark_iconb.png"),
    icon: require("./../../assests/img/bookmark_icon.png"),
}

export class Review extends Component {
    closeNav = () => {
        document.getElementById("mySidepanel").style.width = "";
    }
    constructor() {
        super();
        this.state = {
            fill: false,
            content: '',
            solution: '',
            end: 'false',
            option_list: [],
            correct_option: '',
            end_practice_action_list: [],
            end_practice_question_list: [],
            isLoader: false,
            isVisible: false,
            isOptionVisible: false,
            all_question_ids: '',
            previousButton: false,
            nextButton: false,
            endReviewButton: false,
            level: '',
            recommTime: '',
            questiontime: '',
            questionTopic: '',
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.endReview = this.endReview.bind(this);
        this.openNav = this.openNav.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.previousQuestion = this.previousQuestion.bind(this);
        this.getspecificQuestion = this.getspecificQuestion.bind(this);
        this.calRecommTime = this.calRecommTime.bind(this);
        this.calQuestionTime = this.calQuestionTime.bind(this);
        this.getQuestionTopic = this.getQuestionTopic.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }
    scrollToTop() {
        let scrollingTop = { top: 0, behavior: "smooth" }
        if(isMobileOnly){
            document.getElementById('questionSolution').scrollTop = scrollingTop;
        }
    }
    calRecommTime(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
    }
    calQuestionTime(intialTime, finalTime) {
        const _final = finalTime;
        const _initial = intialTime;
        const fromTime = new Date(_initial);
        const toTime = new Date(_final);
        const differenceTravel = toTime.getTime() - fromTime.getTime();
        const seconds = Math.floor((differenceTravel) / (1000));
        return seconds
    }

    getQuestionTopic(given_topic_id) {
        let url = root_url + 'api/utils/RetriveTopic/' + given_topic_id + '/';
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response.status == 201) {
                let topic_data = response.data;
                if (topic_data.id == given_topic_id) {
                    this.setState({ questionTopic: topic_data.name })
                }
            }
        })
    }

    //--------------------------------------------This  function add by prep_prep_1st for get specific question showing-----------------------------
    getspecificQuestion = (id) => {
        
        for (let n = 1; n <= parseInt(localStorage.getItem('total')); n++) {
           if (n == id) {
                document.getElementById(String(n)).style.borderStyle = "ridge"
                document.getElementById(String(n)).style.borderColor = "black"
            }
            else {
                document.getElementById(String(n)).style.borderStyle = "none"
                // document.getElementById(String(n)).style.borderColor= "red"
            }
        }
        
        let question_seq = localStorage.getItem('review_question_sequence');
        let question_sequence = parseInt(question_seq);
        let sequence = question_sequence + 1;
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let question_count = localStorage.getItem('total');
        let qc = parseInt(question_count);
        let url = root_url + 'api/utils/practice_question_details/?question_sequence=' + id + '&practice__id=' + practice;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response.next !== null) {
            } else {
                this.setState({
                    end_practice_question_list: response.results,
                })
                localStorage.setItem('practice_action_id', response.results[0].id);
                localStorage.setItem('review_question_sequence', response.results[0].question_sequence);
                localStorage.setItem('is_correct', response.results[0].is_correct);
                localStorage.setItem('ques_status', response.results[0].status);
                localStorage.setItem('choice_selected', response.results[0].choice_selected);
                let questionTimeSec = this.calQuestionTime(response.results[0].ques_start_timestamp, response.results[0].ques_end_timestamp)
                let questionTime = this.calRecommTime(questionTimeSec)
                let questionTimesplited = questionTime.split(":");
                let questionMinSec = questionTimesplited[0] + ' min ' + questionTimesplited[1] + ' sec'
                this.setState({ questionTime: questionMinSec });
            }
            let url_action = root_url + 'api/utils/practice_action_details/?practice_question_detail__id=' + response.results[0].id;
            fetch(url_action, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    this.setState({
                        end_practice_action_list: response.results,
                    })
                    let count = response.count;
                    for (let i = 0; i < count; i++) {
                        if (response.results[i].action_name === 'bookmark') {
                            this.setState(state => ({ fill: true }))
                        } else {
                            this.setState(state => ({ fill: false }))
                        }
                    }
                } else {
                    this.setState(state => ({ fill: false }))
                }
            })
            if (this.state.end_practice_question_list[0].ques_end_timestamp !== null) {
                let id = this.state.end_practice_question_list[0].question;
                localStorage.setItem('id', id);
                let url1 = root_url + 'api/utils/questions/' + id + '/';
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    let topic_list = response.topic;
                    this.getQuestionTopic(topic_list[0]);
                    let recommTime = this.calRecommTime(response.time)
                    let recommTimesplited = recommTime.split(":");
                    let recomMinSec = recommTimesplited[0] + ' min ' + recommTimesplited[1] + ' sec'
                    this.setState({
                        content: response.content,
                        solution: response.solution,
                        isVisible: false,
                        level: response.level,
                        recommTime: recomMinSec,
                    })
                    localStorage.setItem('score', response.score);
                    setTimeout(() => {
                        this.setState({
                            isVisible: true,
                        })
                    }, 100)
                })
                if (id !== undefined) {
                    let option_url = root_url + 'api/utils/options/?question__id=' + id + '';
                    fetch(option_url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },

                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        this.setState({
                            option_list: response.results,
                            isOptionVisible: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                isOptionVisible: true,
                            })
                        }, 100)
                        for (let i = 0; i < this.state.option_list.length; i++) {
                            if (this.state.option_list[i].is_correct === true) {
                                this.setState({ correct_option: this.state.option_list[i].option });
                            }
                        }
                        this.setState({ isLoader: false });
                    });
                } else {
                    this.setState({ isLoader: false });
                    alert('Did not find any question to display its options');
                }
            } else {
                this.setState({
                    end: 'true',
                })
                this.setState({ isLoader: false });
            }
            if (sequence === qc) {
                this.setState({
                    end: 'true',
                })
            }
        })
    }
    //------------------------------------------end of  function by prep_prep_1st ------------------------------------
    getStatus() {
        let question_count = localStorage.getItem('total');
        let practice_id = localStorage.getItem('practice_id');
        let qc = parseInt(question_count);
        let status = '';
        for (let i = 1; i <= qc; i++) {
            let url = root_url + 'api/utils/practice_question_details/?question_sequence=' + i + '&practice__id=' + practice_id;
            let auth = "Token " + localStorage.getItem('key');
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();

                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    if (response.results[0].status === null) {
                        status = 'correct';
                    } else {
                        let index = status_list.findIndex(x => x.id === i)
                        if (index === -1) {

                            status_list.push({ 'id': i, 'status': response.results[0].status });
                        }

                    }
                }

            })
        }
    }

    getImageName = () => this.state.fill ? 'iconb' : 'icon'

    openNav() {
        document.getElementById("mySidepanel").style.width = "240px";
    }

    endReview() {
        document.location.href = document.location.origin + '/dashboard/';
    }

    previousQuestion() {
        this.scrollToTop();
        let question_seq = localStorage.getItem('review_question_sequence');
        let question_sequence = parseInt(question_seq);
        let sequence = question_sequence - 1;
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let question_count = localStorage.getItem('total');
        let qc = parseInt(question_count);
        let url = root_url + 'api/utils/practice_question_details/?question_sequence=' + sequence + '&practice__id=' + practice;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {

            if (response.next !== null) {
                // this.callNext(response.next);
            }
            else {
                this.setState({
                    end_practice_question_list: response.results,
                })
                localStorage.setItem('practice_action_id', response.results[0].id);
                localStorage.setItem('review_question_sequence', response.results[0].question_sequence);
                localStorage.setItem('is_correct', response.results[0].is_correct);
                localStorage.setItem('ques_status', response.results[0].status);
                localStorage.setItem('choice_selected', response.results[0].choice_selected);
                let questionTimeSec = this.calQuestionTime(response.results[0].ques_start_timestamp, response.results[0].ques_end_timestamp)
                let questionTime = this.calRecommTime(questionTimeSec)
                let questionTimesplited = questionTime.split(":");
                let questionMinSec = questionTimesplited[0] + ' min ' + questionTimesplited[1] + ' sec'
                this.setState({ questionTime: questionMinSec });
            }
            let url_action = root_url + 'api/utils/practice_action_details/?practice_question_detail__id=' + response.results[0].id;
            fetch(url_action, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    this.setState({
                        end_practice_action_list: response.results,
                    })
                    let count = response.count;
                    for (let i = 0; i < count; i++) {
                        if (response.results[i].action_name === 'bookmark') {
                            this.setState(state => ({ fill: true }))
                        }
                        else {
                            this.setState(state => ({ fill: false }))
                        }
                    }
                }
                else {
                    this.setState(state => ({ fill: false }))
                }

            })
            if (this.state.end_practice_question_list[0].ques_end_timestamp !== null) {
                let id = this.state.end_practice_question_list[0].question;
                localStorage.setItem('id', id);
                let url1 = root_url + 'api/utils/questions/' + id + '/';
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    let topic_list = response.topic;
                    this.getQuestionTopic(topic_list[0]);
                    let recommTime = this.calRecommTime(response.time)
                    let recommTimesplited = recommTime.split(":");
                    let recomMinSec = recommTimesplited[0] + ' min ' + recommTimesplited[1] + ' sec'
                    this.setState({
                        content: response.content,
                        solution: response.solution,
                        isVisible: false,
                        level: response.level,
                        recommTime: recomMinSec,
                    })
                    localStorage.setItem('score', response.score);
                    setTimeout(() => {
                        this.setState({
                            isVisible: true,
                        })
                    }, 100)
                })
                if (id !== undefined) {
                    let option_url = root_url + 'api/utils/options/?question__id=' + id + '';
                    fetch(option_url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },

                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        this.setState({
                            option_list: response.results,
                            isOptionVisible: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                isOptionVisible: true,
                            })
                        }, 100)
                        for (let i = 0; i < this.state.option_list.length; i++) {
                            if (this.state.option_list[i].is_correct === true) {
                                this.setState({ correct_option: this.state.option_list[i].option });
                            }
                        }
                        this.setState({ isLoader: false });
                    });
                }
                else {
                    this.setState({ isLoader: false });
                    alert('Did not find any question to display its options');
                }
            }
            if (sequence < qc) {
                this.setState({
                    end: 'false',
                })
            }
        })
    }

    nextQuestion() {
        this.scrollToTop();
        let question_seq = localStorage.getItem('review_question_sequence');
        let question_sequence = parseInt(question_seq);
        let sequence = question_sequence + 1;
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let question_count = localStorage.getItem('total');
        let qc = parseInt(question_count);
        let url = root_url + 'api/utils/practice_question_details/?question_sequence=' + sequence + '&practice__id=' + practice;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {

            if (response.next !== null) {
            } else {
                this.setState({
                    end_practice_question_list: response.results,
                })

                localStorage.setItem('practice_action_id', response.results[0].id);
                localStorage.setItem('review_question_sequence', response.results[0].question_sequence);
                localStorage.setItem('is_correct', response.results[0].is_correct);
                localStorage.setItem('ques_status', response.results[0].status);
                localStorage.setItem('choice_selected', response.results[0].choice_selected);
                let questionTimeSec = this.calQuestionTime(response.results[0].ques_start_timestamp, response.results[0].ques_end_timestamp)
                let questionTime = this.calRecommTime(questionTimeSec)
                let questionTimesplited = questionTime.split(":");
                let questionMinSec = questionTimesplited[0] + ' min ' + questionTimesplited[1] + ' sec'
                this.setState({ questionTime: questionMinSec });
            }
            let url_action = root_url + 'api/utils/practice_action_details/?practice_question_detail__id=' + response.results[0].id;
            fetch(url_action, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    this.setState({
                        end_practice_action_list: response.results,
                    })
                    let count = response.count;
                    for (let i = 0; i < count; i++) {
                        if (response.results[i].action_name === 'bookmark') {
                            this.setState(state => ({ fill: true }))
                        } else {
                            this.setState(state => ({ fill: false }))
                        }
                    }
                } else {
                    this.setState(state => ({ fill: false }))
                }
            })
            if (this.state.end_practice_question_list[0].ques_end_timestamp !== null) {
                let id = this.state.end_practice_question_list[0].question;
                localStorage.setItem('id', id);
                let url1 = root_url + 'api/utils/questions/' + id + '/';
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    let topic_list = response.topic;
                    this.getQuestionTopic(topic_list[0]);
                    let recommTime = this.calRecommTime(response.time)
                    let recommTimesplited = recommTime.split(":");
                    let recomMinSec = recommTimesplited[0] + ' min ' + recommTimesplited[1] + ' sec'
                    this.setState({
                        content: response.content,
                        solution: response.solution,
                        isVisible: false,
                        level: response.level,
                        recommTime: recomMinSec,
                    })
                    localStorage.setItem('score', response.score);
                    setTimeout(() => {
                        this.setState({
                            isVisible: true,
                        })
                    }, 100)
                })
                if (id !== undefined) {
                    let option_url = root_url + 'api/utils/options/?question__id=' + id + '';
                    fetch(option_url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },

                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        this.setState({
                            option_list: response.results,
                            isOptionVisible: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                isOptionVisible: true,
                            })
                        }, 100)
                        for (let i = 0; i < this.state.option_list.length; i++) {
                            if (this.state.option_list[i].is_correct === true) {
                                this.setState({ correct_option: this.state.option_list[i].option });
                            }
                        }
                        this.setState({ isLoader: false });
                    });
                } else {
                    this.setState({ isLoader: false });
                    alert('Did not find any question to display its options');
                }
            } else {
                this.setState({
                    end: 'true',
                })
                this.setState({ isLoader: false });
            }
            if (sequence === qc) {
                this.setState({
                    end: 'true',
                })
            }
        })
    }



    componentDidMount() {

        localStorage.removeItem('level');
        localStorage.removeItem('question_id');
        localStorage.removeItem('ques_end_timestamp');
        localStorage.removeItem('ques_start_timestamp');
        localStorage.removeItem('selected_option');
        localStorage.removeItem('confirm_password');
        localStorage.removeItem('password');
        // localStorage.removeItem('score');
        localStorage.removeItem('correct_option');
        localStorage.removeItem('practice_question_details_id');
        localStorage.removeItem('time');
        localStorage.removeItem('total_score');
        localStorage.removeItem('question_time_difference');
        localStorage.removeItem('practice_end_datetime');
        localStorage.removeItem('minutes');
        localStorage.removeItem('seconds');
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });
        $("#menu-toggle1").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
            $("#wrapper").toggleClass("bgblack");
        });

        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_question_details/?question_sequence=&practice__id=' + practice;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {

                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            //console.log("all questions", response.results);
            this.setState({
                end_practice_question_list: response.results,
            })
            if (response.results.length == 1) {
                localStorage.setItem('end', '0');
            }
            else {
                localStorage.setItem('review_question_sequence', response.results[0].question_sequence);
            }

            localStorage.setItem('practice_action_id', response.results[0].id);
            localStorage.setItem('total_review_question', response.count);
            localStorage.setItem('is_correct', response.results[0].is_correct);
            localStorage.setItem('ques_status', response.results[0].status);
            localStorage.setItem('choice_selected', response.results[0].choice_selected);
            if (response.results[0].ques_start_timestamp != null && response.results[0].ques_end_timestamp != null) {
                let questionTimeSec = this.calQuestionTime(response.results[0].ques_start_timestamp, response.results[0].ques_end_timestamp)
                let questionTime = this.calRecommTime(questionTimeSec)
                let questionTimesplited = questionTime.split(":");
                let questionMinSec = questionTimesplited[0] + ' min ' + questionTimesplited[1] + ' sec'
                this.setState({ questionTime: questionMinSec });
            }

            // }
            let url_action = root_url + 'api/utils/practice_action_details/?practice_question_detail__id=' + response.results[0].id;
            fetch(url_action, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.count > 0) {
                    this.setState({
                        end_practice_action_list: response.results,
                    })
                    let count = response.count;
                    for (let i = 0; i < count; i++) {
                        if (response.results[i].action_name === 'bookmark') {
                            this.setState(state => ({ fill: true }))
                        }
                        else {
                            this.setState(state => ({ fill: false }))
                        }
                    }
                }
                else {
                    this.setState(state => ({ fill: false }))
                }

            })
            if (this.state.end_practice_question_list[0].ques_end_timestamp !== null) {
                let id = this.state.end_practice_question_list[0].question;
                localStorage.setItem('id', id);
                let url1 = root_url + 'api/utils/questions/' + id + '/';
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    //console.log("intial response", response);
                    let topic_list = response.topic;
                    this.getQuestionTopic(topic_list[0]);
                    let recommTime = this.calRecommTime(response.time)
                    let recommTimesplited = recommTime.split(":");
                    let recomMinSec = recommTimesplited[0] + ' min ' + recommTimesplited[1] + ' sec'
                    this.setState({
                        content: response.content,
                        solution: response.solution,
                        isVisible: false,
                        level: response.level,
                        recommTime: recomMinSec,
                    })
                    localStorage.setItem('score', response.score);
                    setTimeout(() => {
                        this.setState({
                            isVisible: true,
                        })
                    }, 100)
                })
                if (id !== undefined) {
                    let option_url = root_url + 'api/utils/options/?question__id=' + id + '';
                    fetch(option_url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },

                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        this.setState({
                            option_list: response.results,
                            isOptionVisible: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                isOptionVisible: true,
                            })
                        }, 100)
                        for (let i = 0; i < this.state.option_list.length; i++) {
                            if (this.state.option_list[i].is_correct === true) {
                                this.setState({ correct_option: this.state.option_list[i].option });
                            }
                        }
                        this.setState({ isLoader: false });
                    });
                }
                else {
                    this.setState({ isLoader: false });
                    alert('Did not find any question to display its options');

                }

            }
            else {
                this.setState({
                    end: 'true',
                })
                this.setState({ isLoader: false });
            }
        })
    }

    componentWillMount() {
        this.getStatus();
    }


    render() {
        const bookmarkImage = this.getImageName();
        let review_count = localStorage.getItem('review_question_sequence');
        let end_count = localStorage.getItem('end');
        let review_id = parseInt(review_count);
        let end_button_only = parseInt(end_count);
        let end_sequence = this.state.end;
        let logo = '';
        if (end_sequence === 'false' && review_id > 1) {
            $("#previous").show();
            $("#next").show();
            $("#end").hide();
            $("#onlyPrevNext").show();
            $("#onlyPrevEnd").hide();
            $("#onlyNext").hide();
            $("#onlyEnd").hide();
            //this.setState({previousButton:true,nextButton:true,endReviewButton:false});
        }
        else if (end_sequence === 'false' && review_id === 1) {
            $("#previous").hide();
            $("#next").show();
            $("#end").hide();
            $("#onlyPrevNext").hide();
            $("#onlyPrevEnd").hide();
            $("#onlyNext").show();
            $("#onlyEnd").hide();
            //this.setState({previousButton:false,nextButton:true,endReviewButton:false});
        }
        else if (end_sequence === 'true' && review_id === 1) {
            $("#previous").hide();
            $("#next").show();
            $("#end").hide();
            $("#onlyPrevNext").hide();
            $("#onlyPrevEnd").hide();
            $("#onlyNext").show();
            $("#onlyEnd").hide();
            //this.setState({previousButton:false,nextButton:true,endReviewButton:false});

        }
        else if (end_button_only === 0) {
            $("#end").show();
            $("#previous").hide();
            $("#next").hide();
            $("#onlyPrevNext").hide();
            $("#onlyPrevEnd").show();
            $("#onlyNext").hide();
            $("#onlyEnd").hide();
            //this.setState({previousButton:false,nextButton:false,endReviewButton:true});

        }
        else {
            $("#previous").show();
            $("#next").hide();
            $("#end").show();
            $("#onlyPrevNext").hide();
            $("#onlyPrevEnd").show();
            $("#onlyNext").hide();
            $("#onlyEnd").hide();
            //this.setState({previousButton:true,nextButton:false,endReviewButton:true});

        }
        let topic_mob = "";
        let id = localStorage.getItem('id');
        let score = localStorage.getItem('score');
        let topic = localStorage.getItem('topic_name');
        
        if (topic.includes('[')) {
            let topics = JSON.parse(localStorage.getItem('topic_name'));
            if (topics.includes('all')) {
                topic = 'All';
            }
            else {
                topic = topics.join();
            }
        }
        else {
            topic = localStorage.getItem('topic_name');
        }

        let goal = localStorage.getItem('goal_name');
        let subject = localStorage.getItem('subject_name');
        let is_correct = localStorage.getItem('is_correct');
        let ques_status = localStorage.getItem('ques_status');
        let choice_selected = localStorage.getItem('choice_selected');
        let count = localStorage.getItem('total') ? localStorage.getItem('total') : 0;
        let correct = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
        let skip = localStorage.getItem('skip') ? localStorage.getItem('skip') : 0;
        let per = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
        let percent = parseInt(per);
        // console.log("visibility", this.state.isVisible);
        var response_msg = []
        if (is_correct === 'false') {
            if (ques_status === 'wrong') {
                response_msg.push(<div className={`good-msg wrong-msg ${isMobileOnly ? 'good-msg-mob' : ''}`}>
                    <i className="fa fa-times-circle" aria-hidden="true"></i>&nbsp;
                Oops! You missed this - check out the solutions!
                </div>)
            }
            else {
                response_msg.push(<div className={`good-msg skip-msg ${isMobileOnly ? 'good-msg-mob' : ''}`}>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
                Oops! You skipped this - check out the solutions!
                </div>)
            }
        }
        else {
            response_msg.push(<div className={`good-msg ${isMobileOnly ? 'good-msg-mob' : ''}`}>
                <i className="fa fa-check-circle-o" aria-hidden="true"></i>&nbsp;
                Woo-hoo! Good Work - you ae right!
            </div>)
        }
        let pathColor = '';
        if (percent >= 75) {
            pathColor = '#42a37a';
        }
        else if (percent < 75 && percent >= 50) {
            pathColor = '#FF7F50';
        }
        else {
            pathColor = '#a93672';
        }
        let option_dropdown = []
        let option_dropdown_mob = []
        let correct_option = ''
        for (let i = 0; i < this.state.option_list.length; i++) {
            if (this.state.option_list[i].option === this.state.correct_option) {
                correct_option = this.state.correct_option;

            }
            // else{
            //  option_dropdown.push(
            //     <li key={this.state.option_list[i].id}>
            //         <input type="radio" id={this.state.option_list[i].id} value={this.state.option_list[i].option} name="selector" onClick={this.getSelectedOption} />
            //         <label htmlFor={this.state.option_list[i].id}><Latex >{this.state.option_list[i].option}</Latex></label>
            //         <div className="check">
            //             <div className="inside"></div>
            //         </div>
            //     </li>
            // )
            // }

        }
        // if option wrong
        if (is_correct === 'false') {
            if (ques_status === 'wrong') {
                option_dropdown.push(
                    <div className="col-md-12">
                        <div className="solutons-p">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="radio-btn">
                                        <ul>
                                            <li>
                                                <input type="radio" id="f-option1" name="selector" checked />
                                                <label htmlFor="f-option1">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: choice_selected,
                                                                id: "id_option_wrong",
                                                                height: 300,
                                                                name: "id_option-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                    {/*<Latex>{choice_selected?choice_selected:'0:0'}</Latex>*/}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-3 text-right">
                                    <div className="youans correctans">Your Answer</div>
                                </div>
                            </div>
                        </div>

                        <div className="solutons-p">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="radio-btn">
                                        <ul>
                                            <li>
                                                <input type="radio" id="f-option" name="selector" />
                                                <label htmlFor="f-option">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: correct_option,
                                                                id: "id_option_correct",
                                                                height: 300,
                                                                name: "id_option-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                    {/*<Latex>{correct_option?correct_option:'0:0'}</Latex>*/}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-3 text-right">
                                    <div className="youans ">Correct Answer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                option_dropdown.push(
                    <div className="col-md-12">
                        <div className="solutons-p">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="radio-btn">
                                        <ul>
                                            <li>
                                                <input type="radio" id="f-option1" name="selector" checked />
                                                <label htmlFor="f-option1">You skipped this question</label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-3 text-right">
                                    <div className="youans skips">Your Answer</div>
                                </div>
                            </div>
                        </div>

                        <div className="solutons-p">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="radio-btn">
                                        <ul>
                                            <li>
                                                <input type="radio" id="f-option" name="selector" />
                                                <label htmlFor="f-option">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: correct_option,
                                                                id: "id_correct_option",
                                                                height: 300,
                                                                name: "id_content-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                    {/*<Latex>{correct_option?correct_option:'0:0'}</Latex>*/}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-3 text-right">
                                    <div className="youans ">Correct Answer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            option_dropdown.push(
                <div className="col-md-12">
                    <div className="solutons-p">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="radio-btn">
                                    <ul>
                                        <li>
                                            <input type="radio" id="f-option1" name="selector" checked />
                                            <label htmlFor="f-option1">
                                                {(this.state.isOptionVisible) ? (
                                                    <Editor.EditorShow config={
                                                        {
                                                            markdown: choice_selected,
                                                            id: "id_option_correct",
                                                            height: 300,
                                                            name: "id_option-markdown-doc",
                                                            saveHTMLToTextarea: true,
                                                            toolbar: false,
                                                            flowChart: false,
                                                            sequenceDiagram: false,

                                                        }
                                                    } />
                                                ) : ''}
                                                {/*<Latex>{choice_selected?choice_selected:'0,0'}</Latex>*/}
                                            </label>
                                            <div className="check"></div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3 text-right">
                                <div className="youans">Your Answer</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        // show option with correct wrong status in mobile screen

        if (is_correct === 'false') {
            if (ques_status === 'wrong') {
                option_dropdown_mob.push(
                    <div className="">
                        <div className="solutons-p-mob">
                            <div className="d-flex flex-column">
                                <div className="text-left">
                                    <div className="youans-mob correctans m-0">Your Answer</div>
                                </div>
                                <div className="">
                                    <div className="radio-btn">
                                        <ul className="pl-0 m-0">
                                            <li>
                                                <input type="radio" id="f-option1" name="selector" checked />
                                                <label htmlFor="f-option1">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: choice_selected,
                                                                id: "id_option_wrong",
                                                                height: 300,
                                                                name: "id_option-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="solutons-p-mob">
                            <div className="d-flex flex-column pt-3">
                                <div className="text-left">
                                    <div className="youans-mob m-0">Correct Answer</div>
                                </div>
                                <div className="">
                                    <div className="radio-btn">
                                        <ul className="pl-0 m-0" >
                                            <li>
                                                <input type="radio" id="f-option" name="selector" />
                                                <label htmlFor="f-option">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: correct_option,
                                                                id: "id_option_correct",
                                                                height: 300,
                                                                name: "id_option-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                    {/*<Latex>{correct_option?correct_option:'0:0'}</Latex>*/}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            }
            else {
                option_dropdown_mob.push(
                    <div className="">
                        <div className="solutons-p-mob">
                            <div className="d-flex flex-column pt-3">
                                <div className="text-right">
                                    <div className="youans-mob skips m-0">Your Answer</div>
                                </div>
                                <div className="">
                                    <div className="radio-btn">
                                        <ul className="pl-0 m-0">
                                            <li>
                                                <input type="radio" id="f-option1" name="selector" checked />
                                                <label htmlFor="f-option1">You skipped this question</label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="solutons-p-mob">
                            <div className="d-flex flex-column pt-3">
                                <div className="text-right">
                                    <div className="youans-mob m-0">Correct Answer</div>
                                </div>
                                <div className="">
                                    <div className="radio-btn">
                                        <ul className="pl-0 m-0">
                                            <li>
                                                <input type="radio" id="f-option" name="selector" />
                                                <label htmlFor="f-option">
                                                    {(this.state.isOptionVisible) ? (
                                                        <Editor.EditorShow config={
                                                            {
                                                                markdown: correct_option,
                                                                id: "id_correct_option",
                                                                height: 300,
                                                                name: "id_content-markdown-doc",
                                                                saveHTMLToTextarea: true,
                                                                toolbar: false,
                                                                flowChart: false,
                                                                sequenceDiagram: false,

                                                            }
                                                        } />
                                                    ) : ''}
                                                    {/*<Latex>{correct_option?correct_option:'0:0'}</Latex>*/}
                                                </label>
                                                <div className="check"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            option_dropdown_mob.push(
                <div className="col-md-12">
                    <div className="solutons-p-mob">
                        <div className="d-flex flex-column pt-3">
                            <div className="col-md-3 text-right">
                                <div className="youans-mob m-0">Your Answer</div>
                            </div>
                            <div className="">
                                <div className="radio-btn">
                                    <ul className="pl-0 m-0">
                                        <li>
                                            <input type="radio" id="f-option1" name="selector" checked />
                                            <label htmlFor="f-option1">
                                                {(this.state.isOptionVisible) ? (
                                                    <Editor.EditorShow config={
                                                        {
                                                            markdown: choice_selected,
                                                            id: "id_option_correct",
                                                            height: 300,
                                                            name: "id_option-markdown-doc",
                                                            saveHTMLToTextarea: true,
                                                            toolbar: false,
                                                            flowChart: false,
                                                            sequenceDiagram: false,

                                                        }
                                                    } />
                                                ) : ''}
                                                {/*<Latex>{choice_selected?choice_selected:'0,0'}</Latex>*/}
                                            </label>
                                            <div className="check"></div>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            )
        }
        // end prep_prep1 code 
        // this.getStatus();
        let review_question_li = [];
        let question_count = localStorage.getItem('total');
        localStorage.setItem('total',status_list.length)
        let qc = parseInt(question_count);
        let corct = 0
        let skp = 0
        for (let i = 1; i <= status_list.length; i++) {
            if (status_list.length > 0) {
                for (let j = 0; j < status_list.length; j++) {
                    if (status_list[j].id === i) {

                        if (status_list[j].status === "correct") {

                            corct = corct + 1
                            review_question_li.push(
                                <li ClassName="btn" style={{ "backgroundImage": "linear-gradient(#2f7a73, #42a37a)", cursor: "pointer" }} key={i} id={String(status_list[j].id)} onClick={() => this.getspecificQuestion(status_list[j].id)}>{i}</li>
                            )
                        }
                        else if (status_list[j].status === "wrong") {
                            review_question_li.push(
                                <li ClassName="btn" style={{ "backgroundImage": "linear-gradient(#882c6d, #a93672)", cursor: "pointer" }} key={i} id={String(status_list[j].id)} onClick={() => this.getspecificQuestion(status_list[j].id)}>{i}</li>
                            )
                        }
                        else {
                            skp = skp + 1
                            review_question_li.push(
                                <li ClassName="btn" style={{ "backgroundImage": "linear-gradient(#696969, #808080)", cursor: "pointer" }} key={i} id={String(status_list[j].id)} onClick={() => this.getspecificQuestion(status_list[j].id)}>{i}</li>
                            )
                        }
                    }
                }
            }
            else {
                review_question_li.push(
                    <li style={{ "backgroundImage": "linear-gradient(#2f7a73, #42a37a)" }} key={i}>{i}</li>
                )
            }
        }
        localStorage.setItem('correct', '' + corct);
        localStorage.setItem('skip', '' + skp);
        if (localStorage.getItem('is_logo') == 'true') {
            logo = localStorage.getItem('org_logo');
        }
        else {
            logo = PrepHub_logo;
        }
        //right side panel 
        let right_side_panel = [];
        right_side_panel.push(
            <div className="row right-s-pan">
                <div className="col">
                    <div className="panel-op">
                        <div className="panel-open">
                            <button className="openbtn" onClick={this.openNav}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <div id="mySidepanel" className="sidepanel">
                        <Link className="closebtn" onClick={this.closeNav}>
                            <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        <div className="scroe-c">
                            <div className="scroec-tit"> Accuracy</div>
                            <CircularProgressbar value={per} text={`${per}%`} styles={buildStyles({
                                strokeLinecap: 'round',
                                textSize: '12px',
                                pathTransitionDuration: 0.5,
                                pathColor: pathColor,
                                textColor: '#a55fcae0',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#a55fcae0',
                            })} />;
                                        <div className="scr-result">
                                <ul>
                                    <li>Attempted <span>{count - skip}</span></li>
                                    <li>Accurate <span>{correct}</span></li>
                                    <li>Skipped <span>{skip}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="score-num pt-5">
                            <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                            <ul id="uls">
                                {review_question_li}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        )

        return (
            <>
                {isMobileOnly ? <ReviewQuestionMobile response_msg={response_msg} topic={this.state.questionTopic} questionId={id} questionScore={score} questionContent={this.state.content} option_dropdown_mob={option_dropdown_mob} questionsolution={this.state.solution}  nextButton={this.state.nextButton} previousButton={this.state.previousButton} endReviewButton={this.state.endReviewButton} previousQuestion={this.previousQuestion} nextQuestion={this.nextQuestion} endReview={this.endReview} level={this.state.level} recommTime={this.state.recommTime} questionTime={this.state.questionTime} right_side_panel={right_side_panel}  visible={this.state.isVisible} /> :
                    <div>
                        <SharePopup />
                        <Errorlog />
                        {/*<Endprac />*/}
                        {/*<PausePractie />*/}
                        <Bookmarkprac />
                        <Dash backButton={false} />
                        <section className="dash-mid">
                            <div id="wrapper">
                                <div id="sidebar-wrapper">
                                    <div className="btn-tog" id="menu-toggle"></div>
                                    <div className="sidebar-nav perf-bdr">
                                        <div className="side-nav">
                                            <div className="logo dnone"><img src={logo} /></div>
                                            <div className="ham2" id="menu-toggle1"><img src={require("./../../assests/img/cross-menu.png")} width="15" /></div>
                                            <div className="side-select dnone">
                                                <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                                <select id="mySelect" data-show-content="true" className="form-control">
                                                    <option>{goal}</option>
                                                </select>
                                            </div>
                                            <div className="lft-side-menu middle-menu">
                                                <ul className="fix-left-nav">
                                                    <li><a href="/assignments"><img src={require("./../../assests/img/marks.jpg")} /><h6>Assignments</h6></a></li>
                                                    <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2-active.png")} /><h6>Practice Mode</h6></Link></li>
                                                    <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} /><h6>Performance Management</h6></Link></li>
                                                    <li><Link to="#"><img src={require("./../../assests/img/side-icon-3.png")} /><h6>Contact Us</h6></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- sidebar-wrapper --> */}
                                {/* <!--   Right-side-page --> */}
                                <div id="page-content-wrapper" className="mt-80 mt-lg-0">
                                    <div className="container-fluid">
                                        {/* <!--     Right-side-panel-button --> */}

                                        {/* <!--     Right-side-panel-button -->
                            <!--     Right-side-panel --> */}
                                        {/*<div className="row right-s-pan">
                                <div className="col">
                                <div className="panel-op">
                                    <div className="panel-open">
                                        <button className="openbtn" onClick={this.openNav}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div id="mySidepanel" className="sidepanel">
                                    <Link className="closebtn" onClick={this.closeNav}>
                                    <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                    <div className="scroe-c">
                                        <div className="scroec-tit"> Accuracy</div>
                                        <CircularProgressbar value={per} text={`${per}%`} styles={buildStyles({
                                            strokeLinecap: 'round',
                                            textSize: '12px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: pathColor,
                                            textColor: '#a55fcae0',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#a55fcae0',
                             })} />;
                                        <div className="scr-result">
                                            <ul>
                                                <li>Attempted <span>{count-skip}</span></li>
                                                <li>Accurate <span>{correct}</span></li>
                                                <li>Skipped <span>{skip}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="score-num pt-5">
                                        <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                                        <ul id="uls">
                                            {review_question_li}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>*/}
                                        {right_side_panel}
                                        {/* <!--     Right-side-panel-end --> */}
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row pl-4 pr-4 pt-4 dash2-q mb-d-80">
                                                    <div className="col">
                                                        <div className="row dash-2-bdr ipad-responsive mob-time-band">
                                                            <div className="clock ques-time">               </div>

                                                            <div className="col-md-3">
                                                                <div className="divh4"><h4>Q id - 00{id ? id : 0} (Score: {score ? score : 0} points)</h4></div>
                                                            </div>
                                                            <div className="col-md-2 head-icon-test ">
                                                                <ul>
                                                                    <li><img src={bookmarkImagePath[bookmarkImage]} data-toggle="modal" data-target="#myModal4" /> <h6>Bookmark</h6></li>
                                                                    <li><img src={require("./../../assests/img/share.jpg")} data-toggle="modal" data-target="#myModal" /> <h6>Share</h6></li>
                                                                    <li><img src={require("./../../assests/img/error.png")} width="22" data-toggle="modal" data-target="#myModal5" /><h6>Error</h6></li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                        <div className="row  dash-accord">
                                                            <div className="col qustion-mob">
                                                                <div id="accordion" className="accordion">
                                                                    <div className="card mb-0">

                                                                        <div className="faq-accord">
                                                                            <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                                                                                <a className="card-title">
                                                                                    {subject} - {this.state.questionTopic ? this.state.questionTopic : topic}
                                                                                </a>
                                                                            </div>
                                                                            <div id="collapseOne" className="collapse show" data-parent="#accordion">
                                                                                <div className="row ptag">
                                                                                    <div className="col-md-7">

                                                                                        {(this.state.isVisible) ? (
                                                                                            <Editor.EditorShow config={
                                                                                                {
                                                                                                    markdown: this.state.content,
                                                                                                    id: "id_content" + id,
                                                                                                    height: 300,
                                                                                                    name: "id_content-markdown-doc" + id,
                                                                                                    saveHTMLToTextarea: true,
                                                                                                    toolbar: false,
                                                                                                    flowChart: false,
                                                                                                    sequenceDiagram: false,

                                                                                                }
                                                                                            } />
                                                                                        ) : ''}

                                                                                        {/*<p><Latex displayMode={true}>{this.state.content}</Latex></p>*/}
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        {response_msg}
                                                                                    </div>
                                                                                    {/*<div className="pt-4"><a href="">Read more</a></div>*/}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.isLoader && <div style={{
                                                            width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                                        }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                                        <div className="row">
                                                            {option_dropdown}

                                                        </div>

                                                        <div className="row pt-4" >
                                                            <div className="col">
                                                                <div className="sm-tit">Solution :</div>
                                                                <div className="ptag">
                                                                    {(this.state.isVisible) ? (
                                                                        <Editor.EditorShow config={
                                                                            {
                                                                                markdown: this.state.solution,
                                                                                id: "id_solution",
                                                                                height: 300,
                                                                                name: "id_solution-markdown-doc",
                                                                                saveHTMLToTextarea: true,
                                                                                toolbar: false,
                                                                                flowChart: false,
                                                                                sequenceDiagram: false,

                                                                            }
                                                                        } />
                                                                    ) : ''}
                                                                    {/*<p><Latex displayMode={true}>{this.state.solution}</Latex></p>*/}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row dash-2-btn pt-5">
                                                            <div className="col dash-hov text-center solution">
                                                                <ul>
                                                                    <li id="previous">
                                                                        <div className="common-btn" ><button onClick={this.previousQuestion}>Previous Question</button>
                                                                        </div>
                                                                    </li>
                                                                    <li id="next">
                                                                        <div className="common-btn" ><button onClick={this.nextQuestion}>Next Question</button>
                                                                        </div>
                                                                    </li>
                                                                    <li id="end">
                                                                        <div className="common-btn" ><button onClick={this.endReview}>End Review</button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row mob-side-nav ">
                                                            <Bottombar value="perform" />
                                                        </div>
                                                        {/*submit button*/}

                                                    </div>
                                                </div>

                                            </div>
                                            {/*col-lg-12-END*/}
                                        </div>
                                    </div>


                                </div>
                                {/*Right-side-page END*/}


                            </div>
                            {/*wrapper end*/}
                        </section>
                    </div>}
            </>
        )
    }

}

export default Review