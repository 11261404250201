import React, { Component } from 'react';
import root_url from './../components/api_url';
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import PrepHub_footer_logo from "./../../assests/img/powered_by_logo.png";
import { isMobileOnly } from 'mobile-device-detect';
import DesktopDashboard from './DesktopDashboard';
import MobileDashboard from './MobileDashboard'
import { filters } from '../../config/goalLangSubTopicFilters'
import {CallingQuestion} from './CallingQuestion.js';

export class Dashboard extends Component {

    constructor() {

        super();
        //-------------------------This two string add for instruction purpose---------------------------
        this.timed_mode_instruction_string = `1.You have selected <font  style="font-weight: bold;" >timed</font> mode  and you will get <font  style="font-weight: bold;" >40 minutes </font> to solve as many questions as you can in that time. <br />
                                 2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br />
                                 3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                 4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next"</font>. Once submitted answers to the questions cannot be changed.<br />
                                 5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br /> <br />
                                 Pro-tip: Always focus on accuracy before speed.`;

        this.non_timed_mode_instruction_string = `1.You have selected a <font  style="font-weight: bold;" > non-timed </font> practice mode. As the name suggests, there is no limit on time and you can practice as many questions you would like.<br />
                                                2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br /> 
                                                3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                                4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next" </font>. Once submitted answers to the questions cannot be changed.<br />
                                                5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br />
                                                6.You can also see the solution to questions in this mode during the course of the test by clicking on <font  style="font-weight: bold;" >"See Solution" </font>, however, we recommend reviewing them together at the end of the test.<br />
                                                7.If you select an option before clicking on "See solution" that question will be counted as submitted, but if you see the solution without selecting any option, that question will be counted as skipped.`;

        this.state = {
            name: '',
            email: '',
            goal: '',
            subject: '',
            board: '',
            mobile: '',
            country: '',
            goal_code: '',
            goal_name: '',
            goal_id: '',
            subject_list: '',
            topic_list: '',
            subjectSelected: '',
            topicSelected: '',
            is_timed: '',
            isLoader: false,
            instruction_msg: this.timed_mode_instruction_string,
            selected_topic: '',
            sel_subject: '',
            togl: 'toggled',
            user_verified: '',
            updated_selected_topic: '',
            full_name: '',
            issue_type: 'Issues in using PrepHub',
            comments: '',
            contact_phone: '',
            contact_email: '',
            send_me: 'false',
            full_name_error: '',
            validation_error: '',
            email_error: '',
            phone_error: '',
            feedback_success: '',
            comments_error: '',
            filtered_topic_list:[],
            filtered_subject_list:[],
            selected_subject:[],
            userGoalLang:{},
            
     }
        this.changeSubject = subjectSelected => {
            this.setState({ subjectSelected });
            this.setState({ sel_subject: subjectSelected.label })
            localStorage.setItem('subject_selected', subjectSelected.value);
            let subject_id = localStorage.getItem('subject_selected');
            let url = root_url + 'api/utils/subject/' + subjectSelected.value + '/';
            let auth = 'Token ' + localStorage.getItem('key');
            this.setState({ isLoader: true });
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {

                localStorage.setItem("subject_name", response.name);
            });
            this.getTopic();
        };
        this.sidebarOnToogled = () => {
            this.setState({ togl: '' });
        }
        this.sidebarOffToogled = () => {

            this.setState({ togl: 'toggled' });
        }

        this.getProfile = this.getProfile.bind(this);
        this.getGoal = this.getGoal.bind(this);
        this.getSubject = this.getSubject.bind(this);
        this.getTopic = this.getTopic.bind(this);
        this.timedChange = this.timedChange.bind(this);
        this.beginPractice = this.beginPractice.bind(this);
        this.userLogout = this.userLogout.bind(this);
        this.getOrganizationLogo = this.getOrganizationLogo.bind(this);
        this.sendVerificationLink = this.sendVerificationLink.bind(this);
        this.changeUserdata = this.changeUserdata.bind(this);
        this.sendContactdata = this.sendContactdata.bind(this);
        this.send_me_check = this.send_me_check.bind(this);
        this.getSubjectTopic = this.getSubjectTopic.bind(this);

    }

    // send verification method for banner
    sendVerificationLink() {
        document.getElementById("resend").innerHTML = 'Re-send Verification Email'
        document.getElementById('verification_popup').click()
    }
   
    getSubjectTopic(given_goal,given_language){
        let goallist = given_goal.split("-");
        let lang=given_language.split("-");
        let language=lang[1].trim()
        let goal=goallist[1]
        let goalangData={'goal':goal,'language':language}
        let topic_filters=filters['topics']['All']
        let goal_data=topic_filters[goal][language]
        let topic_data=goal_data['Maths']
        this.setState({ filtered_topic_list:topic_data });
        let specific_subject_filter=filters['subjects']['subject']
        let required_subject=specific_subject_filter[goal][language]
        this.setState({ selected_subject:required_subject });
        this.setState({ filtered_subject_list:required_subject });
        this.setState({userGoalLang:goalangData})
        localStorage.setItem('userSelectedQuestionsType','All')
    }

    // user logout method
    userLogout(e) {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/accounts/logout/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            localStorage.removeItem('language');
            localStorage.removeItem('practice_action_id');
            localStorage.removeItem('subject_name');
            localStorage.removeItem('user');
            localStorage.removeItem('question_time_diff');
            localStorage.removeItem('practice_found');
            localStorage.removeItem('name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('not_access_code');
            localStorage.removeItem('bookmark_id');
            localStorage.removeItem('bookmark');
            localStorage.removeItem('country');
            localStorage.removeItem('goal');
            localStorage.removeItem('mobile');
            localStorage.removeItem('topic_name');
            localStorage.removeItem('ques_status');
            localStorage.removeItem('is_paused');
            localStorage.removeItem('userLoggedIn');
            localStorage.removeItem('sequential_skip');
            localStorage.removeItem('practice_start_datetime');
            localStorage.removeItem('message_option');
            localStorage.removeItem('topic_selected');
            localStorage.removeItem('key');
            localStorage.removeItem('feedback');
            localStorage.removeItem('key_local');
            localStorage.removeItem('board');
            localStorage.removeItem('subject_selected');
            localStorage.removeItem('goal_name');
            localStorage.removeItem('is_timed');
            document.location.href = document.location.origin;
        })
    }
    timedChange(e) {

        if (e.target.checked) {
            this.setState({ instruction_msg: this.timed_mode_instruction_string })
        }
        else {
            this.setState({ instruction_msg: this.non_timed_mode_instruction_string })
        }
        
        this.setState({
            is_timed: e.target.checked
        });
        localStorage.setItem('is_timed', e.target.checked)
        
    }
    beginPractice(e) {
        e.preventDefault();
        let topic = localStorage.getItem('topic_selected') ? localStorage.getItem('topic_selected') : '';
        if (topic !== '') {
            let verified = this.state.user_verified;
            if (verified == true) {
                        document.getElementById("start_button").style.display = 'none';
                        document.getElementById("countdown").innerHTML = '<div class="spinner-border text-primary"></div>';
                        let given_t_name = ""
                        let topics = JSON.parse(localStorage.getItem('topic_name'));
                        if (topics.includes('all')) {
                            given_t_name = "All"
                        }
                        else {
                            given_t_name = topics.join()
                        }
                        let auth = "Token " + localStorage.getItem('key');
                        let url = root_url + 'api/utils/practice/';
                        let goal = localStorage.getItem('goal');
                        let user_id = localStorage.getItem('user_id');
                        let subject = localStorage.getItem('subject_selected');
                        let topic = JSON.parse(localStorage.getItem('topic_selected'))
                        let is_timed = localStorage.getItem('is_timed');
                        let s_name = localStorage.getItem('subject_name');
                        let t_name = given_t_name;
                        let g_name = localStorage.getItem('goal_name');
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let end_time = new Date(tempDate);
                        end_time.setMinutes(tempDate.getMinutes() + 40);
                        let topic_id_list = topic.map(item => parseInt(item, 10));
                        let new_end_time = end_time.getFullYear() + '-' + (end_time.getMonth() + 1) + '-' + end_time.getDate() + ' ' + end_time.getHours() + ':' + end_time.getMinutes() + ':' + end_time.getSeconds();
                        let final_data={
                            "goal_name": g_name,
                            "subject_name": s_name,
                            "topic_name": t_name,
                            "timed": is_timed,
                            "start_datetime": start_time,
                            "end_datetime": new_end_time,
                            "timer_start": start_time,
                            "timer_end": new_end_time,
                            "user": user_id,
                            "goal": goal,
                            "subject": subject,
                            "topic": topic_id_list
                        }
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify(final_data)
                            }).then(response => {
                            if (response.status === 201) {
                                return response.json();
                            } else {
                                return false;
                            }
                        }).then(response => {
                            let practice = response.id;
                            let start_time = response.start_datetime;
                            let end_time = response.end_datetime;
                            localStorage.setItem('practice_id', practice);
                            localStorage.setItem('practice_start_datetime', start_time);
                            localStorage.setItem('practice_end_datetime', end_time);
                            localStorage.setItem('topic_selected', topic.join());
                            let get_level = localStorage.getItem('level')?localStorage.getItem('level'):2;
                            localStorage.setItem("backdrop_open","true");
                            let level = parseInt(get_level);
                            let given_topic=localStorage.getItem('topic_selected')
                            if (is_timed === 'true') {
                                CallingQuestion(level,practice,given_topic);
                            } else {
                                document.location.href = document.location.origin + '/untimed-questions/';
                            }
                        })
                 
            }
           else {
               
                document.getElementById('instructions_close').click();
                document.getElementById('verification_popup').click();
            }

        }
        else {
            alert('Please select any topic to continue Practice.')
        }

    }

    // Method to get organization logo
    getOrganizationLogo(e) {
        let auth = 'Token ' + localStorage.getItem('key');
        let access = localStorage.getItem('access_code')
        let url = root_url + 'api/utils/get_organization_logo/?access=' + access;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            //            console.log('check this', response);
            if (response.is_logo) {
                localStorage.setItem('is_logo', true);
                localStorage.setItem('org_logo', response.org_logo);
            }
            else {
                localStorage.setItem('is_logo', false);
            }
        })
    }


    //method to get user profile with verification details
    getProfile(e) {
        const query = new URLSearchParams(this.props.location.search);
        const given_key = query.get('User-Token');
        const given_user_goal = query.get('User-Goal');
        if(given_key){
            localStorage.setItem('key',given_key);
            localStorage.setItem("UserType","Unpaid");
            localStorage.setItem('userGoal',given_user_goal);
        }
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/profile/profile/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    email: response.results[0].user.email,
                    goal: response.results[0].goal[0],
                    subject: response.results[0].subject,
                    board: response.results[0].board,
                    mobile: "",
                    name: response.results[0].user.first_name,
                    full_name: "",
                    contact_phone:"",
                    country: response.results[0].country,
                    user_verified: response.results[0].is_verified,
                    contact_email: response.results[0].user.email,
                })
                localStorage.setItem('userGoal',response.results[0].goal[0]);
                localStorage.setItem('access_code', response.results[0].access_code);
                localStorage.setItem('user_id', response.results[0].user.id);
                localStorage.setItem('email', response.results[0].user.email);
                localStorage.setItem('is_timed', 'true');
                localStorage.setItem('user_verified', response.results[0].is_verified);
                localStorage.setItem('mobile', response.results[0].user.username);
                this.getOrganizationLogo();
                let goal = response.results[0].goal[0]
                let given_language=response.results[0].language
                this.getSubjectTopic(goal,given_language);
                let goal_code = goal.substring(0, goal.indexOf("-"));
                let url2 = root_url + 'api/utils/goal/?code=' + goal_code;
                fetch(url2, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    },

                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    localStorage.setItem('goal', response.results[0].id);
                    localStorage.setItem("goal_name", response.results[0].name);
                    this.setState({ isLoader: false });
                    this.getGoal();
                    this.getSubject();
                })
            }
        });
    }

    //method to get goal list
    getGoal() {
        let id = localStorage.getItem('goal')
        let url = root_url + 'api/utils/goal/' + id + '/';
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
             
            this.setState({
                goal_code: response.code,
                goal_name: response.name,
                goal_id: response.id,
            });
            localStorage.setItem('goal_name', response.name);
            localStorage.setItem('goal', response.id);
            this.setState({ isLoader: false });
        });
    }

    //method to get subject list
    getSubject() {
        let id = localStorage.getItem('goal')
        let goal_id = parseInt(id);
        let url = root_url + 'api/utils/subject/' + '?goal__id=' + goal_id + '&goal__name=';
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            // console.log('Subject', response);
            this.setState({
                subject_list: response.results,
            });
            let subject_default = []
            subject_default.push(
                { value: response.results[0].id, label: response.results[0].name },
            )
            this.setState({
                subjectSelected: subject_default
            })
            if (this.state.subjectSelected !== '') {
                let subjectSelected = this.state.subjectSelected;
                localStorage.setItem('subject_selected', subjectSelected[0].value);
                let url = root_url + 'api/utils/subject/' + subjectSelected[0].value + '/';
                let auth = 'Token ' + localStorage.getItem('key');
                this.setState({ isLoader: true });
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        return false;
                    }
                }).then(response => {
                    localStorage.setItem("subject_name", response.name);
                    // this.setState({isLoader: false});
                });
                // comment by prep prep 1
                this.getTopic();
            }
            // this.setState({isLoader: false});
        });
    }

    //method to get topic list
    getTopic() {
        let id = localStorage.getItem('subject_selected');
        // let url = root_url+'api/utils/topic/'+'?subject__id='+id+'&subject__name=';
        let url = root_url + 'api/utils/topic_filter/?subject_id=' + id;
        let auth = 'Token ' + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            this.setState({
                topic_list: response.results,
            });
            this.setState({ isLoader: false });
        });
    }

    // component did mount
    componentDidMount() {
        localStorage.removeItem('level');
        localStorage.removeItem('question_id');
        localStorage.removeItem('ques_end_timestamp');
        localStorage.removeItem('ques_start_timestamp');
        localStorage.removeItem('selected_option');
        localStorage.removeItem('practice_id');
        localStorage.removeItem('question_count');
        localStorage.removeItem('confirm_password');
        localStorage.removeItem('password');
        localStorage.removeItem('correct');
        localStorage.removeItem('score');
        localStorage.removeItem('correct_option');
        localStorage.removeItem('practice_question_details_id');
        localStorage.removeItem('time');
        localStorage.removeItem('skip');
        localStorage.removeItem('solved');
        localStorage.removeItem('percentage');
        localStorage.removeItem('total_score');
        localStorage.removeItem('question_time_difference');
        localStorage.removeItem('practice_end_datetime');
        localStorage.removeItem('minutes');
        localStorage.removeItem('seconds');
        localStorage.removeItem('total_review_ques');
        localStorage.removeItem('review_question_sequence');
        localStorage.removeItem('q_count');
        localStorage.removeItem('question_error');
        localStorage.removeItem('option_error');
        localStorage.removeItem('solution_error');
        localStorage.removeItem('other_error');
        localStorage.removeItem('id');
        localStorage.removeItem('choice_selected');
        localStorage.removeItem('is_correct');
        localStorage.removeItem('total_review_question');
        localStorage.removeItem('match');
        localStorage.removeItem('msg');
        localStorage.removeItem('access_code');
        localStorage.removeItem('incorrect_percentage');
        localStorage.removeItem('correct_exceeded_percentage');
        localStorage.removeItem('correct_within_percentage');
        localStorage.removeItem('attempted');
        localStorage.removeItem('accuracy');
        localStorage.removeItem('end_minutes');
        localStorage.removeItem('end_seconds');
        localStorage.removeItem('total');
        localStorage.removeItem('options');
        this.getProfile();
        //this.getTopic();
        
    }


    //----------this function used for get user data for feedback purpose by prep prep 1st-------------
    changeUserdata(event) {
        this.setState({ feedback_success: "" });

        if (event.target.name == "full_name") {
            this.setState({ full_name: event.target.value });
            if (event.target.value == "") {
                this.setState({ full_name_error: "Invalid. Please enter name" });
            }
            else {
                this.setState({ full_name_error: "" });

            }

        }

        if (event.target.name == "emails") {
            this.setState({ contact_email: event.target.value });
            if (event.target.value == "") {
                this.setState({ email_error: "Invalid. Please enter email" });
            }
            else {
                this.setState({ email_error: "" });

            }

        }
        if (event.target.name == "phone") {
            this.setState({ contact_phone: event.target.value });
            if (event.target.value == "") {
                this.setState({ phone_error: "Invalid. Please enter phone number" });
            }
            else {
                this.setState({ phone_error: "" });

            }

        }
        if (event.target.name == "issue_type") {

            this.setState({ issue_type: event.target.value });


        }
        if (event.target.name == "comments") {
            this.setState({ comments: event.target.value });
            if (event.target.value == "") {
                this.setState({ comments_error: "Invalid. Please enter comment" });
            }
            else {
                this.setState({ comments_error: "" });

            }


        }
    }
    //----------this function used for send user data for feedback purpose by prep prep 1st-------------
    sendContactdata() {
        let contact_data = {
            full_name: this.state.full_name,
            contact: this.state.contact_phone,
            email: this.state.contact_email,
            issue_type: this.state.issue_type,
            comments: this.state.comments,
            send_me: this.state.send_me
        };
       if (this.state.full_name_error == "" && this.state.email_error == "" && this.state.phone_error == "") {
            this.setState({ validation_error: "" });
            this.setState({ feedback_success: "Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours." });
            
            let url = root_url + "Contact_Us/";
            let auth = 'Token ' + localStorage.getItem('key');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                   
                }, body: JSON.stringify(contact_data)
            }).then(response => {
                if (response.status === 200) {
                   //this.setState({feedback_success:"Your feedback send successfully."});
                   return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                //console.log('Goal', response);
            });
        }
        else {
            this.setState({ validation_error: "Feedback not sent. All fields are required." });
            this.setState({ feedback_success: "" });

        }

    }

       //----------this function used for send me checked or not -------------

    send_me_check(e) {
        this.setState({ feedback_success: "" });

        if (e.target.checked) {
            this.setState({ send_me: "true" });
        }
        else {
            this.setState({ send_me: "false" });
        }


    }

    

    render() {
        let logo = '';
        let footer_logo = '';
        let footer_logo_msg = '';
        let full_name = this.state.name;
        let name = (full_name.split(" ")[0]);
        let goal_name = this.state.goal_name;
        let subject_dropdown = []
        let topic_dropdown = []
        const { subjectSelected } = this.state;
        const { topicSelected } = this.state;
        if (this.state.subject_list !== undefined) {
            for (let i = 0; i < this.state.subject_list.length; i++) {
                subject_dropdown.push(
                    { value: this.state.subject_list[i].id, label: this.state.subject_list[i].name },
                )
            }
        }
        else {
            subject_dropdown.push(
                { value: '', label: 'No subject Found' }
            )
        }
        if (this.state.topic_list !== undefined) {
            for (let i = 0; i < this.state.topic_list.length; i++) {
                topic_dropdown.push(
                    { value: this.state.topic_list[i].id, label: this.state.topic_list[i].name },
                )
            }
        }
        else {
            topic_dropdown.push(
                { value: '', label: 'No Topic Found' }
            )
        }
        if (localStorage.getItem('is_logo') == 'true') {
            logo = localStorage.getItem('org_logo');
            footer_logo = PrepHub_footer_logo;
            footer_logo_msg = 'powered by :';
        }
        else {
            logo = PrepHub_logo;
            footer_logo = '';
            footer_logo_msg = '';
        }
        return (
           <div>
            {isMobileOnly?<MobileDashboard userLogout={this.userLogout} userGoalLang={this.state.userGoalLang} />:<DesktopDashboard  logo={logo} goal_name={goal_name} footer_logo={footer_logo} subjectSelected={this.state.selected_subject} changeSubject={this.changeSubject} subject_dropdown={this.state.filtered_subject_list} topic_dropdown={this.state.filtered_topic_list} name={name} instruction_msg={this.state.instruction_msg} timedChange={this.timedChange} sidebarOffToogled={this.sidebarOffToogled} beginPractice={this.beginPractice} sidebarOnToogled={this.sidebarOnToogled} userLogout={this.userLogout} questionTypeOfTopics={this.state.questionTypeOfTopics} userGoalLang={this.state.userGoalLang} />}
        </div>
        )
    }
}



export default Dashboard