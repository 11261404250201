import React from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import ButtonMob from './TopBottomBar/BurttonMob'
import AllTopicsMobile from './AllTopicsMobile'
import { useEffect, useState } from 'react';



export default function MobileDashboard(props) {
    const [beginPractice, setBeginPractice] = useState(false);
    const changeBeginPracticeStatus = (given_array) => {
        if (given_array.length > 0) {
            setBeginPractice(true);
        }
        else {
            setBeginPractice(false);
        }
    }

    return (
        <>
            <div className="d-flex flex-column w-100" style={{height:'100vh'}} >
                <Topbar backButton={false} />
                <AllTopicsMobile changeBeginPracticeStatus={changeBeginPracticeStatus} userGoalLang={props.userGoalLang}/>
                <ButtonMob status={beginPractice} button_name="Begin Practice" />
                <Bottombar value="dashboard"/>
            </div>

        </>
    )

}

