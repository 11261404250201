import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Import static content
import './assests/css/bootstrap.min.css';
import './assests/css/aos.css';
import './assests/css/style.css';
import './assests/css/sidebar.css';

// Include component
import LogIn from './layout/components/login';
import GetStartedLogIn from './layout/getStarted/GetStartedLogIn';
import Dash1 from './layout/components/dash1';
import Perform from './layout/components/perform';
import NewUser from './layout/components/NewUser';
import GetStartedNewUser from './layout/getStarted/GetStartedNewUser';
import Enterotp from './layout/components/enterotp';
import GetStartedEnterotp from './layout/getStarted/GetStartedEnterotp';
import GetStartedLearning from './layout/getStarted/GetStartedLearning';
import ForgotPassword from './layout/components/forgotpassword';
import ForgotPassword_LinkSent from './layout/components/forgotpassword_link_sent';
import Solution1 from './layout/components/solution1';
import Review from './layout/components/review_questions';
import TimedQuestion from './layout/components/timed_question';
import UnTimedQuestion from './layout/components/untimed_question';
import PerformanceReview from "./layout/components/PerformTabs/PerformaceReview";
import ForgotPasswordConfirm from "./layout/components/forgotpassword_confirm";
import PageNotFound from "./layout/components/404";
import VerificationInfo from "./layout/components/verificationinfo";
import learningUpdated from './layout/components/learningUpdated';
import LearningOrganic from './layout/components/LearningOrganic';
import LearningAccess from './layout/components/LearningAccess';
import Dashboard from './layout/components/dashboard';
import TestStartMob from './layout/components/TestStartMob';
import EndPracticeMobile from './layout/components/EndPracticeMobile'
import ReviewQuestionMobile from './layout/components/ReviewQuestionMobile'
import { EkalLogIn } from './layout/ekal/EkalLogIn';
import NewUserEkal from './layout/ekal/NewUserEkal';
import EnterotpEkal from './layout/ekal/enterotpEkal';
import EkalPlans from './layout/ekal/EkalPlans';
import TestStartSamplePaper from './layout/components/TestStartSamplePaper';
import SamplePaperQuestion from './layout/components/SamplePaperQuestion';
import Assignments from './layout/components/Assignments'
import SamplePaper from './layout/components/SamplePaper'
import EndPracticeReview from './layout/components/EndPracticeReview'


function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/' component={LogIn} />
          <Route exact path='/get-started' component={GetStartedLogIn} />
          <Route exact path='/ekal/:subscription_type' component={EkalLogIn} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/assignments' component={Assignments} />
          <Route exact path='/sample-papers' component={SamplePaper} />
          <Route exact path='/sample-papers-test' component={SamplePaperQuestion} />
          <Route exact path='/perform' component={Perform} />
          <Route exact path='/performance-review' component={PerformanceReview} />
          <Route exact path='/newuser' component={NewUser} />
          <Route exact path='/get-started/newuser' component={GetStartedNewUser} />
          <Route exact path='/newuser-ekal/:subscription_type' component={NewUserEkal} />
          <Route exact path='/enterotp-ekal/:subscription_type' component={EnterotpEkal} />
          <Route exact path='/get-started/enterotp' component={GetStartedEnterotp} />
          <Route exact path='/ekal-plans' component={EkalPlans} />
         {/* <Route exact path='/learning' component={Learning} />*/}
          <Route exact path='/get-started/learning' component={GetStartedLearning} />
          <Route exact path='/enterotp' component={Enterotp} />
          <Route exact path='/learning_organic' component={LearningOrganic} />
          <Route exact path='/learning_access' component={LearningAccess} />
          <Route exact path='/solution' component={Solution1} />
          <Route exact path='/review' component={Review} />
          <Route exact path='/questions' component={TimedQuestion} />
          <Route exact path='/untimed-questions' component={UnTimedQuestion} />
          <Route exact path='/forgotpassword' component={ForgotPassword} />
          <Route exact path='/password-reset/' component={ForgotPasswordConfirm} />
          <Route exact path='/test-start/' component={TestStartMob} />
          <Route exact path='/test-start-sample-paper/' component={TestStartSamplePaper} />
         {/* <Route exact path='/password-reset/:uid/:token/' component={ForgotPasswordConfirm} />*/}
          <Route exact path='/forgotpassword_link_sent' component={ForgotPassword_LinkSent} />
          <Route exact path='/verification-info' component={VerificationInfo} />
          <Route exact path='/result-summary' component={EndPracticeReview} />
          <Route exact path='/review-mob' component={ReviewQuestionMobile} />
          <Route exact path='*' component={PageNotFound} />
         </Switch>
      </div>
    </Router>
  );
}

export default App;
