import OtpInput from 'react-otp-input';
import React, { useState, useEffect } from 'react';

export default function OTPComponent(props) {
  const [otp, setOtp] = useState('');
  const handleChange = (otp) => {
    props.otpChange(otp);
    setOtp(otp);
  }

  const useStyles = {
    width: "32px",
    color: "#815CC6",
    outline: "none",
    border: "0",
    borderBottom: "1px solid #8854b9",
  }

  return (
    <>
      <div className="">
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          containerStyle="d-flex justify-content-between w-100 mt-2 pr-2"
          inputStyle={useStyles}
        />
      </div>
    </>
  );
}