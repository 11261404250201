import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import root_url from '../../components/api_url';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: 'black',
    height: '100%',
    width: '100%',
    padding: 'none'
  },
  menuButton: {
    width: '5%',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    paddingLeft: 10,
    width: '80%',
  },
}));



export default function Topbar(props) {
  let history = useHistory();
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    { props.backButton ? setBackButton(true) : setBackButton(false) }

  }, [])

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userLogout = () => {
    let auth = "Token " + localStorage.getItem('key');
    let url = root_url + 'api/accounts/logout/';
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth,
      },
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      }
      else {
        return false;
      }
    }).then(response => {
      localStorage.removeItem('language');
      localStorage.removeItem('practice_action_id');
      localStorage.removeItem('subject_name');
      localStorage.removeItem('user');
      localStorage.removeItem('question_time_diff');
      localStorage.removeItem('practice_found');
      localStorage.removeItem('name');
      localStorage.removeItem('user_id');
      localStorage.removeItem('not_access_code');
      localStorage.removeItem('bookmark_id');
      localStorage.removeItem('bookmark');
      localStorage.removeItem('country');
      localStorage.removeItem('goal');
      localStorage.removeItem('mobile');
      localStorage.removeItem('topic_name');
      localStorage.removeItem('ques_status');
      localStorage.removeItem('is_paused');
      localStorage.removeItem('userLoggedIn');
      localStorage.removeItem('sequential_skip');
      localStorage.removeItem('practice_start_datetime');
      localStorage.removeItem('message_option');
      localStorage.removeItem('topic_selected');
      localStorage.removeItem('key');
      localStorage.removeItem('feedback');
      localStorage.removeItem('key_local');
      localStorage.removeItem('board');
      localStorage.removeItem('subject_selected');
      localStorage.removeItem('goal_name');
      localStorage.removeItem('is_timed');
      localStorage.removeItem("UserType");
      document.location.href = document.location.origin;
    })
  }

  return (
<div className="w-100 h-10" style={{zIndex:110}} >
    <div className={classes.root} >
      <AppBar position="static" className={classes.root}>

        <Toolbar>
          <IconButton edge="center" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => history.goBack()}>
            {/* <MenuIcon />*/}
            {backButton ? <ArrowBackIosOutlinedIcon fontSize="medium" /> : ''}
          </IconButton>
          <Typography variant="h6" edge="center" className={classes.title}>
            {props.topbarHeading?props.topbarHeading:'Practice Home'}
          </Typography>
          {auth && (
            <div >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Contact Us</MenuItem>
                <MenuItem onClick={handleClose} onClick={userLogout}>Log Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
    </div>
  );
}
