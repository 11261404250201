import React, { Component } from 'react';
import $ from 'jquery';
import './../../assests/css/aos.css';
import './../../assests/css/bootstrap.min.css';
import './../../assests/css/style.css';
import './../../assests/css/sidebar.css';
import { Helmet } from "react-helmet";
import Dash from './../reusecomponent/dash';
import Tab1 from './PerformTabs/Tab1';
import PerformanceToggleTab from './PerformTabs/PerformanceToggleTab';
import root_url from "./api_url";
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'mobile-device-detect';


export class Perform extends Component {

  constructor() {

    super();
    this.state = {
      name: '',
      email: '',
      goal: '',
      subject: '',
      board: '',
      mobile: '',
      country: '',
      isLoader: false,
      togl: 'bg-white',
      user_verified: '',
      full_name: '',
      issue_type: 'Issues in using PrepHub',
      comments: '',
      contact_phone: '',
      contact_email: '',
      send_me: 'false',
      full_name_error: '',
      validation_error: '',
      email_error: '',
      phone_error: '',
      feedback_success: '',
      comments_error: ''
    }
    this.getProfile = this.getProfile.bind(this);
    this.changeUserdata = this.changeUserdata.bind(this);
    this.sendContactdata = this.sendContactdata.bind(this);
    this.send_me_check = this.send_me_check.bind(this);
    this.sidebarOnToogled = this.sidebarOnToogled.bind(this);
    this.idebarOffToogled = this.sidebarOffToogled.bind(this);

  }

  sidebarOnToogled = () => {
    this.setState({ togl: 'bg-white' });
  }
  sidebarOffToogled = () => {
    this.setState({ togl: '' });
  }
  getProfile(e) {

    let auth = 'Token ' + localStorage.getItem('key');
    let url = root_url + 'api/profile/profile/';
    this.setState({ isLoader: true });
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': auth,
      },

    }).then(response => response.json()).then(response => {
      if (response.count > 0) {
        this.setState({
          name: response.results[0].user.first_name,
          email: response.results[0].user.email,
          goal: response.results[0].goal[0],
          subject: response.results[0].subject,
          board: response.results[0].board,
          mobile: response.results[0].mobile,
          country: response.results[0].country,
          user_verified: response.results[0].is_verified,
          full_name: response.results[0].user.first_name + " " + response.results[0].user.last_name,
          contact_email: response.results[0].user.email,
          contact_phone: response.results[0].mobile,
        })
        localStorage.setItem('access_code', response.results[0].access_code);
        localStorage.setItem('user_id', response.results[0].user.id);
        localStorage.setItem('email', response.results[0].user.email);
        localStorage.setItem('is_timed', 'true');

        let goal = response.results[0].goal[0]
        // console.log(goal);
        let goal_code = goal.substring(0, goal.indexOf("-"));
        let url2 = root_url + 'api/utils/goal/?code=' + goal_code;
        fetch(url2, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': auth
          },

        }).then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return false;
          }
        }).then(response => {
          localStorage.setItem('goal', response.results[0].id);
          localStorage.setItem("goal_name", response.results[0].name);
          this.setState({ isLoader: false });

        })
      }
    });
  }
  changeUserdata(event) {
    this.setState({ feedback_success: "" });
    if (event.target.name == "full_name") {
      this.setState({ full_name: event.target.value });
      if (event.target.value == "") {
        this.setState({ full_name_error: "Invalid. Please enter name" });
      }
      else {
        this.setState({ full_name_error: "" });
      }
    }
    if (event.target.name == "emails") {
      this.setState({ contact_email: event.target.value });
      if (event.target.value == "") {
        this.setState({ email_error: "Invalid. Please enter email" });
      }
      else {
        this.setState({ email_error: "" });
      }
    }
    if (event.target.name == "phone") {
      this.setState({ contact_phone: event.target.value });
      if (event.target.value == "") {
        this.setState({ phone_error: "Invalid. Please enter phone number" });
      }
      else {
        this.setState({ phone_error: "" });
      }
    }
    if (event.target.name == "issue_type") {

      this.setState({ issue_type: event.target.value });
    }
    if (event.target.name == "comments") {
      this.setState({ comments: event.target.value });
      if (event.target.value == "") {
        this.setState({ comments_error: "Invalid. Please enter comment" });
      }
      else {
        this.setState({ comments_error: "" });

      }
    }
  }
  //----------this function used for send user data for feedback purpose by prep prep 1st-------------
  sendContactdata() {
    let contact_data = {
      full_name: this.state.full_name,
      contact: this.state.contact_phone,
      email: this.state.contact_email,
      issue_type: this.state.issue_type,
      comments: this.state.comments,
      send_me: this.state.send_me
    };
    if (this.state.full_name_error == "" && this.state.email_error == "" && this.state.phone_error == "") {
      this.setState({ validation_error: "" });
      this.setState({ feedback_success: "Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours." });
      let url = root_url + "Contact_Us/";
      let auth = 'Token ' + localStorage.getItem('key');
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth

        }, body: JSON.stringify(contact_data)
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return false;
        }
      }).then(response => {
      });
    }
    else {
      this.setState({ validation_error: "Feedback not sent. All fields are required." });
      this.setState({ feedback_success: "" });
    }
  }
  //----------this function used for send me checked or not -------------

  send_me_check(e) {
    this.setState({ feedback_success: "" });
    if (e.target.checked) {
      this.setState({ send_me: "true" });
    }
    else {
      this.setState({ send_me: "false" });
    }
  }


  render() {
    let logo = '';
    if (localStorage.getItem('is_logo') == 'true') {
      logo = localStorage.getItem('org_logo');
    }
    else {
      logo = PrepHub_logo;
    }

    let goal = localStorage.getItem('goal_name');
    return (
      <div>
        <Dash backButton={true} />
        <section className="dash-mid perf" >
          <div id="wrapper" className={this.state.togl}>
            <div id="sidebar-wrapper">
              <div className="btn-tog " id="menu-toggle" ></div>
              <div className="sidebar-nav perf-bdr">
                <div className="side-nav">
                  <div className="logo dnone"><img src={logo} /></div>
                  <div className="ham2" id="menu-toggle1"><img src={require("./../../assests/img/cross-menu.png")} width="15" /></div>
                  <div className="side-select dnone">
                    <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                    <select id="mySelect" data-show-content="true" className="form-control">
                      <option>{goal}</option>
                    </select>
                  </div>
                  <div className="lft-side-menu middle-menu">
                    <ul className="fix-left-nav">
                      <li><a href="/assignments"><img src={require("./../../assests/img/marks.jpg")} /><h6>Assignments</h6></a></li>
                      <li><a href="/dashboard"><img src={require("./../../assests/img/side-icon-2.png")} /><h6>Practice Mode</h6></a></li>
                      <li className="active"><a href="/perform"><img src={require("./../../assests/img/side-icon-3-active.png")} /><h6><font className="activeTab">Performance Management</font></h6></a><span></span></li>
                      <li data-toggle="modal" data-target="#contact_modal" onClick={this.sidebarOnToogled} className=" pointer"><a><img src={require("./../../assests/img/mail.png")} style={{ height: "30px", width: "30px" }} /><h6 id="contact-id">Contact Us</h6></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row right-s-pan">
                  <div className="col">
                    <div id="mySidepanel" className="sidepanel">
                      <a href="#" className="closebtn" onClick="closeNav()">
                        <i className="fa fa-angle-right" aria-hidden="true"></i></a>
                      <div className="scroe-c">
                        <div className="scroec-tit"> Score</div>
                        <img src={require("./../../assests/img/score-card.png")} />
                      </div>
                      <div className="score-num pt-5">
                        <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                        <ul>
                          <li>1</li>
                          <li>2</li>
                          <li>3</li>
                          <li>4</li>
                          <li>5</li>
                          <li>6</li>
                          <li>7</li>
                          <li>8</li>
                          <li>9</li>
                          <li>10</li>
                          <li>11</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--     Right-side-panel-end --> */}
                {/*<Tab1 />*/}
                {isMobileOnly?<PerformanceToggleTab />:<Tab1 />}
               
              </div>
            </div>
            {/* <!--   Right-side-page END --> */}
          </div>
          {/* <!-- wrapper end  --> */}
        </section>
        <div className="modal fade" id="contact_modal" >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">


              <div className="modal-body contact_modal-body">
                <div className="row mt-1 justify-content-between">
                  <h5 class="modal-title text-dark ml-2"><strong>Report / Feedback </strong></h5>
                  <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                    <img src={require("./../../assests/img/cross.jpg")} id="close_modal" />
                  </button>
                </div>
                <div className="row">
                  <p className="text-dark ml-3">Facing issues in using PrepHub? Let us know your feedback in the form below and our team will get in touch with you in the next 48 hours.</p>
                </div>
                <div className="row mt-3">
                  <p className="text-dark ml-3"> Please complete all fields.</p>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-12 col-lg-12">
                    <p className="text-danger">{this.state.validation_error}</p>
                    <p className="text-success">{this.state.feedback_success}</p>

                    <div className="form-group ">

                      <label for="exampleFormControlInput1" className="text_color">Name</label>
                      <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="full_name" placeholder="" value={this.state.full_name} onChange={this.changeUserdata} />
                      <p className="text-danger">{this.state.full_name_error}</p>
                    </div>
                    <div className="form-group">
                      <label for="exampleFormControlInput1" className="text_color">Email</label>
                      <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="emails" placeholder="" value={this.state.contact_email} onChange={this.changeUserdata} />
                      <p className="text-danger">{this.state.email_error}</p>
                    </div>
                    <div className="form-group">
                      <label for="exampleFormControlInput1" className="text_color">Phone</label>
                      <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="phone" placeholder="" value={this.state.contact_phone} onChange={this.changeUserdata} />
                      <p className="text-danger">{this.state.phone_error}</p>
                    </div>
                    <div class="form-group">
                      <label for="inputState" className="text_color">Issue type</label>
                      <select id="inputState" class="form-control form-control-sm  border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="issue_type" onChange={this.changeUserdata} >

                        <option value="Issues in using PrepHub" className="text-primary" selected>Issues in using PrepHub</option>
                        <option value="New feature request" className="text-primary">New feature request</option>
                        <option value="Others" className="text-primary">Others</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1" className="text_color">Comments</label>
                      <textarea className="form-control  border-top-0 text-dark" id="exampleFormControlTextarea1" rows="3" name="comments" onChange={this.changeUserdata} />
                      <p className="text-danger">{this.state.comments_error}</p>

                    </div>
                  </div>

                </div>
                <div className="row justify-content-between">

                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="send_me_checkbox" onChange={this.send_me_check} />
                    <label className="form-check-label text-dark" for="send_me_checkbox">Send me a copy of my response</label>
                  </div>
                  <div>
                    <button type="button " className="btn btn-primary  btn-sm" onClick={this.sendContactdata}>Send</button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>


      </div>
    )
  }

  componentDidMount() {
    this.getProfile();
    // Show the first tab by default
    $('.tabs-stage div').hide();
    //  $('.tabs-stage div:first').show();
    $('.tabs-nav li:first').addClass('tab-active');

    // Change tab class and display content
    $('.tabs-nav a').on('click', function (event) {
      event.preventDefault();
      $('.tabs-nav li').removeClass('tab-active');
      $(this).parent().addClass('tab-active');
      $('.tabs-stage div').hide();
      $($(this).attr('href')).show();
    });

    $("tr").click(function () {
      $(this).addClass("selected").siblings().removeClass("selected");
    });

    $(".tab2-cont tbody tr").click(function () {
      $(this).addClass("selected1").siblings().removeClass("selected1");
    });

    $(document).ready(function () {
      $('#trigger').click(function () {
        $('#overlay').fadeIn(300);
      });
      $('#close').click(function () {
        $('#overlay').fadeOut(300);
      });
    });

    $.fn.WBslider = function () {
      return this.each(function () {
        var $_this = $(this),
          $_input = $('input', $_this),
          $_current_value = $('.current-value', $_this),
          $_min_value = $('.min-value', $_this).text(),
          $_max_value = $('.max-value', $_this).text(),
          thumbwidth = 50; // set this to the pixel width of the thumb

        // set range max to current year
        $_input.attr('max', $_max_value);
        $('.max-value', $_this).text($_max_value);
        //$_input.val($_max_value - 10);

        $_input.on('input change keyup', function () {
          var $_this = $(this),
            val = parseInt($_input.val(), 10);

          if (val < 30) {
            val = '< 31';
          }
          if (val === '') { // Stop IE8 displaying NaN
            val = 0;
          }

          $_current_value.text(val);

          var pos = (val - $_input.attr('min')) / ($_input.attr('max') - $_input.attr('min'));

          // position the title with the thumb
          var thumbCorrect = thumbwidth * (pos - 0.5) * -1,
            titlepos = Math.round((pos * $_input.width()) - thumbwidth / 4 + thumbCorrect);

          $_current_value.css({ 'left': titlepos });

          // show "progress" on the track
          pos = Math.round(pos * 99); // to hide stuff behide the thumb
          var grad = 'linear-gradient(90deg, #8241be ' + pos + '%,#8241be ' + (pos + 1) + '%)';
          $_input.css({ 'background': grad });

        }).on('focus', function () {
          if (isNaN($(this).val())) {
            $(this).val(0);
          }
        }).trigger('change');

        $(window).on('resize', function () {
          $_input.trigger('change');
        });
      });
    };

    $(function () {
      $('.range-slide').WBslider();
    });

    $("#menu-toggle").click(function (e) {

      e.preventDefault();

      $("#wrapper").toggleClass("toggled");
    });

    $("#menu-toggle1").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $("#wrapper").toggleClass("bgblack");
    });
    $("#contact-id").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $("#wrapper").toggleClass("bgblack");
    });
  }
}

export default Perform