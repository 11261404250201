import React, { Component } from 'react';

export class Bookmarkprac extends Component {  
    render() {  
        return (
            <div className="container share-mod bookmark">
                <div className="modal" id="myModal4">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal">
                            <img src={require("./../../../assests/img/cross.jpg")} />
                            </button>
                            <p id='bookmarked-msg'>The question has been bookmarked, you can review all your bookmarked questions in the performance management tab under the <span>Review Practice </span>sesion</p>
                            <p id='un-bookmarked-msg'>The question has been un-bookmarked successfully.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Bookmarkprac