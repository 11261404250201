import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import root_url from '../components/api_url';
import Loader from 'react-loader-spinner';
import Cookies from 'universal-cookie';
import { withRouter } from "react-router";
import prephubIcon from "./../../assests/img/prephubIcon.png";
import { isMobileOnly } from 'mobile-device-detect';


const cookies = new Cookies;
export class EkalLogIn extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            isLoader: false,
            mobile: ''
        }
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.loginHandler = this.loginHandler.bind(this);
        this.handleKeypress = this.handleKeypress.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
        this.userLogin = this.userLogin.bind(this);
        this.checkUserExist = this.checkUserExist.bind(this);
    }
    componentDidMount() {
        localStorage.setItem('user_mobile', '');
        localStorage.setItem('user_password', '');
        document.getElementById('id_pass').value = localStorage.getItem('Rpass')?localStorage.getItem('Rpass'):'';
        document.getElementById('id_phone').value = localStorage.getItem('Rphone')?localStorage.getItem('Rphone'):'';
    }

    emailChange(e) {
        this.setState({
            email: e.target.value
        });
        localStorage.setItem('Remail', e.target.value);
    }
    phoneChange(e) {
        this.setState({
            mobile: e.target.value
        });
        localStorage.setItem('Rphone', e.target.value);

    }
    passwordChange(e) {
        this.setState({
            password: e.target.value
        });
        localStorage.setItem('Rpass', e.target.value);
    }
    handleKeypress = e => {
        if (e.key === 'Enter') {
            this.loginHandler(e)
        }
    };

    sendOTP(given_user_mobile) {
        let mobile = given_user_mobile
        let url = root_url + 'mobile_otp_send/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mobile: mobile,
                user_entry_type: 'EmailVerify_User'
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 200) {
                //this.setState({ numberMessage: response.message, isLoader: false });
            }
            if (response.status == 201) {
                document.location.href = document.location.origin + `/enterotp-ekal/newuser-ekal?mobile=${mobile}&user_type=EmailVerify_User`;
                localStorage.setItem('user_type', 'EmailVerify_User');
            }
        })
    }

    checkUserExist(given_user_mobile, given_user_password) {
        this.setState({ isLoader: true });
        let url = root_url + `check_user_exist/${given_user_mobile}/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 200 && response.message == "User not found" && response.user_mobile == "") {
                localStorage.setItem('user_mobile', given_user_mobile);
                localStorage.setItem('user_password', given_user_password);
                let subscription_type=this.props.match.params.subscription_type
                document.location.href = `/newuser-ekal/${subscription_type}/`;
            }
            if (response.status == 201 && response.message == "User found" && response.user_mobile != "") {
                this.userLogin(given_user_mobile, given_user_password);
            }
        })


    }

    userLogin(given_user_mobile, given_user_password) {
        let mobile = given_user_mobile;
        let password = given_user_password;
        let url = root_url + 'api/accounts/login/';
        let emailverifyurl = root_url + `check_user_verify/${mobile}/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: mobile,
                password: password,
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.email) {
                this.setState({ isLoader: false });
                this.setState({ errorMessage: response.email[0] });
            }
            else if (response.key) {
                let key = response.key;
                localStorage.setItem('key', key);
                let url1 = root_url + 'api/profile/profile/';
                let auth = "Token " + localStorage.getItem('key')
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    },

                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    let email = response.results[0].user.email;
                    let id = response.results[0].user.id;
                    let given_user_result = response.results;
                    localStorage.setItem('user_id', id);
                    localStorage.setItem('is_timed', 'true');
                    localStorage.setItem('email', email);
                    localStorage.setItem('first_name', given_user_result[0].user.first_name);
                    localStorage.setItem('last_name', given_user_result[0].user.last_name);
                    localStorage.setItem('mobile', given_user_result[0].user.username);
                    fetch(emailverifyurl, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },

                    }).then(response => {
                        if (response.status === 400) {
                            return response.json();
                        }
                        else if (response.status === 200) {
                            return response.json();
                        }
                        else if (response.status === 201) {
                            return response.json();
                        }
                        else {
                            return false;
                        }
                    }).then(response => {
                        if (response.status == 200 && response.message == 'User email not verified') {
                            this.sendOTP(given_user_result[0].user.username)
                        }
                        if (response.status == 201 && response.message == 'User email verified') {
                            if (given_user_result[0].access_code == null || given_user_result[0].country == null || given_user_result[0].language == null || given_user_result[0].goal.length == 0) {
                                document.location.href = document.location.origin + '/learning';
                            }
                            if (given_user_result[0].access_code != null && given_user_result[0].country != null && given_user_result[0].language != null && given_user_result[0].goal.length != 0) {
                                document.location.href = document.location.origin + '/dashboard';
                            }
                        }
                    })
                })
            }
            else {
                this.setState({
                    errorMessage: response.non_field_errors
                })
                this.setState({ isLoader: false });
            }
        })

    }


    loginHandler(e) {
        let mobileexp = /^\d{10}$/;
        e.preventDefault();
        this.setState({ errorMessage: '' });
        let mobile = this.state.mobile ? this.state.mobile : localStorage.getItem('Rphone');
        let email = this.state.email ? this.state.email : localStorage.getItem('Remail');
        let password = this.state.password ? this.state.password : localStorage.getItem('Rpass');
        localStorage.setItem('user_email', email);
        localStorage.setItem('user_pass', password);
        localStorage.setItem('user_phone', mobile);
        localStorage.setItem('user_username', mobile);
        localStorage.setItem('user_pass', password);
        if (mobile == '') {
            this.setState({ errorMessage: 'Please enter your mobile' });
        }
        else if (!mobile.match(mobileexp)) {
            this.setState({ errorMessage: 'Enter valid mobile number' });
        }
        else if (password == '') {
            this.setState({ errorMessage: 'Please enter your password' });
        }
        else {
            this.checkUserExist(mobile, password);
        }
    }

    render() {
        return (
            // Html Template
            <section className="sign-page">
                <div className="prephubIcon">
                    <img src={prephubIcon} className={`${isMobileOnly ? 'hw-80' : 'hw-110'}`} />
                </div>
                <div className="container">
                    <div className="col-md-5 res-size m-auto">
                        {this.state.isLoader && <div style={{
                            width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                        }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                        {/* <div className="logo"> <img src="img/preblogo.svg"></div> */}
                        <div className="logo-home-ekal"><img src="https://cdnimages.myclassboard.com/loginpages/ekal_assets/img/ekal-logo.png" /></div>
                        <div className="row">
                            <div className="col">
                                <div className="row form-group" >
                                    <div className="col-md-12 pt-5 m-auto" /*data-aos="fade-left" */>
                                        <input type="text" placeholder="Enter 10 digit mobile number" className="form-control" id="id_phone" onKeyPress={this.handleKeypress} onChange={this.phoneChange} required />
                                    </div>
                                </div>
                                <div className="row form-group" /*data-aos="fade-right"*/>
                                    <div className="col-md-12">
                                        <input type="password" placeholder="Password" className="form-control" id="id_pass" onKeyPress={this.handleKeypress} onChange={this.passwordChange} required />
                                    </div>
                                </div>
                                {this.state.errorMessage && <span className="error"> {this.state.errorMessage} </span>}

                                <div className="row form-group" >
                                    <div className="col-md-12 pt-2 lg:pt-5 m-auto" /*data-aos="fade-left" */>
                                        <div className="common-btn  w-100" >
                                            <button type="submit" className="btn w-100 lnheight-10" onClick={this.loginHandler} id="sign-in">Begin Practice</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="row form-group" >
                                    <div className="d-flex justify-content-center  col-md-12 pt-2 lg:pt-5 w-100 ">
                                        <div className="divh6"> <h6><Link to="/forgotpassword" id="forgotpassword-id">Forgot Password ?</Link></h6></div>

                                    </div>
                                </div>
                                {/* <div className="row sign-in">
                                    <div className="col-md-12">
                                        <div className="common-btn" ><button type='submit' onClick={this.loginHandler} id="sign-in">Sign In</button>
                                        </div>
                                    </div>
                               </div> */}
                            </div>
                        </div>
                        {/*<div className="row pt-3">
                            <div className="col-md-6 pt-2">
                                <div className="divh6"> <h6><Link to="/forgotpassword" id="forgotpassword-id">Forgot Password ?</Link></h6></div>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="common-btn">
                                    <button><Link to={`/newuser-ekal/${this.props.match.params.subscription_type}`} id="newuser-id">New User</Link></button>
                                </div>
                            </div>
                            </div>*/}
                    </div>
                </div>
            </section>

        )

    }
}

export default withRouter(EkalLogIn);
