import React, { Component } from 'react';
import Loader from './Loader'
import Editor from 'react-editor-md';


export default function ReviewQuestionSolution(props) {
    return (
        <>
            <div className="d-flex flex-column w-100 ">
                <div className="text-secondary pt-3 ">{props.topic ? props.topic : 'Topic'}</div>
                <div className="d-flex pt-1 pl-1">
                    <div className="col-4 d-flex justify-content-start border-right-colored  pl-0 font-color-size" >Q id : # {props.questionId ? props.questionId : '000'}</div>
                    <div className="col-4 d-flex justify-content-center border-right-colored font-color-size">Level : {props.level ? props.level : ''}</div>
                    <div className="col-4 d-flex justify-content-end pr-1 font-color-size">Score : {props.questionScore ? props.questionScore : '0'} points</div>
                </div>
                <div className="w-100 pt-1 border-bottom pb-4 pl-1">
                    {props.visible ?
                        <Editor.EditorShow config={
                            {
                                markdown: props.questionContent,
                                id: "id_content" + props.questionId,
                                height: 300,
                                name: "id_content-markdown-doc" + props.questionId,
                                saveHTMLToTextarea: true,
                                toolbar: false,
                                flowChart: false,
                                sequenceDiagram: false,
                            }
                        } /> :<div className="pt-3" style={{minHeight:'100px'}}> <Loader color="primary" height="50px" width="50px" divHeight="h-100" /></div>}

                </div>
                <div className="w-100 pt-3 text-secondary border-bottom pb-1 ">Select all that apply </div>
                <div className="w-100 pt-3">
                    {props.option_dropdown}
                </div>
                <div className="w-100 pt-3 text-secondary ">Solution </div>
                {/*<div className="" style={{height:'500px'}}></div>*/}
                <div className="w-100 pt-1  pb-4 pl-1" >
                    {props.visible ?
                        <Editor.EditorShow config={
                            {
                                markdown: props.questionsolution,
                                id: "id_contents",
                                height: 300,
                                name: "id_content-markdown-docs",
                                saveHTMLToTextarea: true,
                                toolbar: false,
                                flowChart: false,
                                sequenceDiagram: false,
                            }
                        } /> :<div className="pt-3" style={{minHeight:'100px'}}> <Loader color="primary" height="50px" width="50px" divHeight="h-100" /></div>}
                </div>

                <div className="container-fluid p-0 mt-5" id="onlyNext">
                    <div class="row">
                        <div className="lightGray col p-0" >
                            <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 submitNext" onClick={props.nextQuestion}>Next Question</button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mt-5" id="onlyEnd">
                    <div class="row">
                        <div className="lightGray col p-0" >
                            <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 endReview" onClick={props.endReview}>End Review</button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mt-5" id="onlyPrevNext">
                    <div class="row">
                        <div className="lightGray col p-0">
                            <button type="button" className="btn text-white font-weight-bold shadow-none pb-2 btn-block h-100 rounded-0 skipbutton" onClick={props.previousQuestion}>Previous Question</button>
                        </div>
                        <div className="lightGray col p-0" >
                            <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 submitNext" onClick={props.nextQuestion}>Next Question</button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 mt-5" id="onlyPrevEnd">
                    <div class="row">
                        <div className="lightGray col p-0">
                            <button type="button" className="btn text-white font-weight-bold shadow-none d-block btn-block  pb-2 rounded-0 skipbutton" onClick={props.previousQuestion} >Previous Question</button>
                        </div>
                        <div className="lightGray col p-0" >
                            <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 endReview" onClick={props.endReview}>End Review</button>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
