import React, { Component } from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import ButtonMob from './TopBottomBar/BurttonMob'
import EndPracticeInformation from './EndPracticeInformation'
import Loader from './Loader'


export default function EndPracticeMobile(props) {
    return (
        <>
            <div className="d-flex flex-column w-100" style={{ height: '100vh' }}>
                <Topbar backButton={false} topbarHeading="Result Summary"/>
                {props.showReview?<EndPracticeInformation solved={props.solved} score={props.score} accuracy={props.accuracy} totalTime={props.totalTime} minutes={props.minutes} seconds={props.seconds} message={props.message} showReviewButton={false} />:<Loader color="primary" height="120px" width="120px" divHeight="h-100" />}
                <ButtonMob button_name="Review" status={props.showReview} reviewPage={props.reviewPage} />
                <Bottombar value="perform" />
            </div>
        </>
    );
}
