import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import root_url from './../components/api_url';
import { isMobileOnly } from 'mobile-device-detect';
import Topbar from '../components/TopBottomBar/Topbar'
export class Dash extends Component {
    constructor(props) {
        super(props);
        this.userLogout = this.userLogout.bind(this);
        this.state = {
            name: '',
            email: '',
            goal: '',
            subject: '',
            board: '',
            mobile: '',
            country: '',
            isLoader: false,
            togl: 'bg-white',
            user_verified: '',
            full_name: '',
            issue_type: 'Issues in using PrepHub',
            comments: '',
            contact_phone: '',
            contact_email: '',
            send_me: 'false',
            full_name_error: '',
            validation_error: '',
            email_error: '',
            phone_error: '',
            feedback_success: '',
            comments_error: ''
    
    
        } 
        this.getProfile = this.getProfile.bind(this);
        this.changeUserdata = this.changeUserdata.bind(this);
        //this.getSubject = this.getSubject.bind(this);
       // this.getTopic = this.getTopic.bind(this);
        this.sendContactdata = this.sendContactdata.bind(this);
        this.send_me_check = this.send_me_check.bind(this);
       }
    userLogout(e){
        let auth = "Token "+localStorage.getItem('key');
        let url = root_url+'api/accounts/logout/';
        fetch(url, {
            method: 'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },
            }).then(response=>{
            if(response.status===200){
                return response.json();
            }
            else{
                return false;
            }
        }).then(response=>{
            localStorage.removeItem('language');
            localStorage.removeItem('practice_action_id');
            localStorage.removeItem('subject_name');
            localStorage.removeItem('user');
            localStorage.removeItem('question_time_diff');
            localStorage.removeItem('practice_found');
            localStorage.removeItem('name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('not_access_code');
            localStorage.removeItem('bookmark_id');
            localStorage.removeItem('bookmark');
            localStorage.removeItem('country');
            localStorage.removeItem('goal');
            localStorage.removeItem('mobile');
            localStorage.removeItem('topic_name');
            localStorage.removeItem('ques_status');
            localStorage.removeItem('is_paused');
            localStorage.removeItem('userLoggedIn');
            localStorage.removeItem('sequential_skip');
            localStorage.removeItem('practice_start_datetime');
            localStorage.removeItem('message_option');
            localStorage.removeItem('topic_selected');
            localStorage.removeItem('key');
            localStorage.removeItem('feedback');
            localStorage.removeItem('key_local');
            localStorage.removeItem('board');
            localStorage.removeItem('subject_selected');
            localStorage.removeItem('goal_name');
            localStorage.removeItem('is_timed');
            localStorage.removeItem("UserType");
            document.location.href = document.location.origin;
        })
    }

    getProfile(e) {
  
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/profile/profile/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
      
        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
               // console.log('response getting', response);
                this.setState({
                    name: response.results[0].user.first_name,
                    email: response.results[0].user.email,
                    goal: response.results[0].goal[0],
                    subject: response.results[0].subject,
                    board: response.results[0].board,
                    mobile: response.results[0].mobile,
                    country: response.results[0].country,
                    user_verified: response.results[0].is_verified,
                    full_name: response.results[0].user.first_name + " " + response.results[0].user.last_name,
                    contact_email: response.results[0].user.email,
                    contact_phone: response.results[0].mobile,
                })
                localStorage.setItem('access_code', response.results[0].access_code);
                localStorage.setItem('user_id', response.results[0].user.id);
                localStorage.setItem('email', response.results[0].user.email);
                localStorage.setItem('is_timed', 'true');
              
                let goal = response.results[0].goal[0]
                // console.log(goal);
                let goal_code = goal.substring(0, goal.indexOf("-"));
                let url2 = root_url + 'api/utils/goal/?code=' + goal_code;
                fetch(url2, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    },
      
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    localStorage.setItem('goal', response.results[0].id);
                    localStorage.setItem("goal_name", response.results[0].name);
                    this.setState({ isLoader: false });
                   
                })
            }
        });
      }
      changeUserdata(event) {
        this.setState({ feedback_success: "" });
        if (event.target.name == "full_name") {
           this.setState({ full_name: event.target.value });
            if (event.target.value == "") {
                this.setState({ full_name_error: "Invalid. Please enter name" });
            }
            else {
                this.setState({ full_name_error: "" });
              }
         }
        if (event.target.name == "emails") {
            this.setState({ contact_email: event.target.value });
            if (event.target.value == "") {
                this.setState({ email_error: "Invalid. Please enter email" });
            }
            else {
                this.setState({ email_error: "" });
            }
        }
        if (event.target.name == "phone") {
            this.setState({ contact_phone: event.target.value });
            if (event.target.value == "") {
                this.setState({ phone_error: "Invalid. Please enter phone number" });
            }
            else {
                this.setState({ phone_error: "" });
            }
          }
        if (event.target.name == "issue_type") {
      
            this.setState({ issue_type: event.target.value });
        }
        if (event.target.name == "comments") {
            this.setState({ comments: event.target.value });
            if (event.target.value == "") {
                this.setState({ comments_error: "Invalid. Please enter comment" });
            }
            else {
                this.setState({ comments_error: "" });
      
            }
        }
      }
       //----------this function used for send user data for feedback purpose by prep prep 1st-------------
       sendContactdata() {
        let contact_data = {
            full_name: this.state.full_name,
            contact: this.state.contact_phone,
            email: this.state.contact_email,
            issue_type: this.state.issue_type,
            comments: this.state.comments,
            send_me: this.state.send_me
        };
        if (this.state.full_name_error == "" && this.state.email_error == "" && this.state.phone_error == "") {
            this.setState({ validation_error: "" });
            this.setState({ feedback_success: "Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours." });
            let url = root_url + "Contact_Us/";
            //let url = "http://127.0.0.1:8000+"/Contact_Us/"
            let auth = 'Token ' + localStorage.getItem('key');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                   
                }, body: JSON.stringify(contact_data)
            }).then(response => {
                if (response.status === 200) {
                   //this.setState({feedback_success:"Your feedback send successfully."});
                   return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                //console.log('Goal', response);
              });
        }
        else {
            this.setState({ validation_error: "Feedback not sent. All fields are required." });
            this.setState({ feedback_success: "" });
          }
        }
      //----------this function used for send me checked or not -------------
      
      send_me_check(e) {
        this.setState({ feedback_success: "" });
         if (e.target.checked) {
             this.setState({ send_me: "true" });
         }
         else {
            this.setState({ send_me: "false" });
        }
      }

      componentDidMount() {
        this.getProfile();
    }
      
    render () {
    let logo = '';
     if(localStorage.getItem('is_logo')=='true'){
            logo = localStorage.getItem('org_logo');
        }
        else{
            logo = PrepHub_logo;
        }

        let goal_name = localStorage.getItem('goal_name')
        return (
            <div>
            <section className="dash pratice-mode">
                <div className="container-fluid">
                   {isMobileOnly?<div className="row"><Topbar  backButton={this.props.backButton} topbarHeading="Performance Management"/></div>:
                    <div className="row">
                    <div className="pro-mob ">
                        <div className="dropdown notification profile-pic profile-mob">
                        <button type="button" data-toggle="dropdown">
                        <img src={require("./../../assests/img/profile-pic.png")} /></button>
                        <div className="dropdown-menu"> <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i>
                            My Account</Link>
                            <Link data-toggle="modal" data-target="#contact_modal" className="dropdown-item" to="#"><i className="fa fa-envelope-open" aria-hidden="true"></i> Contact Us </Link>

                            <Link className="dropdown-item" to="#" onClick={this.userLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>
                            Log Out</Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="logo"><img src={logo} /></div>
                            <div className="hamburg" id="menu-toggle"><span><i className="fa fa-bars" aria-hidden="true"></i>
                            </span>{goal_name}
                            </div>
                        </div>
                        <div className="col-md-2 offset-7">
                            <div className="row wxh">
                            <div className="col-md-6">
                                <div className="dropdown-notification notification">
                                    <i className="fa fa-bell-o" aria-hidden="true"><span>12</span></i> 
                                    <div className="dropdown-content">
                                        <div className="top-noti">
                                        <img src={require("./../../assests/img/noti-icon-0.jpg")} />12 Notification <span>Clear All</span>
                                        </div>
                                        <div className="bot-noti">
                                        <ul>
                                            <li>
                                                <img src={require("./../../assests/img/noti-icon-1.jpg")} />
                                                <h4>T Ritika Singh</h4>
                                                <h5>Sales dashbaord have</h5>
                                                <h6>4 Minutes Ago</h6>
                                            </li>
                                            <li>
                                                <img src={require("./../../assests/img/noti-icon-2.jpg")} />
                                                <h4>T Ritika Singh</h4>
                                                <h5>You have done a great job! #db</h5>
                                                <h6>1 hour Ago</h6>
                                            </li>
                                            <li>
                                                <img src={require("./../../assests/img/noti-icon-3.jpg")} />
                                                <h4>T Ritika Singh</h4>
                                                <h5>You have done a great job! #db</h5>
                                                <h6>1 hour Ago</h6>
                                            </li>
                                            <li>
                                                <img src={require("./../../assests/img/noti-icon-4.jpg")} />
                                                <h4>T Ritika Singh</h4>
                                                <h5>You have done a great job! #db</h5>
                                                <h6>1 hour Ago</h6>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dropdown notification profile-pic">
                                    <button type="button" data-toggle="dropdown">
                                    <img src={require("./../../assests/img/profile-pic.png")} />
                                    </button>
                                    <div className="dropdown-menu">
                                        <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i>
                                        My Account</Link>
                                        <Link data-toggle="modal" data-target="#contact_modal" className="dropdown-item" to="#"><i className="fa fa-envelope-open" aria-hidden="true"></i> Contact Us </Link>

                                        <Link className="dropdown-item" to='#' onClick={this.userLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>
                                        Log Out</Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
        </div>}
    </div>
                </section>
                <div className="modal fade" id="contact_modal" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-body contact_modal-body">
                                <div className="row mt-1 justify-content-between">
                                    <h5 class="modal-title text-dark ml-2"><strong>Report / Feedback </strong></h5>
                                    <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                                        <img src={require("./../../assests/img/cross.jpg")} id="close_modal" />
                                    </button>
                                </div>
                                <div className="row">
                                    <p className="text-dark ml-3">Facing issues in using PrepHub? Let us know your feedback in the form below and our team will get in touch with you in the next 48 hours.</p>
                                </div>
                                <div className="row mt-3">
                                    <p className="text-dark ml-3"> Please complete all fields.</p>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <p className="text-danger">{this.state.validation_error}</p>
                                        <p className="text-success">{this.state.feedback_success}</p>

                                        <div className="form-group ">

                                            <label for="exampleFormControlInput1" className="text_color">Name</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="full_name" placeholder="" value={this.state.full_name} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.full_name_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Email</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="emails" placeholder="" value={this.state.contact_email} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.email_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Phone</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="phone" placeholder="" value={this.state.contact_phone} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.phone_error}</p>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputState" className="text_color">Issue type</label>
                                            <select id="inputState" class="form-control form-control-sm  border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="issue_type" onChange={this.changeUserdata} >

                                                <option value="Issues in using PrepHub" className="text-primary" selected>Issues in using PrepHub</option>
                                                <option value="New feature request" className="text-primary">New feature request</option>
                                                <option value="Others" className="text-primary">Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1" className="text_color">Comments</label>
                                            <textarea className="form-control  border-top-0 text-dark" id="exampleFormControlTextarea1" rows="3" name="comments" onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.comments_error}</p>

                                        </div>
                                    </div>

                                </div>
                                <div className="row justify-content-between">

                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="send_me_checkbox" onChange={this.send_me_check} />
                                        <label className="form-check-label text-dark" for="send_me_checkbox">Send me a copy of my response</label>
                                    </div>
                                    <div>
                                        <button type="button " className="btn btn-primary  btn-sm" onClick={this.sendContactdata}>Send</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dash