import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';

export default function InformationTooltip(props) {
  return (
    <Tooltip title={props.subject} placement="top">
      <IconButton>
      <i className="fa fa-info-circle" aria-hidden="true" id="information" onClick={(e)=>{e.stopPropagation();}}></i>
      {/*<InfoSharpIcon />*/}
      </IconButton>
    </Tooltip>
  );
}