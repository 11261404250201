import React, { useEffect, useState } from 'react'
import TabComponent from './Reacttab/TabComponent';
import AssignmentDetail from './AssignmentDetail';
import root_url from './../components/api_url';
import Loader from './Loader'
import EndPracticeInformation from './EndPracticeInformation';
import SamplePaperTestInstruction from './SamplePaperTestInstruction';
import SamplePaperQuestionTest from './SamplePaperQuestionTest'
const axios = require('axios');

export default function MainbodyPanel(props) {
    const [allSamplePapers, setAllSamplePapers] = useState([]);
    const [unSolvedSamplePapers, setUnSolvedSamplePapers] = useState([]);
    const [completedSamplePapers, setCompletedSamplePapers] = useState([]);
    const [showTab, setShowTab] = useState(false);

    const getAllSamplePapers = () => {
        let logged_user = localStorage.getItem('mobile');
        axios.get(`${root_url}api/utils/get_all_sample_papers/${logged_user}/`)
            .then((res) => {
                let samplePaperData = res.data;
                if (samplePaperData.message == "Sample Papers Found" && samplePaperData.status_code == 201) {
                    setAllSamplePapers(samplePaperData.data);
                    let data = samplePaperData.data;
                    let completedtest = []
                    let unSolvedtest = []
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].sample_paper_status) {
                            completedtest.push(data[i])
                        }
                        else {
                            unSolvedtest.push(data[i])
                        }
                    }
                    setCompletedSamplePapers(completedtest)
                    setUnSolvedSamplePapers(unSolvedtest)
                    setShowTab(true)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        if (props.childComponent == "SamplePapers") {
            getAllSamplePapers();
        }
    }, [])
    let data = [allSamplePapers, unSolvedSamplePapers, completedSamplePapers]
    let labelData = [`All (${allSamplePapers.length})`, `Unsolved (${unSolvedSamplePapers.length})`, `Completed (${completedSamplePapers.length})`]
    return (
        <>
            <div className={`pr-5 pl-5 mt-3 ${props.childComponent == "Assignments" ? 'd-flex justify-content-center' : ''}`} style={{ paddingLeft: "0%" }}>
                {props.childComponent == "Assignments" ? <AssignmentDetail /> : props.childComponent == "TestReview" ? <EndPracticeInformation solved={props.solved} score={props.score} accuracy={props.accuracy} totalTime={props.totalTime} minutes={props.minutes} seconds={props.seconds} message={props.message} showReviewButton={true} /> :props.childComponent == "SamplePaperTestStart" ? <SamplePaperTestInstruction showstartTestButton={true} modalOpen={props.modalOpen} getIntialQuestionSamplepaper={props.getIntialQuestionSamplepaper}  modalStatusChange={props.modalStatusChange}/>:props.childComponent == "SamplePapersQuestion" ?<SamplePaperQuestionTest changePauseTimerState={props.changePauseTimerState} id={props.id} score={props.score} pausePractice={props.pausePractice} seconds={props.seconds}  paused={props.paused} confirmEndPractice={props.confirmEndPractice} pauseTimer={props.pauseTimer} saveBookmark={props.saveBookmark} right_side_panel={props.right_side_panel} isLoader={props.isLoader} isVisible={props.isVisible}  questionData={props.questionData} option_dropdown={props.option_dropdown} getNextQuestion={props.getNextQuestion} getSkipQuestion={props.getSkipQuestion}/>: props.childComponent == "SamplePapers" ?
                    showTab ? <TabComponent labels={labelData} componentName="SamplePapers" componentData={data} centered={true}/> : <div style={{ marginTop: '200px' }}><Loader color="primary" height="200px" width="200px" divHeight="h-100" /></div>
                    : null}
            </div>
        </>
    );
}