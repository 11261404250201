import React, { Component } from 'react';
import root_url from "../api_url";
import Moment from 'react-moment';
import $ from 'jquery';
import Loader from "react-loader-spinner";
import { PieChart } from 'react-minimal-pie-chart';
import Bottombar from '../TopBottomBar/Bottombar'
import { Link } from 'react-router-dom';



class PracticeTab extends React.Component {
    constructor() {
        super();
        this.state = {
            review_practice: [],
            bookmarked_review_practice: [],
            practice_id: localStorage.getItem('practice_id') ? localStorage.getItem('practice_id') : '',
            end_practice: [],
            isLoader: false,
        }
        this.getReview = this.getReview.bind(this)
        this.reviewPage = this.reviewPage.bind(this)
        this.bookmarkedReview = this.bookmarkedReview.bind(this)
    }

    getReview(e, id, topic) {
        this.setState({
            practice_id: id
        })
        localStorage.setItem("topic_name", topic);
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            this.setState({
                end_practice: response.data[0],
            })
            localStorage.setItem('score', response.data[0].score);
            localStorage.setItem('percentage', response.data[0].accuracy);
            localStorage.setItem('solved', response.data[0].solved);
            localStorage.setItem('total', response.data[0].total);
            localStorage.setItem('question_count', response.data[0].solved);
            localStorage.setItem('message_option', response.data[0].message_option);
            localStorage.setItem('time', response.data[0].time);
        });
        localStorage.setItem('practice_id', id);
        document.getElementById('confirm_review_practice').click();
        this.setState({ isLoader: false });
    }

    reviewPage() {
        document.location.href = document.location.origin + '/review';
    }

    bookmarkedReview() {
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_bookmarked_review/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({
                bookmarked_review_practice: response.data,

            })
            this.setState({ isLoader: false });
        });
    }

    componentWillMount() {
        //this.bookmarkedReview();
        let auth = 'Token ' + localStorage.getItem('key');
        let url = root_url + 'api/utils/performance_strength/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {

            this.setState({
                review_practice: response.data,

            })
            this.setState({ isLoader: false });
        });
    }

    render() {
        let score = this.state.end_practice['score'] ? this.state.end_practice['score'] : 0;
        let accuracy = this.state.end_practice['accuracy'] ? this.state.end_practice['accuracy'] : 0;
        let solve = this.state.end_practice['solved'] ? this.state.end_practice['solved'] : 0;
        let msg = this.state.end_practice['message_option'] ? this.state.end_practice['message_option'] : '';
        let minutes = this.state.end_practice['minutes'] ? this.state.end_practice['minutes'] : 0;
        let seconds = this.state.end_practice['seconds'] ? this.state.end_practice['seconds'] : 0;
        return (
            <div>
                <div id="tab2" className="tabcontent tab2-cont mob-filter-review">
                    <div className="row mob-design">
                        <div className="col">
                            <div className="row pt-3 reivew-mob-prac">
                                <div className="col">
                                    <h3>Review Practice</h3>
                                    <div className="review-mob-label">
                                        <ul>
                                            <li>Correct (within time) </li>
                                            <li>Correct (exceeded time)</li>
                                            <li>Incorrect</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="last-five-gr pt-4">
                                        <ul>
                                            {this.state.review_practice.map((item, key) => (
                                                <li key={key}>
                                                    <div className="mob-five-graph  dib">
                                                        <h5>{parseInt(parseInt(item.correct_within_time) + parseInt(item.correct_exceeded_time))}%</h5>
                                                        <PieChart
                                                            data={[
                                                                { title: 'Correct(within time)', value: parseInt(item.correct_within_time), color: '#bcd43e' },
                                                                { title: 'Correct(exceeded time)', value: parseInt(item.correct_exceeded_time), color: '#f7d478' },
                                                                { title: 'incorrect time', value: parseInt(item.incorrect_count), color: '#fc7fab' },
                                                            ]} lineWidth={15} />
                                                    </div>
                                                    <div className="mob-five-msg mob-rev dib">
                                                        <div className="mob-five-arrow"><i onClick={(e) => this.getReview(e, item.practice_id, item.topic)} className="fa fa-angle-right" aria-hidden="true"></i>
                                                        </div>
                                                        <div className="mob-five-subj dib">
                                                            {/*<h4>{item.topic}</h4>*/}
                                                            <div className="multi-sub-mob">
                                                                <div className="multi-icon"><h4>{item.topic} <i className="fa fa-exclamation-circle" aria-hidden="true"></i></h4></div>
                                                                <div className="multi-subs">
                                                                    <p>{item.topic}</p>
                                                                    {/*<h6>{last_five_test[i].subject}</h6>*/}
                                                                </div>
                                                            </div>
                                                            <div className="result-top-mob">
                                                                <ul>
                                                                    <li>{parseInt(item.correct_within_time)}</li>
                                                                    <li>{parseInt(item.correct_exceeded_time)}</li>
                                                                    <li>{parseInt(item.incorrect_count)}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="mob-five-label dib"><h5>Score: <span>{item.correct_score}/{item.score}</span></h5>
                                                            <h6>Avg Speed: <span>{item.minutes}:{item.seconds} min</span></h6>

                                                        </div>
                                                    </div>
                                                </li>)

                                            )}

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mob-side-nav  perm-bot-nav">
                        {/* <Bottombar value="perform" />
                          <div className="col">
                             <ul>
                              <li><a href="/dashboard"><img src={require("./../../../assests/img/side-icon-2.png")} />Practice 123</a></li>
                              <li className="active"><a href="/perform" className="active"><img src={require("./../../../assests/img/side-icon-3-active.png")} />Performance </a></li>

                            </ul>
                                                        </div>*/}
                    </div>

                    <div className="row  review-mob bg-white">
                        <div className="col-md-1 offset-11">

                            <div id="overlay">
                                <div id="popup">
                                    <div id="close">X</div>
                                    <div className="row popin">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <h3>Filter <span><i className="fa fa-question" aria-hidden="true"></i></span></h3>
                                                </div>
                                            </div>
                                            <div className="row pt-5">
                                                <div className="col">
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Select Goal</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row goal">
                                                <div className="col">
                                                    <ul>
                                                        <li><a href="">CBSE className 10 </a></li>
                                                        <li><a href="">Goal2 </a></li>
                                                        <li><a href="">Goal3</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row pt-5">
                                                <div className="col">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck2" name="example1" />
                                                        <label className="custom-control-label" htmlFor="customCheck2">Score</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row rangeslide pt-3">
                                                <div className="col">
                                                    <div className="range-slide">
                                                        <span className="min-value">30</span>
                                                        <div className="range">
                                                            <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                            <span className="current-value">50</span>
                                                        </div>
                                                        <span className="max-value">300</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck3" name="example1" />
                                                        <label className="custom-control-label" htmlFor="customCheck3">Accuracy</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row rangeslide pt-3">
                                                <div className="col">
                                                    <div className="range-slide">
                                                        <span className="min-value">30</span>
                                                        <div className="range">
                                                            <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                            <span className="current-value">50</span>
                                                        </div>
                                                        <span className="max-value">300</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-4">
                                                <div className="col">
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck4" name="example1" />
                                                        <label className="custom-control-label" htmlFor="customCheck4">Average Speed</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row rangeslide pt-3">
                                                <div className="col">
                                                    <div className="range-slide">
                                                        <span className="min-value">30</span>
                                                        <div className="range">
                                                            <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                            <span className="current-value">50</span>
                                                        </div>
                                                        <span className="max-value">300</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row rang-btn">
                                                <div className="col text-center">
                                                    <button>Go</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isLoader && <div style={{
                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                    }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                    <table className="table review-mob bg-white" id="data">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Practice Session</th>
                                <th>Practice Date</th>
                                <th>Score</th>
                                <th>Solves</th>
                                <th>Accuracy</th>
                                <th>Avg Speed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.bookmarked_review_practice.map((item, key) => (
                                <tr className="highlight" style={{ height: "10px" }} >
                                    <td><a href="">View</a></td>
                                    <td>Bookmarked</td>

                                    <td><Moment format="DD/MM/YYYY ">{item.date}</Moment></td>
                                    <td>{item.correct_score}/{item.score}</td>
                                    <td>{item.solves}</td>
                                    <td>{item.accuracy}%</td>
                                    <td>{item.minutes}:{item.seconds} min</td>
                                </tr>)
                            )}
                            {this.state.review_practice.map((item, key) => (
                                <tr className="highlight" style={{ height: "10px" }} key={item.practice_id} >
                                    <td><Link to={`/result-summary?practiceId=${item.practice_id}`} className='p-0 border-0'><button type="button">View</button></Link></td>
                                    <td>{item.topic}<span>{item.subject} | {item.goal}</span></td>
                                    <td><Moment format="DD/MM/YYYY">{item.practice_date}</Moment><span><Moment format="LT">{item.practice_date.slice(0, -1)}</Moment></span></td>
                                    <td>{item.correct_score}/{item.score}</td>
                                    <td>{item.solved}</td>
                                    <td>{item.accuracy}%</td>
                                    <td>{item.minutes}:{item.seconds} min</td>
                                </tr>)
                            )}
                        </tbody>
                    </table>
                    <div className="hidethisdiv"><button id='confirm_review_practice' data-toggle="modal" data-target="#myModal9" onClick={this.endPractice}></button>
                    </div>
                </div>

                <div className="container share-mod share-mod2 share-mod3">
                    <div className="modal" id="myModal9">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id='confirm_review_close' className="close" data-dismiss="modal">
                                        <img src={require("./../../../assests/img/cross.jpg")} />
                                    </button>
                                    <div className="pop2-img"><img src={require("./../../../assests/img/pop-img.jpg")} /></div>
                                    <div className="row pt-3">
                                        <div className="col">
                                            <div className="divh3">
                                                <h3>{msg}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-5 score-points">
                                        <div className="col-md-6">
                                            <img src={require("./../../../assests/img/pop-icon1.jpg")} /> <span>Score <br />{score} points</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../../assests/img/pop-icon2.jpg")} /> <span>Solve <br /> {solve} questions</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../../assests/img/pop-icon3.jpg")} /> <span>Accuracy <br />You - {accuracy}% <br />Median - NA</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../../assests/img/pop-icon4.jpg")} /> <span>Speed (min:sec) <br />You - {minutes}:{seconds} <br />Median - NA</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <div className="common-btn" data-dismiss="modal"><button onClick={this.reviewPage}>Review</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default PracticeTab