import React, { Component } from 'react';
export default function EndPracticeDetail(props) {
    return (
        <>
            <div className="d-flex ">
                        <div className="p-2">{props.icon}</div>
                        <div className="p-2 fot-bold text-dark font-weight-bold">{props.heading}</div>
                        <div className="ml-auto p-2 text-dark">{props.data}</div>
            </div>
        </>
    );
}
