import React from 'react';
import Loader from './Loader'
import TabComponent from './TabComponent'


export default function AllTopicsMobile(props) {
  return (
    <>
      <div className="w-100 d-flex flex-column p-1 overflow-auto  h-80" >
        <div className="d-flex justify-content-center font-weight-bold text-dark mt-3 ">
          Select topics you want to practice
        </div>
        <div className="mt-3">
          {props.userGoalLang.goal != undefined && props.userGoalLang.language != undefined ?
            <TabComponent changeBeginPracticeStatus={props.changeBeginPracticeStatus} userGoalLang={props.userGoalLang} />
            : <div className="centerDiv"> <Loader color="primary" height="120px" width="120px" divHeight="h-100" /></div>}
        </div>
      </div>

    </>
  );
}
