import React from 'react';
import {sha512} from 'js-sha512';
import './../../assests/css/style.css';
import root_url from './api_url';
import PhotosList from './PhotosList';
import { isMobileOnly } from 'mobile-device-detect';


export default function AssignmentPack(props) {
    const { pack, packDescri, amount, mRP_INR, save_INR,userSelectedPack } = props;

    // const sendTransactionData = React.useCallback((amount) => {
    //         const url = root_url + 'user_payment/';
    //         const auth = "Token "+localStorage.getItem('key');
    //         fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json',
    //                 'Authorization': auth,
    //             },
    //             credentials: 'same-origin',
    //             body: JSON.stringify({
    //                 "user":"9923415489",
    //                 "billing_name":"somnath bachal",
    //                 "mobile_number":"9923415489",
    //                 "purchase_type":"3 months",
    //                 "payment_mode":"UPI",
    //                 "amount":"300",
    //                 "start_date":"2021-9-28 12:1:7",
    //                 "end_date":"2021-9-28 12:1:7",
    //                 "payment_status": "Inprogress"
    //             }),
    //             // body: JSON.stringify({
    //             //     user: localStorage.getItem('mobile'),
    //             //     billing_name:localStorage.getItem('first_name'),
    //             //     mobile_number:localStorage.getItem('mobile'),
    //             //     purchase_type:"sample_paper_1",
    //             //     payment_mode:"UPI",
    //             //     amount,
    //             //     payment_status: "Inprogress",
    //             //     start_date:"",
    //             //     end_date:"",
    //             // })
    //         }).then(response => {
    //             if (response.status === 400) {
    //                 return response.json();
    //             }
    //             else if (response.status === 200) {
    //                 return response.json();
    //             }
    //             else if (response.status === 201) {
    //                 return response.json();

    //             }
    //             else {
    //                 return false;
    //             }
    //         }).then(response => {
    //             if (response.status == 200) {
    //                 // this.setState({
    //                 //     errorMessage: response.message,
    //                 // });
    //                 console.log('errorMessage', response.message);
    //             }
    //             if (response.status == 201) {
    //                 // this.setState({
    //                 //     errorMessage: '',
    //                 // });
    //                 console.log('all good');
    //                 window.location.href = "https://pmny.in/4ICqPrCFuDYX"
    //             }
    //         });
    // }, []);

    const getPaymentForm = (amount, productInfo) => {
        const txn = `txn${Math.round(Math.random(0, 99999) * 1000000000)}`;
        const mobile = localStorage.getItem('mobile');
        const firstname = localStorage.getItem('first_name');
        const lastname = localStorage.getItem('last_name');
        const email = localStorage.getItem('email');

        return (
        <div>
            <form action='https://secure.payu.in/_payment' method='post'>
                <input type="hidden" name="key" value="TdBif3" />
                <input type="hidden" name="txnid" value={txn} />
                <input type="hidden" name="productinfo" value={productInfo} />
                <input type="hidden" name="amount" value={amount} />
                <input type="hidden" name="email" value={email} />
                <input type="hidden" name="firstname" value={firstname} />
                <input type="hidden" name="udf1" value={mobile} />
                <input type="hidden" name="lastname" value={lastname} />
                <input type="hidden" name="surl" value="https://admin.getprephub.com/nodemw/response/" />
                <input type="hidden" name="furl" value="https://admin.getprephub.com/nodemw/response/failure" />
                <input type="hidden" name="phone" value={mobile} />
                <input type="hidden" name="hash" value={sha512(`TdBif3|${txn}|${amount}|${productInfo}|${firstname}|${email}|${mobile}||||||||||9yt1AJB3Kwx6e4j3nuIfvLWaVPsU3mjb`)} />
                <input style={{
                    width: "100px", backgroundColor: "#717CFF", textAlign: "center",
                    fontWeight: 800, padding: "8px 0px", color: "white", fontSize: "12px",
                    display: "inline-block", textDecoration: "none", borderRadius: "25px", border: 0
                }} type="submit" value="Buy Now" />
            </form>
        </div>
        );
    }

    const getPaymentButton = () => {
        switch(amount) {
            case "49":
                return getPaymentForm(49, "sample_paper_1");
            case "199":
                return getPaymentForm(199, "sample_paper_5");
            case "299":
                return getPaymentForm(299, "sample_paper_10");
        }
    }

    const getBoughtContent = () => {
        switch(amount) {
            case "49":
                return (
                    <div className="text-dark flex photos-container">
                        <PhotosList list={[1,2,3]} />
                        <p className="bought-text">3,500 bought it</p>
                    </div>
                );
            case "199":
                return (
                    <div className="text-dark flex relative photos-container">
                        <PhotosList list={[4,5,6]} />
                        <p className="bought-text">5,700 bought it</p>
                    </div>
                );
            case "299":
                return (
                    <div className="text-dark flex relative photos-container">
                        <PhotosList list={[7,8,9]} />
                        <p className="bought-text">2,000 bought it</p>
                    </div>
                );
        }
    }
    return (
        <>
            <div className="shadow border border-light pt-4 pb-3 pl-2 pr-2 mb-3 bg-white rounded " >
                <div className="row">
                    <div className="col-7 d-flex justify-content-start mr-0 pr-0">
                        <div className={`font-weight-bold ${isMobileOnly?'assignmentPackHeadingMobile':'assignmentPackHeadingDesktop'}`}>{pack}</div>
                    </div>
                    <div className="col-5 d-flex justify-content-end ml-0 pl-0">
                        <div className="text-dark font-weight-bold" style={{ fontSize: '18px' }}>{`INR ${amount}/-`}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-7 d-flex justify-content-start w-100 mr-0 pr-0">
                        <div className="text-secondary"><p  className={`${isMobileOnly?'assignmentPackDescriMobile':'assignmentPackDescriDesktop'}`}>{packDescri}</p></div>
                    </div>
                    <div className="col-5 d-flex justify-content-end ml-0 pl-0">
                        <div className="text-dark ">
                            {mRP_INR ?
                                <small className={`${isMobileOnly?'assignmentPackPriceMobile':'assignmentPackPriceDesktop'}`}style={{wordBreak: "break-all" }}>
                                    <del>{`MRP INR ${mRP_INR}`}</del>
                                    <font style={{ color: "red" }}>{` Save INR ${save_INR}`}</font>
                                </small>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 d-flex justify-content-start">
                        {getBoughtContent()}
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="">
                            {getPaymentButton()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
