import React, { Component } from 'react';
import $ from 'jquery';
import Select from "react-select";
import root_url from './../components/api_url';
import Loader from '../components/Loader'

export class GetStartedLearning extends Component {
    constructor() {
        super();
        this.state = {
            access_code: '',
            country_list: [],
            country_select: '',
            goal_list: [],
            goal_select: '',
            language_list: [],
            language_select: '',
            csrftoken: '',
            errorMessage: '',
            isLoader: false,
            access_code_disabled: false,
            goalrrorMessage: '',
            langrrorMessage: '',
            countryerrorMessage: '',
            submiterrorMessage: '',
            accesCodeLoader: false,
            accessCodeLoaded: false

        }
        this.getGoal = this.getGoal.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
        this.getCountry = this.getCountry.bind(this);
        this.goalSelect = this.goalSelect.bind(this);
        this.getAccessCode = this.getAccessCode.bind(this);
        this.accessCode = this.accessCode.bind(this);
        this.countrySelect = this.countrySelect.bind(this);
        this.languageSelect = this.languageSelect.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.notAccessCode = this.notAccessCode.bind(this);
        this.loginHandler = this.loginHandler.bind(this);

        localStorage.setItem('key', 'cb54ee07a63fc0efa9070565357541158f705a8e')
        localStorage.setItem('key_local', 'f895ed4f212ae9c78da01795c54631e4de598638')

    }
    notAccessCode(e) {
        this.setState({ is_timed: e.target.checked, country_select: '', goal_select: '', language_select: '' });
        localStorage.setItem('not_access_code', e.target.checked)
        if (e.target.checked == true) {
            localStorage.setItem("goal", "");
            localStorage.setItem("language", "");
            localStorage.setItem("country", "");
            localStorage.setItem("access_code", "");
            this.setState({ isLoader: true, access_code_disabled: true, goal_select: null, country_select: null, language_select: null });
            let url = root_url + 'api/utils/organization/?name=Organic+User';
            var auth = "Token " + localStorage.getItem('key');
            var auth1 = "Token " + localStorage.getItem('key_local');
            fetch(url, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                credentials: 'same-origin',
            }).then(response => response.json()).then(response => {
                if (response.count > 0) {
                    if (response.results[0].id) {
                        const code_id = response.results[0].id;
                        let url = root_url + 'api/utils/organization_access_code/?organization__id=' + code_id;
                        fetch(url, {
                            method: "GET",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            credentials: 'same-origin',
                        }).then(response => response.json()).then(response => {
                            for (let i = 0; i < response.results.length; i++) {
                                if (code_id == response.results[i].id) {
                                    document.getElementById('access_code').value = response.results[i].access_code
                                    localStorage.setItem("access_code", response.results[0].access_code);
                                    this.getAccessCode(response.results[0].access_code);
                                }
                            }
                        });
                        this.setState({ isLoader: false });
                    }
                }
                else {
                    this.setState({ isLoader: false});
                    this.setState({ errorMessage: "Please create an Organization with the name Organic User" });
                }
            });
        }
        else {
            this.setState({ access_code_disabled: false,accessCodeLoaded:false  });
            document.getElementById('access_code').value = ''
        }
    }
    accessCode(e) {
        if (e.target.value !== '') {
            this.setState({ access_code: e.target.value, accesCodeLoader: true });
            this.getAccessCode(e.target.value);
            localStorage.setItem('access_code', e.target.value ? e.target.value : '');
        }
        if (e.target.value == '') {
            this.setState({ errorMessage: '' })
        }
    }

    getAccessCode(code) {
        let url = root_url + 'api/utils/organization_access_code/?access_code=' + code;
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            this.setState({ isLoader: false });
            if (response.count > 0) {
                this.setState({ accesCodeLoader: false, langrrorMessage: '', goalerrorMessage: '', accessCodeLoaded: true })
                let org_capacity = response.results[0].capacity;
                let goal = response.results[0].goal;
                let language = response.results[0].language;
                let country = response.results[0].country;
                if (org_capacity == 'unlimited' || org_capacity == 'Unlimited') {
                    this.setState({ errorMessage: '' });
                    if (goal !== null) {
                        let url = root_url + 'api/utils/goal/?id=' + goal;
                        this.getGoal(url);
                    }
                    else {
                        let url = root_url + 'api/utils/goal/';
                        this.getGoal(url);
                    }
                    if (language !== null) {
                        let url = root_url + 'api/utils/language/?id=' + language;
                        this.getLanguage(url);
                    }
                    else {
                        let url = root_url + 'api/utils/language/';
                        this.getLanguage(url);
                    }
                    if (country !== null) {
                        let url = root_url + 'api/utils/country/?id=' + country;
                        this.getCountry(url);
                    }
                    else {
                        let url = root_url + 'api/utils/country/';
                        this.getCountry(url);
                    }
                }
                else {
                    this.setState({ errorMessage: '' });
                    let url = root_url + 'api/utils/org_capacity/?access_code=' + code
                    fetch(url, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },
                        credentials: 'same-origin',
                    }).then(response => response.json()).then(response => {
                        if (response.results >= parseInt(org_capacity)) {
                            this.setState({ errorMessage: "Your Organization's capacity is full. Please contacts your Administration for more details." });
                        }
                        else {
                            if (goal !== null) {
                                let url = root_url + 'api/utils/goal/?id=' + goal;
                                this.getGoal(url);
                            }
                            else {
                                let url = root_url + 'api/utils/goal/';
                                this.getGoal(url);
                            }
                            if (language !== null) {
                                let url = root_url + 'api/utils/language/?id=' + language;
                                this.getLanguage(url);
                            }
                            else {
                                let url = root_url + 'api/utils/language/';
                                this.getLanguage(url);
                            }
                            if (country !== null) {
                                let url = root_url + 'api/utils/country/?id=' + country;
                                this.getCountry(url);
                            }
                            else {
                                let url = root_url + 'api/utils/country/';
                                this.getCountry(url);
                            }
                        }
                    });
                }
            }
            else {
                this.setState({ accesCodeLoader: true, goal_select: null, country_select: null, language_select: null, accessCodeLoaded: false });
                this.setState({ errorMessage: 'Yor are not recognized under any Organization. Please contact your Administration for more details.' });
                localStorage.setItem("goal", "");
                localStorage.setItem("language", "");
                localStorage.setItem("country", "");
                localStorage.setItem("access_code", "");
            }

        });
    }

    goalSelect = goal_select => {
        this.setState({ goal_select });
        localStorage.setItem('goal', goal_select.value);
    }

    countrySelect = country_select => {
        this.setState({ country_select });
        localStorage.setItem('country', country_select.value);
    }

    languageSelect = language_select => {
        this.setState({ language_select });
        localStorage.setItem('language', language_select.value);
    }
    getGoal(url) {
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    goal_list: response.results
                })
                if (response.count == 1) {
                    this.setState({
                        goal_select: { value: response.results[0].id, label: response.results[0].name }
                    })
                    localStorage.setItem('goal', this.state.goal_select.value);
                }
                this.setState({ isLoader: false });
            }
        });
    }
    getLanguage(url) {
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    language_list: response.results
                })
                if (response.count == 1) {
                    this.setState({
                        language_select: { value: response.results[0].id, label: response.results[0].name }
                    })
                    localStorage.setItem('language', this.state.language_select.value);
                }
                this.setState({ isLoader: false });
            }
        });
    }

    getCountry(url) {
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            if (response.count > 0) {
                this.setState({
                    country_list: response.results
                })
                if (response.count == 1) {
                    this.setState({
                        country_select: { value: response.results[0].id, label: response.results[0].name }
                    })
                    localStorage.setItem('country', this.state.country_select.value);
                }
                this.setState({ isLoader: false });
            }
        });
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.6-rc.0/js/select2.min.js";
        script.async = true;
        document.body.appendChild(script);
    }

    componentWillMount() {
        let name = "csrftoken";
        let cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = $.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        this.setState({ csrftoken: cookieValue })
        localStorage.setItem("goal", "");
        localStorage.setItem("language", "");
        localStorage.setItem("country", "");
        localStorage.setItem("access_code", "");
    }
    submitHandler(e) {
        e.preventDefault();
        this.setState({ errorMessage: '', countryerrorMessage: '', goalerrorMessage: '', langerrorMessage: '', submiterrorMessage: '', isLoader: true })
        let email = localStorage.getItem('email');
        let first_name = localStorage.getItem('first_name');
        let last_name = localStorage.getItem('last_name');
        let mobile = localStorage.getItem('mobile');
        let get_goal = localStorage.getItem('goal') ? localStorage.getItem('goal') : '';
        let get_language = localStorage.getItem('language') ? localStorage.getItem('language') : '';
        let get_country = localStorage.getItem('country') ? localStorage.getItem('country') : '';
        let get_access_code = localStorage.getItem('access_code') ? localStorage.getItem('access_code') : '';
        let error = this.state.errorMessage;
        let gol = [get_goal]
        if (get_access_code === '') {
            this.setState({ errorMessage: 'Access Code cannot be blank', isLoader: false })
        }
        else if (get_country === '') {
            this.setState({ countryerrorMessage: 'Country cannot be  blank', isLoader: false })
        }
        else if (get_goal === '') {
            this.setState({ goalerrorMessage: 'Goal cannot be blank', isLoader: false })
        }
        else if (get_language === '') {
            this.setState({ langerrorMessage: 'Language cannot be blank', isLoader: false })
        }

        else if (error !== '') {
            alert("Your Organization is not allowing you.Please contact your administrator for more details.")
        }
        else {
            let url = root_url + 'api/profile/update/?email=' + email;
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrftoken,
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                    username: mobile,
                    first_name: first_name,
                    last_name: last_name,
                    "profile": {
                        "mobile": mobile,
                        "country": get_country,
                        "goal": gol,
                        "language": get_language,
                        "access_code": get_access_code,
                    }
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 400) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.email) {
                    localStorage.setItem('user', response.email);
                    let user_username = localStorage.getItem('user_username');
                    let user_password = localStorage.getItem('user_pass');
                    if (user_username) {
                        this.loginHandler(user_username, user_password);
                    }
                    else {
                        document.location.href = document.location.origin;
                    }
                }
                if (response.goal) {
                    alert("Goal : " + response.goal);
                }
                if (response.country) {
                    alert("Country : " + response.country);
                }

            })
        }
    }

    // user login 
    loginHandler(user_username, user_password) {
        let username = user_username
        let password = user_password
        let url = root_url + 'api/accounts/login/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.email) {
                this.setState({ isLoader: false });
                this.setState({ submiterrorMessage: response.email[0] });
            }
            else if (response.key) {
                let key = response.key;
                localStorage.setItem('key', key);
                let url1 = root_url + 'api/profile/profile/';
                let auth = "Token " + localStorage.getItem('key')
                fetch(url1, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth
                    },

                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    let email = response.results[0].user.email;
                    let id = response.results[0].user.id;
                    localStorage.setItem('user_id', id);
                    localStorage.setItem('is_timed', 'true');
                    localStorage.setItem('email', email);
                })
                document.location.href = document.location.origin + '/dashboard';
            }
            else {
                this.setState({
                    submiterrorMessage: response.non_field_errors
                })
                this.setState({ isLoader: false });
            }
        })
    }


    

    render() {
        let goal_dropdown = []
        let country_dropdown = []
        let board_dropdown = []
        let language_dropdown = []
        const { goal_select } = this.state;
        const { board_select } = this.state;
        const { language_select } = this.state;
        const { country_select } = this.state;

        if (this.state.goal_list !== undefined) {
            for (let i = 0; i < this.state.goal_list.length; i++) {
                goal_dropdown.push(
                    { value: this.state.goal_list[i].id, label: this.state.goal_list[i].name }
                )
            }
        }
        else {
            goal_dropdown.push(
                { value: '', label: 'No Goal Found' }
            )
        }
        if (this.state.country_list !== undefined) {
            for (let i = 0; i < this.state.country_list.length; i++) {
                country_dropdown.push(
                    { value: this.state.country_list[i].id, label: this.state.country_list[i].name }
                )
            }
        }
        else {
            country_dropdown.push(
                { value: '', label: 'No Country Found' }
            )
        }
        if (this.state.board_list !== undefined) {
            for (let i = 0; i < this.state.board_list.length; i++) {
                board_dropdown.push(
                    { value: this.state.board_list[i].id, label: this.state.board_list[i].name }
                )
            }
        }
        else {
            board_dropdown.push(
                { value: '', label: 'No Board Found' }
            )
        }
        if (this.state.language_list !== undefined) {
            for (let i = 0; i < this.state.language_list.length; i++) {
                language_dropdown.push(
                    { value: this.state.language_list[i].id, label: this.state.language_list[i].name }
                )
            }
        }
        else {
            language_dropdown.push(
                { value: '', label: 'No Language Found' }
            )
        }
        return (
            <div>
                <section className="forgot-pass learning">
                    <div className="container pl-0 pr-0 w-100">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img2.png")} /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divh3 ">
                                            <h3>Few to help us personalise learning</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row loginhform">
                                    <div className="col">
                                        <div className="row form-group mt-2 pr-2" >
                                            <div class="col my-1">
                                                <label className="sr-only" for="inlineFormInputGroupUsername"></label>
                                                <div className="input-group">
                                                    <input type='text' placeholder="Enter Access Code" className="form-control" id="access_code" required="" onChange={this.accessCode} disabled={(this.state.access_code_disabled) ? "disabled" : ""} autocomplete="off" spellcheck="false"/>
                                                    <div className="input-group-prepend bg-white">
                                                        <div className="input-group-text bg-white border-top-0 border-left-0 border-right-0 ">{this.state.accessCodeLoaded?<i className={`fa fa-check-circle text-success`} aria-hidden="true"> </i>:<i className={`fa fa-times-circle text-secondary`} aria-hidden="true"> </i>}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.errorMessage && <span className="error ml-3"> {this.state.errorMessage} </span>}
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-12">
                                                <div className="cust_check">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck4" onChange={this.notAccessCode} name="example1" />
                                                        <label className="custom-control-label bg-white ml-1" htmlFor="customCheck4">I dont have an access code</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-center">
                                                {this.state.accesCodeLoader ? <Loader color="primary" height="30px" width="30px" /> : ''}

                                            </div>
                                        </div>
                                        <div className="row form-group ">
                                            <div className="col-md-12 pt-2 m-auto">
                                                <Select value={country_select} placeholder='Select Country..' onChange={this.countrySelect} options={country_dropdown} />
                                            </div>
                                        </div>
                                        <div className="row mt-n3 ">
                                            <div className="col-md-12 height-10">
                                                {this.state.countryerrorMessage && <span className="error ml-1"> {this.state.countryerrorMessage} </span>}
                                            </div>
                                        </div>
                                        <div className="row form-group mt-2 ">
                                            <div className="col-md-12 pt-3 m-auto">
                                                {/* <!-- <label></label> --> */}
                                                <Select value={goal_select} placeholder='Select Goal..' onChange={this.goalSelect} options={goal_dropdown} />
                                            </div>
                                        </div>
                                        <div className="row mt-n3">
                                            <div className="col-md-12 height-10">
                                                {this.state.goalerrorMessage && <span className="error ml-1"> {this.state.goalerrorMessage} </span>}
                                            </div>
                                        </div>
                                        <div className="row form-group mt-2" >
                                            <div className="col-md-12 pt-3 m-auto">
                                                <Select value={language_select} placeholder='Select Language..' onChange={this.languageSelect} options={language_dropdown} />
                                            </div>
                                        </div>
                                        <div className="row mt-n3">
                                            <div className="col-md-12 height-10">
                                                {this.state.langerrorMessage && <span className="error ml-1"> {this.state.langerrorMessage} </span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row sign-in pt-1 mt-2">
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader color="primary" height="50px" width="50px" /></div>}

                                </div>
                                <div className="row">
                                    <div className="col-md-12 height-10 ">
                                        {this.state.submiterrorMessage && <span className="error ml-2"> {this.state.submiterrorMessage} </span>}
                                    </div>
                                </div>
                                <div className="row sign-in pt-2">
                                    <div className="col-md-12">
                                        <div className="common-btn pt-2"><button onClick={this.submitHandler}>Go!</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default GetStartedLearning