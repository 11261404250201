import React, { Component } from 'react';
import Topbar from './TopBottomBar/Topbar'
import Bottombar from './TopBottomBar/Bottombar'
import ReviewQuestionDetail from './ReviewQuestionDetail'


export default function ReviewQuestionMobile(props) {
    return (
        <>
            <div className="d-flex flex-column w-100 " style={{ height: '100vh'}}>
                <Topbar backButton={false} topbarHeading="Review"/>
                <ReviewQuestionDetail response_msg={props.response_msg} topic={props.topic} questionId={props.questionId} questionScore={props.questionScore} questionContent={props.questionContent} option_dropdown={props.option_dropdown_mob} questionsolution={props.questionsolution}  previousQuestion={props.previousQuestion} nextQuestion={props.nextQuestion} endReview={props.endReview} level={props.level} recommTime={props.recommTime} questionTime={props.questionTime} right_side_panel={props.right_side_panel}  visible={props.visible}/>
                <Bottombar value="perform" />
            </div>
        </>
    );
}
