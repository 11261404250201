import React, { Component } from 'react';

export default function SamplePaperReviewSidePanelDetail(props) {
    const {heading,countBackground,total_count}=props;
    return (
        <>
            <div className={`d-flex pt-1 pb-0 pr-0 pl-1 ${heading=='Answered & Bookmarked'?'':''} `}>
                <div className={`p-1 rounded-circle   text-dark reviewCircleStyle ${countBackground}`}> {total_count<10?'0'+String(total_count):total_count}</div>
                <div className="p-1 rounded-pill  ml-n4 text-dark bg-gray"><small className="pr-2 ml-4">{heading}</small></div>
            </div>
                            
            </>
    );
}
