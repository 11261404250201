import React, { Component } from 'react';
export default function AccuracyPieChartContainMob(props) {
return (
        <>
            <li className="mt-n4">
                <div className="d-flex  ">
                    <div className="col-11">
                        <div className="d-flex flex-column">
                            <div><p className={`mobAccGrapfCorrectwithinTime ${props.heading=="Incorrect"?'mt-0':'mt-n1'}`}>{props.heading}</p></div>
                            <div className="mt-n4"><p className="mobAccGrapfCorrectwithinTime mt-n2">{props.subHeading}</p></div>
                        </div>
                    </div>
                    <div className={`ck  ml-n1 ${props.heading=="Incorrect"?'mt-0':'mt-1'}`}>{Math.round(props.calculatedValue)}%</div>
                </div>
            </li>

        </>
    )
}
