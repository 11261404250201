import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import InformationTooltip from './InformationTooltip';
import Moment from 'react-moment';


export default function NeedAndPracticeTable(props) {
    useEffect(() => {

    }, [])
    const stringTruncate = (given_topic) => {
        let given_string = given_topic;
        let updated_string = ""
        if (given_string.length > 9) {
            updated_string = given_string.slice(0, 9) + '...';
        }
        else {
            updated_string = given_string
        }
        return updated_string;
    }

    const fmtMinSec = (s) => { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }
    return (
        <><li>
            <div className="mob-five-graph dib col-6 " >
                <h5 >{Math.floor(props.accuracy)}</h5>
                <PieChart
                    data={[
                        { title: 'Correct(within time)', value: parseInt(props.correct_within_time), color: '#bcd43e' },
                        { title: 'Correct(exceeded time)', value: parseInt(props.correct_exceeded_time), color: '#f7d478' },
                        { title: 'incorrect time', value: parseInt(props.incorrect), color: '#fc7fab' },
                    ]} lineWidth={15} />
            </div>&nbsp;&nbsp;
            <div className=" mob-five-msg dib ml-n3 mt-2">
                <div className="mob-five-subj dib col-12 p-0" >
                    <div className="multi-sub-mob d-flex justify-content-between ">
                        <div className="multi-icon ">
                            <h4 className="">
                                {stringTruncate(props.topic)}<InformationTooltip subject={props.topic} /> </h4>
                        </div>
                        <div className="mr-n3 d-flex mt-2">
                            <div className="score-font-heading mt-n1">Total Solves:</div>
                            <div className="score-font mt-n1">{props.solved}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-n2 dib d-flex justify-content-between">
                    <div className="mr-n3 d-flex mt-1">
                        <div className="score-font-heading mt-n1">Last Test:</div>
                        <div className="score-font mt-n1"><Moment format="ddd Do MMM">{props.last_seen}</Moment></div>
                    </div>
                    <div className={`d-flex mt-1 justify-content-start ${props.average_speed == 0|| props.average_speed ==undefined?'mr-n2':'mr-n3'}`}>
                        <div className="score-font-heading mt-n1">Avg Speed:</div>
                        <div className={`score-font mt-n1 ${props.average_speed == 0|| props.average_speed ==undefined?'pl-2':''}`}>{props.average_speed == 0|| props.average_speed ==undefined? '-' : fmtMinSec(Math.floor(props.average_speed))}</div>
                    </div>
                </div>
            </div>
        </li>
    </>
    )
}
