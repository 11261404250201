import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = (props) => {
  
  const options = props.props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  
  
  
  useEffect(() => {
    

    setSelectedOptions([{ label: "Select All", value: "*" }, ...options]);
  }, []);
  
  
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else{
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }

  }
  
  function onChange(value, event) {
    
    localStorage.setItem("topic_name_array",event.option.label)
    if (event.action === "select-option" && event.option.value === "*") {
      
      this.setState(this.options);
      

      
      
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
      localStorage.removeItem('topic_selected');
      localStorage.removeItem("topic_name");
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
      console.log("cheked22=",this.options);

    } else {
      this.setState(value);
}
   

  }

  useEffect(()=>{
    
  
      if(selectedOptions.length>0) {
        let topic_arr = []
        let all_selected = false;
        let topic_name_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
            if(selectedOptions[i].value !== '*'){
             topic_arr.push(selectedOptions[i].value);
             topic_name_arr.push(selectedOptions[i].label);
            }
            if(selectedOptions[i].label === 'Select All'){
              all_selected = true;
            }
        }
        localStorage.setItem('topic_selected', topic_arr);
        if(all_selected){
          localStorage.setItem("topic_name", 'All');
          localStorage.setItem("topic_name_given", 'All');

        }
        else{
          localStorage.setItem("topic_name", topic_name_arr);
          localStorage.setItem("topic_name_given", topic_name_arr);
        }
      }

  }, [selectedOptions])
  

  return (
    <ReactMultiSelectCheckboxes
      options={[{ label: "Select All", value: "*" }, ...options]}
      placeholderButtonLabel="Topics"
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
     
      setState={setSelectedOptions}
    />
  );
};

export default MultiSelectAll;
