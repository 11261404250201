import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import root_url from './../components/api_url';
import Loader from "react-loader-spinner";

export class learningUpdated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrftoken: '',
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            password: '',
            confirm_password: '',
            firstNameMessage: '',
            lastNameMessage: '',
            emailMessage: '',
            numberMessage: '',
            passwordMessage: '',
            confirmPasswordMessage: '',
            verificationMessage: '',
            isLoader: false,
            access_code_given: '',
            errorMessage: '',
            limit_errorMessage: ''
        }
        this.firstName = this.firstName.bind(this);
        this.lastName = this.lastName.bind(this);
        this.Email = this.Email.bind(this);
        this.Mobile = this.Mobile.bind(this);
        this.Password = this.Password.bind(this);
        this.Confirm_Password = this.Confirm_Password.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.dontAccessCode = this.dontAccessCode.bind(this);
        this.haveAccessCode = this.haveAccessCode.bind(this);
        this.saveAccessCode = this.saveAccessCode.bind(this);
        this.getAccessCode = this.getAccessCode.bind(this);
        this.notAccessCode = this.notAccessCode.bind(this);
        this.sendAccessCodeInDB = this.sendAccessCodeInDB.bind(this);








    }
    firstName(e) {
        this.setState({
            first_name: e.target.value
        });
    }
    lastName(e) {
        this.setState({
            last_name: e.target.value
        });
    }
    Email(e) {
        this.setState({
            email: e.target.value
        });
    }
    Mobile(e) {
        this.setState({
            mobile: e.target.value
        });
    }
    Password(e) {
        this.setState({
            password: e.target.value
        })
    }
    Confirm_Password(e) {
        this.setState({
            confirm_password: e.target.value
        })
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const phone = query.get('phone');
        const goal = query.get('goal');
        if (email) {
            document.getElementById('id_email').value = email;
            this.setState({
                email: email,
            })
            localStorage.setItem("email", email);

        }

        if (phone) {
            document.getElementById('id_phone').value = phone;
            this.setState({
                mobile: phone,
            })
            localStorage.setItem("mobile", phone);
        }
        if (goal) {
            localStorage.setItem('goal', goal);

        }

    }
    dontAccessCode(e) {
        e.preventDefault();

        this.notAccessCode();
        // document.location.href = document.location.origin + '/learning_organic/';


    }
    haveAccessCode(e) {
        e.preventDefault();
        if (this.state.access_code_given != "" && localStorage.getItem('access_code') !== "ORG101") {
            this.getAccessCode(localStorage.getItem('access_code'));
            //   this.sendAccessCodeInDB(this.state.access_code_given);
            //document.location.href = document.location.origin + '/learning_access/';
        }
        else {
            this.setState({ errorMessage: "Please enter access code" });

        }


    }
    sendAccessCodeInDB(code_access) {
      //  alert(code_access);
        let email = localStorage.getItem('email');
        let url = root_url + 'api/profile/update/?email=' + email;
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken,
            },
            credentials: 'same-origin',
            body: JSON.stringify({
                username: email,

                "profile": {

                    "access_code": code_access,
                }
            })
        }).then(response => {
            if (response.status === 200) {
                
                return response.json();
            }
            else if (response.status === 400) {
                return response.json();
            }
            else {
                return false;
            }
        })
    }
    saveAccessCode(e) {
        localStorage.setItem('access_code', e.target.value ? e.target.value : '');
        this.setState({ access_code_given: e.target.value });



    }

    getAccessCode(code) {
        //    console.log('calling access code');
        this.setState({ isLoader: true });
        let url = root_url + 'api/utils/organization_access_code/?access_code=' + code;
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            this.setState({ isLoader: false });
            if (response.count > 0) {
                let org_capacity = response.results[0].capacity;
                let goal = response.results[0].goal;
                let language = response.results[0].language;
                let country = response.results[0].country;
                if (org_capacity == 'unlimited' || org_capacity == 'Unlimited') {
                    this.setState({ errorMessage: '' });
                    if (goal !== null) {
                        let url = root_url + 'api/utils/goal/?id=' + goal;
                        this.getGoal(url);
                    }
                    else {
                        let url = root_url + 'api/utils/goal/';
                        this.getGoal(url);
                    }
                    if (language !== null) {
                        let url = root_url + 'api/utils/language/?id=' + language;
                        this.getLanguage(url);
                    }
                    else {
                        let url = root_url + 'api/utils/language/';
                        this.getLanguage(url);
                    }
                    if (country !== null) {
                        let url = root_url + 'api/utils/country/?id=' + country;
                        this.getCountry(url);
                    }
                    else {
                        let url = root_url + 'api/utils/country/';
                        this.getCountry(url);
                    }
                }
                else {
                    this.setState({ errorMessage: '' });
                    let url = root_url + 'api/utils/org_capacity/?access_code=' + code
                    fetch(url, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },
                        credentials: 'same-origin',
                    }).then(response => response.json()).then(response => {

                        if (response.results >= parseInt(org_capacity)) {
                            this.setState({ limit_errorMessage: "The access code limit is already full, please contact your administrator." });
                            this.setState({ errorMessage: "" });

                        }
                        else {
                          this.sendAccessCodeInDB(this.state.access_code_given);

                            document.location.href = document.location.origin + '/learning_access/';
                        }
                    });
                }
            }
            else {
                this.setState({ isLoader: false });
                // this.setState({errorMessage: 'Yor are not recognized under any Organization. Please contacts your Administration for more details.'});
                this.setState({ limit_errorMessage: 'The access code does not exist' });
                this.setState({ errorMessage: "" });


            }

        });
    }

    notAccessCode() {
        this.setState({ isLoader: true });
        let url = root_url + 'api/utils/organization/?name=Organic+User';
        var auth = "Token " + localStorage.getItem('key');
        var auth1 = "Token " + localStorage.getItem('key_local');
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            const code_id=response.results[0].id;
            if (response.count > 0) {
                if (response.results[0].id) {
                    let url = root_url + 'api/utils/organization_access_code/?organization__id=' + code_id;
                    fetch(url, {
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth,
                        },
                        credentials: 'same-origin',
                    }).then(response => response.json()).then(response => {
                        for(let i=0;i<response.results.length;i++){
                            if(code_id==response.results[i].id){
                            if (response.results[i].capacity == "Unlimited") {
                                // document.getElementById('access_code').value = response.results[0].access_code
                                localStorage.setItem("access_code", response.results[i].access_code);
                                this.sendAccessCodeInDB(response.results[i].access_code);
                                document.location.href = document.location.origin + '/learning_organic/';
                            }
                    }
                    }
                    });
                    this.setState({ isLoader: false });
                }
            }
            else {
                this.setState({ isLoader: false });
                this.setState({ limit_errorMessage: "" });


                this.setState({ errorMessage: "Please create an Organization with the name Organic User" });
            }
        });

    }

    submitHandler(e) {
        e.preventDefault();
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let email = this.state.email;
        let mobile = this.state.mobile;
        let password = this.state.password;
        let confirm_password = this.state.confirm_password;
        localStorage.setItem('email', email);
        localStorage.setItem('first_name', first_name);
        localStorage.setItem('last_name', last_name);
        localStorage.setItem('mobile', mobile);

        if (first_name === '') {
            this.setState({
                firstNameMessage: 'First Name can not be blank'
            })
        }
        else if (last_name === '') {
            this.setState({
                lastNameMessage: 'Last Name can not be blank'
            })
        }
        else if (email === '') {
            this.setState({
                emailMessage: 'Email can not be blank'
            })
        }
        else if (mobile === '') {
            this.setState({
                numberMessage: 'Mobile can not be blank'
            })
        }
        else if (password === '') {
            this.setState({
                passwordMessage: 'Password can not be blank'
            })
        }
        else if (confirm_password === '') {
            this.setState({
                confirmPasswordMessage: 'Confirm Password can not be blank'
            })
        }
        else {
            this.setState({ isLoader: true });
            let url = root_url + 'api/registrations/';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrftoken,
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                    first_name: first_name,
                    last_name: last_name,
                    mobile: mobile,
                    username: email,
                    email: email,
                    password1: password,
                    password2: confirm_password,
                })
            }).then(response => {
                if (response.status === 201) {
                    this.setState({ isLoader: false })
                    document.location.href = document.location.origin + '/learning/';
                    return response.json();
                }
                else if (response.status === 400) {
                    this.setState({ isLoader: false })
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.key) {
                    let key = response.key;
                    localStorage.setItem('userLoggedIn', 'true');
                    localStorage.setItem('is_timed', 'true');
                    localStorage.setItem('user', email);
                    localStorage.setItem('key', key);
                    // alert("Successfully Registered");
                    document.location.href = document.location.origin + '/learning/';
                }
                else if (response.email) {
                    this.setState({
                        emailMessage: response.email[0]
                    })
                }
                else if (response.password1) {
                    this.setState({
                        passwordMessage: response.password1[0]
                    })
                }
                else if (response.password2) {
                    this.setState({
                        confirmPasswordMessage: response.password2[0]
                    })
                }
                else if (response.non_field_errors) {
                    this.setState({
                        confirmPasswordMessage: response.non_field_errors
                    })

                }
                else {
                    this.setState({
                        verificationMessage: response.detail

                    })
                    document.location.href = document.location.origin + '/learning/';
                }
            })
        }
    }
    componentWillMount() {
        let name = "csrftoken";
        let cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = $.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        console.log("cokie", cookieValue)
        this.setState({
            csrftoken: cookieValue
        })
    }
    render() {
        return (
            // Html Template
            <div>
                <section className="forgot-pass loginhelp">
                    <div className="container">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row">
                                    <div className="col">
                                        <div className="close"><img src={require("./../../assests/img/cross.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col">
                                        <div className="divh3"><h3>New User</h3></div>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-center">
                                    {this.state.limit_errorMessage && <span className="error"> {this.state.limit_errorMessage} </span>}
                                    {this.state.errorMessage && <span className="error"> {this.state.errorMessage} </span>}

                                </div>
                                <div className="row loginhform pt-5" >
                                    <div className="col">
                                        <div className="row form-group ">
                                            <div className="col-12 col-md-8 pt-3 m-auto">

                                                <input type="text" placeholder="Enter Access Code" onChange={this.saveAccessCode} className="form-control email" required />

                                            </div>
                                            <div className=" col-md-4 pt-3 m-auto ">
                                                <div className=""><button onClick={this.haveAccessCode} style={{ height: "38px", backgroundColor: "#5d39db", color: "white" }} className="btn btn-block" id="go-id">Go</button></div>

                                            </div>
                                            {this.state.firstNameMessage && <span className="error"> {this.state.firstNameMessage} </span>}

                                        </div>
                                        <div className="row justify-content-center ">
                                            <h5>OR</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="row sign-in pt-3 justify-content-center">
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                    <div className="col-md-12">
                                        <div className=" "><button onClick={this.dontAccessCode} className="btn  btn-block" style={{ backgroundColor: "#5d39db", color: "white" }} id="donthaveaccess-id">I dont have an access code !</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>


        )

    }
}

export default learningUpdated;
