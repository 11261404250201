import React from 'react';
import BackDrop from './BackDrop';
import TopBarTestPage from './TopBottomBar/TopBarTestPage';
import ReviewRightSidePanel from './ReviewRightSidePanel';
import Loader from './Loader';
import Editor from "react-editor-md";
export default function SamplePaperQuestionTest(props) {
    return (
        <>
            <div className="pt-2">
                <div className="dash-mid pr-3" >
                    {localStorage.getItem('backdrop_open') == "true" ? <BackDrop openStatus={true} component="samplePaper_question" changePauseTimerState={props.changePauseTimerState} /> : ''}
                    <div className='mt-n5'>
                        <TopBarTestPage id={props.id} score={props.score} pausePractice={props.pausePractice} seconds={props.seconds} paused={props.paused} confirmEndPractice={props.confirmEndPractice} pauseTimer={props.pauseTimer} saveBookmark={props.saveBookmark} />
                    </div>
                    <ReviewRightSidePanel right_side_panel={props.right_side_panel} />
                    <div className="pr-3 pt-1 pl-1">{props.questionData.QuestionTopic}</div>
                    <div className="pr-3 pt-1 pb-3 pl-1">
                        {(props.isVisible) ? (
                            <div key={props.questionData.QuestionId}>
                                <Editor.EditorShow config={
                                    {
                                        markdown: props.questionData.QuestionContent,
                                        id: "id_content" + props.questionData.QuestionId,
                                        height: 300,
                                        name: "id_content-markdown-doc",
                                        saveHTMLToTextarea: true,
                                        toolbar: false,
                                        flowChart: false,
                                        sequenceDiagram: false,
                                    }

                                } />  </div>
                        ) : ''}
                    </div>
                    {props.isLoader && <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Loader color="primary" height="80px" width="80px" /></div>}
                    <div className="row pl-1">
                        <div className={`col qustion-mob mt-3`} >
                            <div className="raio-tit">Select all that apply:</div>
                            <div className="radio-btn mt-n2 mt-md-0 pl-4">
                                <ul className="ml-n5 pl-4">
                                    {props.option_dropdown}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row dash-2-btn pt-5 ">
                        <div className="d-flex justify-content-center col-12">
                            <div className="common-btn" onClick={props.getSkipQuestion}><button >Skip Question</button></div>
                            <div className="common-btn ml-2" onClick={props.getNextQuestion}><button >Submit & Next</button></div>
                        </div>
                        <div className="push-end-btn pr-1 mt-1 mt-lg-0 mr-4 mr-lg-0">
                            <ul>
                                <li>
                                    <div><button data-toggle="modal" data-target="#myModal2" type="button" className="btnStyle" onClick={() => { props.pausePractice(); }}>Pause </button>
                                    </div>
                                </li>
                                <li>
                                    <div ><button id='end_practice' data-toggle="modal" className="btnStyle" type="button" onClick={() => { props.confirmEndPractice(); }}>End Test</button>
                                    </div>
                                    <div className="hidethisdiv"><button id='confirm_end_practice' data-toggle="modal" data-target="#myModal15"></button>
                                    </div>
                                </li>
                            </ul>
                            <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModal6" id='selectQuestion'></button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

