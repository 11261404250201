import React from 'react';
import { useEffect, useState } from 'react';
import Clock_logo from "./../../assests/img/pop-icon4.jpg";
import Backdrop from './BackDrop'
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default function TestStartInfromation(props) {
  const [allTopics, setAllTopics] = useState([]);
  const [questionType, setQuestionType] = useState(localStorage.getItem('userSelectedQuestionsType'));

  useEffect(() => {
    let arr = []
    let topics_list = JSON.parse(localStorage.getItem('topic_name'));
    let topics_length = topics_list.length;
    if (topics_length > 2) {
      if (topics_list.includes('all')) {
        setAllTopics(' All Topics ');
        localStorage.setItem("topic_name_given", 'All');
      }
      else {
        let remaning_topic = topics_length - 2;
        let topics = topics_list[0] + ', ' + topics_list[1] + ' and ' + String(remaning_topic) + ' other';
        setAllTopics(topics);
        localStorage.setItem("topic_name_given", JSON.stringify(topics_list.join()));
      }
    }
    else {
      setAllTopics(topics_list.join());
      localStorage.setItem("topic_name_given", topics_list[0]);
    }
  }, [])



  return (
    <>
      <div className="d-flex flex-column p-4 overflow-auto h-80">
        <div className="font-weight-bold text-dark">
          Practice Test
        </div>
        <div className="mt-3 word-wrap">
          <font className="font-weight-bold text-dark">Topics:</font>&nbsp;&nbsp;<font className="text-dark">{allTopics}</font>
        </div>
        <div className="mt-3">
          <div className="row">
            <div className="col-6">
              <div><img src={Clock_logo} className="clockImg" /><font className="font-weight-bold text-dark">40 minutes</font></div>
            </div>
            <div className="col-6">
              <div className="mt-2"><FactCheckIcon sx={{ fontSize: 35 }} className='questionTypetImg' /> <font className="font-weight-bold text-dark"> {questionType!=undefined?questionType:null} </font></div>
            </div>
          </div>
        </div>
        <div className="border-top mt-3 pb-5">
          <div className="font-weight-bold text-dark mt-3"> Instructions:</div>
          <div className="pt-3 text-dark ">
            <p>1.You have selected timed mode  and you will get 40 minutes to solve as many questions as you can in that time. </p>
            <p>2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.</p>
            <p>3.You cannot move back and forth between questions, but you can review them all after you end your practice.</p>
            <p>4.To submit an answer to a question - first, select the right option and then click Submit and Next. Once submitted answers to the questions cannot be changed.</p>
            <p>5.If you don't know the answer to a question you can skip it by clicking on Skip Question. Once skipped, you cannot come back to it, but you see the solution to it later after you end the practice.</p>
            <p>Pro-tip: Always focus on accuracy before speed.</p>
          </div>
          <Backdrop openStatus={props.modalOpen} beginPractice={props.beginPractice} component="start_test" />
        </div>
      </div>
    </>
  );
}
