import React from 'react';
import {useLocation} from "react-router-dom";
import SamplePaperMobile from './SamplePaperMobile';
import { isMobileOnly } from 'mobile-device-detect';
import SamplePaperDesktop from './SamplePaperDesktop'

export default function SamplePaper() {
    const  query = useLocation().search;
    const given_key = new URLSearchParams(query).get('User-Token');
    const given_contact = new URLSearchParams(query).get('User-Mobile');
    const given_goal = new URLSearchParams(query).get('User-Goal');
    if(given_key){
        localStorage.setItem('key',given_key);
        localStorage.setItem('mobile',given_contact);
        localStorage.setItem('userGoal',given_goal);
    }
    return (
        <>
        {isMobileOnly?<SamplePaperMobile />:< SamplePaperDesktop />}
        </>
    )
}

