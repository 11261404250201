import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import PerformanceTab from './PerformanceTab';
import { useEffect, useState } from 'react';
import TabsSlider from 'tabs-slider';
import Bottombar from '../TopBottomBar/Bottombar'
import NeedAndPracticeTab from './NeedAndPracticeTab';




export default function PerformanceToggleTab() {
  useEffect(() => {
    new TabsSlider('.tabs');
  }, [])
  return (
    <>
      <div className="perf-title">Performance Management</div>
      <div className="tabs border border-white" >
        <div className="tabs__bar-wrap">
          <div className="tabs__bar">
            <div className="tabs__controls">Performance Monitor</div>
            <div className="tabs__controls">Insights</div>
          </div>
        </div>
        <div className="tabs__content pt-0 " >
          <div className="tabs__section pl-2 mt-n2 pt-0 pb-5">
            <PerformanceTab />
          </div>
          <div className="tabs__section pl-2 ">
            <NeedAndPracticeTab />
          </div>
        </div>
      </div>
      <Bottombar value="perform" />

    </>
  )
}
