import React, { Component } from 'react';
import $ from 'jquery';
import root_url from './../components/api_url';
import Loader from '../components/Loader';
import Select from "react-select";
import { withRouter } from "react-router";

export class NewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrftoken: '',
            first_name: '',
            last_name: '',
            mobile: localStorage.getItem('user_mobile')?localStorage.getItem('user_mobile'):'',
            password: localStorage.getItem('user_password')?localStorage.getItem('user_password'):'',
            nameMessage: '',
            numberMessage: '',
            passwordMessage: '',
            verificationMessage: '',
            isLoader: false,
            name: '',
            showPaasword: false,
            goal_select: '',
            goal_list: [],
            goalerrorMessage:'',
        }
        this.Name = this.Name.bind(this);
        this.Mobile = this.Mobile.bind(this);
        this.Password = this.Password.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
        this.createUser = this.createUser.bind(this);
        this.showPaasword = this.showPaasword.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
    }


    Name(e) {
        this.setState({ name: e.target.value });
        let given_name = e.target.value;
        let given_name_split = given_name.split(" ");
        if (given_name_split.length > 2) {
            let first_name = given_name_split[0];
            given_name_split.shift();
            let last_name = given_name_split.join(' ')
            this.setState({ first_name: first_name, last_name: last_name });
        }
        else if (given_name_split.length === 2) {
            this.setState({ first_name: given_name_split[0], last_name: given_name_split[1] });
        }
        else if (given_name_split.length === 1) {
            this.setState({ first_name: given_name_split[0], last_name: "NULL" });
        }
        else {
            this.setState({ first_name: "", last_name: "" });
        }
    }
    Mobile(e) {
        this.setState({ mobile: e.target.value });
    }
    Password(e) {
        this.setState({ password: e.target.value })
    }
    showPaasword() {
        if (this.state.showPaasword) {
            this.setState({
                showPaasword: false
            });
        }
        else {
            this.setState({
                showPaasword: true
            });
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const phone = query.get('phone');
        const goal = query.get('goal');
        let mobile=localStorage.getItem('user_mobile')?localStorage.getItem('user_mobile'):'';
        let password=localStorage.getItem('user_password')?localStorage.getItem('user_password'):'';
        if (email) {
            localStorage.setItem("email", email);
        }
        if (phone) {
            //document.getElementById('id_mobile').value = phone;
            this.setState({ mobile: phone })
            localStorage.setItem("mobile", phone);
        }
        if (goal) {
            localStorage.setItem('goal', goal);
        }
        if(mobile!=''){
            document.getElementById('id_mobile').value = mobile;
        }
        if(password!=''){
            document.getElementById('id_password').value = password;
        }
        this.getGoal();
    }

    submitHandler(e) {
        let mobileexp=/^\d{10}$/;
        e.preventDefault();
        this.setState({
            nameMessage: '',
            numberMessage: '',
            passwordMessage: '',
            verificationMessage: '',
            goalerrorMessage:'',
        })
        let name = this.state.name;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let email = this.state.mobile + '@dummymail.com';
        let mobile = this.state.mobile;
        let password = this.state.password;
        let get_goal = localStorage.getItem('goal') ? localStorage.getItem('goal') : '';
       if (name === '') {
            this.setState({ nameMessage: 'Name cannot be blank' })
        }
        else if (get_goal === '') {
            this.setState({ goalerrorMessage: 'Goal cannot be blank'})
        }
        else if (mobile === '') {
            this.setState({ numberMessage: 'Mobile number cannot be blank'})
        }
        else if (!mobile.match(mobileexp)) {
            this.setState({ numberMessage: 'Enter valid mobile number'})
        }
        else if (password === '') {
            this.setState({ passwordMessage: 'Password cannot be blank'})
        }
        else if (password.length < 8) {
            this.setState({ passwordMessage: 'Minimum 8 characters required'})
        }
        else {
            this.setState({ isLoader: true });
            localStorage.setItem('email', email);
            localStorage.setItem('first_name', first_name);
            localStorage.setItem('last_name', last_name);
            localStorage.setItem('mobile', mobile);
            localStorage.setItem('name', name);
            localStorage.setItem('password', password);
            let user_data = {
                first_name: first_name,
                last_name: last_name,
                mobile: mobile,
                username: mobile,
                email: email,
                password1: password,
                password2: password
            }
            this.sendOTP(user_data);
        }
    }

    sendOTP(given_user_data) {
        let mobile = given_user_data.mobile
        let url = root_url + 'mobile_otp_send/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mobile: mobile,
                user_entry_type: 'Signup_User'
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 200) {
                this.setState({ numberMessage: response.message, isLoader: false });
            }
            if (response.status == 201) {
                localStorage.setItem('user_type', 'Signup_User');
                this.createUser(given_user_data);
            }
        })
    }


    createUser(given_user_data) {
        localStorage.setItem('user_email', given_user_data.email);
        localStorage.setItem('user_pass', given_user_data.password1);
        localStorage.setItem('user_username', given_user_data.mobile);
        let url = root_url + 'api/registrations/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken,
            },
            credentials: 'same-origin',
            body: JSON.stringify(given_user_data)
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else if (response.status === 400) {
                this.setState({ isLoader: false })
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.key) {
                this.setState({ isLoader: false })
                let key = response.key;
                localStorage.setItem('userLoggedIn', 'true');
                localStorage.setItem('is_timed', 'true');
                localStorage.setItem('user', given_user_data.mobile);
                localStorage.setItem('key', key);
                this.updateUserData(given_user_data);
                //document.location.href = document.location.origin + `/enterotp?mobile=${given_user_data.mobile}&user_type=Signup_User`;
            }
            else if (response.email) {
                this.setState({ numberMessage: 'A user is already registered with this mobile number' })
            }
            else if (response.password1) {
                this.setState({ passwordMessage: response.password1[0] })
            }
            else {
                this.setState({ verificationMessage: response.detail })
            }
        })
    }


    componentWillMount() {
        let name = "csrftoken";
        let cookieValue = '';
        localStorage.setItem("goal", "");
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = $.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        this.setState({ csrftoken: cookieValue })
    }

    goalSelect = goal_select => {
        this.setState({ goal_select });
        localStorage.setItem('goal', goal_select.value);
    }

    getGoal = () => {
        const url = root_url + 'api/utils/goal/';
        fetch(url,{
            method:"GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
        }).then(response => response.json()).then(response => {
            if (response.count>0){
                     this.setState({
                        goal_list:response.results
                    })
                    if(response.count==1){
                        this.setState({
                            goal_select:{value: response.results[0].id, label: response.results[0].name}
                        })
                      localStorage.setItem('goal', this.state.goal_select.value);
                    }
                    this.setState({isLoader: false});
                }
            });
        }


        updateUserData(given_user_data){
            let get_goal = localStorage.getItem('goal') ? localStorage.getItem('goal') : '';
            let gol = [get_goal]
            let email=given_user_data.email;
            let url = root_url + 'api/profile/update/?email=' + email;
            let access_code = "ORG101";
            localStorage.setItem("access_code", access_code);
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrftoken,
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                    username: given_user_data.mobile,
                    first_name: given_user_data.first_name,
                    last_name: given_user_data.last_name,
                    "profile": {
                        "mobile": given_user_data.mobile,
                        "country": 1,
                        "goal": gol,
                        "language": 1,
                        "access_code": access_code,
                    }
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 400) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.email) {
                    localStorage.setItem('user', response.email);
                    let user_username = localStorage.getItem('user_username');
                    let user_password = localStorage.getItem('user_pass');
                    if (user_username) {
                        document.location.href = document.location.origin + `/enterotp?mobile=${given_user_data.mobile}&user_type=Signup_User`;
                    }
                    else {
                        document.location.href = document.location.origin;
                    }
                }
            })
        }
    
    render() {

        const { goal_select } = this.state;
        let goal_dropdown = [];
        if(this.state.goal_list !== undefined){
            for(let i=0; i<this.state.goal_list.length;i++){
                goal_dropdown.push({value: this.state.goal_list[i].id, label: this.state.goal_list[i].name});
            }
        }
        else{
			goal_dropdown.push({value: '', label: 'No Goal Found'});
		}

        return (

            <div>
                <section className="forgot-pass loginhelp">
                    <div className="container  pl-0 pr-0 w-100">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divh3"><h3>New User</h3></div>
                                    </div>
                                </div>
                                <div className="row loginhform pt-5" >
                                    <div className="col">
                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <input type="text" placeholder="Enter your name" id="id_name" onChange={this.Name} className="form-control mob" required spellcheck="false" />
                                                <label>Name</label>
                                            </div>
                                            {this.state.nameMessage && <span className="error ml-3"> {this.state.nameMessage} </span>}
                                        </div>

                                        <div className="row form-group mt-2 ">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <Select value={goal_select} placeholder='Select Goal..' onChange={this.goalSelect} options={goal_dropdown} />
                                            </div>
                                            {this.state.goalerrorMessage && <span className="error ml-3"> {this.state.goalerrorMessage} </span>}

                                        </div>

                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <input type="text" placeholder="Enter 10 digit mobile number" id="id_mobile" onChange={this.Mobile} className="form-control mob" required />
                                                <label>Mobile Number</label>
                                            </div>
                                            {this.state.numberMessage && <span className="error ml-3"> {this.state.numberMessage} </span>}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <div className="input-group ">
                                                    <input type={`${this.state.showPaasword ? 'text' : 'password'}`} placeholder="Minimum 8 characters" className="form-control form-control-sm  border-top-0 border-left-0 border-right-0 text-lg" id="id_password1" onChange={this.Password} required style={{ borderColor: "#8b54b9", color: "#8b54b9", fontSize: "15px" }} id="id_password" />
                                                    <div className="input-group-prepend bg-white">
                                                        <div className="input-group-text bg-white border-top-0 border-left-0 border-right-0 " style={{ borderColor: "#8b54b9" }}><i className={`${this.state.showPaasword ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={this.showPaasword} aria-hidden="true"></i></div>
                                                    </div>
                                                </div>
                                                <label className="">Password</label>
                                            </div>
                                            {this.state.passwordMessage && <span className="error ml-3"> {this.state.passwordMessage} </span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row sign-in pt-3">
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader color="primary" height="50px" width="50px" /></div>}
                                    <div className="col-md-12">
                                        <div className="common-btn pt-3"><button onClick={this.submitHandler} id="next-id">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

export default withRouter(NewUser);
