import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useEffect, useState } from 'react';

export default function TopicNameComponent(props) {
    const [questionType,setQuestionType]=useState(localStorage.getItem('userSelectedQuestionsType'));

    return (
        <>
            <div className="w-100 border-bottom pt-1 pl-3 pr-3">
                <div className=" w-100 d-flex justify-content-between">
                    <div className="mt-2">
                        <h6 className="text-dark">{props.topic.label}</h6>
                    </div>
                    <div className={`${questionType!='All'?'mt-3':'mt-2'}`}>
                        <FormControlLabel
                            value={props.topic.value}
                            control={<Checkbox color="primary" />}
                            labelPlacement="start"
                            id={props.topic.value}
                            name={props.topic.label}
                            onChange={props.topicChange}
                            checked={props.selectedTopics.includes(props.topic.label)}
                        />
                    </div>
                </div>
                {props.totalQuestions != undefined ?
                    <div className="w-100 d-flex justify-content-start mt-n3">
                        <div className='text-secondary'>{`${props.totalQuestions} Questions`}</div>
                    </div> : null}
            </div>
        </>
    );
}
