import root_url from './../components/api_url';

export function CallingOptions(question_id){
    let auth = "Token " + localStorage.getItem('key');
    let option_url = root_url + 'api/utils/random_option/?question_id=' + question_id + '';
    if (question_id !== undefined) {
        fetch(option_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            if (response.results.length > 0) {
                let option_list=response.results;
                localStorage.setItem('options', JSON.stringify(response.results))
                localStorage.setItem('seconds',60);
                localStorage.setItem('countdown_pause','true');
                localStorage.setItem("countdown_seconds", "2400");

                for (let i = 0; i < option_list.length; i++) {
                    if (option_list[i][0].is_correct === true) {
                        localStorage.setItem('match', option_list[i][0].option)
                    }
                }
               document.location.href = document.location.origin + '/questions/'
            }
            else {
                alert('No options found');
            }
        });
    }
    else {
        alert('Did not find any Question to get their options');
    }
}

