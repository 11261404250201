import React, { Component } from 'react';
import Dash from './../reusecomponent/dash';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import root_url from './../components/api_url';
import { Helmet } from "react-helmet";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './../../../src/assests/css/progress.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
//import Loader from 'react-loader-spinner';
//let Latex = require('react-latex');
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import Latex from "react-latex-next";
import Editor from "react-editor-md";
import { isMobileOnly } from 'mobile-device-detect';
import BackDrop from './BackDrop';
import TopBarTestPage from './TopBottomBar/TopBarTestPage';
import Loader from './Loader'
import CountDownTimer from './CountDownTimer'

let status_list = [];
const bookmarkImagePath = {
    iconb: require("./../../assests/img/bookmark_iconb.png"),
    icon: require("./../../assests/img/bookmark_icon.png"),
}
let myInterval;

export class TimedQuestion extends Component {
    confirmEndPractice = () => {
        this.callingPausePractice();
        confirmAlert({
            title: 'End Practice',
            message: 'Are you sure you want to end practice ? ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.saveStrengthNeedPracticeData();
                        this.EndPracticeLastQuestionScenario();
                        let id = localStorage.getItem('practice_id');
                        let auth = 'Token ' + localStorage.getItem('key');
                        let practice_url = root_url + 'api/utils/practice/' + parseInt(id) + '/';
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
                        this.setState({ isLoader: true });
                        fetch(practice_url, {
                            method: 'PATCH',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify({
                                "end_datetime": start_time,

                            })
                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            }
                            else {
                                return false;
                            }
                        }).then(response => {
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': auth
                                }

                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    return false;
                                }
                            }).then(response => {
                                if (response && response.data[0]) {
                                    this.setState({
                                        end_practice: response.data[0],
                                        end_score: response.data[0].score,
                                        end_accuracy: response.data[0].accuracy,
                                        end_solve: response.data[0].solved,
                                        end_msg: response.data[0].message_option,
                                        end_minutes: response.data[0].minutes,
                                        end_seconds: response.data[0].seconds,
                                    });
                                    localStorage.setItem('percentage', response.data[0].accuracy)
                                    localStorage.setItem('total_score', response.data[0].score)
                                    localStorage.setItem('solved', response.data[0].solved)
                                    localStorage.setItem('total', response.data[0].total)
                                    localStorage.setItem('question_time_difference', response.data[0].speed)
                                    localStorage.setItem('end_minutes', response.data[0].minutes)
                                    localStorage.setItem('end_seconds', response.data[0].seconds)
                                    localStorage.setItem('msg', response.data[0].message_option)
                                }
                            });
                            if (isMobileOnly) {
                                document.location.href = document.location.origin + `/result-summary?practiceId=${id}`;
                            }
                            else {
                                document.getElementById('confirm_end_practice').click()
                                this.setState({ isLoader: false });
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        this.callingResumePractice();

                    }
                }
            ]
        });
    };

    alertPerformance = () => {
        confirmAlert({
            title: 'End Practice',
            message: 'Are you sure you want to end practice ? ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.saveStrengthNeedPracticeData();
                        this.EndPracticeLastQuestionScenario();
                        let id = localStorage.getItem('practice_id');
                        let auth = 'Token ' + localStorage.getItem('key');
                        let practice_url = root_url + 'api/utils/practice/' + parseInt(id) + '/';
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
                        this.setState({ isLoader: true });
                        fetch(practice_url, {
                            method: 'PATCH',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify({
                                "end_datetime": start_time,

                            })
                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            }
                            else {
                                return false;
                            }
                        }).then(response => {
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': auth
                                }

                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    return false;
                                }
                            }).then(response => {
                                // console.log('End Practice', response);
                                this.setState({
                                    end_practice: response.data[0],
                                    end_score: response.data[0].score,
                                    end_accuracy: response.data[0].accuracy,
                                    end_solve: response.data[0].solved,
                                    end_msg: response.data[0].message_option,
                                    end_minutes: response.data[0].minutes,
                                    end_seconds: response.data[0].seconds,
                                });
                                localStorage.setItem('percentage', response.data[0].accuracy)
                                localStorage.setItem('total_score', response.data[0].score)
                                localStorage.setItem('solved', response.data[0].solved)
                                localStorage.setItem('total', response.data[0].total)
                                localStorage.setItem('question_time_difference', response.data[0].speed)
                                localStorage.setItem('end_minutes', response.data[0].minutes)
                                localStorage.setItem('end_seconds', response.data[0].seconds)
                                localStorage.setItem('msg', response.data[0].message_option)
                            });
                            document.getElementById('confirm_end_practice').click()
                            this.setState({ isLoader: false });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => ''
                }
            ]
        });
    };

    alertDashboard = () => {
        confirmAlert({
            title: 'End Practice',
            message: 'Are you sure you want to end practice ? ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.saveStrengthNeedPracticeData();
                        this.EndPracticeLastQuestionScenario();
                        let id = localStorage.getItem('practice_id');
                        let auth = 'Token ' + localStorage.getItem('key');
                        let practice_url = root_url + 'api/utils/practice/' + parseInt(id) + '/';
                        let tempDate = new Date();
                        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
                        this.setState({ isLoader: true });
                        fetch(practice_url, {
                            method: 'PATCH',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': auth,
                            },
                            body: JSON.stringify({
                                "end_datetime": start_time,
                            })
                        }).then(response => {
                            if (response.status === 200) {
                                return response.json();
                            }
                            else {
                                return false;
                            }
                        }).then(response => {
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': auth
                                }

                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    return false;
                                }
                            }).then(response => {
                                this.setState({
                                    end_practice: response.data[0],
                                    end_score: response.data[0].score,
                                    end_accuracy: response.data[0].accuracy,
                                    end_solve: response.data[0].solved,
                                    end_msg: response.data[0].message_option,
                                    end_minutes: response.data[0].minutes,
                                    end_seconds: response.data[0].seconds,
                                });
                                localStorage.setItem('percentage', response.data[0].accuracy)
                                localStorage.setItem('total_score', response.data[0].score)
                                localStorage.setItem('solved', response.data[0].solved)
                                localStorage.setItem('total', response.data[0].total)
                                localStorage.setItem('question_time_difference', response.data[0].speed)
                                localStorage.setItem('end_minutes', response.data[0].minutes)
                                localStorage.setItem('end_seconds', response.data[0].seconds)
                                localStorage.setItem('msg', response.data[0].message_option)
                            });
                            document.getElementById('confirm_end_practice').click()
                            this.setState({ isLoader: false });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => ''
                }
            ]
        });
    };

    constructor() {
        super();

        this.state = {
            end_score: localStorage.getItem('total_score') ? localStorage.getItem('total_score') : 0,
            end_accuracy: localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0,
            end_solve: localStorage.getItem('solved') ? localStorage.getItem('solved') : 0,
            end_msg: localStorage.getItem('msg') ? localStorage.getItem('msg') : '',
            end_minutes: localStorage.getItem('end_minutes'),
            end_seconds: localStorage.getItem('end_seconds'),
            fill: false,
            option_list: [],
            topic: '',
            mode: '',
            question_id: '',
            flag: false,
            question_time_difference: localStorage.getItem('question_time_difference') ? localStorage.getItem('question_time_difference') : 0,
            total_score: localStorage.getItem('total_score') ? localStorage.getItem('total_score') : 0,
            end_practice_question_list: [],
            correct_color: "background-image: linear-gradient('#2f7a73', '#42a37a')",
            wrong_color: "background-image: linear-gradient('#882c6d', '#a93672')",
            // skip_color:"background-image: linear-gradient('#696969', '#808080')",
            percentage: localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0,
            correct: localStorage.getItem('correct') ? localStorage.getItem('correct') : 0,
            skip: localStorage.getItem('skip') ? localStorage.getItem('skip') : 0,
            level: localStorage.getItem('level') ? localStorage.getItem('level') : 2,
            solved: localStorage.getItem('solved') ? localStorage.getItem('solved') : 0,
            type: '',
            question_content: '',
            score: '',
            time: '',
            solution: '',
            option_selected: '',
            correct_option: '',
            max_two_loops: 0,
            count: localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 0,
            practice_id: localStorage.getItem('practice_id') ? localStorage.getItem('practice_id') : 538,
            topic_name: localStorage.getItem('topic_name') ? localStorage.getItem('topic_name') : '',
            topic_selected: localStorage.getItem('topic_selected') ? localStorage.getItem('topic_selected') : '',
            minutes: localStorage.getItem('minutes') ? localStorage.getItem('minutes') : 40,
            seconds: localStorage.getItem('seconds') ? localStorage.getItem('seconds') : 0,
            is_paused: localStorage.getItem('is_paused') ? localStorage.getItem('is_paused') : 'false',
            pause: false,
            timer_status: null,
            email: '',
            mobile: '',
            is_question_error: localStorage.getItem('question_error') ? localStorage.getItem('question_error') : false,
            is_option_error: localStorage.getItem('option_error') ? localStorage.getItem('option_error') : false,
            is_solution_error: localStorage.getItem('solution_error') ? localStorage.getItem('solution_error') : false,
            is_other_error: localStorage.getItem('other_error') ? localStorage.getItem('other_error') : false,
            feedback: localStorage.getItem('feedback') ? localStorage.getItem('feedback') : '',
            bookmark_count: 1,
            isVisible: localStorage.getItem('is_visible') ? localStorage.getItem('is_visible') : false,
            given_topic: localStorage.getItem('given_topic') ? localStorage.getItem('given_topic') : '',
            countdown_start: false,
            countdown_pause: localStorage.getItem('countdown_pause') == 'false' ? false : true,
            countdown_seconds: localStorage.getItem("countdown_seconds") ? localStorage.getItem("countdown_seconds") : 0

        }
        this.getSelectedOption = this.getSelectedOption.bind(this);
        this.getNextQuestion = this.getNextQuestion.bind(this);
        this.pausePractice = this.pausePractice.bind(this);
        this.skipQuestion = this.skipQuestion.bind(this);
        this.endPractice = this.endPractice.bind(this);
        this.CallingQuestion = this.CallingQuestion.bind(this);
        this.CallingOptions = this.CallingOptions.bind(this);
        this.gotoA = this.gotoA.bind(this);
        this.gotoB = this.gotoB.bind(this);
        this.CreatingPracticeQuestionDetail = this.CreatingPracticeQuestionDetail.bind(this);
        this.saveBookmark = this.saveBookmark.bind(this);
        this.resumePractice = this.resumePractice.bind(this);
        this.callingTimerInterval = this.callingTimerInterval.bind(this);
        this.callingPausePractice = this.callingPausePractice.bind(this);
        this.callingResumePractice = this.callingResumePractice.bind(this);
        this.timerBookmark = this.timerBookmark.bind(this);
        this.questionError = this.questionError.bind(this);
        this.optionError = this.optionError.bind(this);
        this.solutionError = this.solutionError.bind(this);
        this.otherError = this.otherError.bind(this);
        this.submitErrorLog = this.submitErrorLog.bind(this);
        this.ExtraError = this.ExtraError.bind(this);
        this.shareQuestion = this.shareQuestion.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeMobile = this.changeMobile.bind(this);
        this.pauseTimer = this.pauseTimer.bind(this);
        this.reviewPage = this.reviewPage.bind(this);
        this.EndPracticeLastQuestionScenario = this.EndPracticeLastQuestionScenario.bind(this);
        this.saveStrengthNeedPracticeData = this.saveStrengthNeedPracticeData.bind(this);
        this.changePauseTimerState = this.changePauseTimerState.bind(this);
        this.clearTimerInterval = this.clearTimerInterval.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.endTest = this.endTest.bind(this);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        // page refresh detect code
        if (window.performance) {
            if (performance.navigation.type == 1) {
                this.callingTimerInterval();
            }
        }

    }

    endTest() {
        this.saveStrengthNeedPracticeData();
        this.EndPracticeLastQuestionScenario();
        let id = localStorage.getItem('practice_id');
        let auth = 'Token ' + localStorage.getItem('key');
        let practice_url = root_url + 'api/utils/practice/' + parseInt(id) + '/';
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let url = root_url + 'api/utils/end_practice/?practice_id=' + id;
        this.setState({ isLoader: true });
        fetch(practice_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "end_datetime": start_time,

            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth
                }

            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response && response.data[0]) {
                    this.setState({
                        end_practice: response.data[0],
                        end_score: response.data[0].score,
                        end_accuracy: response.data[0].accuracy,
                        end_solve: response.data[0].solved,
                        end_msg: response.data[0].message_option,
                        end_minutes: response.data[0].minutes,
                        end_seconds: response.data[0].seconds,
                    });
                    localStorage.setItem('percentage', response.data[0].accuracy)
                    localStorage.setItem('total_score', response.data[0].score)
                    localStorage.setItem('solved', response.data[0].solved)
                    localStorage.setItem('total', response.data[0].total)
                    localStorage.setItem('question_time_difference', response.data[0].speed)
                    localStorage.setItem('end_minutes', response.data[0].minutes)
                    localStorage.setItem('end_seconds', response.data[0].seconds)
                    localStorage.setItem('msg', response.data[0].message_option)
                }
            });
            if (isMobileOnly) {
                document.location.href = document.location.origin + `/result-summary?practiceId=${id}`;
            }
            else {
                document.getElementById('confirm_end_practice').click()
                this.setState({ isLoader: false });
            }
        });

    }
    saveStrengthNeedPracticeData() {
        let topic = localStorage.getItem('topic_selected');
        let practice = localStorage.getItem('practice_id');
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/save_strength_data/' + '?topic_id=' + topic + '&practice_id=' + practice;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {

        })
    }

    reviewPage(e) {
        document.location.href = document.location.origin + '/review/';
    }

    /* countDown start */
    changePauseTimerState() {
        this.setState({ countdown_pause: false });
        this.callingTimerInterval();
        localStorage.setItem('countdown_pause', 'false');
    }
    //scroll top position
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    getImageName = () => this.state.fill ? 'iconb' : 'icon'

    EndPracticeLastQuestionScenario() {
        let selected_option = localStorage.getItem('selected_option');
        if (selected_option == undefined) {
            this.setState({ isLoader: true });
            this.setState(state => ({ fill: false }))
            let get_question_count = localStorage.getItem('question_count');
            let skip_count = localStorage.getItem('skip') ? localStorage.getItem('skip') : 0;
            let skip = parseInt(skip_count);
            skip = skip + 1;
            let skp = '' + skip;
            localStorage.setItem("skip", skp);
            let question_count = parseInt(get_question_count);
            let auth = "Token " + localStorage.getItem('key');
            let id = localStorage.getItem('practice_question_details_id')
            let tempDate1 = new Date();
            let end_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
            let url1 = root_url + 'api/utils/practice_question_details/' + id + '/';
            this.setState({ isLoader: true });
            fetch(url1, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "ques_end_timestamp": end_time,
                    "status": 'skip',
                })
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                let end_time = response.ques_end_timestamp;
                localStorage.setItem('ques_end_timestamp', end_time);
            })
            let url = root_url + 'api/utils/practice_action_details/';
            let practice_id = localStorage.getItem('practice_question_details_id');
            let tempDate = new Date();
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "action_name": 'skip_question',
                    "action_id": "skip_question" + practice_id,
                    "action_timestamp": start_time,
                    "practice_question_detail": practice_id

                })
            }).then(response => {
                if (response.status === 201) {
                    this.setState({ isLoader: false });
                    return response.json();
                }
                else {
                    return false;
                }
            })
        }
        else {
            this.setState({ isLoader: true });
            this.setState(state => ({ fill: false }))
            localStorage.removeItem('selected_option');
            let get_solved_count = localStorage.getItem('solved') ? localStorage.getItem('solved') : 0;
            let solved_count = parseInt(get_solved_count);
            solved_count = solved_count + 1;

            let solved = '' + solved_count;

            localStorage.setItem("solved", solved);

            if (selected_option === localStorage.getItem('match')) {
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                correct = correct + 1;
                let crct = '' + correct;
                localStorage.setItem("correct", crct);
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                this.setState({
                    percentage: perc,
                })
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let question_id = localStorage.getItem('question_id');
                let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": true,
                        "ques_end_timestamp": end_time,
                        "choice_selected": selected_option,
                        "status": 'correct',
                    })
                }).then(response => {
                    if (response.status === 200) {
                        this.setState({ isLoader: false });
                        return response.json();
                    }
                    else {
                        return false;
                    }
                })
            }

            else {
                // practice_question_details_id
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                this.setState({
                    percentage: perc,
                })
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let question_id = localStorage.getItem('question_id');
                let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": false,
                        "ques_end_timestamp": end_time,
                        "status": 'wrong',
                        "choice_selected": selected_option,
                    })
                }).then(response => {
                    if (response.status === 200) {
                        this.setState({ isLoader: false });
                        return response.json();
                    }
                    else {
                        return false;
                    }
                })
            }

        }

    }

    saveBookmark(e) {
        //e.preventDefault();
        this.callingPausePractice();
        let auth = "Token " + localStorage.getItem('key');
        this.setState(prev => ({ bookmark_count: prev.bookmark_count + 1 }));
        if (this.state.bookmark_count % 2 === 0) {
            // alert(this.state.bookmark_count);
            this.setState({ isLoader: true });
            this.setState(state => ({ fill: !state.fill }))
            let id = parseInt(localStorage.getItem('bookmark_id'));
            if (id > 0) {
                let bookmark_delete_url = root_url + 'api/utils/practice_action_details/' + id + '/';
                fetch(bookmark_delete_url, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 204) {
                        this.setState({ isLoader: false });
                    } else {
                        return false;
                    }
                })
                let ubm = document.getElementById('un-bookmarked-msg');
                let bm = document.getElementById('bookmarked-msg');
                ubm.style.display = "block";
                bm.style.display = "none";
            }
            else {
                alert("You haven't bookmarked this question yet");
            }
        }
        else {
            // alert(this.state.bookmark_count);
            this.setState({ isLoader: true });
            this.setState(state => ({ fill: !state.fill }))
            let url = root_url + 'api/utils/practice_action_details/';
            let practice_id = localStorage.getItem('practice_question_details_id');
            let tempDate = new Date();
            let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "action_name": 'bookmark',
                    "action_id": "bookmark" + practice_id,
                    "action_timestamp": start_time,
                    "practice_question_detail": practice_id

                })
            }).then(response => {
                if (response.status === 201) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                // console.log(response);
                localStorage.setItem('bookmark_id', response.id);
                this.setState({ isLoader: false });
                let bm = document.getElementById('bookmarked-msg');
                let ubm = document.getElementById('un-bookmarked-msg');
                bm.style.display = "block";
                ubm.style.display = "none";
            })
        }
    }

    changeEmail(e) {
        this.setState({
            email: e.target.value
        })
    }

    changeMobile(e) {
        this.setState({
            mobile: e.target.value
        })
    }

    pauseTimer() {
        //clearInterval(myInterval);
        this.callingPausePractice();
    }

    shareQuestion(e) {
        e.preventDefault();
        let url = root_url + 'api/utils/share_questions/';
        let email = this.state.email;
        let mobile = this.state.mobile;
        let question_id = localStorage.getItem('question_id');
        let practice_id = localStorage.getItem('practice_id');
        let question = parseInt(question_id);
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        if (email == '' || mobile == '') {
            alert('Please enter fields info to share')
        }
        else {
            this.setState({ isLoader: true });
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify({
                    "email": email,
                    "mobile": mobile,
                    "practice": practice,
                    "question": question,
                })
            }).then(response => {
                if (response.status === 201) {
                    alert('You have successfully shared this question');
                    $('#dismiss').click();
                    this.setState({ isLoader: false });
                    return response.json()

                }
                else {
                    this.setState({ isLoader: true });
                    return false;
                }
            })
        }
    }

    timerBookmark() {
        this.callingResumePractice();

    }

    callingPausePractice() {
        clearInterval(myInterval);
        myInterval = null;
        this.setState({ countdown_pause: true });
        let auth = "Token " + localStorage.getItem('key');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let practice_id = localStorage.getItem('practice_id');
        let timer_url = root_url + 'api/utils/practice/' + practice_id + '/';
        fetch(timer_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "timer_pause": start_time,
            })
        })
    }

    callingTimerInterval() {
        if (!myInterval) {
            myInterval = setInterval(() => {
                let seconds = parseInt(localStorage.getItem("countdown_seconds"));
                if (seconds == 0) {
                    localStorage.setItem("countdown_seconds", seconds);
                    clearInterval(myInterval);
                    myInterval = null;
                }
                else {
                    if (seconds > 0) {
                        seconds = seconds - 1
                        localStorage.setItem("countdown_seconds", seconds);
                    }
                }
            }, 1000);
        }
    }


    clearTimerInterval() {
        console.log("interval clear trigger");
    }

    callingResumePractice() {
        this.callingTimerInterval();
        this.setState({ countdown_pause: false });
        let auth = "Token " + localStorage.getItem('key');
        let practice_id = localStorage.getItem('practice_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let timer_url = root_url + 'api/utils/practice_timer_perform_update/' + practice_id + '/';
        this.setState({ isLoader: true });
        fetch(timer_url, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "timer_resume": start_time,
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            let timer_url = root_url + 'api/utils/practice/' + practice_id + '/';
            fetch(timer_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                this.setState({
                    //minutes: parseInt(response.timer_minutes),
                    //seconds: parseInt(response.timer_seconds),
                    minutes: this.state.minutes,
                    seconds: this.state.seconds,
                });
                localStorage.setItem('practice_end_datetime', response.timer_end);
                localStorage.setItem('practice_start_datetime', response.timer_start);
            });
            this.setState({ isLoader: false });
        });
    }

    endPractice() {
        let practice_id = localStorage.getItem('practice_id');
        let practice = parseInt(practice_id);
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_question_details/?question_sequence=&practice__id=' + practice;
        let url1 = root_url + 'api/utils/end_practice/?practice_id=' + practice_id;
        this.setState({ isLoader: true });
        fetch(url1, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth
            }

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response && response.data) {

                this.setState({
                    end_practice: response.data[0],
                    end_score: response.data[0].score,
                    end_accuracy: response.data[0].accuracy,
                    end_solve: response.data[0].solved,
                    end_msg: response.data[0].message_option,
                    end_minutes: response.data[0].time,
                    end_seconds: response.data[0].time,
                });
                localStorage.setItem('percentage', response.data[0].accuracy)
                localStorage.setItem('total_score', response.data[0].score)
                localStorage.setItem('solved', response.data[0].solved)
                localStorage.setItem('total', response.data[0].total)
                // alert(response.data[0].total);
                localStorage.setItem('time', response.data[0].time)
                localStorage.setItem('question_time_difference', response.data[0].speed)
                localStorage.setItem('msg', response.data[0].message_option)
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    if (response.next !== null) {
                        // console.log(response.next);
                    }
                    else {
                        this.setState({
                            end_practice_question_list: response.results,
                        })
                    }
                    for (let i = 0; i < this.state.end_practice_question_list.length; i++) {
                        if (this.state.end_practice_question_list[i].ques_end_timestamp !== null && this.state.end_practice_question_list[i].is_correct === 'true') {
                            let id = this.state.end_practice_question_list[i].question;
                            let url1 = root_url + 'api/utils/questions/' + id + '/';
                            fetch(url1, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': auth,
                                },
                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    return false;
                                }
                            }).then(response => {
                                let total_score = (parseInt(this.state.total_score) + parseInt(response.score));
                                this.setState({
                                    total_score: total_score,
                                })
                                let total = '' + total_score;
                                localStorage.setItem('total_score', this.state.total_score);
                            })
                            this.setState({ isLoader: false });
                        }
                    }
                })
            }

        });

    }


    getSelectedOption(e) {

        this.setState({
            option_selected: e.target.value
        })
        localStorage.setItem("selected_option", e.target.value);
    }

    pausePractice(e) {
        localStorage.setItem('is_paused', 'true');
        this.setState({
            pause: true,
            countdown_pause: true,
        })
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'pause',
                "action_id": "pause" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            this.setState({ isLoader: false });
            this.callingPausePractice();
        })
    }

    resumePractice(e) {
        e.preventDefault();
        localStorage.setItem('is_paused', 'false');
        this.setState({ countdown_pause: false });
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'resume',
                "action_id": "resume" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            this.callingResumePractice();
        })

        this.setState({ isLoader: false });
    }


    skipQuestion(e) {
        e.preventDefault();
        this.setState(state => ({ fill: false }))
        let get_question_count = localStorage.getItem('question_count');
        let skip_count = localStorage.getItem('skip') ? localStorage.getItem('skip') : 0;
        let sequential_skip_count = localStorage.getItem('sequential_skip') ? localStorage.getItem('sequential_skip') : 0;
        let skip = parseInt(skip_count);
        let sequential_skip = parseInt(sequential_skip_count);
        skip = skip + 1;
        sequential_skip = sequential_skip + 1;
        let skp = '' + skip;
        let sequential_skp = '' + sequential_skip;
        localStorage.setItem("skip", skp);
        localStorage.setItem("sequential_skip", sequential_skp);
        let question_count = parseInt(get_question_count);
        let auth = "Token " + localStorage.getItem('key');
        let id = localStorage.getItem('practice_question_details_id')
        let tempDate1 = new Date();
        let end_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
        let url1 = root_url + 'api/utils/practice_question_details/' + id + '/';
        this.setState({ isLoader: true });
        fetch(url1, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "ques_end_timestamp": end_time,
                "status": 'skip',
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            let end_time = response.ques_end_timestamp;
            localStorage.setItem('ques_end_timestamp', end_time);
        })

        let url = root_url + 'api/utils/practice_action_details/';
        let practice_id = localStorage.getItem('practice_question_details_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "action_name": 'skip_question',
                "action_id": "skip_question" + practice_id,
                "action_timestamp": start_time,
                "practice_question_detail": practice_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            // getting question
            let get_level = localStorage.getItem('level') ? localStorage.getItem('level') : 2;
            let level = parseInt(get_level);
            // Adding Adaptive Logic for skip question
            if (sequential_skip > 1) {
                if (level > 1) {
                    level = level - 1;
                }
            }
            this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
            //                this.setState({isLoader: false});
        })
    }

    componentDidMount() {
        this.setState({
            end_score: localStorage.getItem('total_score') ? localStorage.getItem('total_score') : 0,
            end_accuracy: localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0,
            end_solve: localStorage.getItem('solved') ? localStorage.getItem('solved') : 0,
            end_msg: localStorage.getItem('msg') ? localStorage.getItem('msg') : '',
            end_minutes: localStorage.getItem('end_minutes'),
            end_seconds: localStorage.getItem('end_seconds'),
            percentage: localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0,
            count: localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 0,
            correct: localStorage.getItem('correct') ? localStorage.getItem('correct') : 0,
            skip: localStorage.getItem('skip') ? localStorage.getItem('skip') : 0,
            total_score: localStorage.getItem('total_score') ? localStorage.getItem('total_score') : 0,
            practice_id: localStorage.getItem('practice_id') ? localStorage.getItem('practice_id') : '',
            topic_name: localStorage.getItem('topic_name') ? localStorage.getItem('topic_name') : '',
            is_paused: localStorage.getItem('is_paused') ? localStorage.getItem('is_paused') : 'false'

        })
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

        $("#menu-toggle1").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
            $("#wrapper").toggleClass("bgblack");
        });

        $("#end_practice_close").click(function (e) {
            document.location.href = document.location.origin + '/dashboard/';
        })

    }

    CreatingPracticeQuestionDetail(question_id) {
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/practice_question_details/';
        let practice = localStorage.getItem('practice_id');
        let tempDate = new Date();
        let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "is_correct": false,
                "question_sequence": count,
                "ques_start_timestamp": start_time,
                "ques_end_timestamp": null,
                "choice_selected": null,
                "practice": practice,
                "question": question_id

            })
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            let practice_details = response.id;
            let start_time = response.ques_start_timestamp;
            let resume_time = response.ques_end_timestamp;
            localStorage.setItem('practice_question_details_id', practice_details);
            // localStorage.setItem('ques_start_timestamp', start_time);
            // localStorage.setItem('ques_end_timestamp', resume_time);
            this.setState({ isLoader: false });
        })
    }

    CallingQuestion(level, practice, topic) {
        this.scrollToTop();
        let questionType = localStorage.getItem('userSelectedQuestionsType');
        let url = root_url + 'api/utils/random_question/?topic=' + topic + '&practice=' + practice + '&level=' + level + '&questionType=' + questionType;
        let auth = "Token " + localStorage.getItem('key');
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {

            if (response && response.results && response.results.length > 0 && response.status==201) {
                localStorage.setItem('score', response.results[0].score);
                localStorage.setItem('time', response.results[0].time);
                localStorage.setItem('question_id', response.results[0].id);
                localStorage.setItem('level', response.results[0].level);
                localStorage.setItem('given_topic', response.results[0].topic);
                this.setState({
                    question_content: response.results[0].content,
                    score: response.results[0].score,
                    time: response.results[0].time,
                    question_id: response.results[0].id,
                    isVisible: false,
                    given_topic: response.results[0].topic
                })
                let get_question_count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 0;
                let question_count = parseInt(get_question_count);
                question_count = question_count + 1;
                let quest = '' + question_count;
                localStorage.setItem("question_count", quest);
                this.setState({
                    count: question_count
                })
                setTimeout(() => {
                    this.setState({
                        isVisible: true,
                    })
                }, 100)
                this.CreatingPracticeQuestionDetail(response.results[0].id);
                this.CallingOptions(response.results[0].id);

            }
            else if(response && response.results && response.results.length == 0 && response.status==200){
                this.endTest();
            }
            else {
                alert('No Questions found..')
            }
        })
    }

    CallingOptions(question_id) {
        let auth = "Token " + localStorage.getItem('key');
        let option_url = root_url + 'api/utils/random_option/?question_id=' + question_id + '';
        if (question_id !== undefined) {
            this.setState({ isLoader: true });
            fetch(option_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },

            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return false;
                }
            }).then(response => {
                if (response.results.length > 0) {
                    this.setState({
                        option_list: response.results,
                        isVisible: false,
                    });
                    setTimeout(() => {
                        this.setState({
                            isVisible: true,
                        })
                    }, 100)
                    localStorage.setItem('options', JSON.stringify(response.results))
                    for (let i = 0; i < this.state.option_list.length; i++) {
                        if (this.state.option_list[i][0].is_correct === true) {
                            this.setState({ correct_option: this.state.option_list[i][0].option });
                        }
                    }
                    localStorage.setItem('match', this.state.correct_option)

                }
                else {
                    alert('No options found');
                }
            });
        }
        else {
            alert('Did not find any Question to get their options');
        }
    }


    componentWillMount() {

        let tempDate = new Date();
        let current_time = tempDate.getFullYear() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
        let get_level = localStorage.getItem('level') ? localStorage.getItem('level') : 2;
        let level = parseInt(get_level);
        let ques_id = parseInt(localStorage.getItem('question_id'));
        if (ques_id == undefined || isNaN(ques_id)) {
            this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);

        }
        else {
            this.setState({
                question_content: localStorage.getItem('question_content'),
                score: localStorage.getItem('score'),
                time: localStorage.getItem('time'),
                question_id: localStorage.getItem('question_id'),
                option_list: JSON.parse(localStorage.getItem("options")),
            })
            this.setState({ isLoader: false });
        }
    }



    gotoA(recommend_time, taken_time) {
        let get_level = localStorage.getItem('level');
        let level = parseInt(get_level);
        let max_level = 5
        let recommend_15_per_time = recommend_time + (recommend_time * 0.15)
        if (taken_time > recommend_15_per_time) {
            let max_2_loops = this.state.max_two_loops + 1;
            this.setState({
                max_two_loops: max_2_loops
            })
            if (this.state.max_two_loops <= 2) {
                this.setState({
                    level: level,
                    flag: false
                })
                let set_level = '' + level;
                localStorage.setItem('level', set_level);
            }
        }
        else {
            if (level === max_level) {
                this.setState({
                    level: level,
                    flag: false
                })
                let set_level = '' + level;
                localStorage.setItem('level', set_level);
            }
            else {
                level = level + 1;
                this.setState({
                    level: level,
                    flag: true
                })
                let set_level = '' + level;
                localStorage.setItem('level', set_level);
            }
        }
    }

    gotoB() {
        let get_level = localStorage.getItem('level');
        let level = parseInt(get_level);
        let min_level = 1
        if (level === min_level) {
            this.setState({
                level: level,
                flag: false
            })
            let set_level = '' + level;
            localStorage.setItem('level', set_level);
        }
        else {
            if (this.state.flag === true) {
                this.setState({
                    level: level,
                    flag: false
                })
                let set_level = '' + level;
                localStorage.setItem('level', set_level);
            }
            else {
                level = level - 1;
                this.setState({
                    level: level,
                    flag: false
                })
                let set_level = '' + level;
                localStorage.setItem('level', set_level);
            }
        }
    }

    correctQuestion() {
        let max_level = 5;
        let question_count = localStorage.getItem('question_count');
        let qc = parseInt(question_count);
        let get_time = localStorage.getItem('time');
        let recommend_time = parseInt(get_time)
        let time = localStorage.getItem('question_time_diff');
        let taken_time = parseInt(time);
        if (qc <= 5) {
            this.gotoA(recommend_time, taken_time);
            let get_level = localStorage.getItem('level');
            let level = parseInt(get_level);
            this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
        }

        else if (qc > 5 && qc <= 10) {
            // identifying level
            let get_level = localStorage.getItem('level');
            let level = parseInt(get_level);
            if (qc === 6) {
                if (level >= 4) {
                    this.setState({
                        level: 5,
                        flag: false
                    })
                    level = 5;
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                } else {
                    this.setState({
                        level: 4,
                        flag: false
                    })
                    level = 4;
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                }
            } else {
                this.gotoA(recommend_time, taken_time);
                let get_level = localStorage.getItem('level');
                let level = parseInt(get_level);
                this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
            }
        }

        else {
            let get_level = localStorage.getItem('level');
            let level = parseInt(get_level);
            if (level === max_level) {
                this.setState({
                    level: level,
                    flag: false
                })
                this.gotoA(recommend_time, taken_time);
                let get_level = localStorage.getItem('level');
                let level1 = parseInt(get_level);
                this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
            }
            else {
                if (qc === 11 || qc === 13) {
                    level = level + 1;
                    this.setState({
                        level: level,
                        flag: false
                    })
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                }
                else {
                    this.setState({
                        level: level,
                        flag: false
                    })
                    this.gotoA(recommend_time, taken_time);
                    let get_level = localStorage.getItem('level');
                    let level1 = parseInt(get_level);
                    this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
                }
            }
        }
    }

    incorrectQuestion() {
        let max_level = 5;
        let question_count = localStorage.getItem('question_count');
        let qc = parseInt(question_count);
        if (qc <= 5) {
            this.gotoB();
            let get_level = localStorage.getItem('level');
            let level1 = parseInt(get_level);
            this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
        }
        else if (qc > 5 && qc <= 10) {
            let get_level = localStorage.getItem('level');
            let level = parseInt(get_level);
            if (qc === 6) {
                if (level >= 4) {
                    this.setState({
                        level: 5,
                        flag: false
                    })
                    level = 5
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                }
                else {
                    this.setState({
                        level: 4,
                        flag: false
                    })
                    level = 4
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                }
            }
            else {
                this.gotoB();
                let get_level = localStorage.getItem('level');
                let level1 = parseInt(get_level);
                this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
            }
        }

        else {
            let get_level = localStorage.getItem('level');
            let level = parseInt(get_level);
            if (level < max_level) {
                if (qc === 11 || qc === 12) {
                    level = level + 1;
                    this.setState({
                        level: level,
                        flag: false
                    })
                    let set_level = '' + level;
                    localStorage.setItem('level', set_level);
                    this.CallingQuestion(level, this.state.practice_id, this.state.topic_selected);
                }
                else {
                    this.setState({
                        level: level,
                        flag: false
                    })
                    this.gotoB();
                    let get_level = localStorage.getItem('level');
                    let level1 = parseInt(get_level);
                    this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
                }
            }
            else {
                this.setState({
                    level: level,
                    flag: false
                })
                this.gotoB();
                let get_level = localStorage.getItem('level');
                let level1 = parseInt(get_level);
                this.CallingQuestion(level1, this.state.practice_id, this.state.topic_selected);
            }
        }
    }



    getNextQuestion() {
        let selected_option = localStorage.getItem('selected_option');
        if (selected_option == undefined) {
            document.getElementById('selectQuestion').click()
        }
        else {
            this.setState({ isLoader: true });
            this.setState(state => ({ fill: false }))
            localStorage.removeItem('selected_option');
            let get_solved_count = localStorage.getItem('solved') ? localStorage.getItem('solved') : 0;
            let solved_count = parseInt(get_solved_count);
            solved_count = solved_count + 1;
            let solved = '' + solved_count;
            localStorage.setItem("solved", solved);
            // changing sequential skip to 0
            let sequential_skp = '' + 0;
            localStorage.setItem("sequential_skip", sequential_skp)
            if (selected_option === localStorage.getItem('match')) {
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                correct = correct + 1;
                let crct = '' + correct;
                localStorage.setItem("correct", crct);
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                this.setState({
                    percentage: perc,
                })
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let question_id = localStorage.getItem('question_id');
                let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": true,
                        "ques_end_timestamp": end_time,
                        "choice_selected": selected_option,
                        "status": 'correct',
                    })
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        return false;
                    }
                }).then(response => {
                    let tempDate1 = new Date(response.ques_end_timestamp);
                    let en_time = tempDate1.getFullYear() + '-' + (tempDate1.getMonth() + 1) + '-' + tempDate1.getDate() + ' ' + tempDate1.getHours() + ':' + tempDate1.getMinutes() + ':' + tempDate1.getSeconds();
                    let end_time1 = new Date(en_time);
                    end_time1.setMinutes(tempDate1.getMinutes() - 330);
                    let end_time2 = end_time1.getFullYear() + '-' + (end_time1.getMonth() + 1) + '-' + end_time1.getDate() + ' ' + end_time1.getHours() + ':' + end_time1.getMinutes() + ':' + end_time1.getSeconds();
                    let tempDate = new Date(response.ques_start_timestamp);
                    let start_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                    let start_time1 = new Date(start_time);
                    start_time1.setMinutes(tempDate.getMinutes() - 330);
                    let start_time2 = start_time1.getFullYear() + '-' + (start_time1.getMonth() + 1) + '-' + start_time1.getDate() + ' ' + start_time1.getHours() + ':' + start_time1.getMinutes() + ':' + start_time1.getSeconds();
                    let diffInMilliseconds = Math.abs(new Date(end_time2) - new Date(start_time2));
                    let minutes = Math.floor(diffInMilliseconds / 60000);
                    let seconds = ((diffInMilliseconds % 60000) / 1000).toFixed(0);
                    if (minutes > 0) {
                        seconds = seconds + (minutes * 60);
                    }
                    localStorage.setItem('question_time_diff', seconds);
                })
                this.correctQuestion();
                this.setState({ isLoader: false });
            }

            else {
                // practice_question_details_id
                let get_percentage = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
                let perc = parseInt(get_percentage);
                let correct_count = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
                let correct = parseInt(correct_count);
                if (solved_count > 0 && correct > 0)
                    perc = ((correct / solved_count) * 100).toFixed(2);
                else
                    perc = 0;
                let percent = '' + perc;
                localStorage.setItem('percentage', percent);
                this.setState({
                    percentage: perc,
                })
                let auth = "Token " + localStorage.getItem('key');
                let id = localStorage.getItem('practice_question_details_id')
                let url = root_url + 'api/utils/practice_question_details/' + id + '/';
                let tempDate = new Date();
                let end_time = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
                let question_id = localStorage.getItem('question_id');
                let count = localStorage.getItem('question_count') ? localStorage.getItem('question_count') : 1;
                fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': auth,
                    },
                    body: JSON.stringify({
                        "is_correct": false,
                        "ques_end_timestamp": end_time,
                        "status": 'wrong',
                        "choice_selected": selected_option,
                    })
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        return false;
                    }
                }).then(response => {
                    let end_time = response.ques_end_timestamp;
                    localStorage.setItem('ques_end_timestamp', end_time);
                })
                this.incorrectQuestion();
                this.setState({ isLoader: false });
            }

        }
    }

    ExtraError(e) {
        this.setState({
            feedback: e.target.value
        });
        localStorage.setItem('feedback', e.target.value);
    }


    questionError(e) {
        this.setState({
            is_question_error: e.target.checked
        });
        localStorage.setItem('question_error', e.target.checked);
    }

    optionError(e) {
        this.setState({
            is_option_error: e.target.checked
        });
        localStorage.setItem('option_error', e.target.checked);
    }

    solutionError(e) {
        this.setState({
            is_solution_error: e.target.checked
        });
        localStorage.setItem('solution_error', e.target.checked);
    }

    otherError(e) {
        this.setState({
            is_other_error: e.target.checked
        });
        localStorage.setItem('other_error', e.target.checked);
    }

    submitErrorLog(e) {
        e.preventDefault();
        let practice = localStorage.getItem('practice_id');
        let practice_id = parseInt(practice);
        let question = localStorage.getItem('question_id');
        let question_id = parseInt(question);
        let question_error = localStorage.getItem('question_error') ? localStorage.getItem('question_error') : false;
        let option_error = localStorage.getItem('option_error') ? localStorage.getItem('option_error') : false;
        let solution_error = localStorage.getItem('solution_error') ? localStorage.getItem('solution_error') : false;
        let other_error = localStorage.getItem('other_error') ? localStorage.getItem('other_error') : false;
        let feedback = localStorage.getItem('feedback') ? localStorage.getItem('feedback') : '';
        let auth = "Token " + localStorage.getItem('key');
        let url = root_url + 'api/utils/error_logs/';
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },
            body: JSON.stringify({
                "question_error": question_error,
                "solution_error": solution_error,
                "option_error": option_error,
                "other_error": other_error,
                "practice": practice_id,
                "question": question_id,
                "feedback": feedback,
            })
        }).then(response => {
            if (response.status === 201) {
                alert('Your response for this question has been send.')
                $('#dismiss_error_log').click();
                this.setState({ isLoader: false });
                return response.json();

            }
            else {
                this.setState({ isLoader: false });
                return false;
            }
        });
    }

    render() {
        const bookmarkImage = this.getImageName();
        let goal_name = localStorage.getItem('goal_name');
        let topic = localStorage.getItem('topic_name');
        let question_count = localStorage.getItem('question_count');
        let qc = parseInt(question_count);
        let count = localStorage.getItem('solved') ? localStorage.getItem('solved') : 0;
        let correct = localStorage.getItem('correct') ? localStorage.getItem('correct') : 0;
        let skip = localStorage.getItem('skip') ? localStorage.getItem('skip') : 0;
        let per = localStorage.getItem('percentage') ? localStorage.getItem('percentage') : 0;
        let percent = parseInt(per);
        let score = localStorage.getItem("score");
        let time = localStorage.getItem("time");
        let id = localStorage.getItem("question_id");
        let time_in_minutes = Math.floor(time / 60);
        let time_in_seconds = time - time_in_minutes * 60;
        const { minutes, seconds } = this.state
        let pathColor = '';
        let logo = '';
        if (localStorage.getItem('is_logo') == 'true') {
            logo = localStorage.getItem('org_logo');
        }
        else {
            logo = PrepHub_logo;
        }

        if (percent >= 75) {
            pathColor = '#42a37a';
        }
        else if (percent < 75 && percent >= 50) {
            pathColor = '#FF7F50';
        }
        else {
            pathColor = '#a93672';
        }
        let count_li = parseInt(count);
        let topic_name = topic;

        let option_dropdown = []
        for (let i = 0; i < this.state.option_list.length; i++) {
            option_dropdown.push(
                <li key={this.state.option_list[i][0].id}>
                    <input type="radio" id={this.state.option_list[i][0].id} value={this.state.option_list[i][0].option} name="selector" onClick={this.getSelectedOption} />
                    <label htmlFor={this.state.option_list[i][0].id}>
                        {(this.state.isVisible) ? (
                            <Editor.EditorShow config={
                                {
                                    markdown: this.state.option_list[i][0].option,
                                    id: i,
                                    height: 300,
                                    name: "id_option-markdown-doc",
                                    saveHTMLToTextarea: true,
                                    toolbar: false,
                                    flowChart: false,
                                    sequenceDiagram: false,

                                }
                            } />
                        ) : ''}
                        {/*<Latex >{this.state.option_list[i][0].option}</Latex>*/}
                    </label>
                    <div className="check">
                        <div className="inside"></div>
                    </div>
                </li>
            )
        }

        // let end_practice = this.state.end_practice;
        // let end_score = localStorage.getItem('total_score')?localStorage.getItem('total_score'):0;
        // let end_accuracy = localStorage.getItem('percentage')?localStorage.getItem('percentage'):0;
        // let end_solve = localStorage.getItem('solved')?localStorage.getItem('solved'):0;
        // let end_msg = localStorage.getItem('msg')?localStorage.getItem('msg'):'';
        // let end_minutes = Math.floor(parseInt(localStorage.getItem('time')) / 60);
        // let end_seconds = parseInt(localStorage.getItem('time')) - end_minutes * 60;

        return (

            <div>
                {/*<Endprac />*/}
                {localStorage.getItem('backdrop_open') == "true" ? <BackDrop openStatus={true} component="timed_question" changePauseTimerState={this.changePauseTimerState} /> : ''}
                {isMobileOnly ? '' : <Dash backButton={true} />}
                <Popup />

                <section className="dash-mid" >
                    {isMobileOnly ? <TopBarTestPage saveBookmark={this.saveBookmark} pauseTimer={this.pauseTimer} pausePractice={this.pausePractice} confirmEndPractice={this.confirmEndPractice} minutes={minutes} seconds={this.state.countdown_seconds} id={id} score={score} paused={this.state.countdown_pause} callingTimerInterval={this.callingTimerInterval} clearTimerInterval={this.clearTimerInterval} /> : ''}
                    <div id="wrapper">
                        <div id="sidebar-wrapper">
                            <div className="btn-tog"></div>
                            <div className="sidebar-nav perf-bdr">
                                <div className="side-nav">
                                    <div className="logo dnone"><img src={logo} /></div>
                                    <div className="ham2" id="menu-toggle1"><span>
                                        <img src={require("./../../assests/img/cross-menu.png")} width="15" />
                                    </span>
                                    </div>
                                    <div className="side-select dnone">
                                        <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                        <select id="mySelect" data-show-content="true" className="form-control">
                                            <option>{goal_name}</option>
                                        </select>
                                    </div>
                                    <div className="lft-side-menu middle-menu">
                                        <ul className="fix-left-nav">
                                            <li className="active" onClick={this.alertDashboard}><Link><img src={require("./../../assests/img/side-icon-2-active.png")} /><h6><font className="activeTab">Practice Mode</font></h6></Link>
                                                <span></span>
                                            </li>
                                            <li onClick={this.alertPerformance}><Link><img src={require("./../../assests/img/side-icon-3.png")} /><h6>Performance Management</h6></Link></li>
                                            <li><Link to="#"><img src={require("./../../assests/img/mail.png")} style={{ height: "30px", width: "30px" }}/><h6>Contact Us</h6></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container share-mod share-mod2 background-blur">
                            <div className="modal" id="myModal2" onClick={this.resumePractice}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" className="close" data-dismiss="modal">
                                                <img src={require("./../../assests/img/cross.jpg")} />
                                            </button>
                                            <div className="pop2-img"><img src={require("./../../assests/img/pop-img.jpg")} /></div>
                                            <div className="row pt-3">
                                                <div className="col">
                                                    <div className="divh3">
                                                        <h3>Your practice has been paused.
                                                            Please try to come back soon and resume practice
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col text-center">
                                                    <div className="common-btn" data-dismiss="modal"><button onClick={this.resumePractice}>Resume</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container share-mod bookmark background-blur">
                            <div className="modal" id="myModal4">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" className="close" onClick={this.timerBookmark} data-dismiss="modal">
                                                <img src={require("./../../assests/img/cross.jpg")} />
                                            </button>
                                            <p id='bookmarked-msg'>The question has been bookmarked, you can review all your bookmarked questions in the performance management tab under the <span>Review Practice </span>sesion</p>
                                            <p id='un-bookmarked-msg'>The question has been un-bookmarked successfully.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container share-mod popfive background-blur">
                            <div className="modal" id="myModal5">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" id="dismiss_error_log" onClick={this.timerBookmark} className="close" data-dismiss="modal">
                                                <img src={require("./../../assests/img/cross-menu.png")} width="16px" />
                                            </button>
                                            <div className="row">
                                                <div className="col">

                                                    <div className="row  pt-4">
                                                        <div className="col new">
                                                            <form>
                                                                <div className="form-group">
                                                                    <input type="checkbox" id="one" onClick={this.questionError} />
                                                                    <label htmlFor="one">&nbsp;&nbsp;Question has an
                                                                        error</label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="checkbox" id="two" onClick={this.optionError} />
                                                                    <label htmlFor="two">&nbsp;&nbsp;Options have an
                                                                        error</label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="checkbox" id="three" onClick={this.solutionError} />
                                                                    <label htmlFor="three">&nbsp;&nbsp;Solution has an
                                                                        error</label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="checkbox" id="four" onClick={this.otherError} />
                                                                    <label htmlFor="four">&nbsp;&nbsp;Other Issues</label>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-md-12 pt-1 m-auto">
                                                                        <textarea className="form-control" onChange={this.ExtraError}></textarea>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="common-btn">
                                                                <button onClick={this.submitErrorLog}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container share-mod background-blur">
                            <div className="modal" id="myModal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button id="dismiss" type="button" onClick={this.timerBookmark} className="close" data-dismiss="modal">
                                                <img src={require("./../../assests/img/cross.jpg")} />
                                            </button>
                                            <div className="divh3">
                                                <h3>Share Question</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row  pt-4">
                                                        <div className="col-md-9">
                                                            <input type="text" className="form-control" onChange={this.changeEmail} placeholder="Enter Email Id" name="" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="common-btn"><button onClick={this.shareQuestion}>Share</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-2">
                                                        <div className="col-md-9">
                                                            <input type="text" className="form-control" onChange={this.changeMobile} placeholder="Enter Mobile Number" name="" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="common-btn"><button onClick={this.shareQuestion}>Share</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- sidebar-wrapper --> */}
                        {/* <!--   Right-side-page --> */}
                        <div id="page-content-wrapper" className={`${isMobileOnly ? 'p-0' : ''}`}>
                            <div className={`container-fluid ${isMobileOnly ? 'overflow-auto' : ''}`} >
                                {/* <!--     Right-side-panel-button --> */}


                                {/* <!--     Right-side-panel-end --> */}
                                <div className="row">
                                    <div className="col-lg-12 mb-d-80">
                                        <div className="row pl-4 pr-4 pt-4 dash2-q mb-d-50">
                                            <div className="col height-mob">
                                                {/*<Timer paused ={is_paused}/>*/}
                                                {isMobileOnly ? ''
                                                    :
                                                    <div className="row dash-2-bdr ipad-responsive mob-time-band-timed-question" >
                                                        <div className="col-md-3">
                                                            <div className="divh4">
                                                                <h4>Q id - Q00{id} (Score: {score} points)</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 head-icon-test">
                                                            <ul>
                                                                <li><img src={bookmarkImagePath[bookmarkImage]} data-toggle="modal" onClick={this.saveBookmark} data-target="#myModal4" /><h6>Bookmark</h6></li>
                                                                <li><img src={require("./../../assests/img/share.jpg")} data-toggle="modal" onClick={this.pauseTimer} data-target="#myModal" /><h6>Share</h6></li>
                                                                <li><img src={require("./../../assests/img/error.png")} width="22" onClick={this.pauseTimer} data-toggle="modal" data-target="#myModal5" /><h6>Error</h6></li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-md-2 c-4 offset-5 clock text-right  d-flex justify-content-end">

                                                            <div className=""><img src={require("./../../assests/img/clock.jpg")} /></div>
                                                            <div ><span className="mt-n2 mt-lg-1">
                                                                {parseInt(localStorage.getItem("countdown_seconds")) > 0 ? <CountDownTimer paused={this.state.countdown_pause} seconds={localStorage.getItem("countdown_seconds")} testType='timedTest'/> : <div className='timer-container text-black '><h4>0m 0s</h4></div>}
                                                            </span></div>
                                                        </div>

                                                    </div>}
                                                <div className={`row dash-accord ${isMobileOnly ? 'p-3' : ''}`} >
                                                    <div className="col qustion-mob">
                                                        <div id="accordion" className="accordion ">
                                                            <div className="card mb-0">
                                                                <div className={`faq-accord ${isMobileOnly ? 'p-0' : 'pt-4 pb-3 pl-0 pr-0'}`}>
                                                                    <div className="card-header collapsed" data-toggle="collapse" to="#collapseOne">
                                                                        <Link className="card-title">
                                                                            {this.state.given_topic}
                                                                        </Link>
                                                                    </div>
                                                                    <div id="collapseOne" className="collapse show pb-3" data-parent="#accordion">
                                                                        <div className="card-body ptag mt-n5" id="show_que">
                                                                            {(this.state.isVisible) ? (
                                                                                <Editor.EditorShow config={
                                                                                    {
                                                                                        markdown: this.state.question_content,
                                                                                        id: "id_content",
                                                                                        height: 300,
                                                                                        name: "id_content-markdown-doc",
                                                                                        saveHTMLToTextarea: true,
                                                                                        toolbar: false,
                                                                                        flowChart: false,
                                                                                        sequenceDiagram: false,
                                                                                    }

                                                                                } />
                                                                            ) : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.isLoader && <div style={{
                                                    width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                                }}><Loader color="primary" height="80px" width="80px" /></div>}

                                                <div className="row" >
                                                    <div className={`col qustion-mob ${isMobileOnly ? 'p-3 mt-n3' : 'mt-3'}`} >
                                                        <div className="raio-tit">Select all that apply:</div>
                                                        <div className="radio-btn mt-n2 mt-md-0">
                                                            <ul>
                                                                {option_dropdown}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!isMobileOnly ? <div className="row dash-2-btn pt-5 ">
                                                    <div className="col dash-hov text-center text-none">
                                                        <ul>
                                                            <li>
                                                                <div className="common-btn"><button onClick={this.skipQuestion}>Skip Question</button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="common-btn"><button onClick={this.getNextQuestion}>Submit & Next</button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="push-end-btn">
                                                        <ul>
                                                            <li>
                                                                <div className="common-btn btn-change "><button data-toggle="modal" data-target="#myModal2" onClick={this.pausePractice}>Pause Practice</button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="common-btn btn-change"><button id='end_practice' data-toggle="modal" onClick={this.confirmEndPractice}>End Practice</button>
                                                                </div>
                                                                <div className="hidethisdiv"><button id='confirm_end_practice' data-toggle="modal" data-target="#myModal15" onClick={this.endPractice}></button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModal6" id='selectQuestion'></button></div>
                                                    </div>
                                                </div>
                                                    :
                                                    <div className="container-fluid p-0 mt-5">
                                                        <div class="row">
                                                            <div className="lightGray col p-0 "></div>
                                                            <div className="col p-0">
                                                                <button type="button" className="btn text-white font-weight-bold shadow-none d-block btn-block pl-1 pr-1 pt-2 pb-2 rounded-0 skipbutton" onClick={this.skipQuestion}>
                                                                    Skip <br />
                                                                    Question<br />
                                                                </button>
                                                            </div>
                                                            <div className="col p-0">
                                                                <button type="button" className="btn text-white font-weight-bold shadow-none h-100 btn-block rounded-0 submitNext" onClick={this.getNextQuestion}>Submit & Next</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                                <div className="hidethisdiv"><button id='confirm_end_practice' data-toggle="modal" data-target="#myModal15" onClick={this.endPractice}></button></div>
                                                <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModal6" id='selectQuestion'></button></div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- col-lg-12-END --> */}

                                </div>

                            </div>

                        </div>

                        {/* <!--   Right-side-page END --> */}

                    </div>
                    {/* <!-- wrapper end  --> */}



                </section>



                <Helmet>
                    {/*<script src="/src/assests/js/progress.js" type="text/javascript" />*/}
                </Helmet>
                <div className="container share-mod share-mod2 share-mod3">
                    <div className="modal" id="myModal15">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id='end_practice_close' className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross.jpg")} />
                                    </button>
                                    <div className="pop2-img"><img src={require("./../../assests/img/pop-img.jpg")} /></div>
                                    <div className="row pt-3">
                                        <div className="col">
                                            <div className="divh3">
                                                <h3>{this.state.end_msg}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-5 score-points">
                                        <div className="col-md-6">
                                            <img src={require("./../../assests/img/pop-icon1.jpg")} /> <span>Score <br />{this.state.end_score} points</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../assests/img/pop-icon2.jpg")} /> <span>Solve <br /> {this.state.end_solve} questions</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../assests/img/pop-icon3.jpg")} /> <span>Accuracy <br />You - {this.state.end_accuracy}% <br />Median - NA</span>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={require("./../../assests/img/pop-icon4.jpg")} /> <span>Speed (min:sec) <br />You - {this.state.end_minutes}:{this.state.end_seconds} <br />Median - NA</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <div className="common-btn" data-dismiss="modal"><button onClick={this.reviewPage}>Review</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export class Popup extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <div className="container share-mod popfive">
                    <div className="modal" id="myModal6">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_log" className="close" data-dismiss="modal" >
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px"></img>
                                    </button>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <p className="popup-text-color">Please select any option before clicking on Next Question</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TimedQuestion