import React from 'react';
import SamplePaperDetail from './SamplePaperDetail';
import { useEffect, useState } from 'react';
import root_url from './../components/api_url';
import Loader from './Loader'
import Backdrop from './BackDrop'

const axios = require('axios');

export default function AllSamplePapers() {
    const [allSamplePapers, setAllSamplePapers] = useState([]);
    const getAllSamplePapers = () => {
        let logged_user=localStorage.getItem('mobile');
        axios.get(`${root_url}api/utils/get_all_sample_papers/${logged_user}/`)
            .then((res) => {
                let samplePaperData = res.data;
                if (samplePaperData.message == "Sample Papers Found" && samplePaperData.status_code == 201) {
                    setAllSamplePapers(samplePaperData.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        getAllSamplePapers();
    }, [])
    return (
        <>
            <div className="w-100 d-flex flex-column p-4 overflow-auto  h-80" >
                {
                    allSamplePapers.length > 0 ? allSamplePapers.map((item, index) => (
                        <SamplePaperDetail samplePaper={item} index={index + 1} key={index} />
                    )) : <Loader color="primary" height="120px" width="120px" divHeight="h-100" />
                }
            </div>
        </>
    )

}

