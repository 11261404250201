import React, { Component } from 'react';
import $ from 'jquery';
import root_url from './../components/api_url';
import Loader from '../components/Loader'


export class GetStartedNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrftoken: '',
            first_name: '',
            last_name: '',
            mobile: '',
            password: '',
            confirm_password: '',
            nameMessage: '',
            numberMessage: '',
            passwordMessage: '',
            confirmPasswordMessage: '',
            verificationMessage: '',
            isLoader: false,
            name: '',
            showPaasword: false
        }
        this.Name = this.Name.bind(this);
        this.Mobile = this.Mobile.bind(this);
        this.Password = this.Password.bind(this);
        this.Confirm_Password = this.Confirm_Password.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
        this.createUser = this.createUser.bind(this);
        this.showPaasword = this.showPaasword.bind(this);

    }


    Name(e) {
        this.setState({ name: e.target.value });
        let given_name = e.target.value;
        let given_name_split = given_name.split(" ");
        if (given_name_split.length > 2) {
            let first_name = given_name_split[0];
            given_name_split.shift();
            let last_name = given_name_split.join(' ')
            this.setState({ first_name: first_name, last_name: last_name });
        }
        else if (given_name_split.length === 2) {
            this.setState({ first_name: given_name_split[0], last_name: given_name_split[1] });
        }
        else if (given_name_split.length === 1) {
            this.setState({ first_name: given_name_split[0], last_name: "NULL" });
        }
        else {
            this.setState({ first_name: "", last_name: "" });
        }
    }
    Mobile(e) {
        this.setState({ mobile: e.target.value });
    }
    Password(e) {
        this.setState({ password: e.target.value })
    }
    Confirm_Password(e) {
        this.setState({ confirm_password: e.target.value })
    }
    showPaasword() {
        if (this.state.showPaasword) {
            this.setState({
                showPaasword: false
            });
        }
        else {
            this.setState({
                showPaasword: true
            });
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const phone = query.get('phone');
        const goal = query.get('goal');
        if (email) {
            localStorage.setItem("email", email);
        }
        if (phone) {
            document.getElementById('id_mobile').value = phone;
            this.setState({ mobile: phone })
            localStorage.setItem("mobile", phone);
        }
        if (goal) {
            localStorage.setItem('goal', goal);
        }
    }

    submitHandler(e) {
        let mobileexp=/^\d{10}$/;
        e.preventDefault();
        this.setState({
            nameMessage: '',
            numberMessage: '',
            passwordMessage: '',
            confirmPasswordMessage: '',
            verificationMessage: '',
        })
        let name = this.state.name;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        let email = this.state.mobile + '@dummymail.com';
        let mobile = this.state.mobile;
        let password = this.state.password;
        let confirm_password = this.state.confirm_password;
        if (name === '') {
            this.setState({ nameMessage: 'Name cannot be blank' })
        }
        else if (mobile === '') {
            this.setState({ numberMessage: 'Mobile number cannot be blank' })
        }
        else if (!mobile.match(mobileexp)) {
            this.setState({ numberMessage: 'Enter valid mobile number' })
        }
        else if (password === '') {
            this.setState({ passwordMessage: 'Password cannot be blank' })
        }
        else if (password.length < 8) {
            this.setState({ passwordMessage: 'Minimum 8 characters required' })
        }
        else if (confirm_password === '') {
            this.setState({ confirmPasswordMessage: 'Confirm Password cannot be blank' })
        }
        else if (password !== confirm_password) {
            this.setState({ confirmPasswordMessage: 'Password and Confirm Password do not match.' })
        }
        else {
            this.setState({ isLoader: true });
            localStorage.setItem('email', email);
            localStorage.setItem('first_name', first_name);
            localStorage.setItem('last_name', last_name);
            localStorage.setItem('mobile', mobile);
            localStorage.setItem('name', name);
            localStorage.setItem('password', password);
            localStorage.setItem('confirm_password', confirm_password);
            let user_data = {
                first_name: first_name,
                last_name: last_name,
                mobile: mobile,
                username: mobile,
                email: email,
                password1: password,
                password2: password
            }
            this.sendOTP(user_data);
        }
    }

    sendOTP(given_user_data) {
        let mobile = given_user_data.mobile
        let url = root_url + 'mobile_otp_send/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mobile: mobile,
                user_entry_type: 'Signup_User'
            })
        }).then(response => {
            if (response.status === 400) {
                return response.json();
            }
            else if (response.status === 200) {
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.status == 200) {
                this.setState({ numberMessage: response.message, isLoader: false });
            }
            if (response.status == 201) {
                localStorage.setItem('user_type', 'Signup_User');
                this.createUser(given_user_data);
            }
        })
    }


    createUser(given_user_data) {
        localStorage.setItem('user_email', given_user_data.email);
        localStorage.setItem('user_pass', given_user_data.password1);
        localStorage.setItem('user_username', given_user_data.mobile);
        let url = root_url + 'api/registrations/';
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': this.state.csrftoken,
            },
            credentials: 'same-origin',
            body: JSON.stringify(given_user_data)
        }).then(response => {
            if (response.status === 201) {
                return response.json();
            }
            else if (response.status === 400) {
                this.setState({ isLoader: false })
                return response.json();
            }
            else {
                return false;
            }
        }).then(response => {
            if (response.key) {
                this.setState({ isLoader: false })
                let key = response.key;
                localStorage.setItem('userLoggedIn', 'true');
                localStorage.setItem('is_timed', 'true');
                localStorage.setItem('user', given_user_data.mobile);
                localStorage.setItem('key', key);
                document.location.href = document.location.origin + `/get-started/enterotp?mobile=${given_user_data.mobile}&user_type=Signup_User`;

            }
            else if (response.email) {
                this.setState({ numberMessage: 'A user is already registered with this mobile number' })
            }
            else if (response.password1) {
                this.setState({ passwordMessage: response.password1[0] })
            }
            else if (response.password2) {
                this.setState({ confirmPasswordMessage: response.password2[0] })
            }
            else if (response.non_field_errors) {
                this.setState({ confirmPasswordMessage: response.non_field_errors })
            }
            else {
                this.setState({ verificationMessage: response.detail })
            }
        })
    }


    componentWillMount() {
        let name = "csrftoken";
        let cookieValue = '';
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = $.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        this.setState({ csrftoken: cookieValue })
    }
    render() {
        return (

            <div>
                <section className="forgot-pass loginhelp">
                    <div className="container  pl-0 pr-0 w-100">
                        <div className="col-md-5 m-auto">
                            <div className="forgot-p">
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divh3"><h3>New User</h3></div>
                                    </div>
                                </div>
                                <div className="row loginhform pt-5" >
                                    <div className="col">
                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <input type="text" placeholder="Enter your name" id="id_name" onChange={this.Name} className="form-control mob" required spellcheck="false" />
                                                <label>Name</label>
                                            </div>
                                            {this.state.nameMessage && <span className="error ml-3"> {this.state.nameMessage} </span>}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <input type="text" placeholder="Enter 10 digit mobile number" id="id_mobile" onChange={this.Mobile} className="form-control mob" required />
                                                <label>Mobile Number</label>
                                            </div>
                                            {this.state.numberMessage && <span className="error ml-3"> {this.state.numberMessage} </span>}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <div className="input-group ">
                                                    <input type={`${this.state.showPaasword ? 'text' : 'password'}`} placeholder="Minimum 8 characters" className="form-control form-control-sm  border-top-0 border-left-0 border-right-0 text-lg" id="id_password1" onChange={this.Password} required style={{ borderColor: "#8b54b9", color: "#8b54b9", fontSize: "15px" }} id="id_password" />
                                                    <div className="input-group-prepend bg-white">
                                                        <div className="input-group-text bg-white border-top-0 border-left-0 border-right-0 " style={{ borderColor: "#8b54b9" }}><i className={`${this.state.showPaasword ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={this.showPaasword} aria-hidden="true"></i></div>
                                                    </div>
                                                </div>
                                                <label className="">Password</label>
                                            </div>
                                            {this.state.passwordMessage && <span className="error ml-3"> {this.state.passwordMessage} </span>}
                                        </div>

                                        <div className="row form-group ">
                                            <div className="col-md-12 pt-3 m-auto">
                                                <input type={`${this.state.showPaasword ? 'text' : 'password'}`} placeholder="Minimum 8 characters" onChange={this.Confirm_Password} className="form-control descrip" id="id_confirm_password" required />
                                                <label>Confirm Password</label>
                                            </div>
                                            {this.state.confirmPasswordMessage && <span className="error ml-3"> {this.state.confirmPasswordMessage} </span>}
                                        </div>

                                    </div>
                                </div>
                                <div className="row sign-in pt-3">
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader color="primary" height="50px" width="50px" /></div>}
                                    <div className="col-md-12">
                                        <div className="common-btn pt-3"><button onClick={this.submitHandler} id="next-id">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

export default GetStartedNewUser
