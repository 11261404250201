import React, { Component, useEffect, useState } from 'react';
import Editor from "react-editor-md";

export default function QuestionCard(props) {
    const [backgroundColorStatus, setBackgroundColorStatus] = useState('');
    const setBackgroundStatusOfQuestion = () => {
        let review_questions = props.reviewQuestion;
        let filtered_obj = review_questions.find(o => o.question === props.id);
        if (filtered_obj !== undefined) {
            if (filtered_obj.status == "skip") {
                setBackgroundColorStatus("skipInSection")
            }
            if (filtered_obj.is_bookmarked == true && filtered_obj.status == null) {
                setBackgroundColorStatus("bookmarkedInSection")
            }
            if (filtered_obj.is_bookmarked == true && filtered_obj.status !== null) {
                setBackgroundColorStatus("answeredAndBookmarkedInSection")
            }
            if (filtered_obj.is_bookmarked == false && filtered_obj.status != "skip" && filtered_obj.status !== null) {
                setBackgroundColorStatus("answeredInSection")
            }
            if (filtered_obj.status == null && filtered_obj.is_bookmarked == false) {
                setBackgroundColorStatus("sequentialQuestionInSection")
            }
        }
        else {
            setBackgroundColorStatus("notVisitedInSection")
        }
    }

    useEffect(() => {
        setBackgroundStatusOfQuestion();
    }, [props.reviewQuestion]);
    const validContent=(string)=>{
            let stringWithoutUrls = string.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
            let finalString = stringWithoutUrls.replace('![]', '');
            return finalString.slice(0, 70)+' ......'
    }
    const selectedReviewQuestion = (given_index, given_id) => {
        let review_questions=props.reviewQuestion
        let filtered_obj = review_questions.find(o => o.question === given_id);
        if (filtered_obj !== undefined) {
            props.renderNextQuestion(parseInt(given_index-2), filtered_obj.choice_selected);
        }
        else{
            props.renderNextQuestion(parseInt(given_index-2));
        }
    }
    return (
        <>
            <div className={`d-flex flex-column justify-content-between col-5 p-0 ml-3 mr-3 mt-2 cursor-pointer ${props.QuestionId==props.question_id?'activeBorder':'border'}`} style={{ height: '250px', borderRadius: "25px" }} onClick={() => selectedReviewQuestion(props.index, props.id)}>
                <div className="p-2 font-weight-bold text-secondary ml-2" >
                  <div>{props.index}</div>
                  <div className="font-weight-bold text-secondary pt-3">{props.question_topic}</div> 
                </div>
                <div className="p-2 mt-n5 overflow-y-auto ml-2" key={props.index}>
                <Editor.EditorShow config={
                                    {
                                        markdown: validContent(props.question_content),
                                        id: "id_contents" + props.id,
                                        height: 300,
                                        name: "id_content-markdown-doc",
                                        saveHTMLToTextarea: true,
                                        toolbar: false,
                                        flowChart: false,
                                        sequenceDiagram: false,
                                    }

                                } />
                </div>
                <div className={`border-top p-2 border-bottom-left border-bottom-right ${backgroundColorStatus}`} style={{height:'30px'}} ></div>
            </div>
        </>
    );
}
