import React from 'react';
import WatchIcon from '@mui/icons-material/Watch';
import UpdateIcon from '@mui/icons-material/Update';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';


const getIcon = (iconType) => {
  switch (iconType) {
    case "a":
      return <WatchIcon style={{ color: "#7FCC77", width: "40px", height: "40px" }} />
    case "b":
        return <UpdateIcon style={{ color: "#FD7276", width: "40px", height: "40px" }} />
    case "c":
      return <EmojiObjectsIcon style={{ color: "#FECB88", width: "40px", height: "40px" }} />
    case "d":
      return <FastForwardOutlinedIcon style={{ color: "#69BCFF", width: "40px", height: "40px" }} />
  }
}

export default function AssignmentInformation(props) {
  const {heading, description, iconType}=props;
  return (
    <>
    
      <div className="w-100 pb-3 pb-lg-5 col-12 col-lg-6" >
        <div className="row">
            <div className="col-2 flex align-center">
                {getIcon(iconType)}
            </div>
            <div className="col-10 flex flex-column justify-center">
                <p className="text-dark font-weight-bold assignment-help-header">{heading}</p>
                <p className="font-weight-normal text-dark assignment-help-content mb-0">{description}</p>
            </div>
        </div>
      </div>
    </>
  );
}
