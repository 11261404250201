import React from 'react';
import AssignmentInformation from './AssignmentInformation.js'
import AssignmentPack from './AssignmentPack'
import { isMobileOnly } from 'mobile-device-detect';


export default function AssignmentDetail() {
    const userSelectedPack =(selectedPack)=>{
        console.log("user selected pack=",selectedPack);
    }
  return (
    <>
    
      <div className={`w-100 d-flex flex-column p-4 overflow-auto  assignments ${isMobileOnly?'h-80':''}`}>
        <div className='d-flex row'>
        <AssignmentInformation iconType="a" heading="90 minutes" description="Maths Sample Papers on Term 1 Syllabus"  />
        <AssignmentInformation iconType="b" heading="Based on latest format by NCERT" description="MCQs,Assertion and Reasoning,Case Based questions based on latest syllabus"  />
        <AssignmentInformation iconType="c" heading="Step by Step Solution" description="Detailed explanation with concept videos for all types of questions" />
        <AssignmentInformation iconType="d" heading="Unlimited Chapter practice" description="Unlimited practice questions for chapterwise tests with adaptive learning"  />
        </div>
        <div className='d-flex flex-column'>
        <AssignmentPack pack="Pack of 1 - Term 1" packDescri="One Maths Sample Paper based on latest CBSE guidelines for Term 1" amount="49"  userSelectedPack={userSelectedPack}/> 
        <AssignmentPack pack="Pack of 5 - Term 1" packDescri="Five Maths Sample Paper based on latest CBSE guidelines for Term 1" amount="199" mRP_INR="245" save_INR="46" userSelectedPack={userSelectedPack}/> 
        <AssignmentPack pack="Pack of 10 - Term 1" packDescri="Ten Maths Sample Paper based on latest CBSE guidelines for Term 1" amount="299" mRP_INR="490" save_INR="191" userSelectedPack={userSelectedPack}/> 
        </div>
    </div>
      
    </>
  );
}
