import React, { Component } from 'react';
import root_url from "../api_url";
import Moment from 'react-moment';
import Dash from './../../reusecomponent/dash';
import $ from 'jquery';
import Loader from 'react-loader-spinner';

class PerformanceReview extends React.Component{
    constructor() {
        super();
        this.state={
            review_practice:[],
            isLoader:false,
        }
        this.getReview = this.getReview.bind(this)
    }

    getReview(e, id, total, per){
        // alert(id);
        localStorage.setItem('practice_id', id);
        localStorage.setItem('total', total);
        localStorage.setItem('percentage', per);
        document.location.href = document.location.origin + '/review';
    }

    componentDidMount() {
         $("#menu-toggle").click(function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
        });

        $("#menu-toggle1").click(function (e) {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");
           $("#wrapper").toggleClass("bgblack");
         });
    }

    componentWillMount() {
        let practice_found = localStorage.getItem('practice_found');
        let auth = 'Token '+localStorage.getItem('key');
        let url = root_url+'api/utils/performance_strength_category/?id='+practice_found;
        this.setState({isLoader: true});
        fetch(url, {
            method: 'GET',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth,
            },

        }).then(response=>response.json()).then(response=>{
                // console.log('review==========',response.data);
              this.setState({
                  review_practice:response.data,

              })
            this.setState({isLoader: false});
        });
    }

    render(){
        return(
            // <dash/>
            <div>
                <Dash/>
                <section className="dash-mid perf">
                <div id="wrapper">
                  <div id="sidebar-wrapper">
                      <div className="btn-tog" id="menu-toggle"></div>
                      <div className="sidebar-nav perf-bdr">
                          <div className="side-nav">
                            <div className="logo dnone"><img src={require("./../../../assests/img/preb-logo.png")} /></div>
                            <div className="ham2" id="menu-toggle1"><img src={require("./../../../assests/img/cross-menu.png")} width="15" /></div>
                            <div className="side-select dnone">
                                <div className="slect-icon"><img src={require("./../../../assests/img/side-b-icon0.jpg")} /></div>
                                <select id="mySelect" data-show-content="true" className="form-control">
                                  <option>CBSE Class 10</option>
                                  <option>CBSE Class Tution</option>
                                  <option>CBSE Extra Class</option>
                                  {/*<option>{goal}</option>*/}
                                </select>
                            </div>
                            <div className="lft-side-menu middle-menu ">
                                <ul className="fix-left-nav">
                                  <li><a href="/dashboard"><img src={require("./../../../assests/img/side-icon-2.png")} /><h6>Practice Mode</h6></a></li>
                                  <li><a href="/perform"><img src={require("./../../../assests/img/side-icon-3-active.png")} /><h6>Performance Management</h6></a>
                                      <span></span>
                                  </li>
                                  <li><a href="#"><img src={require("./../../../assests/img/side-icon-3.png")} /><h6>Contact Us</h6></a></li>
                                </ul>
                            </div>
                          </div>
                      </div>
                    </div>
                  <div id="page-content-wrapper">
                   <div className='container-fluid'>
                    <div id="tab2" className="tabcontent tab2-cont filter-pop ">
                                  <div className="row pt-3">
                                      <div className="col-md-1 offset-11">
                                        {/*<button id="trigger"><i className="fa fa-filter" aria-hidden="true"></i>*/}
                                        {/*</button>*/}
                                        <div id="overlay">
                                            <div id="popup">
                                              <div id="close">X</div>
                                              <div className="row popin">
                                                  <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                          <h3>Filter <span><i className="fa fa-question" aria-hidden="true"></i></span></h3>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-5">
                                                        <div className="col">
                                                          <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" />
                                                              <label className="custom-control-label" htmlFor="customCheck">Select Goal</label>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row goal">
                                                        <div className="col">
                                                          <ul>
                                                              <li><a href="">CBSE Class 10 </a></li>
                                                              <li><a href="">Goal2 </a></li>
                                                              <li><a href="">Goal3</a></li>
                                                          </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-5">
                                                        <div className="col">
                                                          <div className="custom-control custom-checkbox">
                                                              <input type="checkbox" className="custom-control-input" id="customCheck2" name="example1" />
                                                              <label className="custom-control-label" htmlFor="customCheck2">Score</label>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row rangeslide pt-3">
                                                        <div className="col">
                                                          <div className="range-slide">
                                                              <span className="min-value">30</span>
                                                              <div className="range">
                                                                <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                                <span className="current-value">50</span>
                                                              </div>
                                                              <span className="max-value">300</span>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                          <div className="custom-control custom-checkbox">
                                                              <input type="checkbox" className="custom-control-input" id="customCheck3" name="example1" />
                                                              <label className="custom-control-label" htmlFor="customCheck3">Accuracy</label>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row rangeslide pt-3">
                                                        <div className="col">
                                                          <div className="range-slide">
                                                              <span className="min-value">30</span>
                                                              <div className="range">
                                                                <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                                <span className="current-value">50</span>
                                                              </div>
                                                              <span className="max-value">300</span>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                          <div className="custom-control custom-checkbox mb-3">
                                                              <input type="checkbox" className="custom-control-input" id="customCheck4" name="example1" />
                                                              <label className="custom-control-label" htmlFor="customCheck4">Average Speed</label>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row rangeslide pt-3">
                                                        <div className="col">
                                                          <div className="range-slide">
                                                              <span className="min-value">30</span>
                                                              <div className="range">
                                                                <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required />
                                                                <span className="current-value">50</span>
                                                              </div>
                                                              <span className="max-value">300</span>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <div className="row rang-btn">
                                                        <div className="col text-center">
                                                          <button>Go</button>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                        {this.state.isLoader && <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
     }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                  <table className="table" id="data">
                                      <thead>
                                        <tr>
                                            <th>Practice Session</th>
                                            <th>Practice Date</th>
                                            <th>Score</th>
                                            <th>Solves</th>
                                            <th>Accuracy</th>
                                            <th>Avg Speed</th>
                                            <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.review_practice.map((item, key)=>(
                                        <tr className="highlight" style={{height: "10px"}} key={item.practice_id} >
                                            <td>{item.topic}<span>{item.subject} | {item.goal}</span></td>
                                            <td><Moment format="DD/MM/YYYY">{item.practice_date}</Moment></td>
                                            <td>{item.correct_score}/{item.score}</td>
                                            <td>{item.solved}</td>
                                            <td>{item.accuracy}%</td>
                                            <td>{Math.floor( item.speed/ 60)}:{Math.round((item.speed-(Math.floor( item.speed/ 60))*60))} min</td>
                                            <td><a onClick={(e) => this.getReview(e,item.practice_id, item.total, item.accuracy)}>View</a></td>
                                        </tr>)
                                        )}
                                      </tbody>
                                  </table>
                        </div>
                    </div>
                  </div>
                </div>
                </section>
            </div>
        )
    }
}

export default PerformanceReview