import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader'
import root_url from './../components/api_url';
import MultiSelectAll from "./multiselect";
import TabComponent from './TabComponent';

export class DesktopDashboard extends Component {

    constructor(props) {
        super(props);
        this.timed_mode_instruction_string = `1.You have selected <font  style="font-weight: bold;" >timed</font> mode  and you will get <font  style="font-weight: bold;" >40 minutes </font> to solve as many questions as you can in that time. <br />
                                 2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br />
                                 3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                 4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next"</font>. Once submitted answers to the questions cannot be changed.<br />
                                 5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br /> <br />
                                 Pro-tip: Always focus on accuracy before speed.`;

        this.non_timed_mode_instruction_string = `1.You have selected a <font  style="font-weight: bold;" > non-timed </font> practice mode. As the name suggests, there is no limit on time and you can practice as many questions you would like.<br />
                                                2.This is an adaptive practice session, so we will keep adjusting your level based on your accuracy and speed. There is no negative marking for incorrect questions, but we recommend you skip the question if you are not sure about the answer.<br /> 
                                                3.You cannot move back and forth between questions, but you can review them all after you end your practice.<br />
                                                4.To submit an answer to a question - first, select the right option and then click <font  style="font-weight: bold;" >"Submit and Next" </font>. Once submitted answers to the questions cannot be changed.<br />
                                                5.If you don't know the answer to a question you can skip it by clicking on <font  style="font-weight: bold;" >"Skip Question"</font>. Once skipped, you <font  style="font-weight: bold;" >cannot</font> come back to it, but you see the solution to it later after you end the practice.<br />
                                                6.You can also see the solution to questions in this mode during the course of the test by clicking on <font  style="font-weight: bold;" >"See Solution" </font>, however, we recommend reviewing them together at the end of the test.<br />
                                                7.If you select an option before clicking on "See solution" that question will be counted as submitted, but if you see the solution without selecting any option, that question will be counted as skipped.`;

        this.state = {
            otp: '',
            togl: 'toggled',
            full_name_error: '',
            validation_error: '',
            email_error: '',
            phone_error: '',
            feedback_success: '',
            comments_error: '',
            full_name: '',
            issue_type: 'Issues in using PrepHub',
            comments: '',
            contact_phone: '',
            contact_email: '',
            send_me: 'false',
            userSelectedTopics: '',
        }

        this.sidebarOnToogled = () => {
            this.setState({ togl: '' });
        }
        this.sidebarOffToogled = () => {

            this.setState({ togl: 'toggled' });
        }
        this.updateSelectedTopics = () => {
            this.setState({ userSelectedTopics: localStorage.getItem("selected_topic") });
        }
        this.changeUserdata = this.changeUserdata.bind(this);
        this.sendContactdata = this.sendContactdata.bind(this);
        this.send_me_check = this.send_me_check.bind(this);
    }
    //----------this function used for get user data for feedback purpose by prep prep 1st-------------
    changeUserdata(event) {
        this.setState({ feedback_success: "" });
        if (event.target.name == "full_name") {
            this.setState({ full_name: event.target.value });
            if (event.target.value == "") {
                this.setState({ full_name_error: "Invalid. Please enter name" });
            }
            else {
                this.setState({ full_name_error: "" });
            }
        }
        if (event.target.name == "emails") {
            this.setState({ contact_email: event.target.value });
            if (event.target.value == "") {
                this.setState({ email_error: "Invalid. Please enter email" });
            }
            else {
                this.setState({ email_error: "" });
            }

        }
        if (event.target.name == "phone") {
            this.setState({ contact_phone: event.target.value });
            if (event.target.value == "") {
                this.setState({ phone_error: "Invalid. Please enter phone number" });
            }
            else {
                this.setState({ phone_error: "" });

            }
        }
        if (event.target.name == "issue_type") {

            this.setState({ issue_type: event.target.value });
        }
        if (event.target.name == "comments") {
            this.setState({ comments: event.target.value });
            if (event.target.value == "") {
                this.setState({ comments_error: "Invalid. Please enter comment" });
            }
            else {
                this.setState({ comments_error: "" });
            }
        }
    }

    //----------this function used for send user data for feedback purpose by prep prep 1st-------------
    sendContactdata() {
        let contact_data = {
            full_name: this.state.full_name,
            contact: this.state.contact_phone,
            email: this.state.contact_email,
            issue_type: this.state.issue_type,
            comments: this.state.comments,
            send_me: this.state.send_me
        };
        if (this.state.full_name_error != "" || this.state.full_name == "") {
            this.setState({ full_name_error: "Invalid. Please enter name" });
        }
        else if (this.state.email_error != "" || this.state.contact_email == "") {
            this.setState({ email_error: "Invalid. Please enter email" });
        }
        else if (this.state.phone_error != "" || this.state.contact_phone == "") {
            this.setState({ phone_error: "Invalid. Please enter phone" });
        }

        else {
            this.setState({ validation_error: "" });
            this.setState({ feedback_success: "Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours." });
            let url = root_url + "Contact_Us/";
            let auth = 'Token ' + localStorage.getItem('key');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth

                }, body: JSON.stringify(contact_data)
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
            });
        }
    }
    //----------this function used for send me checked or not -------------
    send_me_check(e) {
        this.setState({ feedback_success: "" });

        if (e.target.checked) {
            this.setState({ send_me: "true" });
        }
        else {
            this.setState({ send_me: "false" });
        }
    }


    render() {
        return (
            <>
                <Popup />
                <section className="dash banner-dash">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 dash-mob">
                                <div className="logo"><img src={this.props.logo} /></div>
                            </div>
                            <div className="col-md-2 offset-8">
                                <div className="row wxh">
                                    <div className="col-md-6">
                                        <div className="dropdown-notification notification">
                                            <i className="fa fa-bell-o" aria-hidden="true"><span>12</span></i>
                                            <div className="dropdown-content">
                                                <div className="top-noti">
                                                    <img src={require('./../../assests/img/noti-icon-0.jpg')} />12 Notification <span>Clear All</span></div>
                                                <div className="bot-noti">
                                                    <ul>
                                                        <li><img src={require('./../../assests/img/noti-icon-1.jpg')} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>Sales dashbaord have</h5>
                                                            <h6>4 Minutes Ago</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dash">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-2 dash-mob">
                                <div className="logo"><img src={this.props.logo} /></div>
                            </div>
                            <div className="col-md-2 offset-8">
                                <div className="row wxh">
                                    <div className="col-md-6">
                                        <div className="dropdown-notification notification">
                                            <i className="fa fa-bell-o" aria-hidden="true"><span>12</span></i>
                                            <div className="dropdown-content">
                                                <div className="top-noti">

                                                    <img src={require('./../../assests/img/noti-icon-0.jpg')} />12 Notification <span>Clear All</span></div>
                                                <div className="bot-noti">
                                                    <ul>
                                                        <li><img src={require('./../../assests/img/noti-icon-1.jpg')} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>Sales dashbaord have</h5>
                                                            <h6>4 Minutes Ago</h6>
                                                        </li>

                                                        <li><img src={require("./../../assests/img/noti-icon-2.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                        <li><img src={require("./../../assests/img/noti-icon-3.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                        <li><img src={require("./../../assests/img/noti-icon-4.jpg")} />
                                                            <h4>T Ritika Singh</h4>
                                                            <h5>You have done a great job! #db</h5>
                                                            <h6>1 hour Ago</h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="dropdown notification profile-pic">
                                            <button type="button" data-toggle="dropdown">
                                                <img src={require("./../../assests/img/profile-pic.png")} />
                                            </button>
                                            <div className="dropdown-menu">
                                                <Link className="dropdown-item" to="#"><i className="fa fa-user-o" aria-hidden="true"></i>My Account</Link>
                                                <Link data-toggle="modal" data-target="#contact_modal" className="dropdown-item pointer" to="#" onClick={this.sidebarOnToogled}><i className="fa fa-envelope-open" aria-hidden="true"></i> Contact Us</Link>
                                                <Link className="dropdown-item" to='#' onClick={this.props.userLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>Log Out</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dash-mid banner-pop" >
                    <div id="wrapper" className={this.state.togl}>
                        <div id="sidebar-wrapper">
                            <div className="btn-tog" id="menu-toggle"> </div>
                            <div className="sidebar-nav perf-bdr">
                                <div className="side-nav">
                                    <div className="dashboard-logo">
                                        <img src={this.props.logo} />
                                    </div>

                                    <div className="side-select">
                                        <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                        <select id="mySelect" data-show-content="true" className="form-control">
                                            <option>{this.props.goal_name}</option>
                                        </select>
                                    </div>

                                    <div className="lft-side-menu">
                                        <ul>
                                            <li  ><Link to="/assignments" ><img src={require("./../../assests/img/marks.jpg")} />Assignments</Link></li>
                                            <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2.png")} />Practice Mode</Link></li>
                                            <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} />Performance Management</Link></li>
                                            <li data-toggle="modal" data-target="#contact_modal" className="pointer"><Link><img src={require("./../../assests/img/mail.png")} style={{ height: "30px", width: "30px" }} />Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--   Right-side-page --> */}
                        <div className="pt-0" id="page-content-wrapper">
                            <div className="container-fluid">
                                {/* <!--     <div className="panel-open">
                                <button className="openbtn" onClick="openNav()"><i className="fa fa-angle-left" aria-hidden="true"></i></button>
                                </div> --> */}
                                {/* <!--     Right-side-panel --> */}
                                <div className="row right-s-pan">
                                    <div className="col">
                                        <div id="mySidepanel" className="sidepanel">
                                            <Link to="javascript:void(0)" className="closebtn" onClick="closeNav()">
                                                <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                                            <div className="scroe-c">
                                                <div className="scroec-tit"> Score</div>
                                                <img src={require("./../../assests/img/score-card.png")} />
                                            </div>
                                            <div className="score-num pt-5">
                                                <div className="scroe-tit"> <img src={require("./../../assests/img/marks.jpg")} /> <span>Review</span></div>
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li>8</li>
                                                    <li>9</li>
                                                    <li>10</li>
                                                    <li>11</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--     Right-side-panel-end --> */}
                                <div className="row ">
                                    <div className="col-lg-12 mb-d-80 pt-5 mt-5">
                                        {this.state.isLoader && <div style={{
                                            width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                        }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                        <div className="row">
                                            <div className="col-md-12 pl-5 pr-5 mt-n5">

                                                {this.props.userGoalLang.goal != undefined && this.props.userGoalLang.language != undefined ?
                                                    <TabComponent userGoalLang={this.props.userGoalLang} updateSelectedTopics={this.updateSelectedTopics} />
                                                    : <div style={{ marginTop: '200px' }}><Loader color="primary" height="200px" width="200px" divHeight="h-100" /></div>}
                                                {/*<div className="row pt-4">
                                                    <div className="col text-center">
                                                        <label className="switch" style={{ margin: '0px' }}>
                                                            <input type="checkbox" className="switch-input" onChange={this.props.timedChange} defaultChecked={"true"} />
                                                            <span className="switch-label" data-on="timed" data-off="Off"></span>
                                                            <span className="switch-handle"></span>
                                                        </label>
                                                    </div>
                                            </div>
                                             <div className="row pt-5 dash1">
                                                    <div className="col-md-12 text-center">
                                                        <div className="common-btn pt-3"><button data-toggle="modal" data-target="#myModal2" onClick={this.sidebarOnToogled} > Begin Practice</button>
                                                        </div>
                                                    </div>
                                        </div>*/}
                                                <div className="hidethisdiv"><button data-toggle="modal" data-target="#myModalVerified" id='verification_popup'></button></div>
                                                <div className="row mob-side-nav">
                                                    <div className="col">
                                                        <ul>
                                                            <li ><Link to="/assignments"><img src={require("./../../assests/img/marks.jpg")} />Assignments</Link></li>
                                                            <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2.png")} />Practice Mode</Link></li>
                                                            <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} />Performance Management</Link></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer-logo"><img src={this.props.footer_logo} /></div>
                <div className="modal fade" id="myModal2" onClick={this.resumePractice}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mtcolor "><strong>Practice Test </strong></h5>
                                <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                                    <img src={require("./../../assests/img/cross.jpg")} />
                                </button>
                            </div>
                            <div className="modal-body">

                                <p className="text-dark"> Welcome <strong>{this.props.name == "NULL" ? "User" : this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)} </strong> to your practice session for {this.state.userSelectedTopics}. Please read the following instructions before you start your practice test,</p>
                                <p className="text-dark"> <strong>Instructions</strong></p>
                                <p className="text-dark" dangerouslySetInnerHTML={{ __html: this.props.instruction_msg }} />
                                <div className="row justify-content-center">
                                    <h1 id="countdown" className="text-primary "><strong></strong></h1>
                                </div>
                                <div className="row mt-1" id="start_button">
                                    <div className="col text-center" >
                                        <div className="common-btn" ><button onClick={this.props.beginPractice}>Start</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="contact_modal" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-body contact_modal-body">
                                <div className="row mt-1 justify-content-between">
                                    <h5 class="modal-title text-dark ml-2"><strong>Report / Feedback </strong></h5>
                                    <button type="button" className="close" id="instructions_close" data-dismiss="modal" onClick={this.sidebarOffToogled}>
                                        <img src={require("./../../assests/img/cross.jpg")} id="close_modal" />
                                    </button>
                                </div>
                                <div className="row">
                                    <p className="text-dark ml-3">Facing issues in using PrepHub? Let us know your feedback in the form below and our team will get in touch with you in the next 48 hours.</p>
                                </div>
                                <div className="row mt-3">
                                    <p className="text-dark ml-3"> Please complete all fields.</p>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <p className="text-danger">{this.props.validation_error}</p>
                                        <p className="text-success">{this.state.feedback_success}</p>

                                        <div className="form-group ">

                                            <label for="exampleFormControlInput1" className="text_color">Name</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="full_name" placeholder="" value={this.state.full_name} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.full_name_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Email</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="emails" placeholder="" value={this.state.contact_email} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.email_error}</p>
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleFormControlInput1" className="text_color">Phone</label>
                                            <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="phone" placeholder="" value={this.state.contact_phone} onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.phone_error}</p>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputState" className="text_color">Issue type</label>
                                            <select id="inputState" class="form-control form-control-sm  border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="issue_type" onChange={this.changeUserdata} >

                                                <option value="Issues in using PrepHub" className="text-primary" selected>Issues in using PrepHub</option>
                                                <option value="New feature request" className="text-primary">New feature request</option>
                                                <option value="Others" className="text-primary">Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1" className="text_color">Comments</label>
                                            <textarea className="form-control  border-top-0 text-dark" id="exampleFormControlTextarea1" rows="3" name="comments" onChange={this.changeUserdata} />
                                            <p className="text-danger">{this.state.comments_error}</p>

                                        </div>
                                    </div>

                                </div>
                                <div className="row justify-content-between">

                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="send_me_checkbox" onChange={this.send_me_check} />
                                        <label className="form-check-label text-dark" for="send_me_checkbox">Send me a copy of my response</label>
                                    </div>
                                    <div>
                                        <button type="button " className="btn btn-primary  btn-sm" onClick={this.sendContactdata}>Send</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}




export class Popup extends React.Component {
    constructor() {
        super();
        this.state = {
            errorMessage: '',
            successMessage: '',
            isLoader: false,
        }
        this.sendVerification = this.sendVerification.bind(this)
    }
    sendVerification(e) {
        e.preventDefault();
        let auth = 'Token ' + localStorage.getItem('key');
        let email = localStorage.getItem('email')
        let url = root_url + 'api/profile/send-verification/?email=' + email;
        this.setState({ isLoader: true });
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth,
            },

        }).then(response => response.json()).then(response => {
            this.setState({ isLoader: false });
            if (response.error) {
                this.setState({
                    errorMessage: response.error,
                });
            }
            else {
                this.setState({
                    successMessage: response.detail,
                    errorMessage: '',
                });
                document.getElementById("resend").innerHTML = 'Re-send Verification Email'
            }
        })
    }

    render() {
        return (
            <div>
                <div className="container share-mod pop_verified">
                    <div className="modal " id="myModalVerified">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" id="dismiss_log" className="close" data-dismiss="modal">
                                        <img src={require("./../../assests/img/cross-menu.png")} width="16px" />
                                    </button>
                                    <div className="row">
                                        <div className="col">
                                            <div className="row  pt-4">
                                                <div className="col new">
                                                    <p className="popup-text-color">Your account verification is pending. Please verify your email address to continue practice.</p>
                                                    {this.state.isLoader && <div style={{
                                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                                    }}><Loader type="Circles" color="#5d39db" height={80} width={80}>{this.state.isLoader}</Loader></div>}
                                                    {this.state.errorMessage && <span className="error"> {this.state.errorMessage} </span>}
                                                    {this.state.successMessage && <span className="success"> {this.state.successMessage} </span>}
                                                    <div className="common-btn pt-5"><button id="resend" onClick={this.sendVerification}>Send Verification Email</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DesktopDashboard