import React from 'react';
import './../../assests/css/style.css';

export default function PhotosList(props) {
    const { list } = props;

    return (
        <div className="flex">
            <div className="flex">
            {
                list.map((l,i) => 
                <div className="user-circular-images-container">
                    <img className="user-circular-images" style={{right: -(i+1)*15} }key={l} src={require(`../../assests/img/paidusers/${l}.webp`)} />
                </div>
                )
            }
                <div className="user-circular-images-container">
                    <div className="user-circular-images plus-image" style={{right: -(list.length+1)*15} } />
                </div>
            </div>
        </div>
    );
}
