import React, { Component } from 'react';
import Loader from './Loader'
import DesktopTopbar from './DesktopTopbar'
import LeftSideBar from './LeftSideBar';
import MainbodyPanel from './MainbodyPanel';
import Dash from './../reusecomponent/dash';


export default function EndPracticeDesktop(props) {
    return (
        <>
        <div className="d-flex flex-column w-100" style={{ height: '100vh' }} >
            <Dash />
            <div className='d-flex '>
                <div className='h-100'><LeftSideBar activeTab="Review" toggled={true}/></div>
                <div className='h-100 w-100 mt-5'> 
                {props.showReview?<MainbodyPanel childComponent="TestReview" solved={props.solved} score={props.score} accuracy={props.accuracy} totalTime={props.totalTime} minutes={props.minutes} seconds={props.seconds} message={props.message} />
                :<div style={{ marginTop: '200px' }}><Loader color="primary" height="200px" width="200px" divHeight="h-100" /></div>}</div>
            </div>

        </div>

        </>
    );
}
