import React from 'react';
import Clock_logo from "./../../assests/img/pop-icon4.jpg";
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'mobile-device-detect';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export default function SamplePaperDetail(props) {
    const { samplePaper, index } = props;
    const calculateTime = (given_time) => {
        let now = new Date(given_time.slice(0, -1));
        let calculadteTime = new Intl.DateTimeFormat('default', { hour12: true, hour: 'numeric', minute: 'numeric' }).format(now);
        let calculatedDate = now.getDate() + ' ' + months[now.getMonth()]+' '+now.getFullYear();
        let calculadteTimesplte = calculadteTime.split(":");
        if (calculadteTimesplte[0].length < 2) {
            calculadteTime = '0' + calculadteTime;
        }
        return calculatedDate 
    }
    const calculateTotalMinutes = (given_hour, givenMinutes) => {
        let hourInMinutes = given_hour * 60;
        let calculatedTotalMinutes = hourInMinutes + givenMinutes;
        return calculatedTotalMinutes
    }
    const storeMinutesTopicsSamplePaperIDData = (given_hour, given_minutes, given_topics, given_SamplePaperID, given_sample_paper_product_info, given_topic_ids, given_index) => {
        let samplePaperName = `sample_paper_${index}`
        localStorage.setItem("samplePaperTimeInMinutes", String(calculateTotalMinutes(given_hour, given_minutes)))
        localStorage.setItem('sample_papers_topics_name', given_topics)
        localStorage.setItem('samplePaperID', given_SamplePaperID)
        localStorage.setItem('samplePaperQuestionCount', 0)
        localStorage.setItem('samplePaperTopicIds', given_topic_ids)
        localStorage.setItem('samplePaperProdInfo', given_sample_paper_product_info)
        localStorage.setItem('samplePaperName', samplePaperName)
        localStorage.setItem('topic_name',given_topics)
        localStorage.setItem('practice_id',samplePaper.sample_paper_practice_id)
    }

    return (
        <>
            <div className={`border mt-2  ${!isMobileOnly?'col-md-5 col-lg-5 p-0 ml-5 mr-3':''}`}>
               <div className="col-12 d-flex mt-3"><div className="text-dark font-weight-bold d-flex">{`Sample Paper ${index}`}</div> {samplePaper.sample_paper_status ? <div className="bg-success ml-3 pl-2  pr-2 pt-0 pb-0 text-white"><small>Completed</small></div> : ''}</div>
                <div className="d-flex justify-content-between">
                    <div className="pb-3 pl-3">
                        <div className="text-secondary"><small>{`#${samplePaper.assignment_code}`}</small></div>
                        <div className="text-secondary"><small>{`Start: ${calculateTime(samplePaper.assignment_startdate)}`}</small></div>
                        <div className="text-secondary"><small>{`Due: ${calculateTime(samplePaper.assignment_enddate)}`}</small></div>
                    </div>
                    <div className="mt-n3 pr-3 d-flex flex-column justify-content-center align-items-center" >
                        <div className="text-dark" ><img src={Clock_logo} height="50px" width="50px" /></div>
                        <div className="text-dark mt-1 font-weight-bold" style={{ fontSize: "14px" }}>{`${calculateTotalMinutes(samplePaper.assignment_hour, samplePaper.assignment_minutes)} Minutes`}</div>
                    </div>
                </div>

                <div className="col-12 d-flex justify-content-end pt-1 pb-1 border border-top ">
                    {!samplePaper.sample_paper_status ? <Link to="/test-start-sample-paper/"><div className="text-secondary mt-1" style={{ cursor: "pointer" }} onClick={() => { storeMinutesTopicsSamplePaperIDData(samplePaper.assignment_hour, samplePaper.assignment_minutes, samplePaper.assignment_topics, samplePaper.sample_paper_id, samplePaper.sample_paper_product_info, samplePaper.assignment_topic_ids, index) }}>View Instructions</div></Link> : ''} &nbsp;&nbsp;
                   <div className="text-dark ">
                        <Link to={`${!samplePaper.sample_paper_status ? `/test-start-sample-paper/` : `/result-summary?practiceId=${samplePaper.sample_paper_practice_id}`}`}><button type="button" class="btn btn-sm rounded-sm text-white" style={{ backgroundColor: "#7F72EE" }} onClick={() => { storeMinutesTopicsSamplePaperIDData(samplePaper.assignment_hour, samplePaper.assignment_minutes, samplePaper.assignment_topics, samplePaper.sample_paper_id, samplePaper.sample_paper_product_info, samplePaper.assignment_topic_ids, index) }}>{`${!samplePaper.sample_paper_status ? 'Start' : 'Review'}`}</button></Link>
                    </div>
                </div>
            </div>&nbsp;
        </>
    )

}

