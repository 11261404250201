import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export class VerificationInfo extends Component {
    render() {
        return (
            <div>
                <section className="forgot-pass">
                <div className="container">
                    <div className="col-md-5 m-auto">
                        <div className="forgot-p">
                            <div className="row">
                            <div className="col">
                                <div className="close"><img src={require("./../../assests/img/cross.jpg")} /></div>
                            </div>
                            </div>
                            <div className="row pt-2">
                            <div className="col">
                                <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col">
                                <div className="divh3">
                                    <h3>You need to verify your account before start any new Practice. For more details please contact your Administration.</h3>
                                </div>
                            </div>
                            </div>

                            <div className="row sign-in pt-5">
                            <div className="col-md-12">
                              <div className="common-btn pt-5"><button><Link to="/dashboard">Dashboard</Link></button></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        )
    }
}

export default VerificationInfo