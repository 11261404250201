import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import root_url from "./api_url";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";
import OTPComponent from './OTPComponent'


export class ForgotPasswordConfirm extends Component {
    constructor() {
        super();

        this.state = {
            new_password1: '',
            new_password2: '',
            uid: '',
            token: '',
            isLoader: false,
            errorMessage: '',
            successMessage: '',
            otp: '',
            mobile: '',
            otperrorMessage: '',
            pass1errorMessage: '',
            pass2errorMessage: '',
            otperrorMessage: '',
            counter: 0,
            count: 30,
            showPaasword: false
        }

        this.passwordChange = this.passwordChange.bind(this);
        this.confirmPasswordChange = this.confirmPasswordChange.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.otpChange = this.otpChange.bind(this);
        this.loginUser = this.loginUser.bind(this);
        this.countdown = this.countdown.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.showPaasword = this.showPaasword.bind(this);
    }
    passwordChange(e) {
        this.setState({
            new_password1: e.target.value
        });
    }
    confirmPasswordChange(e) {
        this.setState({
            new_password2: e.target.value
        });
    }

    otpChange(given_otp) {
    this.setState({otp: given_otp});
    }

    componentDidMount() {
        localStorage.setItem('confirm_password_resend_otp_hit', 0);
        const query = new URLSearchParams(this.props.location.search);
        const phone = query.get('phone');
        const uid = this.props.match.params.uid;
        const token = this.props.match.params.token;
        this.setState({
            uid: uid,
            token: token,
        })
        this.setState({
            successMessage:'OTP sent your mobile'
        })
        if (phone) {
            this.setState({
                mobile: phone
            })
        }
    }

    submitHandler(e) {
        this.setState({
            successMessage:''
        })
        this.setState({
            otperrorMessage: ''
        })
        this.setState({
            pass1errorMessage: ''
        })
        this.setState({
            pass2errorMessage: ''
        })
        this.setState({
            otperrorMessage: ''
        })
        e.preventDefault();
        let password1 = this.state.new_password1;
        let password2 = this.state.new_password2;
        let mobile = this.state.mobile;
        let otp=this.state.otp;
        if (otp === '') {
            this.setState({
                otperrorMessage: 'Please enter otp'
            })
        }
        else if (otp.length != 6) {
            this.setState({
                otperrorMessage: 'Please filled all otp numbers'
            })
        }
        else if (password1 === '') {
            this.setState({
                pass1errorMessage: 'Please enter your password'
            })
        }
        else if (password1.length < 8) {
            this.setState({
                pass1errorMessage: 'Minimum 8 characters'
            })
        }
        else if (password2 === '') {
            this.setState({
                pass2errorMessage: 'Please enter your confirm password'
            })
        }
        else if (password1 !== password2) {
            this.setState({
                errorMessage: 'Your password and confirm password are not same.'
            })
        }
        else {
            //let url = root_url + 'api/accounts/password/reset/confirm/';
            let url = root_url + 'password_reset_confirm/';
            this.setState({ isLoader: true });
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    new_password1: password1,
                    new_password2: password2,
                    mobile: mobile,
                    otp: otp,
                    user_entry_type: 'Password_Reset'
                })
            }).then(response => {
                this.setState({ isLoader: false });
                if (response.status === 400) {
                    return response.json();
                }
                else if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 201) {
                    return response.json();

                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.status == 200) {
                    this.setState({
                        errorMessage: response.message,
                    });
                }
                if (response.status == 201) {
                    this.setState({
                        errorMessage: '',

                    });
                    localStorage.setItem('UserPhone', mobile);
                    localStorage.setItem('UserPass', password1);
                    this.loginUser();
                }

            })


        }
    }
    loginUser() {
        this.setState({ errorMessage: '' });
        let mobile = this.state.mobile ? this.state.mobile : localStorage.getItem('UserPhone');
        let password = this.state.new_password1 ? this.state.new_password1 : localStorage.getItem('UserPass');
        localStorage.setItem('UserPass', password);
        localStorage.setItem('UserPhone', mobile);
        let url = root_url + 'api/accounts/login/';
        if (mobile == '') {
            this.setState({ errorMessage: 'Please enter your phone again' });
        }
        else if (password == '') {
            this.setState({ errorMessage: 'Please enter your password  again' });
        }
        else {
            this.setState({ isLoader: true });
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: mobile,
                    password: password,
                })
            }).then(response => {
                if (response.status === 400) {
                    return response.json();
                }
                else if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
                if (response.email) {
                    this.setState({ isLoader: false });
                    this.setState({ errorMessage: response.email[0] });

                }
                else if (response.key) {
                    let key = response.key;
                    localStorage.setItem('key', key);
                    let url = root_url + 'api/profile/profile/';
                    let auth = "Token " + localStorage.getItem('key')
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': auth
                        },

                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    }).then(response => {
                        if (response.results[0].access_code != null && response.results[0].country != null && response.results[0].language != null) {
                            document.location.href = document.location.origin + '/dashboard';
                        }

                    })
                }
                else {
                    this.setState({
                        errorMessage: response.non_field_errors
                    })
                    this.setState({ isLoader: false });
                }
            })
        }

    }

    


    countdown() {
        let myInterval = setInterval(() => {
            if (this.state.count > 0) {
                this.setState({
                    counter: this.state.counter - 1
                })
                this.state.count = this.state.count - 1;
            }
            if (this.state.count == 0) {
                clearInterval(myInterval);
            }
        }, 1000)
    }

    resendOTP() {
        let resend_hit = localStorage.getItem('confirm_password_resend_otp_hit');
        localStorage.setItem('confirm_password_resend_otp_hit', parseInt(resend_hit) + 1);
        let mobile = this.state.mobile ? this.state.mobile : localStorage.getItem('UserPhone');
        this.setState({
            counter: 30
        });
        this.setState({
            count: 30
        });
        this.countdown()
        let url = root_url + `resend_mobile_otp/${mobile}/${'Password_Reset'}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return false;
            }
        }).then(response => {
            this.setState({
                successMessage: ''
            })

        })

    }

    showPaasword() {
        if (this.state.showPaasword) {
            this.setState({
                showPaasword: false
            });
        }
        else {
            this.setState({
                showPaasword: true
            });
        }
    }
    render() {
        return (
            <div>
                <section className="forgot-pass">
                    <div className="container pl-0 pr-0 w-100 ">
                        <div className=" col-md-5 mx-auto">
                            <div className="forgot-p ">
                
                                <div className="row pt-2">
                                    <div className="col">
                                        <div className="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divh3">
                                            <h3>Update Password </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center pt-2" style={{ height: '50px' }}>
                                    {this.state.counter != 0 ? <div className=" text-success ">Resend OTP in 00:{this.state.counter < 10 ? '0' + this.state.counter : this.state.counter}</div> : <div className=" text-primary"></div>}
                                </div>
                                <div className="row d-flex justify-content-between  pl-1 pr-1 ">
                                    <div className="text-secondary pl-3"><h6>OTP</h6></div>
                                    {this.state.counter != 0 || localStorage.getItem('confirm_password_resend_otp_hit') > 2 ?
                                        <div className="text-secondary pr-3" style={{ cursor: 'not-allowed' }}><h6>Resend OTP</h6></div>
                                        : <div className="text-primary pr-3"  ><h6 style={{ cursor: 'pointer' }} onClick={this.resendOTP}>Resend OTP</h6></div>}
                                </div>
                                <div className="row otp-sec center pl-1 " style={{ marginTop: '-10px' }}>
                                    <div className="col">
                                        <div className="otp ">
                                        <OTPComponent otpChange={this.otpChange} />
                                        </div>
                                        <div className="d-flex justify-content-center mt-2"><h6> {this.state.otperrorMessage && <span className="error"> {this.state.otperrorMessage} </span>}</h6></div>

                                    </div>
                                </div>
                               
                                <div className="row form-group mt-2 pr-2" >
                                        <div class="col my-1">
                                            <label className="sr-only" for="inlineFormInputGroupUsername">Password</label>
                                            <div className="input-group">
                                            <input type={`${this.state.showPaasword ? 'text' : 'password'}`} placeholder="Enter password" className="form-control form-control-sm border-secondary border-top-0 border-left-0 border-right-0 text-dark" id="id_password1" onChange={this.passwordChange} required />
                                                <div className="input-group-prepend bg-white">
                                                    <div className="input-group-text bg-white border-top-0 border-left-0 border-right-0 border-secondary"><i  className={`${this.state.showPaasword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}  aria-hidden="true" onClick={this.showPaasword}></i></div>
                                                </div>
                                            </div>
                                        </div>

                                </div>

                                <div className="d-flex justify-content-center "><h6> {this.state.pass1errorMessage && <span className="error text-center"> {this.state.pass1errorMessage} </span>}</h6></div>


                                    <div className="row form-group pl-1 pr-1 pt-1" >
                                        <div className="col-md-12 pt-1 m-auto" /*data-aos="fade-left" */>
                                            <input type={`${this.state.showPaasword ? 'text' : 'password'}`} placeholder="Enter confirm password" className="form-control form-control-sm border-secondary border-top-0 border-left-0 border-right-0" id="id_password2" onChange={this.confirmPasswordChange} required />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center "><h6>{this.state.pass2errorMessage && <span className="error"> {this.state.pass2errorMessage} </span>}</h6></div>
                                    {this.state.isLoader && <div style={{
                                        width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Loader type="Circles" color="#5d39db" height={50} width={50}>{this.state.isLoader}</Loader></div>}
                                    <div className="d-flex justify-content-center mt-2"><h6> {this.state.errorMessage && <span className="error"> {this.state.errorMessage} </span>}</h6></div>
                                    <div className="d-flex justify-content-center mt-2">{this.state.successMessage && <span className="success"> {this.state.successMessage} </span>}</div>
                                    <div className="row sign-in pt-1">
                                        <div className="col-md-12">
                                            <div className="common-btn"><button onClick={this.submitHandler}>Submit</button></div>
                                        </div>
                                    </div>
                                    <div className="row sign-in">
                                        <div className="col-md-12">
                                            <div className="common-btn pt-1 text-secondary"> Remember password? <a><Link to="/">Sign In</Link></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        )
    }
}

export default withRouter(ForgotPasswordConfirm);