import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TopicListMob from './TopicListMob';
import React from 'react';
import { useEffect, useState } from 'react';
import { filters } from '../../config/goalLangSubTopicFilters'
import { Tab, Tabs, makeStyles, } from "@material-ui/core";
import { useTheme } from '@mui/material/styles';
import { isMobileOnly } from 'mobile-device-detect';


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '10px',
    fontSize: '12px',
    textTransform: "none",
    color: "black",
  },
  selected: {
    background: "#747AF9",
    color: "white",
    border: '1px solid #747AF90',
    borderRadius: '90px',
    height: '30px',
    fontSize: '12px',
  },
  selectedDesktop: {
    height: '30px',
    fontSize: '12px',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabComponent(props) {
  const theme = useTheme();
  const classes = useStyles();
  const userGoalLang = props.userGoalLang
  const [value, setValue] = useState(0);
  const [allTopics, setAllTopics] = useState([]);
  const [caseBasedTopics, setCaseBasedTopics] = useState([]);
  const [MCQTopics, setMCQTopics] = useState([]);
  const [AssertionAndReasoningTopics, setAssertionAndReasoningTopics] = useState([]);
  const [topicTypes, setTopicTypes] = useState(['All', 'MCQ', 'Case Based', 'Assertion and Reasoning']);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let selectedTopicType = topicTypes[newValue]
    localStorage.setItem('userSelectedQuestionsType', selectedTopicType);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const selectedTopicsSaved = () => {
    let topics_list = JSON.parse(localStorage.getItem('topic_name'));
    let topics_length = topics_list.length;
    if (topics_length > 1) {
      if (topics_list.includes('all')) {
        localStorage.setItem("selected_topic", 'All Topics');
      }
      else {
        let remaning_topic = topics_length - 2;
        let topics = topics_list[0] + ', ' + topics_list[1] + ' and ' + String(remaning_topic) + ' other';
        localStorage.setItem("selected_topic", topics_list.join());
      }
    }
    else {
      localStorage.setItem("selected_topic", topics_list[0]);
    }
    props.updateSelectedTopics()
  }

  useEffect(() => {
    let filtered_all_topic = filters['topics']['All']
    let filtered_MCQ_topic = filters['topics']['MCQ']
    let filtered_CaseBased_topic = filters['topics']['Case Based']
    let filtered_AssertionandReasoning_topic = filters['topics']['Assertion and Reasoning']
    let MCQTopicData = filtered_MCQ_topic[userGoalLang.goal][userGoalLang.language]
    let allTopicData = filtered_all_topic[userGoalLang.goal][userGoalLang.language]
    let CaseBasedTopicData = filtered_CaseBased_topic[userGoalLang.goal][userGoalLang.language]
    let AssertionandReasoningTopicData = filtered_AssertionandReasoning_topic[userGoalLang.goal][userGoalLang.language]
    setAllTopics(allTopicData['Maths']);
    setCaseBasedTopics(CaseBasedTopicData['Maths'])
    setMCQTopics(MCQTopicData['Maths'])
    setAssertionAndReasoningTopics(AssertionandReasoningTopicData['Maths'])
  }, [])

  return (
    <>
      <Box sx={{ borderBottom: isMobileOnly ? 0 : 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isMobileOnly ? 'scrollable' : 'standard'}
          scrollButtons="on"
          style={{ minHeight: '10px' }}
          classes={classes}
          centered={isMobileOnly ? 'false' : 'true'}
          TabIndicatorProps={{ style: { background: isMobileOnly ? 'None' : '' } }}
          indicatorColor="primary"
        >:
          {
            topicTypes.map((topicType, index) => (
              <Tab label={topicType} {...a11yProps(index)} classes={{
                selected: isMobileOnly ? classes.selected : classes.selectedDesktop, root: classes.root
              }} />
            ))
          }
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {allTopics.length > 0 ? <TopicListMob topic_dropdown={allTopics} changeBeginPracticeStatus={props.changeBeginPracticeStatus} selectedTopicsSaved={selectedTopicsSaved} /> : null}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {MCQTopics.length > 0 ? <TopicListMob topic_dropdown={MCQTopics} changeBeginPracticeStatus={props.changeBeginPracticeStatus} selectedTopicsSaved={selectedTopicsSaved} /> : null}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {caseBasedTopics.length > 0 ? <TopicListMob topic_dropdown={caseBasedTopics} changeBeginPracticeStatus={props.changeBeginPracticeStatus} selectedTopicsSaved={selectedTopicsSaved} /> : null}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {AssertionAndReasoningTopics.length > 0 ? <TopicListMob topic_dropdown={AssertionAndReasoningTopics} changeBeginPracticeStatus={props.changeBeginPracticeStatus} selectedTopicsSaved={selectedTopicsSaved} /> : null}
        </TabPanel>
      </SwipeableViews>
    </>
  );
}
