import React, { Component } from 'react';
import $ from 'jquery';
import root_url from "../../components/api_url";

export class Endprac extends Component {
    constructor() {
        super();
        this.state = {
            end_practice:[]
        }
        this.reviewPage = this.reviewPage.bind(this);
        this.getEndPrcatice = this.getEndPrcatice.bind(this);

    }

    reviewPage(e){
        document.location.href = document.location.origin + '/review/';
    }

    componentDidMount() {
        $("#end_practice_close").click(function (e) {
            document.location.href = document.location.origin + '/dashboard/';
        })
    }
    getEndPrcatice(){
        let id = localStorage.getItem('practice_id');
         let auth = 'Token '+localStorage.getItem('key');
         let url = root_url+'api/utils/end_practice/?practice_id='+id;

  	    fetch(url,{
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization':auth
            }

                }).then(response=>{
                            if(response.status===200){
                                return response.json();
                            }
                            else{
                                return false;
                            }
                    }).then(response=>{
                        // console.log('End Practice end tab will mount', response.data[0]);
                        this.setState({
                            end_practice:response.data[0],
                        });
                    });
    }
    componentWillMount() {

    }

    render() {
        this.getEndPrcatice();
        let end_practice = this.state.end_practice;
        // console.log("en render", end_practice)
        let score = end_practice['score']?end_practice['score']:localStorage.getItem('total_score');
        let accuracy = end_practice['accuracy']?end_practice['accuracy']:localStorage.getItem('percentage');
        let solve = end_practice['solved']?end_practice['solved']:localStorage.getItem('solved');
        let msg = end_practice['message_option']?end_practice['message_option']:'';
        let minutes = Math.floor(end_practice['time'] / 60);
        let seconds = end_practice['time'] - minutes * 60;
        return (
            <div className="container share-mod share-mod2 share-mod3">
                <div className="modal" id="myModal3">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                            <button type="button" id='end_practice_close' className="close" data-dismiss="modal">
                            <img src={require("./../../../assests/img/cross.jpg")} />
                            </button>
                            <div className="pop2-img"><img src={require("./../../../assests/img/pop-img.jpg")} /></div>
                            <div className="row pt-3">
                                <div className="col">
                                    <div className="divh3">
                                        <h3>{msg}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3 pb-5 score-points">
                                <div className="col-md-6">
                                    <img src={require("./../../../assests/img/pop-icon1.jpg")} /> <span>Score <br/>{score} points</span>
                                </div>
                                <div className="col-md-6">
                                    <img src={require("./../../../assests/img/pop-icon2.jpg")} /> <span>Solve <br/> {solve} questions</span>
                                </div>
                                <div className="col-md-6">
                                    <img src={require("./../../../assests/img/pop-icon3.jpg")} /> <span>Accuracy <br/>You - {accuracy}% <br/>Median - NA</span>
                                </div>
                                <div className="col-md-6">
                                    <img src={require("./../../../assests/img/pop-icon4.jpg")} /> <span>Speed (min:sec) <br/>You - {minutes}:{seconds} <br/>Median - NA</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <div className="common-btn" data-dismiss="modal"><button onClick={this.reviewPage}>Review</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        )
    }
}

export default Endprac