import React from 'react'
import ReactDOM from 'react-dom'
import PrepHub_logo from "./../../assests/img/preb-logo.png";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import root_url from './../components/api_url';
import $ from 'jquery'

export default function LeftSideBar(props) {
    const [goal, setGoal] = useState(localStorage.getItem('goal_name'));
    const [selectedTab, setSelectedTab] = useState('');
    const [fullNameError, setfullNameError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [feedbackSuccess, setFeedbackSuccess] = useState('');
    const [commentsError, setCommentsError] = useState('');
    const [issueType, setIssueType] = useState('Issues in using PrepHub');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [sendMe, setSendMe] = useState('false');
    const [comments, setComments] = useState('');

    useEffect(() => {
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

        $("#menu-toggle1").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
            $("#wrapper").toggleClass("bgblack");
        });

        $("#end_practice_close").click(function (e) {
            document.location.href = document.location.origin + '/dashboard/';
        })
    }, [])

    const changeUserdata = (event) => {
        setFeedbackSuccess("");
        if (event.target.name == "full_name") {
            setFullName(event.target.value)
            if (event.target.value == "") {
                setfullNameError("Invalid. Please enter name");
            }
            else {
                setfullNameError("");
            }
        }
        if (event.target.name == "emails") {
            setContactEmail(event.target.value)
            if (event.target.value == "") {
                setEmailError("Invalid. Please enter email");
            }
            else {
                setEmailError("");
            }
        }
        if (event.target.name == "phone") {
            setContactPhone(event.target.value)
            if (event.target.value == "") {
                setPhoneError("Invalid. Please enter phone number")
            }
            else {
                setPhoneError("")
            }
        }
        if (event.target.name == "issue_type") {
            setIssueType(event.target.value)
        }
        if (event.target.name == "comments") {
            setComments(event.target.value)
            if (event.target.value == "") {
                setCommentsError("Invalid. Please enter comment")
            }
            else {
                setCommentsError("")
            }
        }
    }

    const send_me_check = (e) => {
        setFeedbackSuccess("")
        if (e.target.checked) {
            setSendMe("true")
        }
        else {
            setSendMe("false")
        }
    }

    const sendContactdata = () => {
        let contact_data = {
            full_name: fullName,
            contact: contactPhone,
            email: contactEmail,
            issue_type: issueType,
            comments: comments,
            send_me: sendMe
        };
        if (fullNameError != "" || fullName == "") {
            setfullNameError("Invalid. Please enter name")
        }
        else if (emailError != "" || contactEmail == "") {
            setEmailError("Invalid. Please enter email")
        }
        else if (phoneError != "" || contactPhone == "") {
            setPhoneError("Invalid. Please enter phone")
        }
        else {
            setValidationError("")
            setFeedbackSuccess("Your feedback was sent successfully. Our team will get in touch with you in the next 48 hours.")
            let url = root_url + "Contact_Us/";
            let auth = 'Token ' + localStorage.getItem('key');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': auth

                }, body: JSON.stringify(contact_data)
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return false;
                }
            }).then(response => {
            });
        }
    }

    const confirmEndPracticeStatus = () => {
        if (props.activeTab == 'SamplePapersTest') {
            props.confirmEndPractice();
        }
        else {
            return false
        }
    }

    return (
        <><div className=''>
            <section className="dash-mid" >
                <div id="wrapper" className={`${props.toggled ? 'toggled' : ''}`}>
                    <div id="sidebar-wrapper">
                        <div className="btn-tog"></div>
                        <div className="sidebar-nav perf-bdr">
                            <div className="side-nav">
                                <div className="logo dnone"><img src={PrepHub_logo} /></div>
                                <div className="ham2" id="menu-toggle1"><span>
                                    <img src={require("./../../assests/img/cross-menu.png")} width="15" />
                                </span>
                                </div>
                                <div className="side-select dnone">
                                    <div className="slect-icon"><img src={require("./../../assests/img/side-b-icon0.jpg")} /></div>
                                    <select id="mySelect" data-show-content="true" className="form-control">
                                        <option>{goal}</option>
                                    </select>
                                </div>
                                <div className="lft-side-menu middle-menu">
                                    <ul className="fix-left-nav">
                                        <li className="active"><Link to={`${props.activeTab == 'SamplePapersTest' ? '#' : '/assignments'}`}><img src={require("./../../assests/img/marks.jpg")} onClick={() => { confirmEndPracticeStatus(); }} /><h6><font className={`${props.activeTab == 'Assignments' || props.activeTab == 'SamplePapersTest' ? 'activeTab' : ''}`}>Assignments</font></h6><span className='mt-3'></span></Link></li>
                                        <li><Link to={`${props.activeTab == 'SamplePapersTest' ? '#' : '/dashboard'}`}><img src={require("./../../assests/img/side-icon-2.png")} onClick={() => { confirmEndPracticeStatus(); }} /><h6><font className={`${props.activeTab == 'Practice' ? 'activeTab' : ''}`} >Practice Mode</font></h6></Link></li>
                                        <li><Link to={`${props.activeTab == 'SamplePapersTest' ? '#' : '/perform'}`}><img src={require("./../../assests/img/side-icon-3.png")} onClick={() => { confirmEndPracticeStatus(); }} /><h6><font className={`${props.activeTab == 'Performance' ? 'activeTab' : ''}`}>Performance Management</font></h6></Link></li>
                                        <li data-toggle="modal" data-target="#contact_modal"><Link><img src={require("./../../assests/img/mail.png")} style={{ height: "30px", width: "30px" }} /><h6>Contact Us</h6></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div></div>
                <div className={`row mob-side-nav ${props.activeTab == 'SamplePapersTest' ? 'd-none' : ''}`}>
                    <div className="col">
                        <ul>
                            <li><Link to="/assignments" ><img src={require("./../../assests/img/marks.jpg")} /><font className={`${props.activeTab == 'Assignments' ? 'activeTab' : ''}`}>Assignments</font></Link></li>
                            <li><Link to="/dashboard"><img src={require("./../../assests/img/side-icon-2.png")} />Practice Mode</Link></li>
                            <li><Link to="/perform"><img src={require("./../../assests/img/side-icon-3.png")} />Performance Management</Link></li>

                        </ul>
                    </div>
                </div>
            </section>

        </div>
            <div className="modal fade" id="contact_modal" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body contact_modal-body">
                            <div className="row mt-1 justify-content-between">
                                <h5 class="modal-title text-dark ml-2"><strong>Report / Feedback </strong></h5>
                                <button type="button" className="close" id="instructions_close" data-dismiss="modal" >
                                    <img src={require("./../../assests/img/cross.jpg")} id="close_modal" />
                                </button>
                            </div>
                            <div className="row">
                                <p className="text-dark ml-3">Facing issues in using PrepHub? Let us know your feedback in the form below and our team will get in touch with you in the next 48 hours.</p>
                            </div>
                            <div className="row mt-3">
                                <p className="text-dark ml-3"> Please complete all fields.</p>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <p className="text-danger">{validationError}</p>
                                    <p className="text-success">{feedbackSuccess}</p>

                                    <div className="form-group ">
                                        <label for="exampleFormControlInput1" className="text_color">Name</label>
                                        <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="full_name" placeholder="" value={fullName} onChange={changeUserdata} />
                                        <p className="text-danger">{fullNameError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleFormControlInput1" className="text_color">Email</label>
                                        <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="emails" placeholder="" value={contactEmail} onChange={changeUserdata} />
                                        <p className="text-danger">{emailError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleFormControlInput1" className="text_color">Phone</label>
                                        <input type="email" class="form-control form-control-sm border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="phone" placeholder="" value={contactPhone} onChange={changeUserdata} />
                                        <p className="text-danger">{phoneError}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputState" className="text_color">Issue type</label>
                                        <select id="inputState" class="form-control form-control-sm  border-top-0 border-right-0 border-left-0 border-bottom text-dark" name="issue_type" onChange={changeUserdata} >

                                            <option value="Issues in using PrepHub" className="text-primary" selected>Issues in using PrepHub</option>
                                            <option value="New feature request" className="text-primary">New feature request</option>
                                            <option value="Others" className="text-primary">Others</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1" className="text_color">Comments</label>
                                        <textarea className="form-control  border-top-0 text-dark" id="exampleFormControlTextarea1" rows="3" name="comments" onChange={changeUserdata} />
                                        <p className="text-danger">{commentsError}</p>

                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-between">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="send_me_checkbox" onChange={send_me_check} />
                                    <label className="form-check-label text-dark" for="send_me_checkbox">Send me a copy of my response</label>
                                </div>
                                <div>
                                    <button type="button " className="btn btn-primary  btn-sm" onClick={sendContactdata} >Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}