import React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Clock_logo from "./../../assests/img/pop-icon4.jpg";
import Backdrop from './BackDrop';
import { isMobileOnly } from 'mobile-device-detect';

export default function SamplePaperTestInstruction(props) {
  const [allTopics, setAllTopics] = useState([]);
  const [samplePaperTime, setSamplePaperTime] = useState(0);
  useEffect(() => {
    let arr = []
    let topics_list = JSON.parse(localStorage.getItem('sample_papers_topics_name'));
    let timeOfSamplePaper = localStorage.getItem('samplePaperTimeInMinutes') ? localStorage.getItem('samplePaperTimeInMinutes') : '90'
    setSamplePaperTime(timeOfSamplePaper);
    let topics_length = topics_list.length;
    if (topics_length > 2) {
      if (topics_list.includes('all')) {
        setAllTopics(' All Topics ');
        localStorage.setItem("topic_name_given", 'All');
      }
      else {
        let remaning_topic = topics_length - 2;
        let topics = topics_list[0] + ', ' + topics_list[1] + ' and ' + String(remaning_topic) + ' other';
        setAllTopics(topics);
        localStorage.setItem("topic_name_given", JSON.stringify(topics_list.join()));
      }
    }
    else {
      setAllTopics(topics_list.join());
      localStorage.setItem("topic_name_given", topics_list[0]);
    }
  }, [])



  return (
    <>
      <div className="d-flex flex-column p-4 overflow-auto h-80">
        <div className="font-weight-bold text-dark">
          Practice Test
        </div>
        <div className="mt-3 word-wrap">
          <font className="font-weight-bold text-dark">Topics:</font>&nbsp;&nbsp;<font className="text-dark">{allTopics}</font>
        </div>
        <div className="mt-3">
          <img src={Clock_logo} /> <font className="font-weight-bold text-dark">{`${samplePaperTime} Minutes`} </font>
        </div>
        <div className="border-top mt-3 pb-5">
          <div className="font-weight-bold text-dark mt-3"> Instructions:</div>
          <div className="pt-3 text-dark ">
            <p>1.This paper has 40 questions. </p>
            <p>2.All questions are compulsory.</p>
            <p>3.Each Question carry <b>1 mark</b>.</p>
            <p>4.Answer the question as per given instructions.</p>
          </div>
          <Backdrop openStatus={props.modalOpen} component="start_sample_paper_test" getIntialQuestionSamplepaper={props.getIntialQuestionSamplepaper} />
        </div>
        {props.showstartTestButton==true ?
          <div className="w-100 d-flex justify-content-center pt-4 pb-4">
            {/*<Button variant="contained" sx={{ borderRadius: 28, backgroundColor: "#5d39db", textTransform: 'capitalize', paddingLeft: 5, paddingRight: 5 }} onClick={props.modalStatusChange}>Start Test</Button>*/}
            <button type="button" className="btn text-white " style={{borderRadius: 28, backgroundColor: "#5d39db",paddingLeft: 25, paddingRight: 25,textTransform: 'capitalize'}} onClick={props.modalStatusChange}>Start Test</button>
        </div> : null}
      </div>
    </>
  );
}
