import React from 'react';
import { Timer } from 'react-countdown-clock-timer';
import { isMobileOnly } from 'mobile-device-detect';


export default function CountDownTimer(props) {
  return (
    <div className='timer-container text-black font-weight-bold'>

      <Timer
        durationInSeconds={parseInt(localStorage.getItem("countdown_seconds"))}
        formatted={true}
        isPaused={props.paused}
        showPauseButton={false}
        onStart={() => { }}
        onPause={(remainingDuration) => { }}
        onFinish={() => {
          let id = localStorage.getItem('practice_id');
          if (props.testType == 'samplePaperTest') {
            document.location.href = document.location.origin + `/result-summary?practiceId=${id}`;
          }
          if (props.testType == 'timedTest') {
            if (isMobileOnly) {
              document.location.href = document.location.origin + `/result-summary?practiceId=${id}`;
            }
            else {
              document.getElementById('confirm_end_practice').click()
            }
          }

        }}
        onReset={(remainingDuration) => { }}
        onResume={(remainingDuration) => { }}
      />

    </div>
  )
}