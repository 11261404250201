import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export class ForgotPassword_LinkSent extends Component {  
    render() {  
        return (
            <div>
                <section class="forgot-pass forgot-pass-sent">
                <div class="container">
                    <div class="col-md-5 m-auto">
                        <div class="forgot-p">
                            <div class="row">
                            <div class="col">
                                <div class="close"><img src={require("./../../assests/img/cross.jpg")} /></div>
                                <div class="divh4">
                                    <h4><Link to="login_help">Need help?</Link></h4>
                                </div>
                            </div>
                            </div>
                            <div class="row pt-2">
                            <div class="col">
                                <div class="form-img"><img src={require("./../../assests/img/form-top-img.jpg")} /></div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col">
                                <div class="divh3">
                                    <h3>Forgot Password</h3>
                                </div>
                            </div>
                            </div>
                            <div class="row pt-4 reset-btn">
                            <div class="col">
                                <div class="loginbtn"><button>Reset Password</button></div>
                                <div class="divh4">
                                    <h4><Link to="forgotpassword">Login using OTP</Link></h4>
                                </div>
                            </div>
                            </div>
                            <div class="row pt-3 sent-link">
                            <div class="col">
                                <div class="divh5">
                                    <h5>We have sent reset link to your email xyz@gmail.com</h5>
                                </div>
                                <div class="send-btn">
                                    <h6><Link to="forgotpassword">Send again</Link></h6>
                                </div>
                            </div>
                            </div>
                            <div class="row sign-in pt-5">
                            <div class="col-md-12">
                                <div class="error">Invalid OTP. Please Re-enter</div>
                                <div class="common-btn pt-5"><button><Link to="/">Sign in</Link></button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        )
    }
}

export default ForgotPassword_LinkSent