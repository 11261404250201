import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';


export default function SamplePaperSection(props) {
    const { section_data } = props;
    const [expandedPanel, setExpandedPanel] = useState(false);
    let questions_sequence = []
    let question_data = section_data.all_question_data;
    let review_questions = section_data.reviewQuestion;
    const selectedReviewQuestion = (given_index, given_selected_choice) => {
        props.renderNextQuestion(parseInt(given_index - 1), given_selected_choice);
    }

    for (let i = 0; i < question_data.length; i++) {
        if (question_data[i].question_section == section_data.name) {
            let filtered_obj = review_questions.find(o => o.question === question_data[i].question_id);
            if (filtered_obj !== undefined) {
                if (filtered_obj.status == "skip") {
                    questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i, filtered_obj.choice_selected)}><div className={`sectionQuestionNumberStyle skipInSection ${section_data.QuestionId == filtered_obj.question ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
                }
                if (filtered_obj.is_bookmarked == true && filtered_obj.status == null) {
                    questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i, filtered_obj.choice_selected)}><div className={`sectionQuestionNumberStyle bookmarkedInSection ${section_data.QuestionId == filtered_obj.question ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
                }
                if (filtered_obj.is_bookmarked == true && filtered_obj.status !== null) {
                    questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i, filtered_obj.choice_selected)}><div className={`sectionQuestionNumberStyle answeredAndBookmarkedInSection ${section_data.QuestionId == filtered_obj.question ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
                }
                if (filtered_obj.is_bookmarked == false && filtered_obj.status != "skip" && filtered_obj.status !== null) {
                    questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i, filtered_obj.choice_selected)}><div className={`sectionQuestionNumberStyle answeredInSection ${section_data.QuestionId == filtered_obj.question ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
                }
                if (filtered_obj.status == null && filtered_obj.is_bookmarked == false) {
                    questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i, filtered_obj.choice_selected)}><div className={`sectionQuestionNumberStyle sequentialQuestionInSection ${section_data.QuestionId == filtered_obj.question ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
                }
            }
            else {
                questions_sequence.push(<li key={i} onClick={() => selectedReviewQuestion(i)}><div className={`sectionQuestionNumberStyle notVisitedInSection ${section_data.QuestionId == question_data[i].question_id ? 'activeQuestion' : ''}`}>{i + 1}</div></li>)
            }
        }
    }
    const given_string = (given_string) => {
        let x = given_string
        x = x.substring(0, 7) + " " + x.substring(7, x.length);
        let updated_section_name = capitalizeFirstLetter(x)
        return updated_section_name;
    }

    const capitalizeFirstLetter = (str) => {
        const capitalized = str.replace(/^./, str[0].toUpperCase());
        return capitalized;
    }
    const findCurrentSection = (given_question_id) => {
        let all_question = section_data.all_question_data;
        let filtered_obj = all_question.find(o => o.question_id === parseInt(given_question_id));
        if (filtered_obj !== undefined) {
            let currentSection = filtered_obj.question_section
            setExpandedPanel(currentSection);
        }
        else {
            setExpandedPanel("section1");
        }
    }

    useEffect(() => {
        findCurrentSection(section_data.QuestionId);
    }, [section_data]);

    const handleAccordionChange = (section) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? section : false);
    };
    return (
        <>

            <div className="d-flex flex-column  mb-3  pr-2">
                <Accordion expanded={expandedPanel === section_data.name} onChange={handleAccordionChange(section_data.name)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography><div className="pl-1">{given_string(section_data.name)}</div></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <ul className="mt-n4">{questions_sequence}</ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
