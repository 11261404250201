import React, { Component } from 'react';

export default function ReviewRightSidePanel(props) {
    
    return (
        <>
         {props.right_side_panel}
        </>
        
    );
}
